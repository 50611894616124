import { BackHeader } from 'components/screens/reports/BackHeader';
import PostInterventionQCCard from 'components/screens/reports/PostInterventionQcCard';
import SectionHeader from 'components/screens/reports/SectionHeader';
import {
  DROP_IN_PERFORMANCE_VARIANT,
  IMPROVED_VARIANT,
  SAME_AS_BEFORE_VARIANT,
} from 'configs/reports';
import { ConnectionContext, useConnectionContext } from 'contexts/ConnectionContext';
import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Connection, ImprovementVariant, InterventionMetricsEntity } from 'types/reports';

const PostInterventionQc = () => {
  const { chapterId, categoryId: categoryRoute, studentId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [connections, setConnections] = useState<Connection[]>([]);
  const [svgDimensions, setSvgDimensions] = useState({ width: 0, height: 0 });
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const { postInterventionRef, prePostQcGapCompData } = useConnectionContext();

  const containerRef = useRef<HTMLDivElement>(null);
  const postInterventionQCRefs = useRef<Array<HTMLDivElement | null>>([]);
  const isAdminRoute = location.pathname.startsWith('/a');
  const prefix = isAdminRoute ? '/a' : '/p';
  const handleCardClick = (categoryIndex: number, categoryId: string, selectedStatus: string) => {
    setSelectedIndex(categoryIndex);
    setSelectedStatus(selectedStatus);

    if (categoryRoute) {
      navigate(
        `${prefix}/reports/${studentId}/chapter/intervention/${chapterId}/pre-intervention/details/category/${categoryId}/details/questions`,
      );
    } else
      navigate(
        `${prefix}/reports/${studentId}/chapter/intervention/${chapterId}/pre-intervention/details/category/${categoryId}/details`,
      );
  };

  const getImprovementStatus = (preRating: number, postRating: number) => {
    if (postRating > preRating) return IMPROVED_VARIANT;
    if (postRating < preRating) return 'drop in performance';
    return 'same as before';
  };

  const toImprovementVariant = (status: string): ImprovementVariant => {
    const normalized = status.trim().toLowerCase();
    if (normalized === IMPROVED_VARIANT) return IMPROVED_VARIANT;
    if (normalized === 'drop in performance') return DROP_IN_PERFORMANCE_VARIANT;
    return SAME_AS_BEFORE_VARIANT;
  };

  const getDotColor = (variant: ImprovementVariant) => {
    switch (variant) {
      case IMPROVED_VARIANT:
        return '#16A34A';
      case SAME_AS_BEFORE_VARIANT:
        return '#F97316';
      default:
        return '#DC2626';
    }
  };

  const getInterventionData = (metrics: InterventionMetricsEntity) => ({
    questionsAsked: metrics.questionsAsked,
    answeredCorrectly: metrics.questionsAnsweredCorrectly,
    answeredPercentage: Math.round(
      (metrics.questionsAnsweredCorrectly / metrics.questionsAsked) * 100,
    ),
    correctSteps: metrics.correctSteps,
    correctStepsPercentage: Math.round((metrics.correctSteps / metrics.totalSteps) * 100),
    totalSteps: metrics.totalSteps,
  });

  useEffect(() => {
    const calculateConnections = () => {
      if (!containerRef.current || !postInterventionRef?.current || !prePostQcGapCompData) return;

      const containerRect = containerRef.current.getBoundingClientRect();
      const postInterventionRect = postInterventionRef.current.getBoundingClientRect();
      const newConnections: Connection[] = [];

      postInterventionQCRefs.current.forEach((postInterventionQCRef, index) => {
        const intervention = prePostQcGapCompData[index];
        if (!postInterventionQCRef || !intervention) return;

        const postInterventionQCRect = postInterventionQCRef.getBoundingClientRect();

        const startX = postInterventionRect.right - containerRect.left;
        const startY = postInterventionRect.top + 32 - containerRect.top;
        const endX = postInterventionQCRect.left - containerRect.left;
        const endY =
          postInterventionQCRect.top + postInterventionQCRect.height / 2 - containerRect.top;

        const curveWidth = 40;
        const curveRadius = 30;
        const yDifference = Math.abs(endY - startY);
        const isDescending = endY > startY;
        const minLineHeight = 40; // Ensure a minimum height for the line

        const isNearlyHorizontal = yDifference < minLineHeight;

        let startCurve, endCurve, line;

        if (isNearlyHorizontal) {
          startCurve = ``;

          endCurve = `
            M ${endX - curveWidth} ${endY}
            H ${endX}
          `;

          line = {
            top: startY - 1,
            left: startX + curveWidth - 1,
            height: 2,
          };
        } else {
          startCurve = `
            M ${startX} ${startY}
            H ${startX + curveWidth - curveRadius}
            Q ${startX + curveWidth} ${startY},
              ${startX + curveWidth} ${startY + (isDescending ? curveRadius : -curveRadius)}
            V ${isDescending ? endY - curveRadius : endY + curveRadius}
          `;

          endCurve = `
            M ${endX - curveWidth} ${isDescending ? endY - curveRadius : startY - curveRadius}
            V ${endY - (isDescending ? curveRadius : -curveRadius)}
            Q ${endX - curveWidth} ${endY},
              ${endX - curveWidth + curveRadius} ${endY}
            H ${endX}
          `;

          line = {
            top: Math.min(
              startY + (isDescending ? curveRadius - 1 : 0),
              endY + (isDescending ? 0 : curveRadius - 1),
            ),
            left: startX + curveWidth - 1,
            height: Math.abs(endY - startY) - curveRadius * 2 + 2,
          };
        }

        newConnections.push({
          startCurve,
          endCurve,
          line,
          startDot: { x: startX, y: startY },
          endDot: { x: endX, y: endY },
          dotColor: getDotColor(
            toImprovementVariant(
              getImprovementStatus(intervention.preRating, intervention.postRating),
            ),
          ),
        });
      });

      setConnections(newConnections);
      setSvgDimensions({
        width: containerRect.width,
        height: containerRect.height,
      });
    };
    if (postInterventionQCRefs?.current && prePostQcGapCompData) {
      calculateConnections();
      const observer = new ResizeObserver(calculateConnections);
      if (containerRef.current) {
        observer.observe(containerRef.current);
      }
      if (postInterventionRef?.current) {
        observer.observe(postInterventionRef.current);
      }

      window.addEventListener('resize', () => {
        calculateConnections();
      });

      return () => {
        observer.disconnect();
        window.removeEventListener('resize', calculateConnections);
      };
    }
  }, [postInterventionRef, prePostQcGapCompData]);

  const connectionContextValue = {
    selectedPostInterventionQcRef:
      selectedIndex !== null ? { current: postInterventionQCRefs.current[selectedIndex] } : null,
    postInterventionQCRefs,
    selectedStatus,
    prePostQcGapCompData,
  };

  return (
    <ConnectionContext.Provider value={connectionContextValue}>
      <div className='flex flex-col w-full relative'>
        <BackHeader
          title='Post Intervention QC Level Understanding'
          route={`${prefix}/reports/${studentId}/chapter/intervention/${chapterId}/pre-intervention/details`}
        />

        <div className='flex md:gap-20 relative z-0 flex-col md:flex-row' ref={containerRef}>
          <div className={`flex flex-col w-full md:w-[380px] `}>
            <div className='hidden md:block'>
              <SectionHeader
                text='Post Intervention QC Level Understanding'
                textColor='semantic-warning-800'
                backgroundColor='from-[#EFDD81] to-[#F2E6AE]'
              />
            </div>

            <div className='space-y-4 w-full mt-3 md:mt-0 md:w-[380px]'>
              {prePostQcGapCompData
                ? prePostQcGapCompData.map((intervention, index) => (
                    <div
                      key={`${intervention.chapterId}-${intervention?.categoryNumber}`}
                      ref={(el) => (postInterventionQCRefs.current[index] = el)}
                    >
                      <PostInterventionQCCard
                        idx={intervention?.categoryNumber}
                        categoryTitle={intervention.categoryTitle}
                        preInterventionRating={intervention.preRating}
                        postInterventionRating={intervention.postRating}
                        improvementStatus={getImprovementStatus(
                          intervention.preRating,
                          intervention.postRating,
                        )}
                        remarks={intervention.remarkMessage}
                        className='bg-white hover:shadow-lg transition-shadow cursor-pointer'
                        isSelected={true}
                        onClick={() =>
                          handleCardClick(
                            index,
                            intervention.categoryId,
                            getImprovementStatus(intervention.preRating, intervention.postRating),
                          )
                        }
                        isInDepth={categoryRoute ? true : false}
                        // Pass intervention data for mobile view
                        mobileViewData={
                          categoryRoute && intervention.categoryId === categoryRoute
                            ? {
                                preIntervention: getInterventionData(intervention.preIntervention),
                                postIntervention: getInterventionData(
                                  intervention.postIntervention,
                                ),
                                title: intervention.categoryTitle,
                              }
                            : undefined
                        }
                      />
                    </div>
                  ))
                : null}
            </div>
          </div>

          <div className='transition-opacity duration-300 w-full hidden md:block'>
            <Outlet />
          </div>
        </div>
        <div className='hidden md:block absolute inset-0 pointer-events-none'>
          <svg
            className='absolute top-0 left-0'
            width={svgDimensions.width}
            height={svgDimensions.height}
            style={{ overflow: 'visible' }}
          >
            {connections.map((conn, index) => (
              <g key={`curves-${index}`}>
                {/* Connection Curves */}
                <path
                  d={conn.startCurve}
                  fill='none'
                  stroke='#B4B6C5'
                  strokeWidth='2'
                  strokeLinecap='round'
                  className='transition-all duration-300'
                />
                <path
                  d={conn.endCurve}
                  fill='none'
                  stroke='#B4B6C5'
                  strokeWidth='2'
                  strokeLinecap='round'
                  className='transition-all duration-300'
                />
                {/* Start Half-Cycle Dot */}
                <path
                  d={`M ${conn.startDot.x},${conn.startDot.y - 5} 
                A 5,5 0 1,1 ${conn.startDot.x},${conn.startDot.y + 5}`}
                  fill='#148D8A'
                />
                {/* End Half-Cycle Dot */}
                <path
                  d={`M ${conn.endDot.x},${conn.endDot.y - 5} 
                A 5,5 0 1,0 ${conn.endDot.x},${conn.endDot.y + 5}`}
                  fill={conn.dotColor}
                />
              </g>
            ))}
          </svg>
        </div>
      </div>
    </ConnectionContext.Provider>
  );
};

export default PostInterventionQc;
