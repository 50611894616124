import { useDyteMeeting, useDyteSelector } from '@dytesdk/react-web-core';

import { CONFIG, HOST_PRESET_ID, PRESET_TYPE } from 'configs';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetClassJoinTokenQuery } from 'store/apiSlices/classes.apiSlice';

const useMeeting = () => {
  // Getting the participants
  const studentsWithOutSelf = useDyteSelector((meeting) => {
    return meeting?.participants?.joined?.toArray()?.filter((participant) => {
      return (
        participant.presetName !== PRESET_TYPE.TUTOR &&
        participant.presetName !== PRESET_TYPE.PARENT
      );
    });
  });

  // Getting the students
  const students = useDyteSelector((meeting) => {
    const selfParticipant = meeting?.self;
    return [
      ...(meeting?.participants?.joined?.toArray()?.filter((participant) => {
        return (
          participant.presetName !== PRESET_TYPE.TUTOR &&
          participant.presetName !== PRESET_TYPE.PARENT
        );
      }) || []),
      ...(selfParticipant ? [selfParticipant] : []),
    ].flat();
  });

  // Getting the tutors
  const tutors = useDyteSelector((meeting) => {
    return meeting?.participants?.joined?.toArray()?.filter((participant) => {
      return participant.presetName === PRESET_TYPE.TUTOR;
    });
  });

  const participants = useDyteSelector((meeting) => meeting?.participants?.joined);

  // Student count
  const studentsCount = useDyteSelector((meeting) => {
    const selfParticipant = meeting?.self;
    return [
      ...(meeting?.participants?.joined?.toArray()?.filter((participant) => {
        return (
          participant.presetName !== PRESET_TYPE.TUTOR &&
          participant.presetName !== PRESET_TYPE.PARENT
        );
      }) || []),
      ...(selfParticipant ? [selfParticipant] : []),
    ].flat().length;
  });

  // Getting the self participant
  const self = useDyteSelector((meeting) => meeting?.self);

  // Getting the screen share host
  const screenShareHost = useDyteSelector((meeting) => {
    return meeting?.self?.screenShareEnabled
      ? meeting?.self
      : meeting?.participants?.joined?.toArray()?.find((p) => p?.screenShareEnabled);
  });

  // Checking if screen share is enabled
  const screenShareEnabled = useDyteSelector((meeting) => {
    return (
      !!meeting?.participants?.joined
        ?.toArray()
        ?.find((participant) => participant?.screenShareEnabled) ||
      meeting?.self?.screenShareEnabled
    );
  });

  // Active plugins
  const activePlugins = useDyteSelector((meeting) => meeting?.plugins?.active?.toArray());

  //All plugins
  const allPlugins = useDyteSelector((meeting) => meeting?.plugins?.all?.toArray());

  // Getting the logged in user
  const loggedInUser =
    self.presetName === 'group_call_host' ? PRESET_TYPE.TUTOR : PRESET_TYPE.STUDENT;

  const socketUser = self.presetName === 'group_call_host' ? 'tutor' : 'student';

  const state = useDyteSelector((meeting) => meeting?.self?.roomState);

  // Checking if the user is in the main room
  const isSelfInMainRoom = useDyteSelector((meeting) => {
    if (!meeting?.connectedMeetings?.parentMeeting) return true;
    const { connectedMeetings } = meeting;
    if (
      connectedMeetings?.parentMeeting?.participants?.find(
        (participant) => participant.customParticipantId === self?.customParticipantId,
      )
    ) {
      return true;
    }
    return false;
  });

  const meetTitle = useDyteSelector((meeting) => meeting?.meta?.meetingTitle);

  // Checking for classState
  const classState = useMemo(() => {
    if (isSelfInMainRoom) {
      return { type: CONFIG.ROOMS.TEACH, level: null };
    }
    return { type: CONFIG.ROOMS.COACH, level: meetTitle };
  }, [isSelfInMainRoom, meetTitle]);

  const { id } = useParams();
  const authToken = String(id);

  // API Call -> Get the meeting token
  const { data: JoinClassRoomData } = useGetClassJoinTokenQuery(
    {
      authToken,
    },
    {
      skip: !authToken,
    },
  );

  const meetingEndTime = JoinClassRoomData?.data?.meetingEndTime;

  const classId = JoinClassRoomData?.data?.classId;

  const studentId = JoinClassRoomData?.data?.studentId;

  const batchId = JoinClassRoomData?.data?.batchId;

  const batchName = JoinClassRoomData?.data?.name;

  const { meeting } = useDyteMeeting();

  const loginTempToken = JoinClassRoomData?.data?.loginTempToken;

  const meetingStartTime = JoinClassRoomData?.data?.meetingStartTime;

  const studentName = meeting?.self?.name;

  const isTutorInMainRoom = useDyteSelector((meeting) => {
    if (!meeting) return false;
    const { connectedMeetings } = meeting;
    if (
      connectedMeetings?.parentMeeting?.participants?.find(
        (participant) => participant.presetId === HOST_PRESET_ID,
      )
    ) {
      return true;
    }
    return false;
  });

  const tutorName = tutors && tutors.length > 0 ? tutors[0].name : '';

  const classStateForTutor = useMemo(() => {
    if (isTutorInMainRoom) {
      return { type: CONFIG.ROOMS.TEACH, level: '', id: '' };
    }
    const tutorInMeeting = meeting?.connectedMeetings?.meetings.find(
      (meeting) =>
        !!meeting.participants.find((participant) => participant.presetId === HOST_PRESET_ID),
    );
    if (tutorInMeeting) {
      return { type: 'coach', title: tutorInMeeting.title, id: tutorInMeeting.id };
    }
  }, [isTutorInMainRoom, meeting.connectedMeetings.meetings]);

  const tutorId = tutors && tutors.length > 0 ? tutors[0].id : '';

  const isHostPresent = useMemo(() => {
    return !!meeting?.participants?.joined
      ?.toArray()
      ?.find((p) => p.presetName === 'group_call_host');
  }, [meeting?.participants?.joined]);

  // Getting the audio and video status
  const audioStatus = useDyteSelector((meeting) => meeting?.self?.audioEnabled) ?? false;
  const videoStatus = useDyteSelector((meeting) => meeting?.self?.videoEnabled) ?? false;

  return {
    tutorName,
    studentsWithOutSelf,
    students,
    tutors,
    studentsCount,
    self,
    screenShareHost,
    screenShareEnabled,
    activePlugins,
    loggedInUser,
    socketUser,
    state,
    classState,
    classId,
    studentId,
    meeting,
    loginTempToken,
    meetingStartTime,
    studentName,
    allPlugins,
    participants,
    classStateForTutor,
    isTutorInMainRoom,
    tutorId,
    isHostPresent,
    audioStatus,
    videoStatus,
    batchId,
    batchName,
    meetingEndTime,
  };
};

export default useMeeting;
