import React from 'react';
import classNames from 'classnames';
import { PostInterventionCardProps } from 'types/reports';
import { RedirectIcon, StarIcon } from 'assets/svg';
import { CATEGORY_CONFIG } from 'configs/reports';

const PostInterventionCard: React.FC<PostInterventionCardProps> = ({
  preInterventionCounts,
  postInterventionCounts,
  totalCategories,
  onClick,
  className,
  isSelected = true,
}) => {
  return (
    <div
      className={classNames(
        'relative bg-white rounded-[12px] px-[12px] lg:w-[498px] lg:h-[478px]',
        isSelected && 'md:border-[2px] border-teal-200',
        className,
      )}
      onClick={onClick}
    >
      <div className='absolute top-4 bottom-4 left-[-2px] w-1 rounded-r bg-semantic-green-500 md:w-[4px] md:bg-teal-500' />
      <div className='flex justify-between pt-[12px]'>
        <p className='font-semibold font-sora text-sm'>
          Post-Intervention{' '}
          <span className='text-neutral-400 font-sora font-semibold text-sm'>
            ({totalCategories} categories)
          </span>
        </p>
        <img src={RedirectIcon} alt='Right Arrow' />
      </div>
      <div className='lg:border border-neutral-50 rounded-[12px] lg:h-[417px] mt-[12px]'>
        <div className='hidden bg-[#EDF9FF] h-[42px] rounded-t-[12px] pr-[12px] lg:flex items-center justify-end gap-[54px]'>
          <span className='font-medium font-sora text-sm'>Pre-Intervention</span>
          <span className='font-medium font-sora text-sm'>Post-Intervention</span>
        </div>
        {CATEGORY_CONFIG.map((category) => (
          <div
            key={category.key}
            className='py-[16px] lg:px-[20px] lg:flex lg:justify-between border-b border-neutral-50 last:border-none'
          >
            <div className='w-full lg:flex lg:justify-between lg:items-start'>
              <div className='flex flex-col gap-[4px] lg:w-[250px]'>
                {category.rating > 0 && (
                  <div className='flex'>
                    {Array.from({ length: category.rating }).map((_, index) => (
                      <img
                        key={index}
                        src={StarIcon}
                        alt='Star'
                        className='w-5 h-5 mr-1 last:mr-0'
                      />
                    ))}
                  </div>
                )}
                <p className='font-semibold text-sm font-sora'>{category.title}</p>
              </div>
              <div className='bg-[#FAFAFC] lg:bg-transparent mt-[8px] rounded-[8px] lg:mt-0 lg:flex-1 p-[12px] flex gap-[20px] lg:justify-end'>
                <div className='w-fit lg:w-[100px]'>
                  <p className='text-sm lg:hidden mb-2 font-sora'>Pre-Intervention</p>
                  <div
                    className={classNames(
                      'inline-flex px-[6px] py-[1px] rounded-[24px] w-fit',
                      category.preClass,
                    )}
                  >
                    <p className='text-xs font-sora'>
                      <strong>{preInterventionCounts[category.key]}</strong>/{totalCategories}
                    </p>
                  </div>
                </div>
                <div className='w-fit lg:w-[100px]'>
                  <p className='text-sm lg:hidden mb-2 font-sora'>Post-Intervention</p>
                  <div
                    className={classNames(
                      'inline-flex px-[6px] py-[1px] rounded-[24px] w-fit',
                      category.postClass,
                    )}
                  >
                    <p className='text-xs font-sora'>
                      <strong>{postInterventionCounts[category.key]}</strong>/{totalCategories}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PostInterventionCard;
