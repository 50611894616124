import moment from 'moment';
import { memo } from 'react';

import { TimeDisplayProps } from 'types/inClass/PreClassScreen';

import CountdownTimer from '../CountDownTimer';

export const TimeDisplay = memo(({ classStartTime, isEnded }: TimeDisplayProps) => {
  moment.tz?.setDefault('Asia/Kolkata');
  return (
    <div className='space-y-4 text-center'>
      {/* Title and Date */}
      <div>
        <h2 className='mb-2 text-xl font-semibold text-white font-sora md:text-3xl'>
          {isEnded ? 'Class was at ' : 'Class Starts at '}
          {moment(classStartTime).format('h:mm A')}
        </h2>
        <p className='text-lg text-gray-400 font-poppins md:text-lg'>
          {moment(classStartTime).format('dddd, MMMM D, YYYY')}
        </p>
      </div>

      {/* Status Section */}
      {isEnded ? (
        <>
          <p className='text-xl text-red-400'>Class has ended</p>
          <p className='max-w-md mx-auto text-base text-gray-400'>
            This class has already concluded. Check your upcoming classes for new sessions.
          </p>
        </>
      ) : (
        <>
          <CountdownTimer date={moment(classStartTime).toDate()} />
          <p className='max-w-md mx-auto text-base text-gray-400'>
            Your class will begin shortly. You will be able to join once class starts.
          </p>
        </>
      )}
    </div>
  );
});

TimeDisplay.displayName = 'TimeDisplay';
