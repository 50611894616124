import React from 'react';
import classNames from 'classnames';
import { ImprovementVariant, PostInterventionQCCardProps } from 'types/reports';
import Tag from '../Tag';
import { getImprovementIcon, getStatusLabel } from 'configs/studentReport';
import { RedirectIcon, StarIcon } from 'assets/svg';
import InDepthQcCard from '../InDepthQcCard';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DROP_IN_PERFORMANCE_VARIANT,
  IMPROVED_VARIANT,
  SAME_AS_BEFORE_VARIANT,
} from 'configs/reports';

const PostInterventionQCCard: React.FC<PostInterventionQCCardProps> = ({
  idx,
  isSelected = false,
  categoryTitle,
  preInterventionRating,
  postInterventionRating,
  improvementStatus,
  onClick,
  isInDepth = false,
  className,
  mobileViewData,
}) => {
  const { studentId, chapterId, categoryId } = useParams();
  const navigate = useNavigate();
  const isAdminRoute = location.pathname.startsWith('/a');
  const prefix = isAdminRoute ? '/a' : '/p';

  const preRatingArray = Array.from({ length: preInterventionRating }, (_, i) => i);
  const postRatingArray = Array.from({ length: postInterventionRating }, (_, i) => i);
  const toImprovementVariant = (status: string): ImprovementVariant => {
    const normalized = status.trim().toLowerCase();
    if (normalized === IMPROVED_VARIANT) return IMPROVED_VARIANT;
    if (normalized === 'drop in performance') return DROP_IN_PERFORMANCE_VARIANT;
    return SAME_AS_BEFORE_VARIANT;
  };

  const variant = toImprovementVariant(improvementStatus);

  const getTagColor = (variant: ImprovementVariant) => {
    switch (variant) {
      case IMPROVED_VARIANT:
        return 'secondary';
      case SAME_AS_BEFORE_VARIANT:
        return 'accent-orange';
      default:
        return 'error';
    }
  };

  const handleInternalCardClick = () => {
    navigate(
      `${prefix}/reports/${studentId}/chapter/intervention/${chapterId}/pre-intervention/details/category/${categoryId}/details/questions`,
    );
  };

  return (
    <div
      className={classNames(
        'relative bg-white rounded-lg p-4 flex flex-col border',
        isSelected && 'border-solid',
        isSelected && getTagColor(variant),
        variant === IMPROVED_VARIANT &&
          'before:absolute before:top-3 before:h-[calc(100%-24px)] before:left-0 before:w-1 before:bg-green-500 before:rounded-r border-green-200',
        variant === DROP_IN_PERFORMANCE_VARIANT &&
          'before:absolute before:top-3 before:h-[calc(100%-24px)] before:left-0 before:w-1 before:bg-red-600 before:rounded-r border-red-200',
        variant === SAME_AS_BEFORE_VARIANT &&
          'before:absolute before:top-3 before:h-[calc(100%-24px)] before:left-0 before:w-1 before:bg-orange-400 before:rounded-r border-orange-200',
        className,
      )}
      onClick={onClick}
    >
      {/* Header */}
      <div className='mb-3'>
        <div className='flex items-center justify-between mb-1'>
          <span className='text-neutral-500 text-sm font-sora font-semibold'>Category {idx}</span>
          <img src={RedirectIcon} alt='redirect icon' className='w-5 h-5' />
        </div>
        <h3 className='text-neutral-950 text-sm font-sora font-medium'>{categoryTitle}</h3>
      </div>

      {/* Content */}
      <div className='flex flex-col gap-4 bg-[#FAFBFB] p-4 rounded-lg relative'>
        {/* Divider Line */}
        <div className='absolute top-2 h-16 left-[calc(50%-1px)] w-px bg-neutral-100'></div>

        {/* Star rating row */}
        <div className='flex justify-between gap-4'>
          <div className='flex flex-col gap-3'>
            <span className='text-neutral-950 text-xs font-sora font-normal'>Pre-Intervention</span>
            <div className='flex'>
              {preRatingArray.map((_, i) => (
                <img key={`pre-star-${i}`} src={StarIcon} alt='Star' className='w-6 h-6' />
              ))}
            </div>
          </div>

          <div className='flex flex-col gap-3'>
            <span className='text-neutral-950 text-xs font-sora font-normal'>
              Post-Intervention
            </span>
            <div className='flex'>
              {postRatingArray.map((_, i) => (
                <img key={`post-star-${i}`} src={StarIcon} alt='Star' className='w-6 h-6' />
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className='flex flex-col items-start mt-3'>
        <div className='flex items-center gap-1'>
          <span className='font-sora font-medium text-neutral-500 text-sm tracking-wide'>
            Improvement Status:
          </span>
          <Tag
            text={getStatusLabel(variant)}
            iconLeft={getImprovementIcon(variant)}
            className={classNames('bordered', getTagColor(variant))}
          />
        </div>
      </div>

      {isInDepth && mobileViewData && (
        <div className='mt-3 md:hidden'>
          <InDepthQcCard
            mobile={true}
            title={mobileViewData.title}
            preIntervention={mobileViewData.preIntervention}
            postIntervention={mobileViewData.postIntervention}
            onClick={handleInternalCardClick}
            variant={variant}
          />
        </div>
      )}
    </div>
  );
};

export default PostInterventionQCCard;
