import {
  CategoryBasedChapterClassDetailsReponse,
  CategoryBasedExamStatusReport,
  CategoryBasedInterventionReport,
  CategoryBasedReport,
  CategoryBasedReportRequest,
  ChapterWiseStepLevelData,
  ChapterWiseStepLevelDataRequest,
  ExamChaptersLatestQcGapStatusResponse,
  examStatusReportRequest,
  IViewRevisionPlanResponse,
  PracticeEvaluationRequest,
  practiceSheetResponse,
  QcGapReportRequest,
  RegularInterventionGapComparisonResponse,
} from 'types/reports';
import apiSlice from '.';
import QueryString from 'qs';

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCategoryBasedReports: builder.query<CategoryBasedReport, CategoryBasedReportRequest>({
      query: ({ studentId, subjectId }) => ({
        url: `v2/reports/qc-gap/${studentId}?` + QueryString.stringify({ subjectId }),
        method: 'GET',
      }),
    }),

    getCategoryBasedInterventionReports: builder.query<
      CategoryBasedInterventionReport,
      CategoryBasedReportRequest
    >({
      query: ({ studentId, subjectId }) => ({
        url: `v2/reports/qc-gap/intervention/${studentId}?` + QueryString.stringify({ subjectId }),
        method: 'GET',
      }),
    }),

    getCategoryBasedExamStatusReports: builder.query<
      CategoryBasedExamStatusReport,
      CategoryBasedReportRequest
    >({
      query: ({ studentId, subjectId }) => ({
        url:
          `v2/reports/exam-status/${studentId}/completed-chapters?` +
          QueryString.stringify({ subjectId }),
        method: 'GET',
      }),
    }),

    getRegularAndInterventionQcGapsComparison: builder.query<
      RegularInterventionGapComparisonResponse,
      QcGapReportRequest
    >({
      query: ({ studentId, subjectId, chapterId }) => ({
        url:
          `v2/reports/qc-gap/intervention/${studentId}/qc-gap-comparison?` +
          QueryString.stringify({ subjectId, chapterId }),
        method: 'GET',
      }),
    }),

    getChapterStepLevelAnswersData: builder.mutation<
      ChapterWiseStepLevelData,
      ChapterWiseStepLevelDataRequest
    >({
      query: (body) => ({
        url: 'v2/reports/qc-gap/chapter-wise-step-level-answers',
        method: 'POST',
        body,
      }),
    }),

    getCategoryBasedChaptersClassDetails: builder.query<
      CategoryBasedChapterClassDetailsReponse,
      examStatusReportRequest
    >({
      query: ({ studentId, subjectIds, chapterIds }) => ({
        url:
          `v2/reports/exam-status/${studentId}/class-details?` +
          QueryString.stringify({ subjectIds, chapterIds }),
        method: 'GET',
      }),
    }),

    getExamChaptersLatestEvaluatedQcGap: builder.query<
      ExamChaptersLatestQcGapStatusResponse,
      examStatusReportRequest
    >({
      query: ({ studentId, subjectIds, chapterIds }) => ({
        url:
          `v2/reports/exam-status/${studentId}/qc-gap-status?` +
          QueryString.stringify({ subjectIds, chapterIds }),
        method: 'GET',
      }),
    }),

    getExamChaptersRevisionPlan: builder.query<IViewRevisionPlanResponse, examStatusReportRequest>({
      query: ({ studentId, subjectIds, chapterIds }) => ({
        url:
          `v2/reports/exam-status/${studentId}/revision-plan?` +
          QueryString.stringify({ subjectIds, chapterIds }),
        method: 'GET',
      }),
    }),

    getPracticeSheetEvaluationDetails: builder.query<
      practiceSheetResponse,
      PracticeEvaluationRequest
    >({
      query: ({ studentId, practiceSheets }) => {
        const queryString = QueryString.stringify({ practiceSheets });
        return {
          url: `v2/reports/qc-gap/practice-sheet/${studentId}?${queryString}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetCategoryBasedReportsQuery,
  useGetChapterStepLevelAnswersDataMutation,
  useGetPracticeSheetEvaluationDetailsQuery,
  useGetCategoryBasedInterventionReportsQuery,
  useGetRegularAndInterventionQcGapsComparisonQuery,
  useGetCategoryBasedExamStatusReportsQuery,
  useGetCategoryBasedChaptersClassDetailsQuery,
  useGetExamChaptersLatestEvaluatedQcGapQuery,
  useGetExamChaptersRevisionPlanQuery,
} = extendedApiSlice;
