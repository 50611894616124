import { datadogLogs } from '@datadog/browser-logs';
import { Button } from 'components/common';
import { Label } from 'flowbite-react';
import { useCallback, useState } from 'react';
import { CameraInUseErrorProps } from 'types';
import { getCameraInUseInstructions } from 'utils';

export default function CameraInUseError({
  deviceType,
  onPermissionGranted,
  joinMeeting,
}: CameraInUseErrorProps) {
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [previousCameraAvailable, setPreviousCameraAvailable] = useState<boolean>(false);

  const handleCameraAvailable = useCallback(() => {
    setIsRefreshing(true);
    onPermissionGranted();
  }, [onPermissionGranted]);

  const checkCameraAvailability = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: { ideal: 640 },
          height: { ideal: 480 },
        },
      });

      // Verify stream is valid and has video tracks
      if (stream && stream.getVideoTracks().length > 0) {
        // Clean up stream after verification
        stream.getTracks().forEach((track) => track.stop());

        if (!previousCameraAvailable) {
          setPreviousCameraAvailable(true);
          handleCameraAvailable();
          setIsLoading(false);
          return true;
        }
      }
      setIsLoading(false);
      return false;
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Unknown error';
      datadogLogs.logger.warn('Camera availability check failed', {
        type: 'Camera In Use',
        error: errorMessage,
        stack: error instanceof Error ? error.stack : undefined,
      });
      setIsLoading(false);
      return false;
    }
  }, [previousCameraAvailable, handleCameraAvailable]);

  const handleTryAgain = async () => {
    try {
      setIsLoading(true);
      const isAvailable = await checkCameraAvailability();
      if (isAvailable) {
        window.location.reload();
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  if (isRefreshing) {
    return (
      <div className='w-[250px] lg:w-[400px] h-96 font-poppins rounded-xl bg-primary-850 border border-primary-700 p-6'>
        <div className='flex flex-col flex-1 h-full gap-3'>
          <h2 className='text-base text-white lg:text-lg'>Camera is now available!</h2>

          <div className='p-4 mb-6 bg-primary-800 rounded-xl'>
            <div className='flex items-center gap-3'>
              <div className='flex items-center justify-center w-6 h-6 text-white rounded-full bg-semantic-green-500'>
                ✓
              </div>
              <p className='text-primary-300'>Camera access allowed</p>
            </div>
            <div className='flex items-center gap-3 mt-4 text-primary-300'>
              <div className='w-5 h-5 border-2 rounded-full animate-spin border-primary-400 border-t-transparent' />
              Refreshing page...
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='w-[250px] lg:w-[400px] h-96 font-poppins rounded-xl bg-primary-850 border border-primary-700 p-6'>
      <div className='flex flex-col justify-between flex-1 h-full gap-3'>
        <h2 className='text-base text-white lg:text-lg'>
          Camera is being used by another application
        </h2>

        <div className='flex-1 p-5 bg-primary-800 rounded-xl'>
          <ol className='space-y-4'>
            {getCameraInUseInstructions(deviceType).map((instruction, index) => (
              <li key={index} className='flex items-start gap-3'>
                <Label className='flex items-center justify-center px-2 py-1 text-[7px] lg:text-xs font-medium text-white rounded-full bg-primary-500'>
                  {index + 1}
                </Label>
                <span className='text-[8px] lg:text-xs text-primary-300'>{instruction}</span>
              </li>
            ))}
          </ol>
        </div>

        <div className='flex items-end justify-end flex-1 h-full gap-3'>
          <Button
            onClick={handleTryAgain}
            isLoading={isLoading}
            text='Try Again'
            className='flex-1 text-xs text-center text-white capitalize transition-colors border-2 rounded-lg lg:text-sm bg-secondary-500'
          />
          <Button
            text='Join Anyway'
            onClick={() => joinMeeting({ isMediaTest: false })}
            className='flex-1 text-xs text-center text-white capitalize transition-colors border-2 rounded-lg lg:text-sm bg-semantic-green-500'
          />
        </div>
      </div>
    </div>
  );
}
