/* eslint-disable react-hooks/exhaustive-deps */
import { RightAngularIcon } from 'assets/svg';
import { BackHeader } from 'components/screens/reports/BackHeader';
import MobileFooter from 'components/screens/reports/MobileFooter';
import SectionHeader from 'components/screens/reports/SectionHeader';
import {
  ConnectionContext,
  ConnectionContextType,
  useConnectionContext,
} from 'contexts/ConnectionContext';
import { useWindowDimensions } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  useGetCategoryBasedChaptersClassDetailsQuery,
  useGetExamChaptersLatestEvaluatedQcGapQuery,
} from 'store/apiSlices/reports.apiSlice';
import { ChapterData, Connection, SubjectData } from 'types/reports';
import { ConnectionLines } from '../ExamChapters/ConnectionLines';
import AttendanceAndPracticeCard from 'components/screens/reports/AttendanceAndPracticeCard';
import { processProgressData } from 'configs/studentReport';

const ChapterClassDetails = () => {
  const { examId, studentId } = useParams();
  const navigate = useNavigate();
  const { chaptersRef, examCardRefs, selectedExam, examinations, student } = useConnectionContext();
  const { isMobileScreen } = useWindowDimensions();
  const location = useLocation();

  const containerRef = useRef<HTMLDivElement>(null);
  const attendanceCardRef = useRef<HTMLDivElement>(null);
  const [showMobileFoorter, setShowMobileFooter] = useState<boolean>(true);
  const [selectedSubject, setSelectedSubject] = useState<string | null>(null);
  const [attendanceData, setAttendanceData] = useState<Record<string, ChapterData[]>>({});
  const [progressData, setProgressData] = useState<Record<string, SubjectData>>({});
  const [connections, setConnections] = useState<Connection[]>([]);
  const [svgDimensions, setSvgDimensions] = useState({ width: 0, height: 0 });

  const isAdminRoute = location.pathname.startsWith('/a');
  const prefix = isAdminRoute ? '/a' : '/p';

  // Check if the current path contains submissionstatus or classesnotattended
  const isSpecialView =
    location.pathname.includes('submissionstatus') ||
    location.pathname.includes('classesnotattended');
  const selectedExamData = examinations?.find((exam) => exam.examId === examId);
  const chapterIds =
    selectedExamData?.chapters
      ?.filter((chapter) => chapter?.isCompleted)
      ?.map((chapter) => chapter.chapterId) || [];
  const subjectIds =
    selectedExamData?.chapters
      ?.filter((chapter) => chapter?.isCompleted)
      ?.map((chapter) => chapter.subjectId) || [];

  const { data: chapterClassesDetails, isLoading } = useGetCategoryBasedChaptersClassDetailsQuery(
    {
      studentId: studentId && !isNaN(Number(studentId)) ? Number(studentId) : null,
      subjectIds: subjectIds ? [...new Set(subjectIds)] : null,
      chapterIds: chapterIds ? [...new Set(chapterIds)] : null,
    },
    { skip: !studentId || isNaN(Number(studentId)) || !chapterIds?.length || !subjectIds?.length },
  );

  const { data: examChaptersLatestEvaluatedQc } = useGetExamChaptersLatestEvaluatedQcGapQuery(
    {
      studentId: studentId && !isNaN(Number(studentId)) ? Number(studentId) : null,
      subjectIds: subjectIds ? [...new Set(subjectIds)] : null,
      chapterIds: chapterIds ? [...new Set(chapterIds)] : null,
    },
    { skip: !studentId || isNaN(Number(studentId)) || !chapterIds?.length || !subjectIds?.length },
  );

  useEffect(() => {
    if (!chapterClassesDetails?.data?.length) return;
    const groupChaptersBySubject = (response: ChapterData[]) => {
      const groupedData: Record<string, ChapterData[]> = {};

      response.forEach((chapter) => {
        if (!groupedData[chapter.subjectId]) {
          groupedData[chapter.subjectId] = [];
        }
        groupedData[chapter.subjectId].push(chapter);
      });

      return groupedData;
    };

    const attendanceData = groupChaptersBySubject(chapterClassesDetails?.data);
    setSelectedSubject(Object.keys(attendanceData)?.[0]);
    setAttendanceData(attendanceData);
  }, [chapterClassesDetails]);

  useEffect(() => {
    if (examChaptersLatestEvaluatedQc?.data?.length) {
      const groupedQcData = processProgressData(examChaptersLatestEvaluatedQc?.data);
      setProgressData(groupedQcData);
    }
  }, [examChaptersLatestEvaluatedQc]);

  useEffect(() => {
    const calculateConnections = () => {
      if (!containerRef.current || !chaptersRef?.current || !attendanceCardRef.current) {
        return;
      }

      const containerRect = containerRef.current.getBoundingClientRect();
      const startRect = chaptersRef?.current.getBoundingClientRect();
      const endRect = attendanceCardRef.current.getBoundingClientRect();

      if (startRect.width === 0 || endRect.width === 0) return;

      const config = {
        curveWidth: 40,
        curveRadius: 30,
        minLineHeight: 30,
      };

      const startX = startRect.right - containerRect.left;
      const startY = startRect.top - 40;
      const endX = endRect.left - containerRect.left;
      const endY = endRect.top + endRect.height / 2 - containerRect.top;
      const isStartBelow = startY > endY;
      const isNearlyHorizontal = Math.abs(endY - startY) < config.minLineHeight;

      let connection: Connection;

      if (isNearlyHorizontal) {
        // Create straight horizontal line
        connection = {
          startCurve: `M ${startX} ${startY} L ${endX} ${startY}`,
          endCurve: '',
          line: { top: 0, left: 0, height: 0 },
          startDot: { x: startX, y: startY },
          endDot: { x: endX, y: startY },
        };
      } else {
        // Create curved line for vertical connections
        const verticalDistance = Math.abs(endY - startY);
        const effectiveHeight = Math.max(verticalDistance - config.curveRadius * 2, 0);

        const startCurve = `
                M ${startX} ${startY}
                H ${startX + config.curveWidth - config.curveRadius}
                Q ${startX + config.curveWidth} ${startY},
                  ${startX + config.curveWidth} ${startY + (isStartBelow ? -config.curveRadius : config.curveRadius)}
              `;

        const endCurve = `
                M ${endX - config.curveWidth} ${endY + (isStartBelow ? config.curveRadius : -config.curveRadius)}
                Q ${endX - config.curveWidth} ${endY},
                  ${endX - config.curveWidth + config.curveRadius} ${endY}
                H ${endX}
              `;

        connection = {
          startCurve,
          endCurve,
          line: {
            top: Math.min(
              startY + (isStartBelow ? -config.curveRadius : config.curveRadius),
              endY + (isStartBelow ? config.curveRadius : -config.curveRadius),
            ),
            left: startX + config.curveWidth - 1,
            height: effectiveHeight,
          },
          startDot: { x: startX, y: startY },
          endDot: { x: endX, y: endY },
        };
      }

      setConnections([connection]);
      setSvgDimensions({
        width: containerRect.width,
        height: containerRect.height,
      });
    };

    if (attendanceCardRef?.current) {
      calculateConnections();

      const observer = new ResizeObserver(() => {
        requestAnimationFrame(calculateConnections);
      });

      if (containerRef.current) observer.observe(containerRef.current);
      if (chaptersRef?.current) observer.observe(chaptersRef?.current);
      if (attendanceCardRef.current) observer.observe(attendanceCardRef.current);

      let scrollTimeout: NodeJS.Timeout;
      let resizeTimeout: NodeJS.Timeout;

      const handleScroll = () => {
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => {
          requestAnimationFrame(calculateConnections);
        }, 16);
      };

      const handleResize = () => {
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(() => {
          requestAnimationFrame(calculateConnections);
        }, 16);
      };

      window.addEventListener('scroll', handleScroll, { passive: true });
      window.addEventListener('resize', handleResize, { passive: true });

      return () => {
        observer.disconnect();
        clearTimeout(scrollTimeout);
        clearTimeout(resizeTimeout);
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [attendanceCardRef?.current]);

  if (!selectedExamData) return;

  const contextValue: ConnectionContextType = {
    examCardRefs,
    chapterDetailsRef: attendanceCardRef,
    multipleChapterRef: chaptersRef,
    selectedExam,
    examinations,
    progressData,
    student,
  };

  const isProgressView = location.pathname.includes('progress');
  return (
    <ConnectionContext.Provider value={contextValue}>
      <div className='w-full relative pb-20 md:pb-0' ref={containerRef}>
        <div className={`flex md:gap-20 relative z-0 ${isSpecialView ? 'md:min-w-[1300px]' : ''}`}>
          {!(isMobileScreen && isProgressView) && (
            <div className='flex flex-col min-w-full md:min-w-fit w-full md:w-[320px]'>
              <div className='mb-3 md:mb-0'>
                {isMobileScreen ? (
                  <BackHeader
                    title={`${student?.members?.firstName}'s Input for ${selectedExamData?.examName} Exam`}
                    route={`${prefix}/reports/${studentId}/chapter/exam-status/${examId}`}
                  />
                ) : (
                  <SectionHeader
                    text={`${student?.members?.firstName}'s Input for ${selectedExamData?.examName} Exam`}
                    textColor='secondary-900'
                    backgroundColor='from-[#97E5DE] to-[#CEEAE8]'
                  />
                )}
              </div>
              <div className='w-full md:min-w-[385px]' ref={attendanceCardRef}>
                {isLoading ? (
                  <div className='p-4 bg-white rounded-xl shadow-md w-80 animate-pulse'>
                    <div className='flex items-center gap-3'>
                      <div className='w-8 h-8 bg-gray-300 rounded-full' />
                      <div className='h-4 w-32 bg-gray-300 rounded' />
                    </div>

                    <div className='mt-4'>
                      <div className='h-4 w-48 bg-gray-300 rounded mb-2' />
                      <div className='h-4 w-40 bg-gray-300 rounded' />
                    </div>

                    <div className='mt-4 grid grid-cols-2 gap-4'>
                      <div className='h-16 bg-gray-300 rounded' />
                      <div className='h-16 bg-gray-300 rounded' />
                    </div>

                    <div className='mt-4 grid grid-cols-2 gap-4'>
                      <div className='h-16 bg-gray-300 rounded' />
                      <div className='h-16 bg-gray-300 rounded' />
                    </div>

                    <div className='mt-4 h-10 bg-gray-300 rounded w-full' />
                  </div>
                ) :
                  selectedSubject ? (
                    <AttendanceAndPracticeCard
                      data={attendanceData}
                      selectedSubject={selectedSubject}
                      setSelectedSubject={setSelectedSubject}
                      onClick={() =>
                        navigate(
                          `${prefix}/reports/${studentId}/chapter/exam-status/${examId}/details/progress`,
                        )
                      }
                      practiceSheetClick={() =>
                        navigate(
                          `${prefix}/reports/${studentId}/chapter/exam-status/${examId}/details/submissionstatus`,
                        )
                      }
                      classesScheduledClick={() =>
                        navigate(
                          `${prefix}/reports/${studentId}/chapter/exam-status/${examId}/details/classesnotattended`,
                        )
                      }
                      setShowMobileFooter={setShowMobileFooter}
                    />
                  ) : (
                    <div className='p-4 bg-white rounded-xl shadow-md w-80'>
                      <div className='flex flex-col items-center justify-center gap-2 text-center'>
                        <p className='text-gray-600 font-medium'>No class details found</p>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )}
          <div className='transition-opacity duration-300 w-full'>
            <Outlet />
          </div>
        </div>

        <div className='absolute top-0 left-0 w-full h-full pointer-events-none'>
          <ConnectionLines connections={connections} svgDimensions={svgDimensions} />
        </div>

        {/* Mobile Footer */}
        {isMobileScreen && !isProgressView && showMobileFoorter && (
          <div className='fixed bottom-0 left-0 right-0 z-50'>
            <MobileFooter
              text={`View Understanding of ${selectedExamData?.examName} Chapters`}
              onClick={() =>
                navigate(
                  `${prefix}/reports/${studentId}/chapter/exam-status/${examId}/details/progress`,
                )
              }
              iconUrl={RightAngularIcon}
            />
          </div>
        )}
      </div>
    </ConnectionContext.Provider>
  );
};

export default ChapterClassDetails;
