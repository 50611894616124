import { CrossIcon } from 'assets/svg';
import { CATEGORY_CONFIG, RATING_MAP } from 'configs/reports';
import { useWindowDimensions } from 'hooks';
import { useState } from 'react';
import { FaStar } from 'react-icons/fa6';
import {
  ExamCategoryEntity,
  ExamQcLevelUnderstandingEntity,
  ExamQuestionEntity,
} from 'types/reports';
import BottomSheet from '../BottomSheet';
import CategoryDetailCard from '../CategoryDetailCard';
import ExamCategoryCard from '../ExamCategoryCard';
import { Members, membersList } from 'types';

export interface QcLevelUnderstandingPopupProps {
  id: string;
  title: string;
  rating: number;
  categories: ExamQcLevelUnderstandingEntity;
  onClose: () => void;
  studentDetails?: membersList | { members: Members };
}

export const QcLevelUnderstandingPopup: React.FC<QcLevelUnderstandingPopupProps> = ({
  title,
  rating,
  categories,
  onClose,
  studentDetails,
}) => {
  const [questions, setQuestions] = useState<ExamQuestionEntity[] | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<ExamCategoryEntity | null>(null);
  const { isMobileScreen } = useWindowDimensions();
  const { chapterQcsToRender, qcsQuestions } = categories;

  const handleCategoryClick = (category: ExamCategoryEntity) => {
    const questionsToRender = qcsQuestions?.filter(
      (question) => question.categoryId === category?.questionCategoryId,
    );

    setQuestions(questionsToRender || []);
    setSelectedCategory(category);
  };

  const handleCloseCategory = () => {
    setSelectedCategory(null);
  };

  return (
    <div
      className={`flex shadow-md border bg-white border-neutral-100 rounded-xl  overflow-y-hidden ${
        selectedCategory && !isMobileScreen ? 'w-full' : 'w-full md:w-1/2'
      }`}
    >
      {/* Main QC Understanding Popup */}
      <div
        className={`flex flex-col h-[calc(100vh-120px)] ${
          selectedCategory && !isMobileScreen ? 'w-[50%]' : 'w-full'
        }`}
      >
        {/* Header */}
        <div
          className='relative flex items-center justify-between p-4'
          style={{ boxShadow: '0px 16px 32px -4px #0c0c0d0d' }}
        >
          <h3 className='font-sora font-semibold text-base tracking-normal leading-3 p-3 mt-1'>
            {`${studentDetails?.members?.firstName}'s QC Level Understanding`}
          </h3>
          {(!selectedCategory || isMobileScreen) && (
            <button onClick={onClose}>
              <img src={CrossIcon} alt='close popup' width={20} height={20} />
            </button>
          )}
        </div>

        {/* Content */}
        <div className='flex-1 px-4 pt-3 h-full pb-[calc(100%-240px)] overflow-y-scroll'>
          {/* Chapter Info */}
          <div className='mb-4'>
            <h3 className='text-xs font-poppins font-normal tracking-normal text-neutral-300 mb-1'>
              Chapter
            </h3>
            <h3 className='font-sora font-semibold text-sm mb-3'>{title}</h3>

            {/* Star Rating */}
            <div className='flex gap-1 mb-2'>
              {Array.from({ length: rating }).map((_, index) => (
                <FaStar key={index} size={16} className='text-yellow-400 fill-yellow-400' />
              ))}
            </div>
            <h3 className='font-poppins text-xs font-medium text-neutral-700'>
              {CATEGORY_CONFIG.find((category) => category.rating === rating)?.title || 'Unknown'}
            </h3>
          </div>

          {/* Categories */}
          <div className='space-y-4'>
            {chapterQcsToRender.map((category) => (
              <div
                key={category.questionCategoryId}
                onClick={() => handleCategoryClick(category)}
                className='cursor-pointer'
              >
                <ExamCategoryCard
                  idx={category?.questionCategoryId}
                  category={category}
                  evaluationId={RATING_MAP[rating as keyof typeof RATING_MAP] ?? 4}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Category Detail Card */}
      {isMobileScreen ? (
        <BottomSheet isVisible={!!selectedCategory} onClose={handleCloseCategory}>
          {selectedCategory && (
            <CategoryDetailCard
              idx={selectedCategory.questionCategoryId}
              questions={questions!}
              onClick={handleCloseCategory}
              selected={true}
            />
          )}
        </BottomSheet>
      ) : (
        selectedCategory && (
          <div
            className={`w-[50%] rounded-r-lg  border-neutral-100
            transition-all duration-300 transform
            `}
          >
            <CategoryDetailCard
              idx={selectedCategory.questionCategoryId}
              questions={questions!}
              onClick={handleCloseCategory}
              selected={true}
            />
          </div>
        )
      )}
    </div>
  );
};

export default QcLevelUnderstandingPopup;
