import { BouncingDotsLoader } from 'components/common';
import BottomSheet from 'components/screens/reports/BottomSheet';
import ClassesNotAttended from 'components/screens/reports/ClassesNotAttended';
import ExaminationCard from 'components/screens/reports/ExaminationsCard';
import PracticeSheetStatus from 'components/screens/reports/PracticeSheetStatus';
import QcLevelUnderstandingPopup from 'components/screens/reports/QcLevelUnderstandingPopup';
import SectionHeader from 'components/screens/reports/SectionHeader';
import { NO_CHAPTERS_COVERED_FOR_EXAMS, NO_EXAMS_FOUND } from 'configs/reports';
import { ConnectionContext } from 'contexts/ConnectionContext';
import { useWindowDimensions } from 'hooks';
import { useAppSelector } from 'hooks/store';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetCategoryBasedExamStatusReportsQuery } from 'store/apiSlices/reports.apiSlice';
import {
  selectExamChapterPracticeSheet,
  selectExamQcLevelData,
  selectNotAttendedClassesInChapterExam,
} from 'store/slice/reports.slice';
import { ISiblingParentListResponse, Members, membersList } from 'types';
import { ExamData } from 'types/reports';
import Cookie from 'js-cookie';
import { CONFIG, USER_TYPE } from 'configs';
import toast from 'react-hot-toast';

const ExamStatusReport = ({
  isCollapsed,
  setIsCollapsed,
  selectedSegment,
  studentDetails,
}: {
  isCollapsed: boolean;
  selectedSegment: string;
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  studentDetails?: ISiblingParentListResponse;
}) => {
  const { examId, studentId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedExam, setSelectedExam] = useState<string | null>(null);
  const [isAnyPopupVisible, setIsAnyPopVisible] = useState<boolean>(false);
  const [examinations, setExaminations] = useState<ExamData[]>([]);
  const examCardRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const containerRef = useRef<HTMLDivElement>(null);
  const { isMobileScreen } = useWindowDimensions();
  const practiceSheets = useAppSelector(selectExamChapterPracticeSheet);
  const notAttendedClasses = useAppSelector(selectNotAttendedClassesInChapterExam);
  const qcLevelDataToRender = useAppSelector(selectExamQcLevelData);

  const isAdminRoute = location.pathname.startsWith('/a');
  const prefix = isAdminRoute ? '/a' : '/p';
  const isExamStatusRoute = location.pathname.includes(`/exam-status/${examId}`);
  const impersonatedStudentDetails =
    Cookie.get(`${CONFIG.VITE_BASE_DOMAIN_ENV}StudentDetails`) || '';

  let student: membersList | { members: Members } | undefined;
  if (isAdminRoute) {
    student = { members: JSON.parse(impersonatedStudentDetails) };
  } else {
    student = studentDetails?.data?.find(
      (item) =>
        item.members.id === Number(studentId) && item.members.userType === USER_TYPE.STUDENT,
    );
  }

  const { data: examStatusReportData, isLoading: reportDataLoading } =
    useGetCategoryBasedExamStatusReportsQuery(
      {
        studentId: studentId && !isNaN(Number(studentId)) ? Number(studentId) : null,
        subjectId: selectedSegment,
      },
      { skip: !studentId || isNaN(Number(studentId)) || !selectedSegment },
    );

  useEffect(() => {
    if (examStatusReportData?.data) {
      const examData = examStatusReportData.data;
      const processedExamData = examData
        ?.map((data) => {
          return {
            ...data,
            examDate: moment(data?.examDate?.[0]).format('DD MMMM, YYYY'),
          };
        })
        ?.sort((a, b) =>
          moment(a.examDate, 'DD MMMM, YYYY').diff(moment(b.examDate, 'DD MMMM, YYYY')),
        );

      setExaminations(processedExamData);
    }
  }, [examStatusReportData]);

  useEffect(() => {
    if (!containerRef.current) return;

    const isPracticeSheetRoute = location.pathname.includes('/details/submissionstatus');
    const isClassesNotAttendedRoute = location.pathname.includes('/details/classesnotattended');
    const isProgressDetailsRoute = location.pathname.includes('/details/progress/details');

    const shouldScroll =
      isPracticeSheetRoute || isClassesNotAttendedRoute || isProgressDetailsRoute;

    if (shouldScroll) {
      const container = containerRef.current;
      const scrollPosition = container.scrollWidth - container.clientWidth;

      // Determine extra space based on route
      const extraSpace = isProgressDetailsRoute ? 1000 : 500;

      // Immediate scroll
      container.scrollTo({
        left: scrollPosition + extraSpace,
        behavior: 'smooth',
      });

      // Delayed scroll for dynamic content
      const timeoutId = setTimeout(() => {
        if (container) {
          container.scrollTo({
            left: scrollPosition + extraSpace,
            behavior: 'smooth',
          });
        }
      }, 300);

      return () => clearTimeout(timeoutId);
    }
  }, [location.pathname]);

  const handleExamSelect = (examId: string) => {
    setSelectedExam(examinations.find((e) => e.examId === examId)?.examName || null);
    setIsCollapsed(true);
    navigate(`${prefix}/reports/${studentId}/chapter/exam-status/${examId}`);
  };

  const closePopup = () => {
    const newPath = location.pathname.replace('/progress/details', '/progress');
    navigate(newPath);
  };

  const closeClassNotAttendedPopup = () => {
    const newPath = location.pathname.replace('/details/classesnotattended', '/details');
    navigate(newPath);
  };

  const shouldShowPopup =
    location.pathname.includes('/exam-status/') &&
    location.pathname.includes('/details/progress/details');

  const showPracticeSheetPopup =
    location.pathname.includes('/exam-status/') &&
    location.pathname.includes('/details/submissionstatus');

  const showClassesNotAttendedPopup =
    location.pathname.includes('/exam-status/') &&
    location.pathname.includes('/details/classesnotattended');

  const renderPopupContent = () => {
    if (shouldShowPopup && !isEmpty(qcLevelDataToRender)) {
      return (
        <QcLevelUnderstandingPopup
          id={qcLevelDataToRender?.title}
          title={qcLevelDataToRender?.chapterName || ''}
          rating={qcLevelDataToRender?.rating}
          categories={qcLevelDataToRender}
          onClose={closePopup}
          studentDetails={student}
        />
      );
    }
    if (showPracticeSheetPopup && practiceSheets?.length) {
      return (
        <PracticeSheetStatus
          sheets={practiceSheets || []}
          onClose={() =>
            navigate(`${prefix}/reports/${studentId}/chapter/exam-status/${examId}/details`)
          }
        />
      );
    }
    if (showClassesNotAttendedPopup && notAttendedClasses?.length) {
      return (
        <ClassesNotAttended classes={notAttendedClasses} onClose={closeClassNotAttendedPopup} />
      );
    }
    return null;
  };

  useEffect(() => {
    let popUpStatus = false;
    setIsAnyPopVisible((prev) => !prev);
    if (shouldShowPopup && !isEmpty(qcLevelDataToRender)) {
      popUpStatus = true;
    }

    if (showPracticeSheetPopup && practiceSheets?.length) {
      popUpStatus = true;
    } else if (showPracticeSheetPopup) {
      toast.success(`No practice sheet assigned`);
    }

    if (showClassesNotAttendedPopup && notAttendedClasses?.length) {
      popUpStatus = true;
    } else if (showClassesNotAttendedPopup) {
      toast.success(`Student attended all the classes`);
    }

    setIsAnyPopVisible(popUpStatus);
  }, [
    notAttendedClasses?.length,
    practiceSheets?.length,
    qcLevelDataToRender,
    shouldShowPopup,
    showClassesNotAttendedPopup,
    showPracticeSheetPopup,
  ]);

  const isLoading = reportDataLoading;
  return (
    <div
      className={`transition-all duration-300 ease-in-out lg:pb-20 ${
        isCollapsed ? 'lg:ml-16' : 'lg:ml-72'
      }`}
    >
      {isLoading ? (
        <div className='flex h-screen items-center justify-center flex-1 mx-2 xl:mx-6'>
          <BouncingDotsLoader />
        </div>
      ) : (
        <ConnectionContext.Provider value={{ examCardRefs, selectedExam, examinations, student }}>
          <div className='pt-20 lg:pt-0 lg:pl-20'>
            {!examinations?.length ? (
              <div className='flex justify-center items-center h-fit mt-6'>
                <div className='bg-white shadow-lg rounded-lg p-8 w-full max-w-md'>
                  <h2 className='text-2xl font-bold text-red-800 mb-4 font-sora'>
                    {NO_EXAMS_FOUND}
                  </h2>
                  <p className='text-gray-600 font-poppins'>{NO_CHAPTERS_COVERED_FOR_EXAMS}</p>
                </div>
              </div>
            ) : (
              <div
                ref={containerRef}
                className='relative overflow-x-auto lg:p-0'
                style={{
                  overflow: 'auto',
                  scrollbarWidth: 'none',
                  msOverflowStyle: 'none',
                }}
              >
                <div
                  className={`flex lg:gap-20 relative z-0 m-2 lg:ms-4  ${
                    (showPracticeSheetPopup && practiceSheets?.length) ||
                    (showClassesNotAttendedPopup && notAttendedClasses?.length)
                      ? 'md:min-w-[900px]'
                      : shouldShowPopup && !isEmpty(qcLevelDataToRender)
                        ? 'md:min-w-[1800px]'
                        : ''
                  }`}
                  style={isMobileScreen ? { flexDirection: 'column', gap: '24px' } : {}}
                >
                  <div className='flex flex-col min-w-full md:min-w-fit'>
                    {(!isExamStatusRoute || !isMobileScreen) && (
                      <SectionHeader
                        text='Examinations'
                        textColor='primary-950'
                        backgroundColor='from-[#8ECFF8] to-[#CBE9FC]'
                      />
                    )}
                    <div className='flex flex-col md:flex-row gap-3 lg:gap-20 relative w-full z-0'>
                      {(!isExamStatusRoute || !isMobileScreen) && (
                        <div className='space-y-4 w-full md:w-[330px] mb-2'>
                          {examinations.map((exam) => (
                            <div
                              key={exam.examId}
                              ref={(el) => (examCardRefs.current[exam.examId] = el)}
                            >
                              <ExaminationCard
                                title={exam.examName}
                                date={exam.examDate}
                                isSelected={selectedExam === exam.examName}
                                onClick={() => handleExamSelect(exam.examId)}
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    className={`transition-opacity duration-300 w-full ${
                      isMobileScreen && isExamStatusRoute ? 'mt-4' : ''
                    }`}
                  >
                    <Outlet />
                  </div>
                </div>

                {/* Popups */}
                {isAnyPopupVisible && (
                  <>
                    {isMobileScreen ? (
                      <BottomSheet isVisible={isAnyPopupVisible} onClose={closePopup}>
                        {renderPopupContent()}
                      </BottomSheet>
                    ) : (
                      <div
                        className={`fixed top-24 right-8 flex justify-end z-50 transition-all duration-300 transform overflow-y-auto
                    ${isAnyPopupVisible ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'}
                    ${shouldShowPopup ? 'w-[80%] max-w-[800px]' : 'w-[400px]'}`}
                        style={{
                          height: '80vh',
                        }}
                      >
                        {renderPopupContent()}
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </ConnectionContext.Provider>
      )}
    </div>
  );
};

export default ExamStatusReport;
