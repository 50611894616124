import { useGetCurrentClassDetailsQuery } from 'store/apiSlices/studentDashboard.apiSlice';

import { BouncingDotsLoader, Footer } from 'components/common';
import {
  DashboardNav,
  FutureClassList,
  LearningMaterials,
  StudentUpcomingClass,
} from 'components/screens';
import { USER_TYPE } from 'configs/userData';
import { ICurrentClassDetails } from 'types/democlass';
import ZohoSalesIQ from 'components/common/ChatBot/initiateZohoWidget';
import useChatDetails from 'components/common/ChatBot/ChatBotDetails';

export default function StudentDashboard() {
  /* API => get current class list */
  const { data: currentClassResponse, isLoading } = useGetCurrentClassDetailsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  
  const chatBotDetails = useChatDetails();
  
  return (
    <>
      <div className='flex flex-col flex-grow min-h-screen bg-neutral-25'>
        <DashboardNav
          type={USER_TYPE.STUDENT}
          batchType={currentClassResponse?.data?.batchTypeDetails?.name}
        />
        {isLoading ? (
          <div className='flex items-center justify-center flex-1 mx-2 xl:mx-6'>
            <BouncingDotsLoader />
          </div>
        ) : (
          <div className='flex-1 h-full mx-2 xl:mx-6'>
            {currentClassResponse?.data?.id?.toString() && (
              <>
                <StudentUpcomingClass />
                <FutureClassList />
              </>
            )}
            <LearningMaterials
              learningMaterial={currentClassResponse?.data as ICurrentClassDetails}
            />
          </div>
        )}
        <Footer />
        <ZohoSalesIQ
          id={chatBotDetails.userId?.toString() || ''}
          name={chatBotDetails.name}
          email={chatBotDetails.email}
          contactNumber={chatBotDetails.contactNumber}
          role={chatBotDetails.role}
          page={chatBotDetails.landingScreen}
          activeCamera={chatBotDetails.activeCamera}
          activeMicrophone={chatBotDetails.activeMicrophone}
          activeSpeaker={chatBotDetails.activeSpeaker}
        />
      </div>
    </>
  );
}
