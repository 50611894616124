import { CONFIG } from 'configs';
import { motion } from 'framer-motion';
import { ReactElement } from 'react';
import { IReportType, SectionName, SubjectKey } from 'types/reports';
import Cookie from 'js-cookie';
import { MATHS_SUBJECT, SCIENCE_SUBJECT } from 'configs/reports';

const SubjectSection = ({
  subject,
  icon,
  iconBgColor,
  items,
  disabled = false,
  toggleSection,
  handleReportSelection,
  activeReportKey,
}: {
  subject: string;
  icon: ReactElement;
  iconBgColor: string;
  items: { name: string; type?: string }[];
  disabled?: boolean;
  activeReportKey?: string;
  toggleSection: (section: SectionName) => void;
  handleReportSelection: (reportType: IReportType) => void;
}) => {
  const isImpersonatedUser =
    Cookie.get(`${CONFIG.VITE_BASE_DOMAIN_ENV}IsImpersonatedUser`) === 'true';

  return (
    <div className='mb-2'>
      <motion.button
        onClick={() => !disabled && toggleSection(subject.toLowerCase() as SubjectKey)}
        className={`w-full px-6 py-4 flex items-center justify-between text-left rounded-xl 
          ${disabled ? '' : 'hover:bg-gray-50 cursor-pointer'}`}
      >
        <div className='flex items-center gap-3'>
          <div className={`p-1.5 rounded-xl ${iconBgColor}`}>{icon}</div>
          <span className='text-sm font-sora font-medium text-gray-900'>{subject}</span>
        </div>
      </motion.button>

      <div className={`px-4 py-2 space-y-1 rounded-xl ${disabled ? 'bg-gray-50' : ''}`}>
        {items.map((item: { name: string; reportType?: string }, index: number) => {
          return (
            <div
              key={index}
              className={`flex items-center justify-between p-3 rounded-lg 
                ${
                  activeReportKey === item?.reportType
                    ? 'bg-indigo-50 text-indigo-900 cursor-pointer'
                    : 'hover:bg-gray-50 text-gray-600 cursor-pointer'
                }`}
              onClick={() => !disabled && handleReportSelection(item?.reportType as IReportType)}
            >
              <div className='flex items-center gap-2'>
                <span className='text-xs font-sora font-medium'>{item?.name}</span>
              </div>

              {(subject === SCIENCE_SUBJECT ||
                (subject === MATHS_SUBJECT &&
                  !isImpersonatedUser &&
                  item.name !== 'QC Report')) && (
                <span className='px-2 py-1 font-sora text-xs font-medium bg-gray-100 text-gray-600 rounded-full'>
                  {'coming soon'}
                </span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SubjectSection;
