import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Compressor from 'compressorjs';

import {
  useMeeting,
  useSocketSubscribe,
  useNotification,
  useInclassLogger,
  useWebcam,
  useWindowDimensions,
} from 'hooks';
import { CloseWhite } from 'assets/svg';
import { SocketContext } from 'contexts';
import { dataURLtoFile, randomPictureName } from 'utils';
import { useGetClassJoinTokenQuery } from 'store/apiSlices/classes.apiSlice';
import { useAddNoteMutation } from 'store/apiSlices/inClass/evaluation.apiSlice';
import { INotebookCapture, NotebookCaptureState } from 'types';
import ParticipantTile from 'components/inClass/ParticipantTile';
import './styles.css';
import { useAppSelector } from 'hooks/store';
import { selectMultiLecture } from 'store/slice/lecture.slice';
import {
  ENABLE_IMAGE_COMPRESSION,
  IMAGE_COMPRESSION_QUALITY,
  IMAGE_COMPRESSION_SIZE,
} from 'configs';

const NotebookCapture = ({ setRender, data, setActiveMorphcast }: INotebookCapture) => {
  // State variables
  const [notebookCaptureState, setNotebookCaptureState] = useState<NotebookCaptureState>({
    status: false,
    page: null,
    evaluationId: '',
    elementId: '',
    tutorId: null,
  });

  // Ref
  const loadingCapture = useRef(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const videoBoxRef = useRef<HTMLDivElement | null>(null);

  // Hooks
  const { self, classId, studentId, tutors, studentName } = useMeeting();
  const { screenResolution } = useWindowDimensions();
  const socket = useContext(SocketContext);
  const { enableCam, captureScreenshot } = useWebcam({ videoRef, videoBoxRef });
  const triggerNotification = useNotification();
  const { datadogLog } = useInclassLogger();

  const lecture = useAppSelector(selectMultiLecture);

  // Get the auth token from the URL
  const { id } = useParams<{ id: string }>();
  const authToken = id ?? '';

  // API calls
  const [addNotes] = useAddNoteMutation();
  const { data: JoinClassRoomData } = useGetClassJoinTokenQuery(
    { authToken: authToken },
    {
      skip: !authToken,
    },
  );

  // Enable the camera
  useEffect(() => {
    const { page, tutorId } = data;
    const id = tutorId?.toString();
    setNotebookCaptureState((prevState) => ({
      ...prevState,
      status: true,
      evaluationId: data?.evaluationId,
      elementId: data?.elementId,
      tutorId: id,
      page,
    }));
    setTimeout(() => {
      enableCam();
    }, 500);
  }, [data, enableCam, self]);

  // Handle save image
  const saveOriginalNoteBookImageData = useCallback(
    async (capturedImgData: { original: string | null }) => {
      if (capturedImgData.original === null) {
        console.error('No image captured');
        return;
      }
      try {
        const { page, evaluationId, elementId, tutorId } = notebookCaptureState;
        if (capturedImgData && JoinClassRoomData && page && tutorId) {
          const originalImage = capturedImgData?.original;
          const signName1 = randomPictureName();
          const originImageObj = dataURLtoFile(originalImage, signName1);
          // Check if image size is more than 500KB
          let imageToUpload = originImageObj;
          if (originImageObj?.size > 500 * 1024 && ENABLE_IMAGE_COMPRESSION === 'true') {
            const compressStartTime = Date.now();
            try {
              imageToUpload = await new Promise<File>((resolve) => {
                new Compressor(originImageObj, {
                  quality: Number(IMAGE_COMPRESSION_QUALITY) || 0.6, // Compress image quality to 60%
                  convertSize: Number(IMAGE_COMPRESSION_SIZE) || 300 * 1024, // Applying compression when the image size is more than 300KB
                  success: (compressedResult) => {
                    // Create a new File from the compressed Blob
                    const compressedFile = new File([compressedResult], originImageObj?.name, {
                      type: compressedResult?.type,
                      lastModified: Date.now(),
                    });
                    datadogLog(
                      `Compressed image size: ${compressedFile?.size / (1024 * 1024)} MB`,
                      {
                        label: 'image-compression',
                        size: (compressedFile?.size / (1024 * 1024)).toString(),
                        name: compressedFile?.name,
                        type: compressedFile?.type,
                        originalSize: originImageObj?.size.toString(),
                        compressedSize: compressedFile?.size.toString(),
                        differenceInKb: (
                          (originImageObj?.size - compressedFile?.size) /
                          1024
                        ).toString(),
                        studentId: studentId?.toString() || '',
                        tutorId: tutorId?.toString() || '',
                        evaluationId: evaluationId?.toString() || '',
                        elementId: elementId?.toString() || '',
                        page: page?.toString() || '',
                        classId: JoinClassRoomData?.data?.classId?.toString() || '',
                        batchId: JoinClassRoomData?.data?.batchId?.toString() || '',
                      },
                    );
                    resolve(compressedFile);
                  },
                  error: (err) => {
                    console.error('Compression failed:', err);
                    resolve(originImageObj);
                  },
                });
              });
            } catch (compressionError) {
              console.error('Compression error:', compressionError);
              imageToUpload = originImageObj;
            } finally {
              const compressEndTime = Date.now();
              datadogLog(`Time taken to do image compression`, {
                label: 'notebook_capture_analysis',
                duration: `${compressEndTime - compressStartTime}`,
                startTime: compressStartTime.toString(),
                endTime: compressEndTime.toString(),
                studentId: studentId?.toString() || '',
                evaluationId: evaluationId?.toString() || '',
                elementId: elementId?.toString() || '',
                page: page?.toString() || '',
                classId: JoinClassRoomData?.data?.classId?.toString() || '',
              });
            }
          }

          const studentToken = JoinClassRoomData?.data?.loginTempToken;
          if (studentToken === null) {
            console.error('Login token not found');
          } else {
            const formData = new FormData();
            formData.append('original', imageToUpload);
            formData.append('page', page.toString());
            formData.append('classId', JoinClassRoomData?.data?.classId?.toString());
            formData.append('batchId', JoinClassRoomData?.data?.batchId?.toString());
            formData.append('studentId', JoinClassRoomData?.data?.studentId?.toString());
            formData.append('tutorId', tutorId?.toString().split('-').pop() || '');
            lecture?.configId && formData.append('lectureId', lecture?.configId);
            if (evaluationId) {
              formData.append('evaluationId', evaluationId?.toString());
            } else if (elementId) {
              formData.append('elementId', elementId?.toString());
            }
            const reqData = {
              formData,
              authToken: studentToken,
            };
            try {
              const saveStartTime = Date.now();
              const payload = await addNotes(reqData).unwrap();
              const saveEndTime = Date.now();
              datadogLog(`Time taken to save notes (backend api call)`, {
                label: 'notebook_capture_analysis',
                duration: `${saveEndTime - saveStartTime}`,
                startTime: saveStartTime.toString(),
                endTime: saveEndTime.toString(),
                studentId: JoinClassRoomData?.data?.studentId?.toString() || '',
              });
              const { data } = payload;
              const {
                rawImages,
                studentId,
                evaluationId,
                elementId,
                id: notesId,
                imageQuality,
              } = data;
              socket?.emit('studentTookScreenshotEve', {
                rawImages: rawImages,
                studentId: studentId,
                evaluationId: evaluationId,
                elementId: elementId,
                notesId: notesId,
                imageQuality: imageQuality,
              });
              triggerNotification('Notebook captured successfully', 'success');
              setActiveMorphcast(true);
            } catch (error) {
              console.error('Failed to save notes:', error);
              const apiError = error as { data: { message: string } };
              socket?.emit(
                'notebookLogEve',
                JSON.stringify({
                  message: 'Failed to save notes',
                  state: false,
                  error: apiError?.data?.message || 'Failed to save notes',
                }),
              );
            }
            setNotebookCaptureState({
              status: false,
              page: null,
              evaluationId: '',
              elementId: '',
              tutorId: null,
            });
            datadogLog(`Notebook captured for ${studentName}`);
          }
        }
      } catch (e) {
        console.error('Something went wrong. Please try again');
      } finally {
        loadingCapture.current = false;
        setActiveMorphcast(true);
      }
    },
    [
      notebookCaptureState,
      JoinClassRoomData,
      datadogLog,
      studentId,
      lecture?.configId,
      studentName,
      addNotes,
      socket,
      triggerNotification,
      setActiveMorphcast,
    ],
  );

  // Close notebook capture
  const handleNotebookCaptureClose = async () => {
    setRender((prev) => ({ ...prev, notebook: false }));
    setActiveMorphcast(true);
    socket?.emit(
      'studentCancelScreenshotEve',
      JSON.stringify({
        id: self?.customParticipantId?.split('-').pop(),
        classId: classId,
      }),
    );
    setNotebookCaptureState({
      status: false,
      page: null,
      evaluationId: '',
      elementId: '',
      tutorId: null,
    });
    datadogLog(` ${studentName} canceled notebook capture`);
  };

  // Initialize notebook capture
  const notebookCaptureHandler = useCallback(
    async (data: string) => {
      const parsedData = JSON.parse(data);
      datadogLog(`Notebook capture button was clicked for student ${studentName}`);
      try {
        if (studentId === Number(parsedData?.studentId)) {
          const captureStartTime = Date.now();
          const capturedImgData: {
            image: {
              original: string | null;
            };
            log: {
              message: string;
              state: boolean;
            };
          } = await captureScreenshot();
          const captureEndTime = Date.now();
          datadogLog(`Time taken to draw canvas image & convert to data url`, {
            label: 'notebook_capture_analysis',
            duration: `${captureEndTime - captureStartTime}`,
            startTime: captureStartTime.toString(),
            endTime: captureEndTime.toString(),
            studentId: studentId?.toString() || '',
            classId: JoinClassRoomData?.data?.classId?.toString() || '',
          });
          setRender((prev) => ({ ...prev, notebook: false }));
          socket?.emit(
            'notebookLogEve',
            JSON.stringify({
              message: capturedImgData?.log?.message,
              state: capturedImgData?.log?.state,
            }),
          );
          if (capturedImgData && !loadingCapture.current) {
            loadingCapture.current = true;
            setNotebookCaptureState((prev) => ({ ...prev, status: false }));
            await saveOriginalNoteBookImageData(capturedImgData.image);
          } else {
            console.warn('No image captured');
            socket?.emit(
              'notebookLogEve',
              JSON.stringify({
                message: 'Failed to capture image',
                state: false,
                error: 'No image captured',
              }),
            );
          }
        }
      } catch (err) {
        console.error(err);
        socket?.emit(
          'notebookLogEve',
          JSON.stringify({
            message: 'Failed to capture image',
            state: false,
            error:
              (err as { data: { message: string } })?.data?.message || 'Failed to capture image',
          }),
        );
      }
    },
    [
      JoinClassRoomData?.data?.classId,
      captureScreenshot,
      datadogLog,
      saveOriginalNoteBookImageData,
      setRender,
      socket,
      studentId,
      studentName,
    ],
  );

  useSocketSubscribe<string>('notebookCaptureToClient', notebookCaptureHandler);

  // Close notebook capture
  const closeNotebookCaptureHandler = useCallback(
    async (data: string) => {
      const parsedData = JSON.parse(data);
      if (
        parsedData?.studentId.toString().split('-').pop() ===
        self?.customParticipantId?.split('-').pop()
      ) {
        setRender((prev) => ({ ...prev, notebook: false }));
        setActiveMorphcast(true);
        triggerNotification('Notebook captured successfully', 'success');
        setNotebookCaptureState({
          status: false,
          page: null,
          evaluationId: '',
          elementId: '',
          tutorId: null,
        });
        datadogLog(` ${studentName} canceled notebook capture`);
      }
    },
    [
      datadogLog,
      self?.customParticipantId,
      setActiveMorphcast,
      setRender,
      studentName,
      triggerNotification,
    ],
  );

  useSocketSubscribe<string>('closeNotebookCaptureToClient', closeNotebookCaptureHandler);

  return (
    <div className='relative flex flex-col items-center justify-center size-full'>
      <div className='relative flex justify-center w-full'>
        <p className='px-3 py-1 mb-3 bg-yellow-600 rounded-xl'>
          Please hold your notebook up to the camera
        </p>
        <div
          className='absolute top-0 right-0 rounded-lg cursor-pointer bg-primary-700'
          onClick={() => handleNotebookCaptureClose()}
        >
          <img src={CloseWhite} alt='close' className='p-2' />
        </div>
      </div>

      <div ref={videoBoxRef} className='flex items-center justify-end rounded-2xl'>
        <video
          ref={videoRef}
          className={`${screenResolution === 'FHD' ? 'w-[960px] h-[540px] rounded-2xl' : 'w-[640px] h-[360px]'} mx-auto rounded-2xl`}
          autoPlay
          playsInline
        ></video>
      </div>

      <div className='absolute bottom-0 right-0'>
        {tutors?.length > 0 &&
          tutors.map((tutor) => {
            return (
              <div className='w-[20vw] h-[28vh]' key={`${tutor.id}-${tutor.userId}`}>
                <ParticipantTile participant={tutor} />
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default NotebookCapture;
