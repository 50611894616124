import { useDyteSelector } from '@dytesdk/react-web-core';
import { motion } from 'framer-motion';
import Cookie from 'js-cookie';
import { isArray } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Countdown from 'react-countdown';
import { useNavigate, useParams } from 'react-router-dom';
import { datadogLogs } from '@datadog/browser-logs';

import { InClassUprioLogo } from 'assets/svg';
import { Modal } from 'components/common';
import { ClassStarted, Topic } from 'components/inClass/Badges';
import InClassModal from 'components/inClass/Modal';
import { ParentOnboarding } from 'components/screens';
import { useGetClassJoinTokenQuery } from 'store/apiSlices/classes.apiSlice';
import { useGetStudentParentDetailsForInClassQuery } from 'store/apiSlices/studentDashboard.apiSlice';
import { useCreateOrUpdateUserDeviceLogMutation } from 'store/apiSlices/userDeviceLogs.apiSlice';

import {
  CONFIG,
  CONTAINER_VARIANTS,
  DevicePermissionStatus,
  JOINING_ALLOWED_TIME,
  STUDENT_PATHS,
  USER_TYPE,
} from 'configs';
import { useMeeting, useWindowDimensions } from 'hooks';
import { useSetupScreenMediaDevices } from 'hooks/inClass/useSetupScreenMediaDevices';
import { getBrowserInfo, testAudioDevice, testVideoDevice } from 'utils';
import { prepareDeviceLogsPayload, resetDeviceLogsTracker } from 'utils/deviceLogsTracker';
import { getBrightness } from 'utils/getBrightness';
import { useDeviceType } from 'utils/inClass/checkDeviceType';
import DeviceErrorState from './DeviceErrorState';
import { ScreenOrientation, VideoPreview } from './Modals';
import { NetworkCheck } from './Modals/NetworkStatus';
import SetupScreenControls from './SetupScreenControls';

const StudentSetupScreen = ({
  onStatusChange,
}: {
  onStatusChange: (isGoodConnection: boolean) => void;
}) => {
  const { id } = useParams();
  const { isMobileScreen } = useWindowDimensions();
  const { meeting, loginTempToken, meetingStartTime, audioStatus, videoStatus } = useMeeting();
  const navigate = useNavigate();
  const { deviceType, orientation } = useDeviceType();
  const userName = useDyteSelector((meeting) => meeting?.self?.name) ?? 'You';
  const videoEnabled = useDyteSelector((m) => m.self.videoEnabled);
  moment.tz?.setDefault('Asia/Kolkata');

  // Keep the API hook for device logs
  const [createOrUpdateUserDeviceLog] = useCreateOrUpdateUserDeviceLogMutation();

  const {
    videoPermissionDenied,
    audioPermissionDenied,
    cameraInUse,
    checkPermissions,
    audioDevices,
    videoDevices,
    speakerDevices,
    currentSelectedDevice,
    permissionDenialSource,
    errorMessages,
  } = useSetupScreenMediaDevices();

  // State management with useRef for values that don't need re-renders
  const deviceTestTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isTestingRef = useRef(false);
  const lastDeviceChangeRef = useRef<number>(0);
  const activeDeviceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // States that affect rendering
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [videoVisible, setVideoVisible] = useState<boolean>(true);
  const [isMobileModal, setIsMobileModal] = useState<boolean>(false);
  const [isParentOnboarding, setIsParentOnboarding] = useState<boolean>(false);
  const [isStudentInTime, setIsStudentInTime] = useState<boolean>(true);
  const [testDevices, setTestDevices] = useState<boolean>(false);
  const [cameraWorking, setCameraWorking] = useState<boolean>(false);
  const [micWorking, setMicWorking] = useState<boolean>(false);
  const [mediaTestRun, setMediaTestRun] = useState<boolean>(false);
  const [permissionsGranted, setPermissionsGranted] = useState<boolean | null>(true);
  const [previousCameraStatus, setPreviousCameraStatus] = useState<boolean | null>(null);

  const { data: JoinClassRoomData } = useGetClassJoinTokenQuery(
    { authToken: String(id) },
    { skip: !id },
  );

  const { data: studentParentDetails } = useGetStudentParentDetailsForInClassQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  // Memoized values
  const lectureName = useMemo(() => {
    const { lectureConfigurationName, classConfigurationName, name } =
      JoinClassRoomData?.data || {};
    return lectureConfigurationName || classConfigurationName || name || '';
  }, [JoinClassRoomData?.data]);

  const parentData = useMemo(() => {
    return (
      studentParentDetails &&
      isArray(studentParentDetails?.data) &&
      studentParentDetails?.data.some((data) => data?.members?.userType === USER_TYPE.GUARDIAN)
    );
  }, [studentParentDetails]);

  // Token management
  useEffect(() => {
    if (!Cookie.get(`${CONFIG.VITE_BASE_DOMAIN_ENV}CoachToken`)) {
      Cookie.set(`${CONFIG.VITE_BASE_DOMAIN_ENV}CoachToken`, loginTempToken || '');
    }
  }, [loginTempToken]);

  // Device testing functions
  const testCam = useCallback(async () => {
    if (isTestingRef.current) return;
    isTestingRef.current = true;

    try {
      const result = await testVideoDevice({
        videoTrack: meeting.self.videoTrack,
        studentId: Number(JoinClassRoomData?.data?.studentId),
        classId: Number(JoinClassRoomData?.data?.classId),
        setCameraWorking,
        setCameraLogTriggered: () => {},
        cameraLogTriggered: false,
        previousCameraStatus,
        isVideoEnabled: meeting.self.videoEnabled,
      });
      setPreviousCameraStatus(result);
      return result;
    } catch (error) {
      datadogLogs.logger.error(
        `Camera test failed of ${JoinClassRoomData?.data?.studentId} and classId:${JoinClassRoomData?.data?.classId}`,
        {
          error,
          classId: JoinClassRoomData?.data?.classId,
          studentId: JoinClassRoomData?.data?.studentId,
        },
      );
      setCameraWorking(false);
      return false;
    } finally {
      isTestingRef.current = false;
    }
  }, [
    JoinClassRoomData?.data?.classId,
    JoinClassRoomData?.data?.studentId,
    meeting.self.videoTrack,
    previousCameraStatus,
    meeting.self.videoEnabled,
  ]);

  const testMic = useCallback(async () => {
    if (isTestingRef.current) return;
    isTestingRef.current = true;
    try {
      const browserInfo = getBrowserInfo();
      return await testAudioDevice({
        track: meeting.self.audioTrack,
        config: {
          studentId: Number(JoinClassRoomData?.data?.studentId),
          classId: Number(JoinClassRoomData?.data?.classId),
          browserInfo: browserInfo?.name,
        },
      });
    } catch (error) {
      datadogLogs.logger.error(`Microphone test failed`, {
        error,
        classId: JoinClassRoomData?.data?.classId,
        studentId: JoinClassRoomData?.data?.studentId,
      });
      setMicWorking(false);
      return false;
    } finally {
      isTestingRef.current = false;
    }
  }, [
    JoinClassRoomData?.data?.classId,
    JoinClassRoomData?.data?.studentId,
    meeting.self.audioTrack,
  ]);

  // Parent onboarding modal
  useEffect(() => {
    if (studentParentDetails && !parentData) {
      setIsModalOpen(true);
    }
  }, [parentData, studentParentDetails]);

  useEffect(() => {
    if (isParentOnboarding) {
      setIsModalOpen(false);
    }
  }, [isParentOnboarding]);

  // Media test management
  const mediaTestPassed = useMemo(() => cameraWorking && micWorking, [cameraWorking, micWorking]);
  const showMediaButton = mediaTestRun && (!mediaTestPassed || !mediaTestRun);

  const runMediaTest = useCallback(
    async ({ mic, cam }: { mic: boolean; cam: boolean }) => {
      if (deviceTestTimeoutRef.current) {
        clearTimeout(deviceTestTimeoutRef.current);
      }

      const testResults = { camera: false, microphone: false };

      try {
        if (mic) {
          testResults.microphone = (await testMic()) ? true : false;
        }
        if (cam) {
          testResults.camera = (await testCam()) || false;
        }

        setMediaTestRun(true);
        return testResults.camera && testResults.microphone;
      } catch (error) {
        datadogLogs.logger.error('Media test failed', { error });
        return false;
      }
    },
    [testCam, testMic],
  );

  useEffect(() => {
    if (audioPermissionDenied || videoPermissionDenied) {
      checkPermissions();
    }
  }, [audioPermissionDenied, checkPermissions, videoPermissionDenied]);

  // Device testing effects
  useEffect(() => {
    if (videoStatus && permissionsGranted) {
      testCam();
    } else {
      setCameraWorking(false);
    }
  }, [videoStatus, permissionsGranted, testCam]);

  useEffect(() => {
    if (audioStatus && permissionsGranted) {
      testMic();
    } else {
      setMicWorking(false);
    }
  }, [audioStatus, permissionsGranted, testMic]);

  // Join meeting
  const joinMeeting = useCallback(
    async ({ isMediaTest }: { isMediaTest: boolean }) => {
      try {
        if (!isMediaTest) {
          await meeting?.joinRoom();
          return;
        }
        const testPassed = await runMediaTest({ mic: true, cam: true });
        if (testPassed) {
          await meeting?.joinRoom();
        } else {
          setTestDevices(true);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        datadogLogs.logger.warn('Error joining meeting', {
          error: {
            errorCode: error?.errorCode,
            errorMessage: error?.errorMessage,
            errorName: error?.errorName,
          },
          meeting: {
            meetingId: meeting?.meta?.meetingId,
            roomName: meeting?.meta?.meetingTitle,
            userId: meeting?.self?.userId,
            sessionId: meeting?.meta?.sessionId,
          },
        });
        window.location.reload();
      }
    },
    [meeting, runMediaTest],
  );

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        if (showMediaButton || testDevices) {
          if (!mediaTestPassed) {
            return;
          }
          joinMeeting({ isMediaTest: false });
        } else {
          joinMeeting({ isMediaTest: true });
        }
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [joinMeeting, mediaTestPassed, showMediaButton, testDevices]);

  // Leave meeting, disabling the media devices before leaving
  const handleClose = useCallback(() => {
    try {
      if (meeting?.self?.videoEnabled) {
        meeting.self.disableVideo();
      }
      if (meeting?.self?.audioEnabled) {
        meeting.self.disableAudio();
      }
      navigate(STUDENT_PATHS.STUDENT_DASHBOARD);
    } catch (error) {
      datadogLogs.logger.error('Error leaving meeting', { error });
    }
  }, [meeting.self, navigate]);

  // Device change handlers
  const handleCameraChange = useCallback(
    async (deviceId: string) => {
      try {
        const currentDevices = await meeting?.self?.getCurrentDevices();
        if (currentDevices?.video?.deviceId !== deviceId) {
          await meeting?.self?.disableVideo();
          await navigator.mediaDevices.getUserMedia({
            video: { deviceId: { exact: deviceId } },
          });
          await meeting?.self?.enableVideo();
          await runMediaTest({ mic: false, cam: true });
        }
      } catch (error) {
        datadogLogs.logger.error('Error changing camera', { error });
      }
    },
    [meeting?.self, runMediaTest],
  );

  const handleMicrophoneChange = useCallback(
    async (deviceId: string) => {
      try {
        const currentDevices = await meeting?.self?.getCurrentDevices();
        if (currentDevices?.audio?.deviceId !== deviceId) {
          await meeting?.self?.disableAudio();
          await navigator.mediaDevices.getUserMedia({
            audio: { deviceId: { exact: deviceId } },
          });
          await meeting?.self?.enableAudio();
          await runMediaTest({ mic: true, cam: false });
        }
      } catch (error) {
        datadogLogs.logger.error('Error changing microphone', { error });
      }
    },
    [meeting?.self, runMediaTest],
  );

  // Video brightness monitoring
  useEffect(() => {
    if (!videoEnabled) return;

    const { videoTrack } = meeting.self;
    if (!videoTrack) return;

    const videoStream = new MediaStream();
    videoStream.addTrack(videoTrack);
    const video = document.createElement('video');
    video.style.width = '240px';
    video.style.height = '180px';
    video.muted = true;
    video.srcObject = videoStream;

    const handleCanPlay = () => {
      video.play();
    };

    video.addEventListener('canplay', handleCanPlay);

    const canvas = document.createElement('canvas');
    canvas.width = 240;
    canvas.height = 180;
    const ctx = canvas.getContext('2d', { willReadFrequently: true })!;

    const interval = setInterval(() => {
      const brightness = getBrightness(video, canvas, ctx);
      setVideoVisible(brightness >= 0.2);
    }, 1000);

    return () => {
      clearInterval(interval);
      video.removeEventListener('canplay', handleCanPlay);
      video.srcObject = null;
    };
  }, [meeting?.self, videoEnabled]);

  // Mobile device handling
  useEffect(() => {
    setIsMobileModal(isMobileScreen);
  }, [isMobileScreen]);

  // Modified trackDeviceLogs to be more strict
  const trackDeviceLogs = useCallback(
    (forceUpdate = false) => {
      try {
        if (!JoinClassRoomData?.data?.studentId || !JoinClassRoomData?.data?.classId) {
          return false;
        }

        // Skip this update if there are no valid devices to report
        if (!audioDevices?.length && !videoDevices?.length && !speakerDevices?.length) {
          return false;
        }

        const deviceStateInfo = {
          availableDevices: {
            audio: audioDevices,
            video: videoDevices,
            speaker: speakerDevices,
          },
          activeDevices: {
            audio: currentSelectedDevice?.audio || 'default',
            video: currentSelectedDevice?.video || 'default',
            speaker: currentSelectedDevice?.speaker || 'default',
          },
          permissionStatus: {
            browser: {
              camera: permissionDenialSource?.browser?.camera
                ? DevicePermissionStatus.DENIED
                : DevicePermissionStatus.GRANTED,
              microphone: permissionDenialSource?.browser?.microphone
                ? DevicePermissionStatus.DENIED
                : DevicePermissionStatus.GRANTED,
            },
            system: {
              camera: permissionDenialSource?.system?.camera
                ? DevicePermissionStatus.DENIED
                : DevicePermissionStatus.GRANTED,
              microphone: permissionDenialSource?.system?.microphone
                ? DevicePermissionStatus.DENIED
                : DevicePermissionStatus.GRANTED,
            },
          },
          errorMessages,
          cameraInUse,
        };

        const userInfo = {
          userId: Number(JoinClassRoomData?.data?.studentId),
          userName: userName || 'Student',
          userType: USER_TYPE.STUDENT,
          classId: String(JoinClassRoomData?.data?.classId),
          batchId: JoinClassRoomData?.data?.batchId || 0,
          batchName: JoinClassRoomData?.data?.name || '',
        };

        return prepareDeviceLogsPayload(
          userInfo,
          deviceStateInfo,
          async (params) =>
            createOrUpdateUserDeviceLog(params)
              .unwrap()
              .finally(() => resetDeviceLogsTracker()),
          forceUpdate,
        );
      } catch (error) {
        datadogLogs.logger.error('Error in trackDeviceLogs', { error });
        return false;
      }
    },
    [
      JoinClassRoomData?.data,
      audioDevices,
      videoDevices,
      speakerDevices,
      currentSelectedDevice,
      permissionDenialSource,
      errorMessages,
      cameraInUse,
      createOrUpdateUserDeviceLog,
      userName,
    ],
  );

  // Track active device changes separately - MUCH HIGHER DEBOUNCE
  useEffect(() => {
    // This effect tracks changes to the active/selected devices
    if (audioDevices?.length > 0 || videoDevices?.length > 0 || speakerDevices?.length > 0) {
      // Clear any existing timeout to avoid multiple calls
      if (activeDeviceTimeoutRef.current) {
        clearTimeout(activeDeviceTimeoutRef.current);
      }

      // Use a MUCH longer timeout to debounce multiple rapid changes
      activeDeviceTimeoutRef.current = setTimeout(() => {
        // Only try to update if it's been at least 3 seconds since the last attempt
        const now = Date.now();
        if (now - lastDeviceChangeRef.current > 3000) {
          lastDeviceChangeRef.current = now;
          trackDeviceLogs(false); // Not forced, will be filtered by internal rate limits
        }
      }, 5000); // Much longer 5 second debounce

      return () => {
        if (activeDeviceTimeoutRef.current) {
          clearTimeout(activeDeviceTimeoutRef.current);
        }
      };
    }
  }, [
    // Only the current selected devices
    currentSelectedDevice?.audio,
    currentSelectedDevice?.video,
    currentSelectedDevice?.speaker,
    trackDeviceLogs,
    audioDevices?.length,
    videoDevices?.length,
    speakerDevices?.length,
  ]);

  // COMBINE permission and device handlers into a single effect with better debouncing
  useEffect(() => {
    // This combined effect watches for ALL device-related changes
    // We'll use lastDeviceChangeRef to enforce a 30-second minimum interval
    const now = Date.now();
    const timeSinceLastUpdate = now - lastDeviceChangeRef.current;

    // Skip updates if it hasn't been at least 30 seconds
    if (timeSinceLastUpdate < 3000) {
      return; // Exit early without doing anything
    }

    // If we have devices and enough time has passed, try to update
    if (
      (audioDevices?.length > 0 || videoDevices?.length > 0 || speakerDevices?.length > 0) &&
      timeSinceLastUpdate >= 3000
    ) {
      // Use non-forced update - let the internal logic decide if it's significant
      const timeoutRef = setTimeout(() => {
        trackDeviceLogs(false); // NOT forced - let internal rate limiting decide
        lastDeviceChangeRef.current = Date.now(); // Update the timestamp
      }, 3000); // 3 second delay to allow things to settle

      return () => clearTimeout(timeoutRef);
    }
  }, [
    // Include all device-related dependencies
    videoPermissionDenied,
    audioPermissionDenied,
    permissionDenialSource?.browser?.camera,
    permissionDenialSource?.browser?.microphone,
    permissionDenialSource?.system?.camera,
    permissionDenialSource?.system?.microphone,
    currentSelectedDevice?.audio,
    currentSelectedDevice?.video,
    currentSelectedDevice?.speaker,
    audioDevices?.length,
    videoDevices?.length,
    speakerDevices?.length,
    trackDeviceLogs,
  ]);

  // Keep the device change event handler for actual hardware changes
  useEffect(
    () => {
      // Device change handler with strict debouncing
      const handleDeviceChange = () => {
        // Only process once per 3 seconds MINIMUM - use shared timestamp ref
        const now = Date.now();
        if (now - lastDeviceChangeRef.current > 3000) {
          // 3 second minimum between ANY calls
          lastDeviceChangeRef.current = now;

          // Update permissions and device lists first
          checkPermissions().then(() => {
            // After a delay, check if update is needed - NEVER force
            setTimeout(() => {
              if (
                audioDevices?.length > 0 ||
                videoDevices?.length > 0 ||
                speakerDevices?.length > 0
              ) {
                // NEVER force update for device changes - let internal rate limiting decide
                trackDeviceLogs(false);
              }
            }, 2000);
          });
        }
      };

      handleDeviceChange();
    },
    [audioDevices?.length, checkPermissions, speakerDevices?.length, trackDeviceLogs, videoDevices?.length],
  );

  return (
    <motion.div
      initial='hidden'
      animate='visible'
      variants={CONTAINER_VARIANTS}
      className='flex items-center justify-center min-h-screen transition duration-100 ease-in translate-x-0'
    >
      <div className='relative w-full max-w-5xl pt-4 pb-8 mx-4 shadow-lg bg-primary-850 rounded-3xl'>
        {/* Header */}
        {JoinClassRoomData && (
          <div className='flex items-center justify-between mx-6'>
            <div className='flex items-center justify-between'>
              <img src={InClassUprioLogo} alt='logo' />
              <div className='h-10 ml-4 mr-2 border-l-2 border-white opacity-20' />
              <Topic subject={JoinClassRoomData?.data?.subject} topic={lectureName} />
            </div>
            <div className='flex items-center gap-4'>
              <NetworkCheck
                onStatusChange={(isGoodConnection) => onStatusChange(isGoodConnection)} // here we don't have to make the onChange status for any refetching
              />
              <ClassStarted startTime={new Date(JoinClassRoomData?.data?.meetingStartTime)} />
            </div>
          </div>
        )}
        {/* Body */}
        <div className='flex flex-col items-center w-full my-6'>
          <div className='flex flex-col items-center py-6'>
            <p className='text-base text-primary-400 2xl:text-lg'>
              Please ensure that your video and audio remain active at all times.
            </p>
          </div>
          {/* Video and Device Error Container with consistent width */}
          <div className='flex flex-wrap items-start justify-center w-full gap-6 px-6 h-96'>
            {/* Video Preview - Fixed width container */}
            <VideoPreview
              videoPermissionDenied={videoPermissionDenied}
              audioPermissionDenied={audioPermissionDenied}
              cameraInUse={cameraInUse}
              permissionsGranted={permissionsGranted}
              setPermissionsGranted={setPermissionsGranted}
              videoVisible={videoVisible}
              videoEnabled={videoEnabled}
              userName={userName}
              joinMeeting={joinMeeting}
            />

            {/* Device Error State - Fixed width container */}
            <div className='w-[360px] lg:w-[400px] h-96 flex-shrink-0'>
              {/* Device Controls */}
              <DeviceErrorState
                onCameraChange={handleCameraChange}
                onMicrophoneChange={handleMicrophoneChange}
                className='w-full'
              />
            </div>
          </div>
        </div>
        {/* Controls */}
        <SetupScreenControls
          handleClose={handleClose}
          showMediaButton={showMediaButton}
          testDevices={testDevices}
          isStudentInTime={isStudentInTime}
          mediaTestPassed={mediaTestPassed}
          joinMeeting={joinMeeting}
        />
        {/* Countdown Timer */}
        {isStudentInTime && (
          <div className='absolute top-0 left-0 z-30 flex flex-col items-center justify-center text-white rounded-3xl backdrop-blur-md size-full'>
            You will be able to access the class in
            <Countdown
              date={new Date(meetingStartTime || '').getTime() - JOINING_ALLOWED_TIME}
              precision={0}
              renderer={({ minutes, seconds, completed }) => {
                if (completed) {
                  setIsStudentInTime(false);
                  return null;
                } else {
                  setIsStudentInTime(true);
                  return (
                    <span>
                      {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
                    </span>
                  );
                }
              }}
            />
          </div>
        )}
        {/* MODAL FOR CHECKING PARENT HAS ONBOARDED */}
        <Modal
          heading='Parent Onboarding'
          children={
            <div>
              <ParentOnboarding isParentOnboarding={setIsParentOnboarding} />
            </div>
          }
          showCloseBtn={false}
          openModal={isModalOpen}
          setOpenModal={setIsModalOpen}
        />
        {/*
         * MODAL FOR SHOWING DETAILS BASED ON THE JOIN THROUGH
         * IF WE JOIN THROUGH MOBILE ASKING STUDENT TO JOIN USING LAPTOP OR TABLET
         * IF WE JOIN THROUGH TABLET AND SCREEN ORIENTATION IS PORTRAIT MEANS ASKING THE STUDENT TO USE LANDSCAPE
         */}
        <InClassModal
          showCloseBtn={false}
          size={'md'}
          openModal={isMobileModal}
          setOpenModal={setIsMobileModal}
        >
          <ScreenOrientation deviceType={deviceType} orientation={orientation} />
        </InClassModal>
      </div>
    </motion.div>
  );
};

export default StudentSetupScreen;
