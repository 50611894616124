import moment from 'moment';

import { CompassLight, rightArrow, ScienceIcon, SubjectIconWhite } from 'assets/svg';
import { Card } from 'components/common';
import { Common } from 'configs/common';

import { CLASS_NOTES_FORMATTED_TYPES, SUBJECTS } from 'configs';
import { TopicCardProps } from 'types';
import { formattedClassType } from 'utils';

export default function ClassNotesCard({
  time,
  lectureName,
  onClick,
  selectedClassId,
  cardClassId,
  subjectId,
  subjectName,
  lectures,
  classType,
}: TopicCardProps) {
  const subName = subjectName
    ? subjectName
    : subjectId
      ? Common.SUBJECT_NAMES[subjectId as keyof typeof Common.SUBJECT_NAMES]
      : '';
  const isSelected = cardClassId === selectedClassId;

  return (
    <div onClick={() => onClick(cardClassId)}>
      <Card
        className={`flex-1 rounded-[20px] bg-white m-2 p-4 font-poppins font-medium shadow-md 
        ${isSelected ? 'border-2 border-primary-500' : 'hover:bg-primary-500/20'} 
        ${lectures?.length === 0 ? 'cursor-not-allowed' : 'hover:cursor-pointer'}`}
      >
        <div>
          <div className='flex items-center justify-between'>
            <span className='text-xs'>{moment(time?.startTime).format('ll')}</span>
            <span
              className={`text-[10px] 2xl:text-xs ${lectures?.length === 0 && 'bg-semantic-red-500'} text-white px-2 py-1 rounded-full`}
            >
              {lectures?.length === 0 && 'No Content'}
            </span>
          </div>
          <div className='my-2'>
            <h5 className='text-sm font-semibold'>{lectureName}</h5>
          </div>
          <div className='flex flex-col justify-between md:flex-row'>
            <div className='flex items-center gap-2 mb-2 md:mb-0'>
              <span className='flex items-center gap-2 text-xs font-semibold tracking-widest uppercase text-primary-200 lg:text-complementary-colors-50'>
                {subName && (
                  <img
                    src={
                      subName === SUBJECTS.MATHS
                        ? CompassLight
                        : subName === SUBJECTS.SCIENCE
                          ? ScienceIcon
                          : SubjectIconWhite
                    }
                    alt='subject icon'
                    className='w-4 p-1 rounded-md md:w-5 bg-primary-500'
                  />
                )}
                {subName || ''}
              </span>
              <span
                className={`text-xs py-1 capitalize px-2 rounded-full 
                  ${
                    formattedClassType(classType).toString() === CLASS_NOTES_FORMATTED_TYPES.TEACH
                      ? 'bg-semantic-green-100 text-semantic-green-700'
                      : formattedClassType(classType).toString() ===
                          CLASS_NOTES_FORMATTED_TYPES.EVALUATION
                        ? 'bg-semantic-orange-100 text-semantic-orange-700'
                        : formattedClassType(classType).toString() ===
                            CLASS_NOTES_FORMATTED_TYPES.INTERVENTION
                          ? 'bg-semantic-red-100 text-semantic-red-700'
                          : 'bg-secondary-100 text-secondary-700'
                  }
                `}
              >
                {formattedClassType(classType)}
              </span>
            </div>
            {lectures?.length > 0 && (
              <div className='flex justify-end'>
                <img src={rightArrow} alt={'right-arrow'} />
              </div>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
}
