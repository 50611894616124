import React, { forwardRef, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { ClassDetailCardProps, PracticeSheetEntity } from 'types/reports';
import ChapterClassCard from '../ChapterClassCard';
import { VIEW_LESS, VIEW_MORE } from 'configs/reports';
import { useWindowDimensions } from 'hooks';

const ClassDetailCard = forwardRef<HTMLDivElement, ClassDetailCardProps>(
  ({ chapterClasses, isSelected = true, onClick, className, practiceSheets }, ref) => {
    const { isMobileScreen } = useWindowDimensions();
    const [lineHeight, setLineHeight] = useState<number>(0);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const contentRef = useRef<HTMLDivElement | null>(null);
    let practiceSheetIndex = 0; // Initialize pointer for Practice Sheets
    const totalPracticeSheets = practiceSheets?.length;
    const totalClasses = chapterClasses?.length;

    useEffect(() => {
      setIsMobile(isMobileScreen);
    }, [isMobileScreen]);

    // Dynamically observe changes in content height
    useEffect(() => {
      if (contentRef.current) {
        const contentElement = contentRef.current;

        const updateLineHeight = () => {
          const wrappers = contentElement.querySelectorAll('.card-wrapper');
          if (wrappers.length > 0) {
            const firstDot = wrappers[0].querySelector('.dot');
            const lastDot = wrappers[wrappers.length - 1].querySelector('.dot');

            if (firstDot && lastDot) {
              const firstDotRect = firstDot.getBoundingClientRect();
              const lastDotRect = lastDot.getBoundingClientRect();
              const height = lastDotRect.top - firstDotRect.top + 16;
              setLineHeight(height);
              setIsVisible(true);
            }
          } else {
            setLineHeight(0);
            setIsVisible(false);
          }
        };

        // Use ResizeObserver to track changes in size dynamically
        const resizeObserver = new ResizeObserver(updateLineHeight);
        resizeObserver.observe(contentElement);

        // Initial calculation
        updateLineHeight();

        return () => {
          resizeObserver.disconnect();
        };
      }
    }, [chapterClasses]);

    const toggleExpand = () => {
      if (contentRef.current && isMobile) {
        setIsExpanded((prev) => !prev);
      }
    };

    return (
      <div
        className={classNames(
          'relative bg-white rounded-lg pt-3 border-2 border-teal-200 transition-all duration-200 ease-in-out',
          isSelected &&
            "shadow-[0_16px_32px_-4px_rgba(12,12,13,0.1)] before:content-[''] before:absolute before:top-5 before:h-[calc(100%-42px)] before:left-[-2px] before:w-1 before:bg-teal-500 before:rounded-tr-lg before:rounded-br-lg",
          className,
        )}
        ref={ref}
        onClick={onClick}
      >
        <div className='px-4 mb-3'>
          <h2 className='flex items-center justify-between text-sm font-semibold text-neutral-950 font-sora'>
            Class Details
          </h2>
        </div>

        <div
          ref={contentRef}
          className={classNames(
            'relative overflow-hidden transition-all duration-300 px-4 pb-3',
            isVisible &&
              'before:opacity-100 before:absolute before:left-4 before:top-5 before:w-[2px] before:h-[calc(var(--line-height,0)-16px)] before:border-l-2 before:border-dashed before:border-secondary-200',
            isExpanded ? 'max-h-full' : isMobile && 'max-h-[284px]',
          )}
          style={
            {
              '--line-height': `${lineHeight}px`,
            } as React.CSSProperties
          }
        >
          {chapterClasses?.map((chapterClass, index) => {
            const isLastClass = index === chapterClasses.length - 1;
            let practiceSheetsToAssign: PracticeSheetEntity[] = [];

            // Logic for assigning practice sheets (same as original)
            if (totalClasses > totalPracticeSheets) {
              const remainingClasses = totalClasses - index;
              if (remainingClasses <= totalPracticeSheets) {
                practiceSheetsToAssign = practiceSheets?.slice(
                  practiceSheetIndex,
                  practiceSheetIndex + 1,
                );
                practiceSheetIndex += 1;
              }
            } else if (totalClasses <= totalPracticeSheets) {
              if (isLastClass) {
                practiceSheetsToAssign = practiceSheets?.slice(practiceSheetIndex);
              } else {
                practiceSheetsToAssign = practiceSheets?.slice(
                  practiceSheetIndex,
                  practiceSheetIndex + 1,
                );
                practiceSheetIndex += 1;
              }
            }

            return (
              <div key={chapterClass?.id} className='relative pl-5 mb-6 last:mb-0 card-wrapper'>
                <div className='absolute bg-teal-700 -left-1 top-[10px] w-3 h-3 rounded-full border-2 border-teal-150 z-10 dot'></div>
                <ChapterClassCard
                  chapterClass={chapterClass}
                  practiceSheetToRender={practiceSheetsToAssign}
                />
              </div>
            );
          })}
        </div>

        {isMobile && (
          <div className='flex justify-center w-full p-4 shadow-[0_0_24px_-4px_rgba(12,12,13,0.12)]'>
            <button
              className='text-sm font-medium transition-colors duration-300 ease-linear text-primary-400 hover:text-primary-600'
              onClick={(e) => {
                e.stopPropagation();
                toggleExpand();
              }}
            >
              {isExpanded ? VIEW_LESS : VIEW_MORE}
            </button>
          </div>
        )}
      </div>
    );
  },
);

export default ClassDetailCard;
