/* eslint-disable react-hooks/exhaustive-deps */
import { BackHeader } from 'components/screens/reports/BackHeader';
import PreInterventionCard from 'components/screens/reports/PreInterventionCard';
import SectionHeader from 'components/screens/reports/SectionHeader';
import { REINFORCEMENT_GAPS } from 'configs/reports';
import { processInterventionData } from 'configs/studentReport';
import { ConnectionContext, useConnectionContext } from 'contexts/ConnectionContext';
import { get, size, sumBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useGetRegularAndInterventionQcGapsComparisonQuery } from 'store/apiSlices/reports.apiSlice';
import { Connection, Intervention, IPostIntervention, IPreIntervention } from 'types/reports';

const PreIntervention = () => {
  const { studentId, chapterId } = useParams();
  const navigate = useNavigate();
  const [connections, setConnections] = useState<Connection[]>([]);
  const [intervention, setIntervention] = useState<Intervention | null>(null);
  const [svgDimensions, setSvgDimensions] = useState({ width: 0, height: 0 });

  const { chapterRef, subjectId, chapterName } = useConnectionContext();
  const preInterventionRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const isAdminRoute = location.pathname.startsWith('/a');
  const prefix = isAdminRoute ? '/a' : '/p';

  const { data: interventionChaptersReportData } =
    useGetRegularAndInterventionQcGapsComparisonQuery(
      {
        studentId: studentId && !isNaN(Number(studentId)) ? Number(studentId) : null,
        subjectId: subjectId ? subjectId : null,
        chapterId: chapterId ? chapterId : null,
      },
      { skip: !studentId || isNaN(Number(studentId)) || !subjectId || !chapterId },
    );

  useEffect(() => {
    if (interventionChaptersReportData?.data) {
      const { regularQcGaps, interventionQcGaps, interventionQcIds } =
        interventionChaptersReportData.data;

      const processedPreIntervention: IPreIntervention = processInterventionData(regularQcGaps);
      const processedPostIntervention: IPostIntervention =
        processInterventionData(interventionQcGaps);

      setIntervention({
        preIntervention: processedPreIntervention,
        postIntervention: processedPostIntervention,
        interventionQcIds,
        regularQcGaps,
        interventionQcGaps,
      });
    }
  }, [interventionChaptersReportData]);

  useEffect(() => {
    const calculateConnections = () => {
      if (!containerRef.current || !chapterRef?.current || !preInterventionRef.current) {
        return;
      }

      const newConnections: Connection[] = [];
      const containerRect = containerRef.current.getBoundingClientRect();

      // if (chapterRef.current && preInterventionRef.current) {
      const chapterRect = chapterRef.current.getBoundingClientRect();
      const preInterventionRect = preInterventionRef.current.getBoundingClientRect();

      const config = {
        curveWidth: 40, // Horizontal spacing for the curve
        curveRadius: 30, // Radius for the curve
        minLineHeight: 10, // Minimum height for the line
      };

      // Calculate the connection points
      const startX = chapterRect.right - containerRect.left;
      const startY = chapterRect.top + 32 - containerRect.top;
      const endX = preInterventionRect.left - containerRect.left;
      const endY = preInterventionRect.top + 32 - containerRect.top;

      // Calculate path data
      const yDifference = Math.abs(endY - startY);
      const isDescending = endY > startY;
      const isNearlyHorizontal = yDifference < config.minLineHeight;

      let startCurve: string;
      let endCurve: string;
      let line: { top: number; left: number; height: number };

      if (isNearlyHorizontal) {
        // Handle nearly horizontal connections
        startCurve = `
                M ${startX} ${startY}
                H ${startX + config.curveWidth - config.curveRadius}
                Q ${startX + config.curveWidth} ${startY},
                  ${startX + config.curveWidth} ${startY}
              `;

        endCurve = `
                M ${endX - config.curveWidth} ${endY}
                H ${endX}
              `;

        line = {
          top: startY - 1,
          left: startX + config.curveWidth - 1,
          height: 2, // Minimal height for visual consistency
        };
      } else {
        // Handle curved connections
        const verticalDistance = Math.abs(endY - startY);
        const effectiveHeight = Math.max(verticalDistance - config.curveRadius * 2, 0);

        startCurve = `
                M ${startX} ${startY}
                H ${startX + config.curveWidth - config.curveRadius}
                Q ${startX + config.curveWidth} ${startY},
                  ${startX + config.curveWidth} ${
                    startY + (isDescending ? config.curveRadius : -config.curveRadius)
                  }
              `;

        endCurve = `
                M ${endX - config.curveWidth} ${
                  endY + (isDescending ? -config.curveRadius : config.curveRadius)
                }
                Q ${endX - config.curveWidth} ${endY},
                  ${endX - config.curveWidth + config.curveRadius} ${endY}
                H ${endX}
              `;

        line = {
          top: isDescending ? startY + config.curveRadius : endY + config.curveRadius,
          left: startX + config.curveWidth - 1,
          height: effectiveHeight,
        };
      }

      console.log(startCurve, endCurve, line);

      // Create the connection object
      newConnections.push({
        startCurve,
        endCurve,
        line,
        startDot: { x: startX, y: startY },
        endDot: { x: endX, y: endY },
      });
      // }

      // Update state with new connections and dimensions
      setConnections(newConnections);
      setSvgDimensions({
        width: containerRect.width,
        height: containerRect.height,
      });
    };

    calculateConnections();

    const observer = new ResizeObserver(calculateConnections);
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }
    if (chapterRef?.current) {
      observer.observe(chapterRef.current);
    }
    if (preInterventionRef.current) {
      observer.observe(preInterventionRef.current);
    }

    window.addEventListener('scroll', calculateConnections);
    window.addEventListener('resize', calculateConnections);

    return () => {
      observer.disconnect();
      window.removeEventListener('scroll', calculateConnections);
      window.removeEventListener('resize', calculateConnections);
    };
  }, [chapterRef?.current]);

  const isDetailsView = location.pathname.includes('details');

  const categoryCounts = {
    wellUnderstood: size(get(intervention, 'preIntervention.ni', [])),
    sillyMistakes: size(get(intervention, 'preIntervention.li', [])),
    needsReinforcement: sumBy(REINFORCEMENT_GAPS, (key) =>
      size(get(intervention, `preIntervention.${key}`, [])),
    ),
    notAssessed: size(get(intervention, 'preIntervention.not_assessed', [])),
  };

  const handleCardClick = () => {
    navigate(
      `${prefix}/reports/${studentId}/chapter/intervention/${chapterId}/pre-intervention/details`,
    );
  };

  return (
    <ConnectionContext.Provider
      value={{
        preInterventionRef: preInterventionRef,
        intervention: intervention,
        subjectId: subjectId,
      }}
    >
      <div className='flex flex-col min-w-full md:min-w-fit relative'>
        <div
          className='flex md:gap-20 relative z-0'
          style={{ flexDirection: 'row' }}
          ref={containerRef}
        >
          {/* Left Column */}
          <div
            className={`flex flex-col min-w-full md:min-w-fit w-full md:w-[320px] ${
              isDetailsView ? 'hidden md:flex' : ''
            }`}
          >
            {/* Mobile Header - Only visible on mobile and when not in details view */}
            {!isDetailsView && (
              <>
                <BackHeader
                  title='Pre-Intervention Chapter Level Understanding'
                  route={`${prefix}/reports/${studentId}/chapter/intervention`}
                />
                <div className='md:hidden my-3 px-4 py-3 bg-white rounded-[8px]'>
                  <div className='font-sora font-semibold text-sm text-neutral-600'>
                    Chapter: <span className='p303'>{chapterName || ''}</span>
                  </div>
                </div>
              </>
            )}

            {/* Desktop Header - Only visible on desktop */}
            <div className='hidden md:block'>
              <SectionHeader
                text='Pre-Intervention Chapter Level Understanding'
                textColor='accent-purple-900'
                backgroundColor='from-[#D7BBF3] to-[#EAD7FD]'
              />
            </div>

            <div className='w-full md:w-[320px]' ref={preInterventionRef}>
              <PreInterventionCard
                categoryCounts={categoryCounts}
                totalCategories={intervention?.preIntervention?.totalCategories || 0}
                onClick={handleCardClick}
                className='bg-white hover:shadow-lg transition-shadow'
                isSelected={true}
              />
            </div>
          </div>

          {/* Right Column */}
          <div className='transition-opacity duration-300 w-full'>
            <Outlet />
          </div>
        </div>

        {/* Connection Elements */}
        <div className='hidden md:block absolute inset-0 pointer-events-none'>
          {/* SVG Connections */}
          <svg
            className='absolute top-0 left-0'
            width={svgDimensions.width}
            height={svgDimensions.height}
            style={{ overflow: 'visible' }}
          >
            {connections.map((conn, index) => (
              <g key={`curves-${index}`}>
                {/* Start Curve */}
                {conn.startCurve && (
                  <path
                    d={conn.startCurve}
                    fill='none'
                    stroke='#B4B6C5'
                    strokeWidth='2'
                    strokeLinecap='round'
                    className='transition-all duration-300'
                  />
                )}

                {/* End Curve */}
                {conn.endCurve && (
                  <path
                    d={conn.endCurve}
                    fill='none'
                    stroke='#B4B6C5'
                    strokeWidth='2'
                    strokeLinecap='round'
                    className='transition-all duration-300'
                  />
                )}

                {/* Start Half-Cycle Dot (Y-axis) */}
                {conn.startDot && (
                  <path
                    d={`M ${conn.startDot.x},${conn.startDot.y - 5} 
                    A 5,5 0 1,1 ${conn.startDot.x},${conn.startDot.y + 5}`}
                    fill='#086ac5'
                  />
                )}

                {/* End Half-Cycle Dot (Y-axis) */}
                {conn.endDot && (
                  <path
                    d={`M ${conn.endDot.x},${conn.endDot.y - 5} 
                    A 5,5 0 1,0 ${conn.endDot.x},${conn.endDot.y + 5}`}
                    fill='#a54eef'
                  />
                )}
              </g>
            ))}
          </svg>

          {/* Vertical Connection Lines */}
          {connections.map(
            (conn, index) =>
              conn.line && (
                <div
                  key={`line-${index}`}
                  className='absolute bg-neutral-300 w-0.5 transition-all duration-300'
                  style={{
                    top: `${conn.line.top}px`,
                    left: `${conn.line.left}px`,
                    height: `${conn.line.height}px`,
                  }}
                />
              ),
          )}
        </div>
      </div>
    </ConnectionContext.Provider>
  );
};

export default PreIntervention;
