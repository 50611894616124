import { ConnectionQuality } from 'types';

export const getConnectionColor = (percentage: number): string => {
  if (percentage >= 75) return 'bg-semantic-green-500';
  if (percentage >= 50) return 'bg-semantic-orange-500';
  return 'bg-semantic-red-500';
};

export const getConnectionText = (quality: ConnectionQuality, isOnline: boolean): string => {
  if (!isOnline) return "You're offline";
  switch (quality) {
    case 'high':
      return 'Strong connection';
    case 'medium':
      return 'Good connection';
    case 'low':
      return 'Fair connection';
    case 'poor':
      return 'Poor connection';
    default:
      return 'Checking connection...';
  }
};
