import { AbsentIcon, CrossIcon, PracticeIcon, PresentIcon } from 'assets/svg';
import Tag from '../Tag';
import MetricItem from '../MetricItem';
import { CORRECT, SUBMITTED_STATUS } from 'configs/reports';

export interface PracticeSheetEntity {
  practiceSheetId: string;
  status: string;
  assignedOn: string;
  evaluations: PracticeEvaluationEntity[];
}

export interface PracticeEvaluationEntity {
  evaluationId: string;
  evaluationName: string;
  total: number;
  completed: number;
  questions: {
    questionId: string;
    questionStatus: string;
    score: string;
  }[];
}

interface PracticeSheetStatusProps {
  sheets: PracticeSheetEntity[];
  onClose?: () => void;
}

const PracticeSheetCard: React.FC<PracticeSheetEntity> = ({ status, evaluations }) => {
  const { evaluationName, total, completed, questions } = evaluations?.[0] || {};
  const isSubmitted = SUBMITTED_STATUS.includes(status);
  const attemptedQuestions = completed || 0;
  const correctQuestions = questions.filter((q) => q.score === CORRECT)?.length || 0;
  const correctPercentage = Math.round((correctQuestions / total) * 100);

  return (
    <div className='border border-blue-100 rounded-xl p-3'>
      <div className='flex items-center justify-between mb-8'>
        <div className='flex-1 flex items-center gap-3'>
          <div className='w-[32px] h-[32px] p-[7px] rounded-[50%] border border-neutral-100'>
            <img src={PracticeIcon} alt='practice icon' className='w-full h-full' />
          </div>
          <span className='flex-1 font-poppins text-sm font-semibold pr-2'>{evaluationName}</span>
        </div>
        {/* Use Tag component for the submission status */}
        {isSubmitted ? (
          <Tag
            iconLeft={PresentIcon}
            text='Submitted'
            className='success' // Apply styles for success
          />
        ) : (
          <Tag
            iconLeft={AbsentIcon}
            text='Not Submitted'
            className='error' // Apply styles for error
          />
        )}
      </div>

      <div className='flex gap-10 mb-4'>
        <MetricItem value={total} label='Total Questions' circleStyle='bg-neutral-200' />
        <MetricItem value={attemptedQuestions} label='Attempted' circleStyle='bg-neutral-200' />
      </div>
      <div>
        <MetricItem
          value={correctQuestions}
          percentage={correctPercentage}
          label='Correct Questions'
          circleStyle='bg-semantic-green-200'
        />
      </div>
    </div>
  );
};

const PracticeSheetStatus: React.FC<PracticeSheetStatusProps> = ({ sheets, onClose }) => {
  return (
    <div className='bg-white rounded-xl w-full max-w-2xl border border-blue-100'>
      <div
        style={{ boxShadow: '0px 16px 32px -4px #0c0c0d0d' }}
        className='flex items-center justify-between p-4 border-b border-gray-100'
      >
        <h2 className='font-sora font-semibold'>Practice Sheets Submission Status</h2>
        <button onClick={onClose} className='text-gray-500 hover:text-gray-700'>
          <img src={CrossIcon} alt='close popup' width={20} height={20} />
        </button>
      </div>

      <div className='p-4 space-y-4 max-h-[calc(100vh-200px)] overflow-y-auto'>
        {sheets.map((sheet) => (
          <PracticeSheetCard key={sheet.practiceSheetId} {...sheet} />
        ))}
      </div>
    </div>
  );
};

export default PracticeSheetStatus;
