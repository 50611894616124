import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import { PrimaryCloseIcon } from 'assets/svg';
import { IDrawerProps } from 'types/common';

import { DRAWER_VARIENTS } from 'configs';

export default function Drawer({
  isOpenDrawer,
  onClose,
  className,
  heading,
  showCloseBtn = true,
  children,
}: IDrawerProps) {
  return (
    <AnimatePresence>
      {isOpenDrawer && (
        <>
          <motion.div
            initial='closed'
            animate='open'
            exit='closed'
            variants={DRAWER_VARIENTS}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            className={`fixed top-0 right-0 z-50 min-h-screen
               w-[80%] overflow-y-auto rounded-l-3xl bg-white dark:bg-gray-800 shadow-lg`}
          >
            <div className='flex-1 h-full border-b-2 border-neutral-100'>
              <div className={classNames(`flex p-6 items-center justify-between`, className)}>
                <div className='text-base display-bold text-neutral-700 font-sora sm:text-lg lg:text-xl xl:text-2xl'>
                  {heading}
                </div>
                {showCloseBtn && (
                  <button className='p-3 rounded-md focus:outline-none' onClick={onClose}>
                    <img src={PrimaryCloseIcon} alt='Close button' />
                  </button>
                )}
              </div>
            </div>
            <div className='flex-1 h-full overflow-hidden'>{children}</div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className='fixed inset-0 z-40 bg-black bg-opacity-50'
            onClick={onClose}
          />
        </>
      )}
    </AnimatePresence>
  );
}
