import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ChapterWiseData,
  ExamQcLevelUnderstandingEntity,
  NotAttendedClassDetails,
  PracticeSheetEntity,
  QuestionEntity,
  StepLevelAnswers,
} from 'types/reports';

interface IReports {
  stepLevelData: StepLevelAnswers[];
  chapterWiseData?: ChapterWiseData[];
  selectedCategoryQuestionEntity: QuestionEntity[] | null;
  examPracticeSheetEntity: PracticeSheetEntity[] | null;
  notAttendedClassesInChapterExam: NotAttendedClassDetails[] | null;
  examQcLevelData: ExamQcLevelUnderstandingEntity | null;
}

const initialState: IReports = {
  chapterWiseData: undefined,
  stepLevelData: [],
  selectedCategoryQuestionEntity: null,
  examPracticeSheetEntity: null,
  notAttendedClassesInChapterExam: null,
  examQcLevelData: null,
};

export const reportsData = createSlice({
  name: 'reportsData',
  initialState,
  reducers: {
    setChapterWiseData: (state, action: PayloadAction<ChapterWiseData[]>) => {
      state.chapterWiseData = action.payload;
    },
    setChapterWiseStepLevelData: (state, action: PayloadAction<StepLevelAnswers[]>) => {
      state.stepLevelData = action.payload;
    },
    setSelectedCategoryQuestions: (state, action: PayloadAction<QuestionEntity[]>) => {
      state.selectedCategoryQuestionEntity = action.payload;
    },
    setExamChapterPracticeSheet: (state, action: PayloadAction<PracticeSheetEntity[]>) => {
      state.examPracticeSheetEntity = action.payload;
    },
    setExamQcLevelData: (state, action: PayloadAction<ExamQcLevelUnderstandingEntity>) => {
      state.examQcLevelData = action.payload;
    },
    setNotAttendedClassesInChapterExam: (
      state,
      action: PayloadAction<NotAttendedClassDetails[]>,
    ) => {
      state.notAttendedClassesInChapterExam = action.payload;
    },
  },
  selectors: {
    selectChapterWiseData: (state) => state?.chapterWiseData,
    selectChapterWiseStepLevelData: (state) => state?.stepLevelData,
    selectSelectedCategoryQuestions: (state) => state?.selectedCategoryQuestionEntity,
    selectExamChapterPracticeSheet: (state) => state?.examPracticeSheetEntity,
    selectNotAttendedClassesInChapterExam: (state) => state?.notAttendedClassesInChapterExam,
    selectExamQcLevelData: (state) => state?.examQcLevelData,
  },
});

export const {
  setChapterWiseData,
  setChapterWiseStepLevelData,
  setSelectedCategoryQuestions,
  setExamChapterPracticeSheet,
  setNotAttendedClassesInChapterExam,
  setExamQcLevelData,
} = reportsData.actions;

export const {
  selectChapterWiseData,
  selectChapterWiseStepLevelData,
  selectSelectedCategoryQuestions,
  selectExamChapterPracticeSheet,
  selectNotAttendedClassesInChapterExam,
  selectExamQcLevelData,
} = reportsData.selectors;
