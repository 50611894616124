export const ROUTES = {
  ROOT: '/',
  LIST_OF_CLASSES: '/list-of-classes',
  LOGIN: '/login',
  REGISTER: '/register',
  FORGET_PASSCODE: '/forget-passcode',
  ACCOUNTS: '/accounts',
  TERMS_AND_CONDITION: '/terms-and-conditions',
  CLASSROOM_INVITE: '/class/join/:id',
  PRACTICE_CLASS_QRCODE_CAPTURE: '/practice-evaluations',
};

export const PARENT_PATHS = {
  PARENT_DASHBOARD: '/p/home/:studentId',
  ALLCLASSES: '/p/classes/:studentId',
  PRACTICE_SHEET: '/p/practice/:studentId',
  CLASS_OVER: '/p/:studentId/class/:classId',
  REPORTS_MENU: '/p/reports/:studentId',
  REPORTS: '/p/reports/:studentId/chapter',
  QUESTION_CATEGORY: '/p/reports/:studentId/chapter/:chapterId/understanding',
  CHAPTER_LEVEL_UNDERSTANDING:
    '/p/reports/:studentId/chapter/:chapterId/understanding/category/:understandingLevel',
  REPORTS_STEP_LEVEL:
    '/p/reports/:studentId/chapter/:chapterId/understanding/category/:understandingLevel/:categoryId',
  INTERVENTION_REPORTS: '/p/reports/:studentId/chapter/intervention',
  PRE_INTERVENTION: '/p/reports/:studentId/chapter/intervention/:chapterId/pre-intervention',
  POST_INTERVENTION:
    '/p/reports/:studentId/chapter/intervention/:chapterId/pre-intervention/details',
  POST_INTERVENTION_QC:
    '/p/reports/:studentId/chapter/intervention/:chapterId/pre-intervention/details/category',
  IN_DEPTH_POST_INTERVENTION_QC:
    '/p/reports/:studentId/chapter/intervention/:chapterId/pre-intervention/details/category/:categoryId/details',
  IN_DEPTH_POST_INTERVENTION_QC_QUESTIONS:
    '/p/reports/:studentId/chapter/intervention/:chapterId/pre-intervention/details/category/:categoryId/details/questions',
  EXAM_STATUS_REPORTS: '/p/reports/:studentId/chapter/exam-status',
  EXAM_CHAPTERS_COVERED: '/p/reports/:studentId/chapter/exam-status/:examId',
  EXAM_CHAPTERS_COVERED_CLASS_DETAILS: '/p/reports/:studentId/chapter/exam-status/:examId/details',
  EXAM_CHAPTERS_COVERED_CLASS_DETAILS_PRACTICE:
    '/p/reports/:studentId/chapter/exam-status/:examId/details/submissionstatus',
  EXAM_CHAPTERS_COVERED_CLASS_DETAILS_NOT_ATTENDED:
    '/p/reports/:studentId/chapter/exam-status/:examId/details/classesnotattended',
  EXAM_CHAPTERS_WISE_PROGRESS: '/p/reports/:studentId/chapter/exam-status/:examId/details/progress',
  EXAM_CHAPTERS_WISE_PROGRESS_DETAILS:
    '/p/reports/:studentId/chapter/exam-status/:examId/details/progress/details',
  EXAM_CHAPTERS_WISE_PROGRESS_REVISION:
    '/p/reports/:studentId/chapter/exam-status/:examId/details/progress/revision',
};

export const STUDENT_PATHS = {
  STUDENT_DASHBOARD: '/s/home',
  STUDENT_CLASSES: '/s/classes',
  STUDENT_PRACTICE: '/s/practice',
  CLASS_NOTES: '/s/class-notes',
};

export const ADMIN_PATHS = {
  ADMIN_REPORTS_MENU: '/a/reports/:studentId',
  ADMIN_REPORTS: '/a/reports/:studentId/chapter',
  ADMIN_QUESTION_CATEGORY: '/a/reports/:studentId/chapter/:chapterId/understanding',
  ADMIN_CHAPTER_LEVEL_UNDERSTANDING:
    '/a/reports/:studentId/chapter/:chapterId/understanding/category/:understandingLevel',
  ADMIN_REPORTS_STEP_LEVEL:
    '/a/reports/:studentId/chapter/:chapterId/understanding/category/:understandingLevel/:categoryId',
  ADMIN_INTERVENTION_REPORTS: '/a/reports/:studentId/chapter/intervention',
  ADMIN_PRE_INTERVENTION: '/a/reports/:studentId/chapter/intervention/:chapterId/pre-intervention',
  ADMIN_POST_INTERVENTION:
    '/a/reports/:studentId/chapter/intervention/:chapterId/pre-intervention/details',
  ADMIN_POST_INTERVENTION_QC:
    '/a/reports/:studentId/chapter/intervention/:chapterId/pre-intervention/details/category',
  ADMIN_IN_DEPTH_POST_INTERVENTION_QC:
    '/a/reports/:studentId/chapter/intervention/:chapterId/pre-intervention/details/category/:categoryId/details',
  ADMIN_IN_DEPTH_POST_INTERVENTION_QC_QUESTIONS:
    '/a/reports/:studentId/chapter/intervention/:chapterId/pre-intervention/details/category/:categoryId/details/questions',
  ADMIN_EXAM_STATUS_REPORTS: '/a/reports/:studentId/chapter/exam-status',
  ADMIN_EXAM_CHAPTERS_COVERED: '/a/reports/:studentId/chapter/exam-status/:examId',
  ADMIN_EXAM_CHAPTERS_COVERED_CLASS_DETAILS:
    '/a/reports/:studentId/chapter/exam-status/:examId/details',
  ADMIN_EXAM_CHAPTERS_COVERED_CLASS_DETAILS_PRACTICE:
    '/a/reports/:studentId/chapter/exam-status/:examId/details/submissionstatus',
  ADMIN_EXAM_CHAPTERS_COVERED_CLASS_DETAILS_NOT_ATTENDED:
    '/a/reports/:studentId/chapter/exam-status/:examId/details/classesnotattended',
  ADMIN_EXAM_CHAPTERS_WISE_PROGRESS:
    '/a/reports/:studentId/chapter/exam-status/:examId/details/progress',
  ADMIN_EXAM_CHAPTERS_WISE_PROGRESS_DETAILS:
    '/a/reports/:studentId/chapter/exam-status/:examId/details/progress/details',
  ADMIN_EXAM_CHAPTERS_WISE_PROGRESS_REVISION:
    '/a/reports/:studentId/chapter/exam-status/:examId/details/progress/revision',
};
