import { Badge } from 'flowbite-react';
import Cookie from 'js-cookie';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { useState } from 'react';
import Countdown from 'react-countdown';
import { useNavigate } from 'react-router-dom';

import { DateIcon, SubjectIconWhite } from 'assets/svg';
import { BouncingDotsLoader, Card } from 'components/common';
import { PracticePapers } from 'components/screens';

import { CONFIG, EVALUATED_STATUS, LOG_TYPE, ROUTES, SHEET_NOT_FOUND, USER_TYPE } from 'configs';

import { useLogger } from 'hooks';
import { AllPracticeSheetsProps, IHandleStartEvaluation, IPracticeDoc } from 'types';
import { isMobileOrTablet } from 'utils';

export default function AllPracticeSheets({
  docs: practiceSheets,
  isStartPracticePaper,
  isLoading,
  submitted,
  type,
}: AllPracticeSheetsProps) {
  const navigate = useNavigate();
  const { logInfo } = useLogger();

  const [showData, setShowData] = useState<{
    practiceSheetId: string;
    evaluationId: string;
    isShowTheQuestion: boolean;
  }>({
    practiceSheetId: '',
    evaluationId: '',
    isShowTheQuestion: false,
  });

  /* Handle Start evaluation is based on the device */
  const handleStartBasedOntheMobileDevice = async ({
    practiceSheetId,
    evaluationId,
    studentId,
    evaluationStatus,
  }: IHandleStartEvaluation) => {
    try {
      logInfo(
        'Started handling evaluation initiation based on device type',
        { practiceSheetId, evaluationId },
        LOG_TYPE.INFO,
      );
      const result = await isMobileOrTablet();

      logInfo('Device check completed: Mobile or Tablet detected', { result }, LOG_TYPE.INFO);
      if (result && evaluationStatus !== EVALUATED_STATUS.EVALUATED) {
        const token = Cookie.get(`${CONFIG.VITE_BASE_DOMAIN_ENV}CoachToken`);
        const encodedToken = token ? btoa(JSON.stringify(token)) : '';
        const queryParams = new URLSearchParams({
          evaluationId: evaluationId,
          practiceSheetId: practiceSheetId,
          questionId: '',
          studentId: String(studentId),
          token: encodedToken,
          showQuestion: 'true',
        });
        logInfo('Navigating to mobile capture route', { queryParams }, LOG_TYPE.INFO);
        navigate(`${ROUTES.PRACTICE_CLASS_QRCODE_CAPTURE}?${queryParams.toString()}`);
      } else {
        logInfo(
          'Setting up data for non-mobile device',
          { practiceSheetId, evaluationId, isShowTheQuestion: true },
          LOG_TYPE.INFO,
        );
        setShowData({
          practiceSheetId,
          evaluationId,
          isShowTheQuestion: true,
        });
      }
    } catch (error) {
      logInfo('Error encountered during evaluation initiation', { error }, LOG_TYPE.ERROR);
    }
  };

  return (
    <>
      {!practiceSheets?.length ? (
        <>
          <div className='my-5 font-semibold text-center font-poppins text-neutral-950'>
            {SHEET_NOT_FOUND}
          </div>
        </>
      ) : (
        <div className=''>
          {isLoading || isEmpty(practiceSheets) ? (
            <div className='flex items-center justify-center flex-1 mx-2 xl:mx-6'>
              <BouncingDotsLoader />
            </div>
          ) : (
            <div
              className={`grid grid-cols-[1fr] overflow-y-auto sm:grid-cols-[1fr_1fr] md:grid-cols-[1fr_1fr_1fr] p-2 gap-3 mt-3 rounded-lg`}
            >
              {practiceSheets?.map((sheet: IPracticeDoc) => (
                <div key={sheet?.id} className=''>
                  <Card className={`rounded-2xl hover:shadow-sm bg-white`}>
                    <div className='flex justify-between p-4 lg:p-3 xl:p-4'>
                      {/* Class details */}
                      <div className='flex flex-col justify-between ml-1 lg:ml-2 min-h-40 xl:ml-0'>
                        <div>
                          <div className='flex flex-wrap items-center mb-1'>
                            <span className='inline-block p-2 mr-2 rounded-full bg-primary-500'>
                              <img
                                className='object-contain w-3 md:w-4 xl:w-5'
                                src={SubjectIconWhite}
                                alt=''
                              />
                            </span>
                            <h6 className='text-base font-semibold capitalize font-poppins text-primary-600'>
                              {sheet?.type}
                            </h6>
                          </div>
                        </div>
                        <h6 className='text-base text-primary-950 body-semibold md:text-lg lg:text-base xl:text-lg'>
                          {sheet?.evaluationName}
                        </h6>
                        <div className='flex items-center w-full'>
                          <span className='bg-primary-100 flex items-center text-neutral-900 font-poppins md:font-medium body-semibold rounded-2xl p-1 md:px-3 lg:px-2 xl:px-3 text-[10px] whitespace-nowrap xl:text-xs'>
                            <img className='w-5 p-1 lg:w-4 xl:w-5' src={DateIcon} alt='Date' />
                            {moment(sheet?.expiresOn).format('DD MMM YY')}
                          </span>
                        </div>
                      </div>

                      {/* Start Button */}
                      <div className='flex flex-col items-center justify-between pl-5'>
                        <div
                          className={'mb-2 font-medium rounded-full font-poppins whitespace-nowrap'}
                        >
                          {isStartPracticePaper ? (
                            <Badge
                              className={`px-2 py-1 text-[8px] md:text-sm text-white font-semibold text-center rounded-full 
                         ${
                           moment(sheet?.expiresOn).diff(moment(), 'days') <= 0
                             ? 'bg-semantic-red-500 opacity-85'
                             : moment(sheet?.expiresOn).diff(moment(), 'days') <= 3
                               ? 'bg-semantic-orange-500'
                               : 'bg-primary-500'
                         }
                        `}
                            >
                              {moment(sheet?.expiresOn)?.isBefore(moment()) ? (
                                <>Deadline Passed</>
                              ) : (
                                <span>
                                  Deadline:{' '}
                                  <Countdown
                                    date={moment(sheet?.expiresOn)?.toDate()}
                                    now={() => Date.now()}
                                    renderer={({ days, hours, minutes, seconds }) => {
                                      if (days > 0) {
                                        return <span>{`${days} day${days > 1 ? 's' : ''}`}</span>;
                                      } else if (hours > 0) {
                                        return <span>{`${hours}h ${minutes}min`}</span>;
                                      } else if (minutes > 0) {
                                        return <span>{`${minutes}min`}</span>;
                                      } else {
                                        return <span>{`${seconds}s`}</span>;
                                      }
                                    }}
                                  />
                                </span>
                              )}
                            </Badge>
                          ) : (
                            <Badge
                              className={`inline px-2 capitalize py-1 leading-3 text-[8px] md:text-sm text-white rounded-full sm:ml-2 md:px-4 ${sheet.evaluationStatus !== EVALUATED_STATUS.EVALUATED ? 'bg-semantic-orange-400' : 'bg-semantic-green-500'}`}
                            >
                              {sheet.evaluationStatus === EVALUATED_STATUS.EVALUATED
                                ? EVALUATED_STATUS?.EVALUATED
                                : 'Evaluating'}
                            </Badge>
                          )}
                        </div>

                        <button
                          onClick={() =>
                            handleStartBasedOntheMobileDevice({
                              practiceSheetId: sheet?.practiceSheetId,
                              evaluationId: sheet?.evaluationId,
                              studentId: sheet?.studentId,
                              evaluationStatus: sheet?.evaluationStatus,
                            })
                          }
                          className={`px-3 py-1 capitalize hover:cursor-pointer hover:scale-105 hover:bg-semantic-green-600 w-full text-sm md:text-base font-medium text-white rounded-lg bg-semantic-green-500`}
                        >
                          {type === USER_TYPE?.GUARDIAN
                            ? 'View'
                            : !submitted
                              ? 'Start'
                              : sheet.evaluationStatus === EVALUATED_STATUS.EVALUATED
                                ? 'Answers'
                                : 'Submitted'}
                        </button>
                      </div>
                    </div>
                  </Card>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {showData?.evaluationId && showData?.isShowTheQuestion && (
        <>
          <PracticePapers
            evaluationId={showData?.evaluationId}
            practiceSheetId={showData?.practiceSheetId}
            setShowData={() =>
              setShowData({
                practiceSheetId: '',
                evaluationId: '',
                isShowTheQuestion: false,
              })
            }
            submitted={submitted}
          />
        </>
      )}
    </>
  );
}
