import { forwardRef } from 'react';
import { FaChevronRight, FaStar } from 'react-icons/fa6';
import { ChapterItemProps } from 'types/reports';

const ChapterItem = forwardRef<HTMLDivElement, ChapterItemProps>(
  ({ chapter, onClick, isSelected }, ref) => {
    return (
      <div
        ref={ref}
        onClick={onClick}
        className={`p-3 cursor-pointer border space-y-2 rounded-[8px] transition-colors relative
          ${
            isSelected
              ? 'shadow-[0_2px_10px_0px_rgba(0,0,0,0.08)] border-neutral-100 before:absolute before:top-3 before:h-[calc(100%-24px)] before:left-0 before:w-1 before:bg-semantic-orange-400 before:rounded-r'
              : 'border-neutral-100 hover:border-neutral-200'
          }`}
      >
        <div className='flex items-center justify-between mb-1'>
          <span className='font-sora font-semibold text-neutral-700 text-sm'>
            {chapter.chapterName}
          </span>
          <FaChevronRight className='text-neutral-950' size={14} />
        </div>
        <div className='flex flex-col'>
          <div className='flex gap-1 items-center'>
            <div className='font-poppins text-xs font-semibold text-neutral-700'>
              Chapter level understanding:
            </div>
            <div className='flex gap-1'>
              {Array.from({ length: 5 }).map((_, idx) => (
                <FaStar
                  key={idx}
                  size={16}
                  className={`${
                    idx < chapter.understanding
                      ? 'text-yellow-400 fill-yellow-400'
                      : 'text-gray-200'
                  }`}
                />
              ))}
            </div>
          </div>
          <div className='font-poppins text-xs text-neutral-300 mt-[2px]'>
            {chapter.categories} categories
          </div>
        </div>
      </div>
    );
  },
);

export default ChapterItem;
