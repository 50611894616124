import { useWindowDimensions } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import {
  CategoryCounts,
  Chapters,
  ConnectionData,
  UnderstandingAndProgressCardProps,
} from 'types/reports';
import { useTopicConnection } from './ConnectionLine';
import BottomSheet from '../BottomSheet';
import RevisionPlanButton from '../RevisionPlanButton';
import UnderstandingLevelCard from './UnderstandingLevelCard';
import ChapterItem from './ChapterItem';

const UnderstandingAndProgressCard: React.FC<UnderstandingAndProgressCardProps> = ({
  data,
  defaultSubject,
  onUnderstaningCardClick,
  onClick,
  setShowMobileFooter,
}) => {
  const [selectedSubject, setSelectedSubject] = useState<string>(
    defaultSubject || Object.keys(data)[0],
  );
  const [selectedChapter, setSelectedChapters] = useState<Chapters | null>(null);
  const [selectedTopicIndex, setSelectedTopicIndex] = useState<number>(-1);
  const cardRef = useRef<HTMLDivElement>(null);
  const chapterRefs = useRef<(HTMLDivElement | null)[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const { isMobileScreen } = useWindowDimensions();

  useEffect(() => {
    if (data[selectedSubject]) {
      chapterRefs.current = new Array(data[selectedSubject].chapters.length).fill(null);
    }
  }, [selectedSubject, data]);

  const connection = useTopicConnection(
    chapterRefs.current,
    selectedTopicIndex,
    cardRef,
    !!selectedChapter && !isMobileScreen,
    containerRef,
  ) as ConnectionData | null;

  useEffect(() => {
    if (defaultSubject && data[defaultSubject]) {
      setSelectedSubject(defaultSubject);
      setSelectedChapters(null);
      setSelectedTopicIndex(-1);
    }
  }, [data, defaultSubject]);

  const handleSubjectChange = (subject: string): void => {
    if (subject !== selectedSubject) {
      setSelectedChapters(null);
      setSelectedTopicIndex(-1);
      setSelectedSubject(subject);
    }
  };

  const handleTopicClick = (chapter: Chapters, index: number): void => {
    if (chapter.chapterId === selectedChapter?.chapterId) {
      setSelectedChapters(null);
      setSelectedTopicIndex(-1);
    } else {
      setSelectedChapters(chapter);
      setSelectedTopicIndex(index);
      setShowMobileFooter(false);
    }
  };

  const calculateCategoryCounts = (chapter: Chapters): CategoryCounts => ({
    wellUnderstood: chapter?.stats?.ni?.length || 0,
    sillyMistakes: chapter?.stats?.li?.length || 0,
    needsReinforcement: (chapter?.stats?.hi?.length || 0) + (chapter?.stats?.snd?.length || 0),
    notAssessed: chapter?.stats?.not_assessed?.length || 0,
  });

  const handleContainerClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClick?.();
    }
  };

  const handleCloseUnderstanding = () => {
    setSelectedChapters(null);
    setSelectedTopicIndex(-1);
    setShowMobileFooter(true);
  };

  const renderUnderstandingCard = () => (
    <UnderstandingLevelCard
      onClick={onUnderstaningCardClick}
      categoryCounts={calculateCategoryCounts(selectedChapter!)}
      totalCategories={selectedChapter!.categories}
      selectedSubject={selectedSubject}
      selectedChapter={selectedChapter!}
    />
  );

  return (
    <div
      ref={containerRef}
      onClick={handleContainerClick}
      className={`flex ${
        isMobileScreen
          ? 'flex-col'
          : 'gap-[54px] border-[2px] border-semantic-warning-200 relative before:absolute before:top-3 before:h-[calc(100%-24px)] before:left-0 before:w-1 before:bg-semantic-orange-400 before:rounded-r'
      } items-start bg-white rounded-[8px] `}
    >
      <div
        onClick={handleContainerClick}
        className='w-full max-w-md bg-white rounded-[8px] p-4 flex flex-col gap-6'
      >
        <div className='border-[1.5px] border-neutral-100 rounded-[8px]'>
          <div className='border-b'>
            <div className='flex min-w-[340px]'>
              {Object.entries(data).map(([key, subject]) => (
                <button
                  key={key}
                  onClick={() => handleSubjectChange(key)}
                  className={`px-6 py-3 relative text-sm font-medium 
                    ${
                      selectedSubject === key
                        ? 'text-emerald-500'
                        : 'text-gray-500 hover:text-gray-700'
                    }`}
                >
                  {subject?.label}
                  {selectedSubject === key && (
                    <div className='absolute bottom-0 left-0 right-0 h-0.5 bg-emerald-500' />
                  )}
                </button>
              ))}
            </div>
          </div>

          <div className='p-3 max-h-[420px] overflow-y-auto' style={{ scrollbarWidth: 'none' }}>
            {data[selectedSubject] ? (
              <div className='flex flex-col gap-3'>
                {data[selectedSubject].chapters.map((chapter, index) => (
                  <ChapterItem
                    key={chapter?.chapterId}
                    ref={(el) => (chapterRefs.current[index] = el)}
                    chapter={chapter}
                    onClick={() => handleTopicClick(chapter, index)}
                    isSelected={selectedChapter?.chapterId === chapter.chapterId}
                  />
                ))}
              </div>
            ) : (
              <div className='text-center py-8 text-gray-500'>
                No data available for this subject
              </div>
            )}
          </div>
        </div>

        {!selectedChapter && !isMobileScreen && (
          <div className='pt-0'>
            <RevisionPlanButton onClick={onClick} className='w-full' />
          </div>
        )}
      </div>

      {/* Understanding Level Card - Desktop */}
      {!isMobileScreen && selectedChapter && (
        <div className='flex flex-col gap-6'>
          <div ref={cardRef} className='w-full'>
            {renderUnderstandingCard()}
          </div>
          <div className='pr-4 pb-4'>
            <RevisionPlanButton onClick={onClick} className='w-full' />
          </div>
        </div>
      )}

      {/* Understanding Level Card - Mobile BottomSheet */}
      {isMobileScreen && (
        <BottomSheet isVisible={!!selectedChapter} onClose={handleCloseUnderstanding}>
          {selectedChapter && <div className='p-4'>{renderUnderstandingCard()}</div>}
        </BottomSheet>
      )}

      {/* Connection lines */}
      {connection && selectedChapter && (
        <div className='absolute  left-[300px]  pointer-events-none'>
          <svg
            className={`absolute top-0 right-[0]
          `}
            style={{ overflow: 'visible' }}
            pointerEvents='none'
          >
            <path
              d={connection.startCurve}
              fill='none'
              stroke='#B4B6C5'
              strokeWidth='2'
              strokeLinecap='round'
            />
            <path
              d={connection.endCurve}
              fill='none'
              stroke='#B4B6C5'
              strokeWidth='2'
              strokeLinecap='round'
            />
            <path
              d={`M ${connection.startDot.x},${connection.startDot.y - 5} 
        A 5,5 0 1,1 ${connection.startDot.x},${connection.startDot.y + 5}`}
              fill='#10B981'
            />
            <path
              d={`M ${connection.endDot.x},${connection.endDot.y - 5} 
        A 5,5 0 1,0 ${connection.endDot.x},${connection.endDot.y + 5}`}
              fill='#10B981'
            />
            <line
              x1={connection.line.left + 1}
              y1={connection.line.top}
              x2={connection.line.left + 1}
              y2={connection.line.top + connection.line.height}
              stroke='#B4B6C5'
              strokeWidth='2'
            />
          </svg>
        </div>
      )}
    </div>
  );
};

export default UnderstandingAndProgressCard;
