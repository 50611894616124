import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { useUpdatePracticePaperSubmissionMutation } from 'store/apiSlices/studentDashboard.apiSlice';

import { Button } from 'components/common';

import { IPracticeSubmission } from 'types';
import { STUDENT_PATHS } from 'configs';

export default function SubmissionModal({
  totalQuestionCount,
  answeredCount,
  practiceSheetId,
  evaluationId,
  closeSubmissionModal,
  closeDrawer,
}: IPracticeSubmission) {
  const navigate = useNavigate();

  /* API -> For submitting practice sheet */
  const [updateStudentStatus, { isLoading: submissionLoading }] =
    useUpdatePracticePaperSubmissionMutation();

  const handlePracticeSheetSubmission = () => {
    const toastId = toast.loading('Loading...');

    if (answeredCount === 0) {
      toast.error('Please complete at least one capture before submitting.', { id: toastId });
      return;
    }

    updateStudentStatus({ practiceSheetId, evaluationId })
      .unwrap()
      .then((res) => {
        closeDrawer(false);
        closeSubmissionModal(false);
        toast.success(res?.message, { id: toastId });
      })
      .catch((error) => {
        if (error?.data?.data?.isAlreadySubmitted) {
          toast.error('Your Practice Paper has already been submitted.', { id: toastId });
          const timeout: NodeJS.Timeout = setTimeout(() => {
            navigate(STUDENT_PATHS.STUDENT_PRACTICE, {
              replace: true,
            });
          }, 1000);

          return () => clearTimeout(timeout);
        } else {
          toast.error(error.data?.message, { id: toastId });
        }
      })
      .finally(() => {
        closeSubmissionModal(false);
        const timeout: NodeJS.Timeout = setTimeout(() => {
          window.location.reload();
        }, 1000);

        return () => clearTimeout(timeout);
      });
  };

  return (
    <>
      <div className='p-4'>
        <h6 className='mb-4 text-lg font-semibold font-sora text-neutral-950 md:text-xl lg:text-xl'>
          You have answered <span className='font-bold text-primary-500'>{answeredCount}</span> out
          of <span className='font-bold text-primary-600'>{totalQuestionCount} </span>
          questions.
        </h6>
        <p className='mb-4 text-base font-poppins text-neutral-700 md:text-base lg:text-lg'>
          Are you sure you want to submit the evaluation?
        </p>
        <div className='flex justify-end gap-4'>
          <Button
            type='button'
            className='px-4 text-base text-white rounded-lg bg-neutral-500 hover:bg-neutral-600 md:text-lg lg:text-xl'
            onClick={() => closeSubmissionModal(false)}
            text='No, go back'
          />

          <Button
            type='button'
            text='Yes, submit'
            className='px-4 text-base text-white rounded-lg bg-semantic-green-500 hover:bg-semantic-green-600 md:text-lg lg:text-xl'
            disabled={submissionLoading}
            onClick={handlePracticeSheetSubmission}
          />
        </div>
      </div>
    </>
  );
}
