import { RightAngularIcon } from 'assets/svg';
import { ReactNode } from 'react';

export interface OutlinedButtonProps {
  children: ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  rightIcon?: ReactNode;
  className?: string;
}

const OutlinedButton = ({
  children,
  disabled = false,
  onClick,
  className = '',
}: OutlinedButtonProps) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`
        ${disabled ? 'w-full' : 'w-[45.5%]'}        
        py-[13.5px]
        px-6 
        rounded-[6px]
        border-[1.5px]
        flex 
        items-center 
        justify-center 
        gap-2 
        transition-colors
        font-sora
        leading-3
        font-medium
        ${
          disabled
            ? 'border-gray-200 text-gray-300 cursor-not-allowed bg-white'
            : 'border-teal-600 text-teal-600 cursor-pointer '
        }
        ${className}
      `}
    >
      {children}
      <img
        src={RightAngularIcon}
        alt='Right Arrow'
        className={`transition-transform ${disabled ? 'filter grayscale opacity-50' : ''}`}
      />
    </button>
  );
};

export default OutlinedButton;
