import { Badge } from 'flowbite-react';
import { useWindowDimensions } from 'hooks';
import { startCase } from 'lodash';
import { useState } from 'react';
import { HiZoomIn } from 'react-icons/hi';

import { Accordian, Button, Modal, ModalBottomSheet } from 'components/common';

import { IPracticeQuestionData, PracticeTabsProps } from 'types';

import 'assets/styles/scrollbar.css';
import { CheckCircleIcon, CrossCircleIcon, ExclamationWarn } from 'assets/svg';
import PortableTextWithLatex from 'components/common/PortableTextWithLatex';
import { EVALUATED_STATUS, QUESTION_EVALUATION_STATUS, USER_TYPE } from 'configs';

export default function PracticeSheetQuestions({
  questions,
  setIsOpenModal,
  setParamData,
  setQuestion,
  submitted,
  type,
  evaluationStatus,
}: PracticeTabsProps) {
  const { isMobileScreen } = useWindowDimensions();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string>('');

  const handleButtonClick = (question: IPracticeQuestionData['questions'][0], idx: number) => {
    localStorage.removeItem('showQuestion');
    setIsOpenModal(true);
    setParamData({
      evaluationId: question?.studentEvaluation?.evaluationId,
      practiceSheetId: question?.studentEvaluation?.practiceSheetId,
      questionId: question?._id,
      studentId: question?.studentEvaluation?.studentId,
      questionIdx: idx + 1,
    });
    setQuestion(question?.question_description || []);
  };

  const handleImageClick = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setShowModal(true);
  };

  return (
    <>
      {type === USER_TYPE.GUARDIAN && !submitted && (
        <div className='py-3 text-center'>
          <p className='font-semibold text-neutral-700 title-regular font-sora'>
            Note: Ensure you switch to the student account before submitting answers.
          </p>
        </div>
      )}

      <div className='mb-10 question-container'>
        {questions[0]?.questions?.map((question, idx) => (
          <div
            key={question._id}
            className={`p-6 relative my-4 transition-all duration-300 rounded-2xl shadow-lg border-2 ${
              question?.notebookCaptured
                ? question?.questionEvaluationStatus === QUESTION_EVALUATION_STATUS?.CORRECT
                  ? 'bg-semantic-green-200 border-semantic-green-200'
                  : question?.questionEvaluationStatus === QUESTION_EVALUATION_STATUS?.INCORRECT
                    ? 'bg-semantic-red-200 border-semantic-red-200'
                    : 'bg-white text-neutral-800 border-neutral-300 hover:bg-gray-100'
                : 'bg-white text-neutral-800 border-neutral-300 hover:bg-gray-100'
            }`}
          >
            {question?.notebookCaptured ? (
              <span
                className={`absolute flex items-center justify-center gap-1 top-0 right-0 px-3 py-1 text-sm font-medium text-white rounded-l-full rounded-tr-full shadow-md ${
                  submitted &&
                  question?.questionEvaluationStatus === QUESTION_EVALUATION_STATUS?.CORRECT
                    ? 'bg-semantic-green-500'
                    : submitted &&
                        question?.questionEvaluationStatus === QUESTION_EVALUATION_STATUS?.INCORRECT
                      ? 'bg-semantic-red-500'
                      : 'bg-semantic-orange-400'
                }`}
              >
                <span>
                  {submitted &&
                  question?.questionEvaluationStatus === QUESTION_EVALUATION_STATUS?.CORRECT ? (
                    <img src={CheckCircleIcon} className='w-5 h-5' />
                  ) : submitted &&
                    question?.questionEvaluationStatus === QUESTION_EVALUATION_STATUS?.INCORRECT ? (
                    <img src={CrossCircleIcon} className='w-5 h-5' />
                  ) : ![
                      QUESTION_EVALUATION_STATUS?.CORRECT,
                      QUESTION_EVALUATION_STATUS?.INCORRECT,
                    ].includes(question?.questionEvaluationStatus || '') ? (
                    '📝'
                  ) : (
                    '🎉'
                  )}
                </span>
                {evaluationStatus === EVALUATED_STATUS.EVALUATED
                  ? question?.questionEvaluationStatus === QUESTION_EVALUATION_STATUS?.CORRECT
                    ? startCase(QUESTION_EVALUATION_STATUS?.CORRECT)
                    : question?.questionEvaluationStatus === QUESTION_EVALUATION_STATUS?.INCORRECT
                      ? startCase(QUESTION_EVALUATION_STATUS?.INCORRECT)
                      : startCase(QUESTION_EVALUATION_STATUS?.NOT_ATTEMPTED)
                  : evaluationStatus === EVALUATED_STATUS.NOT_EVALUATED
                    ? startCase(QUESTION_EVALUATION_STATUS?.EVALUATION_IN_PROGRESS)
                    : startCase(QUESTION_EVALUATION_STATUS?.SUBMITTED)}
              </span>
            ) : submitted ? (
              <span className='absolute top-0 right-0 px-3 py-1 text-sm font-medium text-white rounded-l-full rounded-tr-full shadow-md bg-semantic-orange-400'>
                <span className='mr-2 animate-bounce'>❓</span>
                {startCase(QUESTION_EVALUATION_STATUS?.NOT_ATTEMPTED)}
              </span>
            ) : (
              ''
            )}

            <div className='flex flex-col justify-between md:flex-row md:items-center'>
              <h5 className={`text-lg font-bold text-primary-500`}>
                Question {`#${idx + 1}`}
                {':'}
              </h5>
            </div>

            <div className='flex flex-col items-start justify-between mb-4 text-lg font-medium break-words md:flex-row md:items-center lg:text-xl'>
              <div
                className={`flex-1 ${
                  question?.notebookCaptured ? 'text-neutral-850' : 'text-neutral-800'
                }`}
              >
                <PortableTextWithLatex value={question?.question_description || []} />
              </div>
              <div className='flex items-center mt-4 md:mt-0 md:ml-4'>
                {!question?.notebookCaptured && type === USER_TYPE?.STUDENT && !submitted && (
                  <Button
                    text='Submit Answer'
                    onClick={() => handleButtonClick(question, idx)}
                    className='px-2 text-base font-semibold text-white transition-colors duration-200 rounded-lg shadow-md bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-opacity-50'
                  />
                )}

                {question?.capturedImages?.map((image, index) => (
                  <button
                    key={index}
                    onClick={() =>
                      handleImageClick(
                        evaluationStatus === EVALUATED_STATUS.EVALUATED
                          ? image.annotated
                          : image.original,
                      )
                    }
                    className='relative w-24 h-24 mr-1 overflow-hidden transition-all duration-200 border border-gray-300 rounded-md group hover:border-primary-500 hover:shadow-md'
                  >
                    <img
                      src={
                        evaluationStatus === EVALUATED_STATUS.EVALUATED
                          ? image.annotated
                          : image.original
                      }
                      alt={`Capture ${index + 1}`}
                      className='object-cover w-full h-full transition-transform duration-200 group-hover:scale-105'
                    />
                    {/* Hover Overlay for Image */}
                    <div className='absolute inset-0 flex items-center justify-center transition-opacity duration-200 opacity-0 bg-black/40 group-hover:opacity-100'>
                      <div className='flex flex-col items-center gap-1 text-white'>
                        <HiZoomIn className='w-5 h-5' />
                        <span className='text-xs font-medium'>View</span>
                      </div>
                    </div>
                  </button>
                ))}
              </div>
            </div>

            {type === USER_TYPE.GUARDIAN && !submitted && (
              <Badge className='inline-flex items-center px-3 py-1 text-sm font-medium text-gray-900 rounded-lg bg-semantic-orange-100'>
                <span className='flex items-center gap-2'>
                  <img src={ExclamationWarn} className='w-4 h-4 text-gray-400' />
                  Kindly ensure you are using the Student Account to submit answers.
                </span>
              </Badge>
            )}

            {question.notebookCaptured &&
              submitted &&
              evaluationStatus === EVALUATED_STATUS.EVALUATED && (
                <Accordian
                  title='Show answer'
                  collapseAll={true}
                  className='px-2 pt-3 border-2 border-primary-500 rounded-xl'
                >
                  <PortableTextWithLatex value={question?.answer} />
                </Accordian>
              )}
          </div>
        ))}
      </div>

      {showModal && (
        <div>
          {isMobileScreen ? (
            <ModalBottomSheet
              setOpenModal={setShowModal}
              openModal={showModal}
              showCloseBtn={true}
              children={
                <div className='customScrollBar'>
                  <h1 className='text-xl font-semibold text-center font-sora text-neutral-800'>
                    Submitted Answer:
                  </h1>

                  <div className='flex items-center justify-center'>
                    <img
                      src={selectedImage}
                      alt='Full Size'
                      className='max-w-full max-h-screen p-2 rounded-xl'
                    />
                  </div>
                </div>
              }
            />
          ) : (
            <Modal
              children={
                <div className='customScrollBar'>
                  <h1 className='font-semibold text-center font-sora text-primary-500 lg:text-xl xl:text-2xl'>
                    Submitted Answer:
                  </h1>

                  <div className='flex items-center justify-center'>
                    <img
                      src={selectedImage}
                      alt='Full Size'
                      className='max-w-full max-h-screen p-2 rounded-xl'
                    />
                  </div>
                </div>
              }
              showCloseBtn={true}
              openModal={showModal}
              setOpenModal={setShowModal}
              size={'5xl'}
            />
          )}
        </div>
      )}
    </>
  );
}
