import { FaAtom, FaChartLine, FaCircleInfo, FaClipboardList } from 'react-icons/fa6';
import {
  EXAM_STATUS,
  MATHS_SUBJECT,
  reportInfo,
  SCIENCE,
  SCIENCE_SUBJECT,
  SUBJECT_REPORTS,
} from 'configs/reports';
import { Tooltip } from 'flowbite-react';
import { IReportType, MainContentProps } from 'types/reports';
import SubjectSection from '../SubjectSection';
import { CONFIG } from 'configs';
import Cookie from 'js-cookie';

const MainContent = ({
  toggleSection,
  handleReportSelection,
  activeReport,
  segments,
}: MainContentProps) => {
  const activeReportKey = Object.keys(activeReport).find(
    (key) => activeReport[key as IReportType] === true,
  );

  const isImpersonatedUser =
    Cookie.get(`${CONFIG.VITE_BASE_DOMAIN_ENV}IsImpersonatedUser`) === 'true';

  return (
    <div className='h-full custom-scrollbar'>
      <div className='p-2 lg:pt-16 max-h-screen overflow-y-auto lg:pb-36'>
        {/* Chapterwise Reports Section */}
        <div className='mb-4 bg-white rounded-xl border border-gray-200/60 shadow-sm hover:shadow-md transition-all duration-200'>
          <div className='px-4 py-3 flex items-center gap-2 border-b border-gray-100 bg-gradient-to-r from-gray-50 to-white rounded-xl'>
            <span className='font-semibold font-sora text-base text-gray-900'>
              Chapterwise Reports
            </span>
            <Tooltip
              style='light'
              content={
                <div className='text-sm font-normal font-poppins cursor-pointer w-60'>
                  {reportInfo.map((text, index) => (
                    <p key={index} className={index > 0 ? 'mt-2' : ''}>
                      {text}
                    </p>
                  ))}
                </div>
              }
              placement='right-start'
            >
              <FaCircleInfo className='w-3.5 h-3.5 text-gray-400 hover:text-gray-600 cursor-help transition-colors' />
            </Tooltip>
          </div>

          <div className='space-y-4'>
            {segments?.map((segment) => {
              return (
                <div key={segment?._id}>
                  <SubjectSection
                    subject={
                      segment?.name?.toLowerCase() === SCIENCE ? SCIENCE_SUBJECT : MATHS_SUBJECT
                    }
                    icon={
                      segment?.name?.toLowerCase() === SCIENCE ? (
                        <FaAtom className='w-6 h-6 text-blue-600' />
                      ) : (
                        <FaChartLine className='w-6 h-6 text-green-600' />
                      )
                    }
                    iconBgColor='bg-blue-50'
                    items={SUBJECT_REPORTS.filter((item) => item.type === segment?.name)}
                    toggleSection={toggleSection}
                    handleReportSelection={handleReportSelection}
                    activeReportKey={activeReportKey}
                    disabled={segment?.name?.toLowerCase() === SCIENCE ? true : false}
                  />
                </div>
              );
            })}
          </div>

          {/* Exam Readiness Reports Section */}
          <div className='bg-white transition-all duration-200'>
            <div className='px-6 py-4 border-b border-gray-100 bg-gradient-to-r from-gray-50 to-white rounded-t-xl flex items-center gap-3'>
              <div className={`p-1.5 rounded-xl bg-blue-50`}>
                <FaClipboardList className='w-6 h-6 text-orange-600' />
              </div>
              <span className='font-semibold text-gray-900 text-sm font-sora'>
                Exam Readiness Reports
              </span>
            </div>
          </div>

          <div className='space-y-4'>
            <div
              className={`flex items-center justify-between p-3 rounded-lg hover:cursor-pointer`}
              onClick={() => handleReportSelection(EXAM_STATUS as IReportType)}
            >
              <div
                className={`flex-1 flex items-center justify-between gap-2 px-4 py-2 ${
                  activeReportKey === EXAM_STATUS
                    ? 'bg-indigo-50 text-indigo-900 cursor-pointer'
                    : 'hover:bg-gray-50 text-gray-600 cursor-pointer'
                }`}
              >
                <span className='text-xs font-sora font-medium text-neutral-600'>Exam Status</span>
                {!isImpersonatedUser && (
                  <span className='px-2 py-1 font-sora text-xs font-medium bg-gray-100 text-gray-600 rounded-full'>
                    coming soon
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainContent;
