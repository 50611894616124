import { CATEGORY, FIXED_CATEGORIES, NEEDS_REINFORCEMENT_GAP, NOT_ASSESSED_GAP, UNDERSTOOD_BUT_MAKES_SILLY_MISTAKES_GAP, WELL_UNDERSTOOD_GAP } from 'configs/reports';
import {
  getExamProcessedCategoryEntity,
  getExamProcessedQuestionEntity,
} from 'configs/studentReport';
import { useWindowDimensions } from 'hooks';
import { useAppDispatch } from 'hooks/store';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FaChevronRight, FaStar } from 'react-icons/fa6';
import { useParams } from 'react-router-dom';
import { useGetChapterStepLevelAnswersDataMutation } from 'store/apiSlices/reports.apiSlice';
import { setExamQcLevelData } from 'store/slice/reports.slice';
import {
  Chapters,
  ChapterStats,
  ExamCategoryEntity,
  ExamChapterUnderstanding,
  ExamQcLevelUnderstandingEntity,
  ExamQuestionEntity,
} from 'types/reports';

interface CategoryCounts {
  wellUnderstood: number;
  sillyMistakes: number;
  needsReinforcement: number;
  notAssessed: number;
}

interface UnderstandingLevelCardProps {
  categoryCounts: CategoryCounts;
  totalCategories: number;
  onClick?: () => void;
  selectedSubject: string;
  selectedChapter: Chapters;
}

export interface Category {
  key: keyof CategoryCounts;
  title: string;
  status: number;
  rating: number;
}

export const UnderstandingLevelCard: React.FC<UnderstandingLevelCardProps> = ({
  categoryCounts,
  totalCategories,
  onClick,
  selectedSubject,
  selectedChapter,
}) => {
  const { studentId } = useParams();
  const dispatch = useAppDispatch();
  const { isMobileScreen } = useWindowDimensions();
  const [chapterCategories, setChapterCategories] = useState<ExamChapterUnderstanding[]>([]);
  const [chapterCategoryEntity, setChapterCategoryEntity] = useState<ExamCategoryEntity[]>([]);
  const [chapterQuestionEntity, setChapterQuestionEntity] = useState<ExamQuestionEntity[]>([]);
  const getStatusStyles = (status: number): string => {
    const baseStyles = 'px-2 py-0.5 rounded-full font-sora font-semibold text-xs font-normal';

    switch (status) {
      case 1:
        return `${baseStyles} bg-green-100 text-green-800`;
      case 2:
        return `${baseStyles} bg-orange-100 text-orange-900`;
      case 3:
        return `${baseStyles} bg-red-100 text-red-800`;
      default:
        return `${baseStyles} bg-gray-100 text-gray-900`;
    }
  };

  const [triggerForStepLevelData, { data: stepLevelAnswers }] =
    useGetChapterStepLevelAnswersDataMutation();

  useEffect(() => {
    const fetchChapterStepLevelAnswersData = async (
      questionCategoryIds: string[],
      reportTypes: string[],
    ) => {
      try {
        await triggerForStepLevelData({
          studentId: studentId ? Number(studentId) : null,
          subjectId: selectedSubject ? selectedSubject : null,
          chapterId: selectedChapter ? selectedChapter?.chapterId : null,
          questionCategoryIds,
          reportType: CATEGORY,
          classTypes: reportTypes,
        }).unwrap();
      } catch (error) {
        toast.error('Failed to fetch step level answers details.Please try again later.');
        console.error(error);
      }
    };
    if (selectedChapter && selectedSubject) {
      const { stats } = selectedChapter;
      const qcIds: string[] = [];
      const reportTypes: string[] = [];
      const categories: ExamChapterUnderstanding[] = [];

      if (stats) {
        Object.values(stats)
          .flat()
          .forEach((stat) => {
            const { questionCategoryId, type } = stat;
            categories.push(stat);
            qcIds.push(questionCategoryId);
            if (type && !reportTypes.includes(type)) reportTypes.push(type);
          });
      }
      if (qcIds.length > 0) {
        setChapterCategories(categories);
        fetchChapterStepLevelAnswersData(qcIds, reportTypes);
      }
    }
  }, [selectedChapter, selectedSubject, studentId, triggerForStepLevelData]);

  useEffect(() => {
    if (stepLevelAnswers?.data?.length && chapterCategories) {
      const processedChapterCategories = getExamProcessedCategoryEntity(
        chapterCategories!,
        stepLevelAnswers?.data,
        selectedChapter,
      );

      const processedQuestionEntity = getExamProcessedQuestionEntity(
        chapterCategories!,
        stepLevelAnswers?.data,
        selectedChapter,
      );
      setChapterCategoryEntity(processedChapterCategories);
      setChapterQuestionEntity(processedQuestionEntity);
    }
  }, [chapterCategories, selectedChapter, stepLevelAnswers]);

  const handleCategoryClick = (category: Category) => {
    const categoryKeyToStatsMap: Record<string, (keyof ChapterStats)[]> = {
      wellUnderstood: WELL_UNDERSTOOD_GAP as (keyof ChapterStats)[],
      sillyMistakes: UNDERSTOOD_BUT_MAKES_SILLY_MISTAKES_GAP as (keyof ChapterStats)[],
      needsReinforcement: NEEDS_REINFORCEMENT_GAP as (keyof ChapterStats)[],
      notAssessed: NOT_ASSESSED_GAP as (keyof ChapterStats)[],
    };

    if (!categoryKeyToStatsMap[category.key]) return;

    const title = category.title;
    const statsKeys = categoryKeyToStatsMap[category.key];

    // Extract relevant question category IDs from selectedChapter.stats
    const relevantQcs = statsKeys.flatMap((key) => selectedChapter?.stats?.[key] || []);
    const questionCategoryIds = new Set(relevantQcs.map((qcs) => qcs.questionCategoryId));

    // Filter entities based on selected categories
    const chapterQcsToRender = chapterCategoryEntity.filter((cqcs) =>
      questionCategoryIds.has(cqcs.questionCategoryId),
    );

    const qcsQuestions = chapterQuestionEntity.filter((qcs) =>
      questionCategoryIds.has(qcs.categoryId),
    );

    const payload: ExamQcLevelUnderstandingEntity = {
      title,
      chapterQcsToRender,
      qcsQuestions,
      rating: category.rating,
      chapterName: selectedChapter?.chapterName,
    };

    dispatch(setExamQcLevelData(payload || {}));
    if (onClick) onClick();
  };

  return (
    <div
      className={`bg-white rounded-xl m-4 ml-0 p-4 relative w-96 border-[1.5px] border-neutral-100 ${
        isMobileScreen
          ? ' '
          : 'before:absolute before:top-3 before:h-[calc(100%-24px)] before:left-0 before:w-1 before:bg-semantic-orange-400 before:rounded-r'
      } `}
    >
      <div className='mb-3 flex justify-between items-center'>
        <h2 className='font-sora font-semibold tracking-normal text-sm'>Understanding Level</h2>
      </div>

      <div className='space-y-3'>
        {FIXED_CATEGORIES.map((category) => (
          <div
            key={category.key}
            onClick={() => handleCategoryClick(category)}
            className='border border-gray-100 rounded-lg p-3 bg-white cursor-pointer'
          >
            <div className='flex items-center justify-between mb-2'>
              <span className='text-sm font-sora tracking-tight font-medium text-gray-900 max-w-[180px] flex-1 pr-2'>
                {category.title}
              </span>
              <div className='flex gap-2'>
                <span className={getStatusStyles(category.status)}>
                  {categoryCounts[category.key] || 0}/{totalCategories}
                </span>
                  <FaChevronRight className='text-neutral-950' size={14} />
              </div>
            </div>
            {category.rating > 0 && (
              <div className='flex gap-1'>
                {Array.from({ length: category.rating }).map((_, index) => (
                  <FaStar key={index} size={24} className='text-yellow-400 fill-yellow-400' />
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UnderstandingLevelCard;
