import {
  ClassList,
  ClassNotes,
  Classes,
  ForgetPasscode,
  Home,
  Login,
  ParentDashboard,
  ParentDashboardClassOver,
  Practice,
  Register,
  StudentDashBoard,
  TermsAndConditions,
} from 'pages';

import { ADMIN_PATHS, PARENT_PATHS, ROUTES, STUDENT_PATHS } from './routePaths';
import QuestionCategory from 'pages/StudentGuardians/Reports/QuestionCategory';
import CategoryOutlet from 'pages/StudentGuardians/Reports/CategoryOutlet';
import CategoryDetails from 'pages/StudentGuardians/Reports/CategoryDetails';
import DashboardLayout from 'pages/StudentGuardians/Reports/DashboardLayout';
import PreIntervention from 'pages/StudentGuardians/Reports/PreIntervention';
import PostIntervention from 'pages/StudentGuardians/Reports/PostIntervention';
import PostInterventionQc from 'pages/StudentGuardians/Reports/PostInterventionQc';
import InDepthPostInterventionQc from 'pages/StudentGuardians/Reports/InDepthPostInterventionQc';
import ExamChapters from 'pages/StudentGuardians/Reports/ExamChapters';
import ChapterClassDetails from 'pages/StudentGuardians/Reports/ChapterClassDetails';
import ExamChapterWiseUnderstandingProgress from 'pages/StudentGuardians/Reports/ExamChapterWiseUnderstandingProgress';
import RevisionPlan from 'pages/StudentGuardians/Reports/RevisionPlan';

export const PROTECTED_ROUTES = [
  { path: PARENT_PATHS.PARENT_DASHBOARD, element: <ParentDashboard /> },
  { path: PARENT_PATHS.CLASS_OVER, element: <ParentDashboardClassOver /> },
  { path: PARENT_PATHS.ALLCLASSES, element: <Classes /> },
  { path: STUDENT_PATHS.STUDENT_DASHBOARD, element: <StudentDashBoard /> },
  { path: STUDENT_PATHS.STUDENT_CLASSES, element: <Classes /> },
  { path: ROUTES.LIST_OF_CLASSES, element: <ClassList /> },
  { path: STUDENT_PATHS.STUDENT_PRACTICE, element: <Practice /> },
  { path: STUDENT_PATHS.CLASS_NOTES, element: <ClassNotes /> },
  { path: PARENT_PATHS.PRACTICE_SHEET, element: <Practice /> },
  {
    path: PARENT_PATHS.REPORTS,
    element: <DashboardLayout />,
    children: [
      {
        path: PARENT_PATHS.QUESTION_CATEGORY,
        element: <QuestionCategory />,
        children: [
          {
            path: PARENT_PATHS.CHAPTER_LEVEL_UNDERSTANDING,
            element: <CategoryOutlet />,
            children: [
              {
                path: PARENT_PATHS.REPORTS_STEP_LEVEL,
                element: <CategoryDetails />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: PARENT_PATHS.INTERVENTION_REPORTS,
    element: <DashboardLayout />,
    children: [
      {
        path: PARENT_PATHS.PRE_INTERVENTION,
        element: <PreIntervention />,
        children: [
          {
            path: PARENT_PATHS.POST_INTERVENTION,
            element: <PostIntervention />,
            children: [
              {
                path: PARENT_PATHS.POST_INTERVENTION_QC,
                element: <PostInterventionQc />,
                children: [
                  {
                    path: PARENT_PATHS.IN_DEPTH_POST_INTERVENTION_QC,
                    element: <InDepthPostInterventionQc />,
                    children: [
                      {
                        path: PARENT_PATHS.IN_DEPTH_POST_INTERVENTION_QC_QUESTIONS,
                        element: <CategoryDetails />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: PARENT_PATHS.EXAM_STATUS_REPORTS,
    element: <DashboardLayout />,
    children: [
      {
        path: PARENT_PATHS.EXAM_CHAPTERS_COVERED,
        element: <ExamChapters />,
        children: [
          {
            path: PARENT_PATHS.EXAM_CHAPTERS_COVERED_CLASS_DETAILS,
            element: <ChapterClassDetails />,
            children: [
              {
                path: PARENT_PATHS.EXAM_CHAPTERS_WISE_PROGRESS,
                element: <ExamChapterWiseUnderstandingProgress />,
                children: [
                  {
                    path: PARENT_PATHS.EXAM_CHAPTERS_WISE_PROGRESS_REVISION,
                    element: <RevisionPlan />,
                  },
                  {
                    path: PARENT_PATHS.EXAM_CHAPTERS_WISE_PROGRESS_DETAILS,
                    element: <CategoryDetails />,
                  },
                ],
              },
              {
                path: PARENT_PATHS.EXAM_CHAPTERS_COVERED_CLASS_DETAILS_PRACTICE,
                element: <CategoryDetails />,
              },
              {
                path: PARENT_PATHS.EXAM_CHAPTERS_COVERED_CLASS_DETAILS_NOT_ATTENDED,
                element: <CategoryDetails />,
              },
            ],
          },
        ],
      },
    ],
  },
];

export const OPEN_ROUTES = [
  { path: ROUTES.ROOT, element: <Home /> },
  { path: ROUTES.LOGIN, element: <Login /> },
  { path: ROUTES.REGISTER, element: <Register /> },
  { path: ROUTES.FORGET_PASSCODE, element: <ForgetPasscode /> },
  { path: ROUTES.TERMS_AND_CONDITION, element: <TermsAndConditions /> },
];

export const ADMIN_ROUTES = [
  {
    path: ADMIN_PATHS.ADMIN_REPORTS,
    element: <DashboardLayout />,
    children: [
      {
        path: ADMIN_PATHS.ADMIN_QUESTION_CATEGORY,
        element: <QuestionCategory />,
        children: [
          {
            path: ADMIN_PATHS.ADMIN_CHAPTER_LEVEL_UNDERSTANDING,
            element: <CategoryOutlet />,
            children: [
              {
                path: ADMIN_PATHS.ADMIN_REPORTS_STEP_LEVEL,
                element: <CategoryDetails />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: ADMIN_PATHS.ADMIN_INTERVENTION_REPORTS,
    element: <DashboardLayout />,
    children: [
      {
        path: ADMIN_PATHS.ADMIN_PRE_INTERVENTION,
        element: <PreIntervention />,
        children: [
          {
            path: ADMIN_PATHS.ADMIN_POST_INTERVENTION,
            element: <PostIntervention />,
            children: [
              {
                path: ADMIN_PATHS.ADMIN_POST_INTERVENTION_QC,
                element: <PostInterventionQc />,
                children: [
                  {
                    path: ADMIN_PATHS.ADMIN_IN_DEPTH_POST_INTERVENTION_QC,
                    element: <InDepthPostInterventionQc />,
                    children: [
                      {
                        path: ADMIN_PATHS.ADMIN_IN_DEPTH_POST_INTERVENTION_QC_QUESTIONS,
                        element: <CategoryDetails />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: ADMIN_PATHS.ADMIN_EXAM_STATUS_REPORTS,
    element: <DashboardLayout />,
    children: [
      {
        path: ADMIN_PATHS.ADMIN_EXAM_CHAPTERS_COVERED,
        element: <ExamChapters />,
        children: [
          {
            path: ADMIN_PATHS.ADMIN_EXAM_CHAPTERS_COVERED_CLASS_DETAILS,
            element: <ChapterClassDetails />,
            children: [
              {
                path: ADMIN_PATHS.ADMIN_EXAM_CHAPTERS_WISE_PROGRESS,
                element: <ExamChapterWiseUnderstandingProgress />,
                children: [
                  {
                    path: ADMIN_PATHS.ADMIN_EXAM_CHAPTERS_WISE_PROGRESS_REVISION,
                    element: <RevisionPlan />,
                  },
                  {
                    path: ADMIN_PATHS.ADMIN_EXAM_CHAPTERS_WISE_PROGRESS_DETAILS,
                    element: <CategoryDetails />,
                  },
                ],
              },
              {
                path: ADMIN_PATHS.ADMIN_EXAM_CHAPTERS_COVERED_CLASS_DETAILS_PRACTICE,
                element: <CategoryDetails />,
              },
              {
                path: ADMIN_PATHS.ADMIN_EXAM_CHAPTERS_COVERED_CLASS_DETAILS_NOT_ATTENDED,
                element: <CategoryDetails />,
              },
            ],
          },
        ],
      },
    ],
  },
];
