import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useTopicAttendanceConnection } from './ConnectionLine';
import { useWindowDimensions } from 'hooks';
import {
  AttendanceAndPracticeCardProps,
  AttendanceDetailsItemProps,
  ChapterData,
  NotAttendedClassDetails,
  PracticeSheetAssigned,
} from 'types/reports';
import BottomSheet from '../BottomSheet';
import { CrossIcon, RedirectIcon } from 'assets/svg';
import AttendanceDetails from '../AttendanceDetails';
import OutlinedButton from '../OutlinedButton';
import ChapterClassesDetailsCard from '../ChapterClassesDetails';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetPracticeSheetEvaluationDetailsQuery } from 'store/apiSlices/reports.apiSlice';
import { getPracticeSheetEntity } from 'configs/studentReport';
import { isEqual } from 'lodash';
import {
  setExamChapterPracticeSheet,
  setNotAttendedClassesInChapterExam,
} from 'store/slice/reports.slice';

const AttendanceAndPracticeCard: React.FC<AttendanceAndPracticeCardProps> = ({
  data,
  selectedSubject,
  studentName = 'Student',
  setSelectedSubject,
  onClick,
  practiceSheetClick,
  classesScheduledClick,
  setShowMobileFooter,
}) => {
  const dispatch = useAppDispatch();
  const { studentId, examId } = useParams();
  const navigate = useNavigate();
  const practiceData = useAppSelector((state) => state.reportsData.examPracticeSheetEntity);
  const [selectedChapter, setSelectedChapter] = useState<ChapterData | null>(null);
  const [showAttendanceDetails, setShowAttendanceDetails] = useState<boolean>(false);
  const [assignedPracticeSheet, setAssignedPracticeSheets] = useState<PracticeSheetAssigned[]>([]);

  const isAdminRoute = location.pathname.startsWith('/a');
  const prefix = isAdminRoute ? '/a' : '/p';

  const containerRef = useRef<HTMLDivElement>(null);
  const chapterContainerRef = useRef<HTMLDivElement>(null);
  const attendanceDetailsRef = useRef<HTMLDivElement>(null);
  const { isMobileScreen } = useWindowDimensions();

  const connection = useTopicAttendanceConnection(
    containerRef,
    chapterContainerRef,
    attendanceDetailsRef,
    showAttendanceDetails && !isMobileScreen,
  );

  const handleSubjectChange = (subject: string): void => {
    setSelectedSubject(subject);
    setSelectedChapter(null);
    setShowAttendanceDetails(false);
  };

  const handleChapterClick = (
    chapterId: string,
    practiceSheets: PracticeSheetAssigned[],
    notAttendedClasses: NotAttendedClassDetails[],
  ): void => {
    const chapter = data[selectedSubject].find((t) => t.chapterId === chapterId);
    if (chapter) {
      setSelectedChapter(chapter);
      setShowAttendanceDetails(true);
      setShowMobileFooter(false);
      if (practiceSheets.length) setAssignedPracticeSheets(practiceSheets);
      else setAssignedPracticeSheets([]);
      dispatch(setNotAttendedClassesInChapterExam(notAttendedClasses || []));
    }

    if (
      location.pathname.includes('/classesnotattended') ||
      location.pathname.includes('/submissionstatus')
    ) {
      navigate(`${prefix}/reports/${studentId}/chapter/exam-status/${examId}/details`);
    }
  };

  const handleCloseAttendanceDetails = () => {
    setShowAttendanceDetails(false);
    setSelectedChapter(null);
    setShowMobileFooter(true);
  };

  const queryParams = useMemo(() => {
    if (assignedPracticeSheet?.length) {
      const practiceSheetMap: Record<string, string[]> = {};
      assignedPracticeSheet?.forEach(({ practiceSheetId, evaluationId }) => {
        if (!practiceSheetId) return;

        if (!practiceSheetMap[practiceSheetId]) {
          practiceSheetMap[practiceSheetId] = [];
        }
        practiceSheetMap[practiceSheetId].push(evaluationId);
      });

      // Convert the grouped data into query parameter structure
      return Object.entries(practiceSheetMap).map(([practiceSheetId, evaluationIds]) => ({
        practiceSheetId,
        evaluationIds,
      }));
    }
    return [];
  }, [assignedPracticeSheet]);

  const { data: practiceEvaluationData } = useGetPracticeSheetEvaluationDetailsQuery(
    {
      studentId: studentId ? Number(studentId) : null,
      practiceSheets: queryParams,
    },
    { skip: !queryParams.length || !studentId },
  );

  useEffect(() => {
    if (practiceEvaluationData?.data && practiceEvaluationData?.data?.length > 0) {
      const processedPracticeSheet = getPracticeSheetEntity(
        practiceEvaluationData?.data,
        assignedPracticeSheet || [],
      );

      if (!isEqual(processedPracticeSheet, practiceData)) {
        dispatch(setExamChapterPracticeSheet(processedPracticeSheet || []));
      }
    }
  }, [dispatch, assignedPracticeSheet, practiceData, practiceEvaluationData]);

  const generateChapterAttendanceData = (
    chapter: ChapterData | null,
  ): AttendanceDetailsItemProps[] => {
    if (!chapter) return [];

    return [
      {
        title: 'Teach classes',
        scheduled: chapter?.classes?.teach?.scheduled || 0,
        attended: chapter?.classes?.teach?.attended || 0,
        attendedPercentage: chapter?.classes?.teach?.scheduled
          ? Math.round(
              (chapter?.classes?.teach?.attended / chapter?.classes?.teach?.scheduled) * 100,
            )
          : 0,
      },
      {
        title: 'Evaluation classes',
        scheduled: chapter?.classes?.evaluation?.scheduled || 0,
        attended: chapter?.classes?.evaluation?.attended || 0,
        attendedPercentage: chapter?.classes?.evaluation?.scheduled
          ? Math.round(
              (chapter?.classes?.evaluation?.attended / chapter?.classes?.evaluation?.scheduled) *
                100,
            )
          : 0,
      },
      {
        title: 'Intervention classes',
        scheduled: chapter?.classes?.intervention?.scheduled || 0,
        attended: chapter?.classes?.intervention?.attended || 0,
        attendedPercentage: chapter?.classes?.intervention?.scheduled
          ? Math.round(
              (chapter?.classes?.intervention?.attended /
                chapter?.classes?.intervention?.scheduled) *
                100,
            )
          : 0,
      },
    ];
  };

  const renderAttendanceDetails = () => (
    <AttendanceDetails
      data={generateChapterAttendanceData(selectedChapter)}
      onClose={handleCloseAttendanceDetails}
      practiceSheetClick={practiceSheetClick}
      classesScheduledClick={classesScheduledClick}
      isPracticeSheetAssigned={
        practiceEvaluationData?.data?.length && practiceEvaluationData?.data?.length > 0
          ? true
          : false
      }
    />
  );

  return (
    <div
      className='bg-white w-full rounded-[12px] border-[2px] border-teal-200 overflow-hidden mx-auto p-4  relative before:absolute before:top-3 before:h-[calc(100%-24px)] before:left-0 before:w-1 before:bg-teal-400 before:rounded-r'
      ref={containerRef}
    >
      <div className='flex justify-between items-center mb-6 cursor-pointer' onClick={onClick}>
        <h2 className='font-sora font-semibold tracking-tight text-sm'>
          Class attendance & practice sheet
        </h2>
        <img src={RedirectIcon} alt='redirect icon' width={20} height={20} />
      </div>
      <div className={`flex ${isMobileScreen ? 'flex-col' : 'gap-[82px]'} justify-between`}>
        <div
          ref={chapterContainerRef}
          className={`border-[1.5px] h-full border-neutral-100 rounded-[8px] ${
            showAttendanceDetails && !isMobileScreen ? 'w-[350px]' : 'w-full'
          }`}
        >
          <div className='border-b'>
            <div className='flex'>
              {Object.entries(data).map(([key, subjectData]) => (
                <button
                  key={key}
                  onClick={() => handleSubjectChange(key)}
                  className={`px-6 py-3 font-poppins font-semibold tracking-tight text-sm relative
                    ${selectedSubject === key ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700'}`}
                >
                  {subjectData?.[0]?.subjectName}
                  {selectedSubject === key && (
                    <div className='absolute bottom-0 left-6 right-6 h-[2px] bg-teal-500' />
                  )}
                </button>
              ))}
            </div>
          </div>

          <div className='max-h-[450px] overflow-y-auto p-4'>
            {data[selectedSubject] ? (
              <div className='space-y-3'>
                {data[selectedSubject].map((chapter) => (
                  <div
                    key={chapter.chapterId}
                    onClick={() =>
                      handleChapterClick(
                        chapter.chapterId,
                        chapter?.practiceSheets?.filter((ps) => ps.chapterId === chapter.chapterId),
                        chapter?.classes?.notAttendedClasses,
                      )
                    }
                    className='cursor-pointer'
                  >
                    <ChapterClassesDetailsCard
                      title={chapter.chapterName}
                      attendance={chapter.classes}
                      practice={
                        chapter?.practiceSheets?.filter(
                          (ps) => ps.chapterId === chapter.chapterId,
                        ) || []
                      }
                      isSelected={selectedChapter?.chapterId === chapter.chapterId}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className='text-center py-8 text-gray-500'>
                No data available for this subject
              </div>
            )}
          </div>
        </div>

        {/* Desktop Attendance Details */}
        {!isMobileScreen && showAttendanceDetails && selectedChapter && (
          <div ref={attendanceDetailsRef} className='w-[350px] overflow-y-auto'>
            {renderAttendanceDetails()}
          </div>
        )}

        {/* Mobile Attendance Details in BottomSheet */}
        {isMobileScreen && (
          <BottomSheet
            isVisible={showAttendanceDetails && !!selectedChapter}
            onClose={handleCloseAttendanceDetails}
          >
            <div
              style={{ boxShadow: '0px 16px 32px -4px #0c0c0d0d' }}
              className='flex items-center justify-between p-4 border-b border-gray-100'
            >
              <h2 className='font-sora font-medium text-sm'>{selectedChapter?.chapterName}</h2>
              <button
                onClick={handleCloseAttendanceDetails}
                className='text-gray-500 hover:text-gray-700'
              >
                <img src={CrossIcon} alt='close popup' width={20} height={20} />
              </button>
            </div>
            {selectedChapter && renderAttendanceDetails()}
          </BottomSheet>
        )}

        {/* Connection lines - only for desktop */}
        {connection && showAttendanceDetails && !isMobileScreen && (
          <div className='absolute left-[0] pointer-events-none'>
            <svg
              className='absolute top-0 left-0'
              style={{ overflow: 'visible' }}
              pointerEvents='none'
            >
              <path
                d={connection.startCurve}
                fill='none'
                stroke='#B4B6C5'
                strokeWidth='2'
                strokeLinecap='round'
              />
              <path
                d={connection.endCurve}
                fill='none'
                stroke='#B4B6C5'
                strokeWidth='2'
                strokeLinecap='round'
              />
              <path
                d={`M ${connection.startDot.x},${connection.startDot.y - 5} 
                   A 5,5 0 1,1 ${connection.startDot.x},${connection.startDot.y + 5}`}
                fill='#10B981'
              />
              <path
                d={`M ${connection.endDot.x},${connection.endDot.y - 5} 
                   A 5,5 0 1,0 ${connection.endDot.x},${connection.endDot.y + 5}`}
                fill='#10B981'
              />
              <line
                x1={connection.line.left + 1}
                y1={connection.line.top}
                x2={connection.line.left}
                y2={connection.line.top + connection.line.height}
                stroke='#B4B6C5'
                strokeWidth='2'
              />
            </svg>
          </div>
        )}
      </div>

      <div
        className={`pt-6 bg-white flex ${showAttendanceDetails ? 'justify-end' : 'justify-start'}`}
      >
        <OutlinedButton
          onClick={classesScheduledClick}
          disabled={!showAttendanceDetails}
          className={`${showAttendanceDetails ? 'w-1/2' : 'w-full'}`}
        >
          Classes Not Attended by {studentName}
        </OutlinedButton>
      </div>
    </div>
  );
};

export default AttendanceAndPracticeCard;
