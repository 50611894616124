export const CLASS_NOTES_TYPES = {
  REGULAR_CLASS: 'regular_class',
  REGULAR_EVALUATION_CLASS: 'regular_evaluation_class',
  INTERVENTION_CLASS: 'intervention_class',
  INTERVENTION_EVALUATION_CLASS: 'intervention_evaluation_class',
  REVISION_CLASS: 'revision_class',
  REVISION_EVALUATION_CLASS: 'revision_evaluation_class',
  MOCK_PAPER: 'mock_paper',
  MOCK_PAPER_DISCUSSION_CLASS: 'mock_paper_discussion_class',
  DEMO_CLASS: 'demo_class',
  DOUBT_SOLVING_CLASS: 'doubt_solving_class',
};

export const CLASS_NOTES_FORMATTED_TYPES = {
  TEACH: 'teach',
  EVALUATION: 'evaluation',
  INTERVENTION: 'intervention',
  REVISION: 'revision',
  MOCK_PAPER: 'mock-paper',
  DEMO: 'demo',
  DOUBT_SOLVING: 'doubt-solving',
};
