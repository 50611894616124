import { datadogLogs } from '@datadog/browser-logs';
import { useCallback, useEffect, useState } from 'react';
import { ConnectionQuality, NavigatorWithConnection, NetworkStatus } from 'types';

export const useNetworkMonitor = (
  onStatusChange: (status: NetworkStatus) => void,
): NetworkStatus => {
  // States
  const [status, setStatus] = useState<NetworkStatus>({
    isOnline: navigator.onLine,
    connectionQuality: navigator.onLine ? 'high' : 'poor',
    qualityPercentage: navigator.onLine ? 100 : 0,
  });

  // Function: To check the connection quality
  const checkConnectionQuality = useCallback(() => {
    // First check if we're online
    if (!navigator.onLine) {
      const offlineStatus: NetworkStatus = {
        isOnline: false,
        connectionQuality: 'poor',
        qualityPercentage: 0,
      };
      setStatus(offlineStatus);
      onStatusChange(offlineStatus);
      return;
    }

    const nav = navigator as NavigatorWithConnection;
    const connection = nav.connection || nav.mozConnection || nav.webkitConnection;

    let quality: ConnectionQuality = 'high';
    let percentage = 100;

    if (connection) {
      const { effectiveType, downlink, rtt } = connection;

      if (effectiveType === '4g' || downlink > 5) {
        quality = 'high';
        percentage = 100;
      } else if (effectiveType === '3g' || downlink > 2) {
        quality = 'medium';
        percentage = 75;
      } else if (effectiveType === '2g' || downlink > 0.5) {
        quality = 'low';
        percentage = 50;
      } else {
        quality = 'poor';
        percentage = 25;
      }

      const newStatus: NetworkStatus = {
        isOnline: navigator.onLine,
        connectionQuality: quality,
        qualityPercentage: percentage,
        downloadSpeed: downlink,
        effectiveType,
        latency: rtt,
      };

      setStatus(newStatus);
      onStatusChange(newStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Effect: To handle the online and offline events
  useEffect(() => {
    const handleOnline = () => {
      checkConnectionQuality();
    };

    const handleOffline = () => {
      const offlineStatus: NetworkStatus = {
        isOnline: false,
        connectionQuality: 'poor',
        qualityPercentage: 0,
      };
      setStatus(offlineStatus);
      onStatusChange(offlineStatus);
    };

    const nav = navigator as NavigatorWithConnection;
    const connection = nav.connection || nav.mozConnection || nav.webkitConnection;

    // Initial check
    checkConnectionQuality();

    // Event listeners
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    if (connection) {
      connection.addEventListener('change', checkConnectionQuality);
    }

    // Performance monitoring
    const performanceObserver = new PerformanceObserver((list) => {
      const entries = list.getEntries();
      entries.forEach((entry: PerformanceEntry) => {
        if (entry.entryType === 'resource' || entry.entryType === 'navigation') {
          const duration = entry.duration;
          if (duration > 5000) {
            setStatus((prev) => ({ ...prev, connectionQuality: 'poor', qualityPercentage: 25 }));
          } else if (duration > 2000) {
            setStatus((prev) => ({ ...prev, connectionQuality: 'low', qualityPercentage: 50 }));
          }
        }
      });
    });

    try {
      performanceObserver.observe({ entryTypes: ['resource', 'navigation'] });
    } catch (error) {
      datadogLogs.logger.warn('PerformanceObserver not supported', {
        error,
        message: 'PerformanceObserver not supported',
        timestamp: new Date().toISOString(),
      });
    }

    // Cleanup
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      if (connection) {
        connection.removeEventListener('change', checkConnectionQuality);
      }
      performanceObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkConnectionQuality]);

  return status;
};
