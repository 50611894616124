import { useEffect, useState } from 'react';
import { DashboardNav } from 'components/screens';
import { CONFIG, USER_TYPE } from 'configs';
import SidebarMenu from './MenuPage/SidebarMenu';
import { BouncingDotsLoader, Footer } from 'components/common';
import { useGetSiblingParentListDetailsQuery } from 'store/apiSlices/parentDashboard.apiSlice';
import RegularQcReports from './index';
import { IReportType } from 'types/reports';
import { EXAM_STATUS, INTERVENTION_MATH, REGULAR_MATH } from 'configs/reports';
import { useNavigate, useParams } from 'react-router-dom';
import InterventionReport from './InterventionReport';
import { SANITY_EPIC_SEARCH_NAMES } from 'configs/common';
import { useSanityOptions } from 'hooks/useSanityOptions';
import Cookie from 'js-cookie';
import ExamStatusReport from './ExamStatusReport';

const DashboardLayout = () => {
  const navigate = useNavigate();
  const { studentId } = useParams();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [subjects, setSubjects] = useState<{ _id: string; name: string }[]>([]);
  const [selectedSegment, setSelectedSegment] = useState<string>('');
  const [activeReport, setActiveReport] = useState({
    regularMath: true,
    interventionMath: false,
    examStatus: false,
  });

  const isImpersonatedUser =
    Cookie.get(`${CONFIG.VITE_BASE_DOMAIN_ENV}IsImpersonatedUser`) === 'true';

  const isAdminRoute = location.pathname.startsWith('/a');
  const prefix = isAdminRoute ? '/a' : '/p';
  const { data: parentSiblingsData, isLoading: isParentSibilingIsloading } =
    useGetSiblingParentListDetailsQuery(undefined, { skip: isAdminRoute });

  const { data: subjectsList, isLoading: loadingSubject } = useSanityOptions(
    SANITY_EPIC_SEARCH_NAMES.SUBJECTS,
  );

  useEffect(() => {
    if (location.pathname.includes('/intervention') && isImpersonatedUser) {
      setActiveReport({ regularMath: false, interventionMath: true, examStatus: false });
    } else if (location.pathname.includes('/exam-status') && isImpersonatedUser) {
      setActiveReport({ regularMath: false, interventionMath: false, examStatus: true });
    } else {
      setActiveReport({ regularMath: true, interventionMath: false, examStatus: false });
    }
  }, [isImpersonatedUser]);

  useEffect(() => {
    if (subjectsList?.docs) {
      const segments = subjectsList.docs.map((doc) => ({
        name: doc?.name || '',
        _id: doc?._id,
      }));
      // default segment as math for now
      setSelectedSegment(segments?.[0]?._id);
      setSubjects(segments);
    }
  }, [subjectsList]);

  const handleReportSelection = (reportType: IReportType) => {
    if ((reportType === INTERVENTION_MATH || reportType === EXAM_STATUS) && !isImpersonatedUser)
      return;

    setActiveReport({
      regularMath: reportType === REGULAR_MATH,
      interventionMath: reportType === INTERVENTION_MATH,
      examStatus: reportType === EXAM_STATUS,
    });

    if (reportType === INTERVENTION_MATH) {
      navigate(`${prefix}/reports/${studentId}/chapter/intervention`);
    } else if (reportType === EXAM_STATUS) {
      navigate(`${prefix}/reports/${studentId}/chapter/exam-status`);
    } else {
      navigate(`${prefix}/reports/${studentId}/chapter/`);
    }
  };

  const isLoading = isParentSibilingIsloading || loadingSubject;

  return (
    <div className='min-h-screen bg-neutral-25 bg-dotted-pattern'>
      <DashboardNav type={USER_TYPE?.GUARDIAN} parentSiblingsData={parentSiblingsData} />
      {isLoading ? (
        <div className='flex items-center h-screen justify-center flex-1 mx-2 xl:mx-6'>
          <BouncingDotsLoader />
        </div>
      ) : (
        <>
          <div className='relative'>
            <SidebarMenu
              isCollapsed={isCollapsed}
              setIsCollapsed={setIsCollapsed}
              handleReportSelection={handleReportSelection}
              activeReport={activeReport}
              segments={subjects}
            />
            {activeReport?.regularMath && (
              <RegularQcReports
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsed}
                selectedSegment={selectedSegment}
                studentDetails={parentSiblingsData}
              />
            )}
            {activeReport?.interventionMath && isImpersonatedUser && (
              <InterventionReport
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsed}
                selectedSegment={selectedSegment}
                studentDetails={parentSiblingsData}
              />
            )}
            {activeReport?.examStatus && isImpersonatedUser && (
              <ExamStatusReport
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsed}
                selectedSegment={selectedSegment}
                studentDetails={parentSiblingsData}
              />
            )}
          </div>
          <Footer classname='lg:fixed lg:bottom-0 lg:z-50' />
        </>
      )}
    </div>
  );
};

export default DashboardLayout;
