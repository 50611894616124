import { memo } from 'react';

interface TimeUnitProps {
  value: number;
  label: string;
}

const TimeUnit = memo<TimeUnitProps>(({ value, label }) => (
  <div className='flex flex-col items-center w-12'>
    <span className='text-4xl font-bold text-blue-400 font-sora'>
      {String(value).padStart(2, '0')}
    </span>
    <span className='mt-2 text-xs font-medium tracking-wider text-gray-400 uppercase md:mt-3 md:text-sm'>
      {label}
    </span>
  </div>
));

TimeUnit.displayName = 'TimeUnit';

export default TimeUnit;
