import React from 'react';
import classNames from 'classnames';
import { RedirectIcon } from 'assets/svg';
import { InDepthQcCardProps } from 'types/reports';
import {
  DROP_IN_PERFORMANCE_VARIANT,
  IMPROVED_VARIANT,
  SAME_AS_BEFORE_VARIANT,
} from 'configs/reports';
import MetricItem from '../MetricItem';

const InDepthQcCard: React.FC<InDepthQcCardProps> = ({
  title,
  preIntervention,
  postIntervention,
  mobile,
  onClick,
  isSelected = false,
  variant = SAME_AS_BEFORE_VARIANT,
  className,
}) => {
  return (
    <div
      className={classNames(
        'relative bg-white rounded-[12px] p-3 border-[2px] border-transparent transition-all duration-200',
        !mobile && 'shadow-md hover:border-primary-100',
        mobile && 'bg-neutral-50',
        variant === IMPROVED_VARIANT &&
          isSelected &&
          'before:absolute before:top-3 before:h-[calc(100%-24px)] before:left-0 before:w-1 before:bg-green-500 before:rounded-r border-green-200',
        variant === DROP_IN_PERFORMANCE_VARIANT &&
          isSelected &&
          'before:absolute before:top-3 before:h-[calc(100%-24px)] before:left-0 before:w-1 before:bg-red-600 before:rounded-r border-red-200',
        variant === SAME_AS_BEFORE_VARIANT &&
          isSelected &&
          'before:absolute before:top-3 before:h-[calc(100%-24px)] before:left-0 before:w-1 before:bg-orange-400 before:rounded-r border-orange-200',
        className,
      )}
      onClick={onClick}
    >
      {/* Card Header */}
      <div className='flex items-center justify-between mb-4'>
        <h1 className='font-semibold text-gray-900 text-base font-sora'>
          {mobile ? 'In-Depth QC Improvement Status' : title}
        </h1>
        <img src={RedirectIcon} alt='redirect' className='text-gray-400' width={20} height={20} />
      </div>

      {/* Pre-Intervention Section */}
      <div className='mb-4 last:mb-0'>
        <h2 className='font-semibold text-neutral-900 mb-1 text-xs font-sora'>Pre-Intervention</h2>
        <div className='grid grid-cols-3 gap-8 relative border border-neutral-50 py-3 pl-3 rounded-lg'>
          <MetricItem
            value={preIntervention.questionsAsked || 0}
            label='Questions asked'
            circleStyle='bg-neutral-100 text-neutral-950'
          />
          <MetricItem
            value={preIntervention.answeredCorrectly || 0}
            percentage={preIntervention.answeredPercentage || 0}
            label='Answered correctly'
            circleStyle='bg-orange-100 text-orange-700'
          />
          <MetricItem
            value={`${preIntervention.correctSteps || 0}/${preIntervention.totalSteps || 0}`}
            percentage={preIntervention.correctStepsPercentage || 0}
            label='Correct steps percentage'
            circleStyle='bg-orange-100 text-orange-700'
          />
        </div>
      </div>

      {/* Post-Intervention Section */}
      <div className='mb-4 last:mb-0'>
        <h2 className='font-semibold text-neutral-900 mb-1 text-xs font-sora'>Post-Intervention</h2>
        <div className='grid grid-cols-3 gap-8 relative border border-neutral-50 py-3 pl-3 rounded-lg'>
          <MetricItem
            value={postIntervention.questionsAsked || 0}
            label='Questions asked'
            circleStyle='bg-neutral-100 text-neutral-950'
          />
          <MetricItem
            value={postIntervention.answeredCorrectly || 0}
            percentage={postIntervention.answeredPercentage || 0}
            label='Answered correctly'
            circleStyle={
              variant === IMPROVED_VARIANT
                ? 'bg-green-100 text-green-700'
                : variant === DROP_IN_PERFORMANCE_VARIANT
                  ? 'bg-red-100 text-red-700'
                  : 'bg-orange-100 text-orange-700'
            }
          />
          <MetricItem
            value={`${postIntervention.correctSteps || 0}/${postIntervention.totalSteps || 0}`}
            percentage={postIntervention.correctStepsPercentage || 0}
            label='Correct steps percentage'
            circleStyle={
              variant === IMPROVED_VARIANT
                ? 'bg-green-100 text-green-700'
                : variant === DROP_IN_PERFORMANCE_VARIANT
                  ? 'bg-red-100 text-red-700'
                  : 'bg-orange-100 text-orange-700'
            }
          />
        </div>
      </div>
    </div>
  );
};

export default InDepthQcCard;
