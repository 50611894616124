import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { StateKeys } from 'types';
import { Accumulator } from 'types/utils/inClass/inClassFunctions';

interface IInClass {
  myCurrentState: StateKeys;
  studentsCurrentStates: Accumulator;
  requestContentValidation: boolean;
  studentAttention: number;
}
const initialState: IInClass = {
  myCurrentState: 'teach',
  studentsCurrentStates: {
    coach_basic: [],
    coach_intermediate: [],
    coach_advanced: [],
    teach: [],
  },
  requestContentValidation: false,
  studentAttention: 0,
};

export const inClassConfigSlice = createSlice({
  name: 'inClassConfig',
  initialState,
  reducers: {
    setMyCurrentState: (state, action: PayloadAction<StateKeys>) => {
      state.myCurrentState = action.payload;
    },
    setStudentAttentionData: (state, action) => {
      state.studentAttention = action.payload;
    },
    setStudentsCurrentStates: (state, action) => {
      state.studentsCurrentStates = action.payload;
    },
    setRequestContentValidation: (state, action: PayloadAction<boolean>) => {
      state.requestContentValidation = action.payload;
    },
  },
  selectors: {
    selectMyCurrentState: (state) => state?.myCurrentState,
    selectStudentsCurrentStates: (state) => state?.studentsCurrentStates,
    selectRequestContentValidation: (state) => state?.requestContentValidation,
    selectStudentAttention: (state) => state?.studentAttention,
  },
});

export const {
  setMyCurrentState,
  setStudentsCurrentStates,
  setRequestContentValidation,
  setStudentAttentionData,
} = inClassConfigSlice.actions;

export const {
  selectMyCurrentState,
  selectStudentsCurrentStates,
  selectRequestContentValidation,
  selectStudentAttention,
} = inClassConfigSlice.selectors;
