import { isEmpty } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useGetLectureQuery } from 'store/apiSlices/inClass/lecture.apiSlice';
import {
  useAddClassNotesAnaltyicsMutation,
  useGetClassDetailsQuery,
  useUpdateClassNotesAnaltyicsMutation,
} from 'store/apiSlices/studentDashboard.apiSlice';
import {
  selectActiveElement,
  selectActiveElementIndex,
  selectActiveHTMLSlide,
  selectSetSlidesContent,
  selectShowPreviousButton,
  setActiveElement,
  setActiveElementIndex,
  setActiveHTMLSlide,
  setCurrentPrevIndex,
  setShowPreviousButton,
  setSlidesContent,
} from 'store/slice/classNotes.slice';

import { leftArrow, rightArrow, SectionHeadingLogo } from 'assets/svg';
import { BouncingDotsLoader, Button, DateFilter, PaginationComp } from 'components/common';
import { ClassNotesCard, Content, Preview, SlidesPreview } from 'components/screens';
import { Common } from 'configs/common';
import { useAppDispatch, useAppSelector } from 'hooks/store';

import { CLASS_STATUS, CONTENT_TYPE } from 'configs';
import { DateFilterOption, LectureData, LectureItem } from 'types';
import { getDateFilterParams } from 'utils';
import './style.css';

export default function ClassNotesView() {
  const dispatch = useAppDispatch();
  const fourDaysAgo = moment().subtract(4, 'days').toDate();
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  // Redux
  const getActiveElement = useAppSelector(selectActiveElement);
  const getActiveElementIndex = useAppSelector(selectActiveElementIndex);
  const getActiveHTMLSlide = useAppSelector(selectActiveHTMLSlide);
  const getIsPrevButton = useAppSelector(selectShowPreviousButton);
  const getSlidesContent = useAppSelector(selectSetSlidesContent);
  // const lectureData = useAppSelector(selectMultiLecture);

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    fourDaysAgo,
    moment().toDate(),
  ]);
  const [classId, setClassId] = useState<number>(0);
  const [isLastStep, setIsLastStep] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [disableNextTopicWhileLoading, setDisableNextTopicWhileLoading] = useState<boolean>(false);

  const onPageChange = (page: number) => setCurrentPage(page);

  const [isClassContainerCollapsed, setIsClassContainerCollapsed] = useState<boolean>(false);

  // Create a ref for the scrollable container
  const classesScrollRef = useRef<HTMLDivElement | null>(null);

  // add ClassNotesAnalytics data
  const [addClassNotesAnalytics] = useAddClassNotesAnaltyicsMutation();

  //api for updating slide viewed
  const [updateClassNotesAnalytics] = useUpdateClassNotesAnaltyicsMutation();

  // Add new state for date filter
  const [dateFilter, setDateFilter] = useState<DateFilterOption>('last_30_days');

  /* API => get the classes data */
  const {
    data: classDetailResponse,
    isLoading: isClassDetailsLoading,
    isFetching: isClassDetailsFetching,
  } = useGetClassDetailsQuery(
    {
      filter: {
        ...getDateFilterParams(dateFilter, dateRange),
        classStatus: CLASS_STATUS.ENDED,
        lectureId: { $exists: true, $ne: '' },
      },
      sort: {
        meetingStartTime: -1,
      },
      pageNumber: currentPage,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const { data: LectureData } = useGetLectureQuery(
    {
      classId: classId.toString(),
    },
    {
      skip: !classId,
      refetchOnMountOrArgChange: true,
    },
  );

  const totalRecord = classDetailResponse?.totalPages || 1;

  // Extract lecture elements from fetched data
  const lectures = useMemo(() => {
    return (
      LectureData?.data?.lectures
        ?.filter((lecture: LectureData) => lecture.teach && lecture.teach.length > 0)
        ?.map((lecture) => [
          ...(lecture.teach || []),
          ...(lecture.coach_basic || []),
          ...(lecture.coach_intermediate || []),
          ...(lecture.coach_advanced || []),
        ])
        ?.flat() || []
    );
  }, [LectureData]);

  // const lecture Elements = lecture?.filter((item) => item.type === 'elements') || [];
  const lectureElements = useMemo(() => {
    return lectures.filter(
      (item) => item.type === CONTENT_TYPE?.ELEMENT || item.type === CONTENT_TYPE.COACH_MODULE,
    );
  }, [lectures]);

  const isGoogleSide = lectureElements?.[0]?.contentUrl?.includes(Common?.GOOGLE_SLIDES_DOMAIN);
  useEffect(() => {
    if (classDetailResponse?.docs[0]?.id) {
      setClassId(classDetailResponse?.docs[0].id);
    }
  }, [classDetailResponse]);

  useEffect(() => {
    // Check if lecture data is available and update slides content and active element accordingly
    if (lectures && !isGoogleSide) {
      dispatch(
        setSlidesContent(lectures.filter((item): item is LectureItem => item !== undefined)),
      );
      dispatch(
        setActiveElement({
          type: '',
          slideNo: 0,
        }),
      );
    } else {
      // Reset active element if no lecture data is available
      dispatch(
        setActiveElement({
          type: '',
          slideNo: 0,
        }),
      );
    }
  }, [dispatch, isGoogleSide, lectures]);

  const handlePrevStep = () => {
    setIsClassContainerCollapsed(true); //closing classes div
    iframeRef?.current?.contentWindow?.postMessage({ type: 'uprio_custom', fn: 'Prev' }, '*');
  };
  const handleNextStep = () => {
    setIsClassContainerCollapsed(true); //closing classes div
    iframeRef?.current?.contentWindow?.postMessage({ type: 'uprio_custom', fn: 'Next' }, '*');
  };

  const handleNextElement = useCallback(() => {
    setIsClassContainerCollapsed(true); //closing classes div
    // const lectureElements = lecture?.filter((item) => item.type === 'elements') || [];
    const nextIndex = getActiveElementIndex + 1; // Start with the next element
    const nextElement = lectureElements?.[nextIndex];

    if (
      nextElement &&
      (nextElement.type === CONTENT_TYPE?.ELEMENT ||
        nextElement.type === CONTENT_TYPE?.COACH_MODULE) &&
      nextElement?.thumbnails &&
      (nextElement?.elementDetails || nextElement?.module)
    ) {
      dispatch(setActiveElementIndex(nextIndex));
      dispatch(setActiveElement(nextElement));
      // API call to create class-notes-analytics
      addClassNotesAnalytics({
        classId: classId,
        elementId: nextElement?.elementDetails?.elementId || nextElement?.module?._key || '',
        elementName: nextElement?.elementDetails?.name || nextElement?.title || '',
        totalSlides: nextElement?.thumbnails?.length,
      });
    } else {
      // Return null or handle the scenario where no element of type 'elements' is found
      return null;
    }
  }, [getActiveElementIndex, lectureElements, dispatch, addClassNotesAnalytics, classId]);

  const handlePrevElement = useCallback(() => {
    setIsClassContainerCollapsed(true); //closing classes div
    // const lectureElements = lecture?.filter((item) => item.type === 'elements') || [];
    let prevIndex = getActiveElementIndex - 1; // Start with the next element
    let prevElement = lectureElements?.[prevIndex];
    // Check if the previous element is of type 'elements', otherwise keep looking
    while (
      prevElement &&
      prevElement.type !== CONTENT_TYPE?.ELEMENT &&
      prevElement?.type !== CONTENT_TYPE?.COACH_MODULE
    ) {
      prevIndex--;
      prevElement = lectureElements?.[prevIndex];
    }
    if (
      prevElement &&
      (prevElement.type === CONTENT_TYPE?.ELEMENT ||
        prevElement?.type === CONTENT_TYPE?.COACH_MODULE)
    ) {
      dispatch(setActiveElementIndex(prevIndex));
      dispatch(setActiveElement(prevElement));
    } else {
      return null;
    }
  }, [dispatch, getActiveElementIndex, lectureElements]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.keyCode === 37) {
        handlePrevStep();
      } else if (e.keyCode === 39) {
        handleNextStep();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const activeIndex = classDetailResponse?.docs?.findIndex(
      (classDetail) => classDetail.id === classId,
    );
    if (activeIndex !== -1 && activeIndex && classesScrollRef.current) {
      classesScrollRef.current.children[activeIndex]?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [classDetailResponse?.docs, classId, isClassContainerCollapsed]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const data = event.data || {};
      const { evt, type, slide } = data || {};
      if (type === 'uprio_custom') {
        if (evt === 'progress') {
          dispatch(setCurrentPrevIndex(data.slide));
          setIsLastStep(false);
          if (getSlidesContent?.slideData) {
            getSlidesContent.slideData.forEach((item) => {
              if (item?.id === getActiveElement?.presentationId) {
                dispatch(setActiveHTMLSlide(item?.slideNo?.[data?.slide]));
              }
            });
          }
          if (slide === 1 && data?.step === 0) {
            dispatch(setShowPreviousButton(true));
          } else {
            dispatch(setShowPreviousButton(false));
          }
          if (data?.step === 0 && data.slide !== 1) {
            updateClassNotesAnalytics({
              slideNo: data.slide,
              viewed: true,
              classId: classId,
              elementId:
                getActiveElement?.elementDetails?.elementId || getActiveElement?.module?._key || '',
            });
          }
        } else if (evt === 'last_step') {
          dispatch(setCurrentPrevIndex(data.slide));
          setDisableNextTopicWhileLoading(false);
          if (getActiveElement?.thumbnails?.length === slide) {
            setIsLastStep(true);
          }
        }
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [
    classId,
    dispatch,
    getActiveElement?.elementDetails?.elementId,
    getActiveElement?.module?._key,
    getActiveElement?.presentationId,
    getActiveElement?.thumbnails?.length,
    getSlidesContent.slideData,
    updateClassNotesAnalytics,
  ]);

  return (
    <div>
      <div className='flex flex-wrap justify-between gap-2 px-2 py-2'>
        <div className='items-center text-xl font-bold tracking-wider uppercase whitespace-nowrap md:py-4 md:flex font-sora text-start md:text-lg 2xl:text-xl xl:leading-6'>
          <img src={SectionHeadingLogo} alt='uprio section logo' className='mr-5' />
          {'Class Notes'}
        </div>
        <DateFilter
          selectedFilter={dateFilter}
          isCustomDateFilter={true}
          onFilterChange={(filter, customRange) => {
            setDateFilter(filter);
            if (customRange) {
              setDateRange([customRange.startDate, customRange.endDate]);
            }
            setCurrentPage(1);
          }}
          setCurrentPage={setCurrentPage}
          isClassNotes={true}
        />
      </div>
      {isClassDetailsLoading || isClassDetailsFetching ? (
        <div className='flex items-center justify-center flex-1 h-[calc(100vh-20rem)]'>
          <BouncingDotsLoader />
        </div>
      ) : (
        <div className='flex flex-col h-auto md:flex-row md:h-full font-sora'>
          {/* Classes Container */}
          {isClassContainerCollapsed ? (
            <>
              <div
                className='absolute flex flex-row items-center flex-1 rotate-90 cursor-pointer'
                style={{ transformOrigin: '0 0' }}
                onClick={() => setIsClassContainerCollapsed(false)}
              >
                <img src={rightArrow} className='px-1 -rotate-90' width={30} />
                <span className='font-semibold'>All Classes</span>
              </div>
            </>
          ) : (
            <div className='flex flex-col justify-between gap-2 p-2 rounded-lg bg-gray-400/20 w-full md:w-[20%]'>
              {/* SHOW THE HEADER AS All Classes */}
              <div className='flex items-center justify-between px-3'>
                <h2 className='text-lg font-bold'>All Classes</h2>
              </div>

              {/* Adjust height responsively */}
              <div className='h-[calc(100vh-25rem)] overflow-y-auto classNotesScrollbar'>
                {classDetailResponse?.docs?.map((classDetail) => (
                  <ClassNotesCard
                    key={classDetail.id}
                    time={{
                      startTime: classDetail?.meetingStartTime,
                      endTime: classDetail?.meetingEndTime,
                    }}
                    lectureName={classDetail?.lectureConfigurationName || classDetail?.name}
                    subjectId={classDetail.subjectId}
                    onClick={() => {
                      setClassId(classDetail.id);
                    }}
                    selectedClassId={classId}
                    cardClassId={classDetail.id}
                    subjectName={classDetail.subjectName}
                    lectures={classDetail.lectures}
                    classType={classDetail.type}
                  />
                ))}
              </div>

              <div className='flex flex-col items-center justify-center overflow-x-auto text-sm lg:text-xs'>
                <span className='text-xs font-poppins'>
                  Showing page <b>{currentPage}</b> of <b>{totalRecord}</b> total pages
                </span>
                <PaginationComp
                  layout='navigation'
                  currentPage={currentPage}
                  totalPages={totalRecord}
                  onPageChange={onPageChange}
                  className='py-0'
                  showIcons
                />
              </div>
            </div>
          )}
          {/* Elements Preview */}
          <div className='flex justify-start mx-2'>
            <Preview
              classId={classId}
              iframeRef={iframeRef}
              isGoogleSide={isGoogleSide}
              setIsClassContainerCollapsed={setIsClassContainerCollapsed}
            />
          </div>
          <SlidesPreview
            iframeRef={iframeRef}
            setIsClassContainerCollapsed={setIsClassContainerCollapsed}
            classId={classId}
          />
          {/* HTML Slides Iframe */}
          {getActiveElement?.contentUrl && !isGoogleSide ? (
            <div className='flex flex-col flex-1'>
              <Content contentUrl={getActiveElement?.contentUrl} iframeRef={iframeRef} />
              <div className='flex items-center gap-6 mb-1'>
                <div className='flex justify-end flex-1'>
                  {getIsPrevButton ? (
                    <>
                      {lectures?.[getActiveElementIndex - 1] && (
                        <button
                          className='bg-white focus:ring-2 display-bold md:text-base ring-2 focus:ring-primary-500 active:ring-primary-500 ring-primary-500 text-primary-500 enabled:hover:bg-white enabled:hover:text-primary-500  rounded-[14px] p-2 m-1'
                          onClick={() => handlePrevElement()}
                        >
                          <span>Previous Topic</span>
                        </button>
                      )}
                    </>
                  ) : (
                    <Button
                      icon={leftArrow}
                      preIcon={true}
                      type='submit'
                      onClick={() => handlePrevStep()}
                      className={`bg-white m-1 focus:ring-2 display-bold md:text-lg ring-2 focus:ring-primary-500 active:ring-primary-500 ring-primary-500 text-primary-500 enabled:hover:bg-white enabled:hover:text-primary-500  rounded-[14px]`}
                    />
                  )}
                </div>
                <div>
                  <span className='flex items-center justify-center w-full text-xl'>
                    {getActiveHTMLSlide} / {getSlidesContent?.lastSlideNo}
                  </span>
                </div>
                <div className='flex-1'>
                  {isLastStep ? (
                    <>
                      {lectureElements?.[getActiveElementIndex + 1] ? (
                        <button
                          className='bg-white focus:ring-2 display-bold md:text-base ring-2 focus:ring-primary-500 active:ring-primary-500 ring-primary-500 text-primary-500 enabled:hover:bg-white enabled:hover:text-primary-500  rounded-[14px] p-2 m-1'
                          onClick={() => {
                            handleNextElement();
                            setDisableNextTopicWhileLoading(true);
                          }}
                          disabled={disableNextTopicWhileLoading}
                        >
                          <span>Next Topic</span>
                        </button>
                      ) : (
                        <button className='bg-white focus:ring-2 display-bold md:text-base ring-2 focus:ring-primary-500 active:ring-primary-500 ring-primary-500 text-primary-500 enabled:hover:bg-white enabled:hover:text-primary-500  rounded-[14px] p-2 m-1'>
                          <span>Completed</span>
                        </button>
                      )}
                    </>
                  ) : (
                    <Button
                      icon={rightArrow}
                      preIcon={true}
                      type='submit'
                      onClick={() => handleNextStep()}
                      className={`bg-white m-1 focus:ring-2 display-bold md:text-lg ring-2 focus:ring-primary-500 active:ring-primary-500 ring-primary-500 text-primary-500 enabled:hover:bg-white enabled:hover:text-primary-500  rounded-[14px]`}
                    />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className='flex items-center justify-center'>
              {isEmpty(lectureElements) ? (
                <div className='w-[70vw] flex justify-center'>No notes present</div>
              ) : (
                <>
                  {lectureElements?.[0]?.contentUrl?.includes(Common?.GOOGLE_SLIDES_DOMAIN) ? (
                    <div className='w-[70vw] flex justify-center'>No notes present</div>
                  ) : (
                    <div className='w-[56vw] flex justify-center'>Please select the topic</div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
