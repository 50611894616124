// ... existing code ...

import { datadogLogs } from '@datadog/browser-logs';
import { DevicePermissionStatus } from 'configs';
import { MediaPermissionState } from 'hooks/inClass/useMediaDevices';
import { IUserDeviceLogPayload, IDevice, NavigatorWithConnection } from 'types';
import { getDeviceType } from 'utils/deviceLogsTracker';

const getConnection = () => {
  const nav = navigator as NavigatorWithConnection;
  return nav?.connection || nav?.mozConnection || nav?.webkitConnection;
};

export const logMediaDeviceSelection = async (
  params: {
    userInfo: {
      userId: number;
      userName: string;
      userType: string;
      classId: number;
      batchId?: number;
      batchName?: string;
    };
    deviceState: {
      availableDevices: {
        audio: MediaDeviceInfo[];
        video: MediaDeviceInfo[];
        speaker: MediaDeviceInfo[];
      };
      currentSelectedDevice: {
        audio?: string;
        video?: string;
        speaker?: string;
      };
      permissions: {
        audio: MediaPermissionState;
        video: MediaPermissionState;
      };
      errors?: {
        audio?: string | null;
        video?: string | null;
        speaker?: string | null;
      };
      cameraInUse: boolean;
    };
  },
  apiCallback: (params: { classId: number; payload: IUserDeviceLogPayload }) => Promise<unknown>,
): Promise<void> => {
  try {
    const { userInfo, deviceState } = params;

    if (!userInfo?.userId || !userInfo?.classId) {
      return;
    }

    // Map MediaPermissionState to DevicePermissionStatus
    const mapPermissionStatus = (state: MediaPermissionState): DevicePermissionStatus => {
      switch (state) {
        case 'ACCEPTED':
          return DevicePermissionStatus.GRANTED;
        case 'DENIED':
          return DevicePermissionStatus.DENIED;
        case 'SYSTEM_DENIED':
          return DevicePermissionStatus.SYSTEM_DENIED;
        case 'COULD_NOT_START':
          return DevicePermissionStatus.COULD_NOT_START;
        case 'NO_DEVICES_AVAILABLE':
          return DevicePermissionStatus.NO_DEVICES_AVAILABLE;
        default:
          return DevicePermissionStatus.GRANTED;
      }
    };

    // Map MediaDeviceInfo to IDevice
    const mapToIDevice = (device: MediaDeviceInfo): IDevice => ({
      deviceId: device?.deviceId || 'default',
      label: device?.label || 'Default',
      groupId: device?.groupId || 'default',
      kind: device?.kind || 'default',
    });

    // Helper function to get active device
    const getActiveDevice = (
      devices: MediaDeviceInfo[],
      selectedId: string | undefined,
      defaultKind: 'audioinput' | 'videoinput' | 'audiooutput',
      defaultLabel: string,
    ): IDevice => {
      const selectedDevice = devices?.find((d) => d?.deviceId === selectedId);
      const defaultDevice = devices?.find((d) => d.groupId === 'default');

      if (selectedDevice) {
        return mapToIDevice(selectedDevice);
      }

      if (defaultDevice) {
        return mapToIDevice(defaultDevice);
      }

      // If no device is found, return a default device with required non-empty values
      return {
        deviceId: 'default',
        label: defaultLabel,
        kind: defaultKind || 'default',
        groupId: 'default',
      };
    };

    // Helper function to get permission status
    const getPermissionStatus = (
      permission: MediaPermissionState,
      isSystem: boolean,
    ): MediaPermissionState => {
      if (isSystem) {
        return permission === 'SYSTEM_DENIED' ? 'SYSTEM_DENIED' : 'NOT_REQUESTED';
      }
      return permission === 'DENIED' ? 'DENIED' : 'NOT_REQUESTED';
    };

    const payload: IUserDeviceLogPayload = {
      userId: userInfo?.userId,
      userName: userInfo?.userName || 'Unknown User',
      userType: userInfo?.userType || 'unknown',
      classId: Number(userInfo?.classId),
      batchId: userInfo?.batchId || 0,
      batchName: userInfo?.batchName || '',
      systemInfo: {
        userAgent: navigator?.userAgent || 'unknown',
        deviceType: getDeviceType(),
        networkInfo: {
          effectiveType: getConnection()?.effectiveType || 'unknown',
        },
      },
      availableDevices: {
        audio: deviceState?.availableDevices?.audio?.map(mapToIDevice) || [],
        video: deviceState?.availableDevices?.video?.map(mapToIDevice) || [],
        speaker: deviceState?.availableDevices?.speaker?.map(mapToIDevice) || [],
      },
      activeDevice: {
        audio: getActiveDevice(
          deviceState?.availableDevices?.audio || [],
          deviceState?.currentSelectedDevice?.audio,
          'audioinput',
          'Default Audio Device',
        ),
        video: getActiveDevice(
          deviceState?.availableDevices?.video || [],
          deviceState?.currentSelectedDevice?.video,
          'videoinput',
          'Default Video Device',
        ),
        speaker: getActiveDevice(
          deviceState?.availableDevices?.speaker || [],
          deviceState?.currentSelectedDevice?.speaker,
          'audiooutput',
          'Default Speaker Device',
        ),
      },
      permissions: {
        browser: {
          camera: {
            status: mapPermissionStatus(
              getPermissionStatus(deviceState?.permissions?.video || 'NOT_REQUESTED', false),
            ),
            reason: deviceState?.errors?.video || undefined,
          },
          microphone: {
            status: mapPermissionStatus(
              getPermissionStatus(deviceState?.permissions?.audio || 'NOT_REQUESTED', false),
            ),
            reason: deviceState?.errors?.audio || undefined,
          },
        },
        system: {
          camera: {
            status: mapPermissionStatus(
              getPermissionStatus(deviceState?.permissions?.video || 'NOT_REQUESTED', true),
            ),
            reason: deviceState?.errors?.video || undefined,
          },
          microphone: {
            status: mapPermissionStatus(
              getPermissionStatus(deviceState?.permissions?.audio || 'NOT_REQUESTED', true),
            ),
            reason: deviceState?.errors?.audio || undefined,
          },
        },
        cameraIsInUse: {
          status: deviceState?.cameraInUse || false,
        },
      },
    };

    await apiCallback({
      classId: Number(userInfo?.classId),
      payload,
    });
  } catch (error) {
    datadogLogs.logger.error('Error logging media device selection', { error });
  }
};
