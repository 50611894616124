import React from 'react';
import classNames from 'classnames';
import { ExamCategoryCardProps } from 'types/reports';
import Tag from '../Tag';
import { getStatusClasses } from 'configs/studentReport';
import { BulletIcon, RedirectIcon } from 'assets/svg';

const ExamCategoryCard: React.FC<ExamCategoryCardProps> = ({
  category,
  onClick,
  className,
  selected = false,
  evaluationId,
}) => {
  return (
    <div
      className={classNames(
        'relative bg-white rounded-[12px] p-[12px] cursor-pointer before:absolute before:top-[12px] before:h-[calc(100%-24px)] before:left-0 before:rounded-tr-[12px] before:rounded-br-[12px] before:w-[4px]',
        className,
        getStatusClasses(evaluationId),
        {
          'border border-primary-500': selected,
        },
      )}
      onClick={onClick}
    >
      <div className='mb-[12px]'>
        <div className='flex items-center justify-between mb-[4px]'>
          <h2 className='text-neutral-300 font-poppins font-medium text-[10px]'>
            Category {category?.categoryNumber}
          </h2>
          {category?.totalQuestions > 0 && (
            <img src={RedirectIcon} alt='redirect icon' width={16} height={16} />
          )}
        </div>
        <div className='flex items-center justify-between gap-[4px] text-neutral-900 text-[14px]'>
          <h3 className='text-neutral-950 max-w-full font-sora font-semibold text-xs'>
            {category?.questionCategoryName}
          </h3>
        </div>
      </div>
      {category?.totalQuestions > 0 && (
        <div className='flex flex-col gap-[16px] py-[12px] px-[8px] bg-neutral-50 rounded-[8px]'>
          {/* Correctly Answered */}
          <div className='flex items-center justify-between'>
            <div className='flex items-center gap-[8px]'>
              <img src={BulletIcon} alt='bullet icon' width={16} height={16} />
              <span className='text-neutral-500 font-poppins font-medium text-xs'>
                Questions answered correctly:
              </span>
            </div>
            <div className='flex items-center gap-[4px]'>
              <Tag
                text={category.questionsAnswered + '/' + category.totalQuestions}
                className='success'
              />
              <span className='text-xs font-sora'>
                ({((category.questionsAnswered / category.totalQuestions) * 100).toFixed(0)}
                %)
              </span>
            </div>
          </div>

          {/* Student Could Not Solve */}
          <div className='flex items-center justify-between'>
            <div className='flex items-center gap-[8px]'>
              <img src={BulletIcon} alt='bullet icon' width={18} height={18} />
              <span className='text-neutral-500 font-poppins font-medium text-xs'>
                Correct steps percentage:
              </span>
            </div>
            <div className='flex items-center gap-[4px]'>
              <Tag text={category.correctSteps + '/' + category.totalSteps} className='success' />
              <span className='text-xs font-sora'>
                ({((category.correctSteps / category.totalSteps) * 100).toFixed(0)}
                %)
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExamCategoryCard;
