import { CLASS_NOTES_FORMATTED_TYPES, CLASS_NOTES_TYPES } from 'configs';
import moment from 'moment';
import { DateFilterOption } from 'types';

export type DateRange = { startDate: Date; endDate: Date } | (Date | null)[] | Date;

export const getDateFilterParams = (
  dateFilter: DateFilterOption,
  dateRange?: DateRange,
  timeField: 'meetingEndTime' | 'assignedOn' = 'meetingEndTime',
) => {
  const getStartDate = (range?: DateRange) =>
    Array.isArray(range) ? range[0] : range instanceof Date ? range : range?.startDate;

  const getEndDate = (range?: DateRange) =>
    Array.isArray(range) ? range[1] : range instanceof Date ? range : range?.endDate;

  switch (dateFilter) {
    case 'last_30_days':
      return {
        [timeField]: {
          $gte: moment().subtract(30, 'days').startOf('day').toISOString(),
          $lte: moment().endOf('day').toISOString(),
        },
      };
    case 'current_month':
      return {
        [timeField]: {
          $gte: moment().startOf('month').toISOString(),
          $lte: moment().endOf('month').toISOString(),
        },
      };
    case 'past_month':
      return {
        [timeField]: {
          $gte: moment().subtract(1, 'month').startOf('month').toISOString(),
          $lte: moment().subtract(1, 'month').endOf('month').toISOString(),
        },
      };
    case 'custom':
      return {
        [timeField]: {
          $gte: moment(getStartDate(dateRange))?.startOf('day')?.toISOString(),
          $lte: moment(getEndDate(dateRange))?.endOf('day')?.toISOString(),
        },
      };
    default:
      return {};
  }
};

export const formattedClassType = (classType: string[]) => {
  // Prioritize evaluation if both regular class types are present
  if (
    classType?.includes(CLASS_NOTES_TYPES.REGULAR_CLASS) &&
    classType?.includes(CLASS_NOTES_TYPES.REGULAR_EVALUATION_CLASS)
  ) {
    return CLASS_NOTES_FORMATTED_TYPES.EVALUATION;
  }

  // Map each class type to its formatted type
  const typeMap = {
    [CLASS_NOTES_TYPES.REGULAR_CLASS]: CLASS_NOTES_FORMATTED_TYPES.TEACH,
    [CLASS_NOTES_TYPES.REGULAR_EVALUATION_CLASS]: CLASS_NOTES_FORMATTED_TYPES.EVALUATION,
    [CLASS_NOTES_TYPES.INTERVENTION_CLASS]: CLASS_NOTES_FORMATTED_TYPES.INTERVENTION,
    [CLASS_NOTES_TYPES.INTERVENTION_EVALUATION_CLASS]: CLASS_NOTES_FORMATTED_TYPES.INTERVENTION,
    [CLASS_NOTES_TYPES.REVISION_CLASS]: CLASS_NOTES_FORMATTED_TYPES.REVISION,
    [CLASS_NOTES_TYPES.REVISION_EVALUATION_CLASS]: CLASS_NOTES_FORMATTED_TYPES.REVISION,
    [CLASS_NOTES_TYPES.MOCK_PAPER]: CLASS_NOTES_FORMATTED_TYPES.MOCK_PAPER,
    [CLASS_NOTES_TYPES.MOCK_PAPER_DISCUSSION_CLASS]: CLASS_NOTES_FORMATTED_TYPES.MOCK_PAPER,
    [CLASS_NOTES_TYPES.DEMO_CLASS]: CLASS_NOTES_FORMATTED_TYPES.DEMO,
    [CLASS_NOTES_TYPES.DOUBT_SOLVING_CLASS]: CLASS_NOTES_FORMATTED_TYPES.DOUBT_SOLVING,
  };

  // Get mapped types and remove duplicates
  const mappedTypes = classType?.map((type) => typeMap[type as keyof typeof typeMap] || type);
  const uniqueTypes = Array.from(new Set(mappedTypes));

  // Return single type if all map to the same, otherwise return array
  return uniqueTypes?.length === 1 ? uniqueTypes[0] : uniqueTypes;
};
