import { BouncingDotsLoader } from 'components/common';
import SectionHeader from 'components/screens/reports/SectionHeader';
import { NO_CHAPTERS_FOUND, NO_CHAPTERS_FOUND_EXPLAINATION } from 'configs/reports';
import { useWindowDimensions } from 'hooks';
import { useScrollManagement } from 'hooks/reports/useScrollManagement';
import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetCategoryBasedInterventionReportsQuery } from 'store/apiSlices/reports.apiSlice';
import { ISiblingParentListResponse } from 'types';
import Cookie from 'js-cookie';
import { CONFIG, USER_TYPE } from 'configs';
import { ChapterEntity } from 'types/reports';
import ChapterCard from 'components/screens/reports/ChapterCard';
import { ConnectionContext } from 'contexts/ConnectionContext';
import { selectSelectedCategoryQuestions } from 'store/slice/reports.slice';
import { useAppSelector } from 'hooks/store';
import BottomSheet from 'components/screens/reports/BottomSheet';
import CategoryDetailCard from 'components/screens/reports/CategoryDetailCard';

const InterventionReport = ({
  isCollapsed,
  setIsCollapsed,
  selectedSegment,
  studentDetails,
}: {
  isCollapsed: boolean;
  selectedSegment: string;
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  studentDetails?: ISiblingParentListResponse;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { studentId, chapterId, categoryId } = useParams();
  const containerRef = useRef<HTMLDivElement>(null);
  const selectedChapterRef = useRef<HTMLDivElement>(null);
  const classDetailRef = useRef<HTMLDivElement>(null);
  const { isMobileScreen } = useWindowDimensions();
  const questions = useAppSelector(selectSelectedCategoryQuestions);

  const [selectedChapterName, setSelectedChapterName] = useState<string>('');
  const [chapters, setChapters] = useState<ChapterEntity[]>([]);
  const [selectedChapter, setSelectedChapter] = useState<ChapterEntity | null>(null);

  const isAdminRoute = location.pathname.startsWith('/a');
  const prefix = isAdminRoute ? '/a' : '/p';
  const isInterventionRoute = location.pathname.includes('/pre-intervention');
  const impersonatedStudentDetails =
    Cookie.get(`${CONFIG.VITE_BASE_DOMAIN_ENV}StudentDetails`) || '';

  const { showPopup, setBottomSheetVisible } = useScrollManagement({
    containerRef,
    isMobile: isMobileScreen,
    defaultConfig: { extraScrollSpace: 500, behavior: 'smooth' },
  });

  const { data: interventionChaptersReportData, isLoading: reportDataLoading } =
    useGetCategoryBasedInterventionReportsQuery(
      {
        studentId: studentId && !isNaN(Number(studentId)) ? Number(studentId) : null,
        subjectId: selectedSegment,
      },
      { skip: !studentId || isNaN(Number(studentId)) || !selectedSegment },
    );

  let student;
  if (isAdminRoute) {
    student = { members: JSON.parse(impersonatedStudentDetails) };
  } else {
    student = studentDetails?.data?.find(
      (item) =>
        item.members.id === Number(studentId) && item.members.userType === USER_TYPE.STUDENT,
    );
  }

  useEffect(() => {
    const formatDate = (date: Date | string): string => {
      const parsedDate = date instanceof Date ? date : new Date(date);
      return isNaN(parsedDate.getTime())
        ? 'Invalid Date'
        : parsedDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          });
    };

    if (interventionChaptersReportData?.data?.length) {
      const processedChapters: ChapterEntity[] = interventionChaptersReportData?.data
        ?.filter((chapter) => chapter?.qcReportStatus !== 'draft')
        ?.map(
          ({
            chapterId,
            chapterName,
            questionsCount,
            qcReportStatus,
            classStartDate,
            classEndDate,
          }) => ({
            chapterId,
            chapterName,
            subjectId: selectedSegment,
            notebookEvaluations: questionsCount,
            reportStatus: qcReportStatus,
            lastClassDate: classEndDate,
            dateRange: `${formatDate(classStartDate)} - ${formatDate(classEndDate)}`,
            pollEvaluation: 0,
          }),
        );

      setChapters(processedChapters || []);
    }
  }, [interventionChaptersReportData, selectedSegment]);

  const closeCategoryDetails = () => {
    setBottomSheetVisible(false);
    const newPath = location.pathname.replace('/questions', '');
    navigate(newPath);
  };

  const handleChapterSelect = (chapter: ChapterEntity) => {
    // on click of chapter close the sidebase by default
    if (!isCollapsed) {
      setIsCollapsed(true);
    }
    setSelectedChapterName(chapter.chapterName);
    setSelectedChapter(chapter);
    if (window.scrollY > 0) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else if (selectedChapterRef.current) {
      window.scrollTo({
        top: 20,
        behavior: 'smooth',
      });
    }

    navigate(
      `${prefix}/reports/${studentId}/chapter/intervention/${chapter.chapterId}/pre-intervention`,
    );
  };

  const connectionContextValue = {
    chapterRef: selectedChapterRef,
    classDetailRef: classDetailRef,
    subjectId: selectedSegment,
    chapterName: selectedChapterName,
  };

  const filteredQuestions =
    chapterId && categoryId
      ? questions?.filter(
          (question) => question.categoryId === categoryId && question.chapterId === chapterId,
        ) || []
      : [];

  const isLoading = reportDataLoading;

  return (
    <div
      className={`transition-all duration-300 ease-in-out lg:pb-20 ${
        isCollapsed ? 'lg:ml-16' : 'lg:ml-72'
      }`}
    >
      {isLoading ? (
        <div className='flex h-screen items-center justify-center flex-1 mx-2 xl:mx-6'>
          <BouncingDotsLoader />
        </div>
      ) : (
        <ConnectionContext.Provider value={connectionContextValue}>
          <div className='pt-20 lg:pt-0 lg:pl-20'>
            {!chapters?.length ? (
              <div className='flex justify-center items-center h-fit mt-6'>
                <div className='bg-white shadow-lg rounded-lg p-8 w-full max-w-md'>
                  <h2 className='text-2xl font-bold text-red-800 mb-4 font-sora'>
                    {NO_CHAPTERS_FOUND}
                  </h2>
                  <p className='text-gray-600 font-poppins'>{NO_CHAPTERS_FOUND_EXPLAINATION}</p>
                </div>
              </div>
            ) : (
              <div
                ref={containerRef}
                className='relative overflow-x-auto lg:p-0'
                style={{
                  overflow: 'auto',
                  scrollbarWidth: 'none',
                  msOverflowStyle: 'none',
                }}
              >
                <div
                  className='flex lg:gap-20 relative z-0 m-2 lg:ms-4'
                  style={isMobileScreen ? { flexDirection: 'column', gap: '24px' } : {}}
                >
                  <div className='flex flex-col min-w-full md:min-w-fit'>
                    {(!isInterventionRoute || !isMobileScreen) && (
                      <SectionHeader
                        text={`What we have taught to ${student?.members?.firstName} so far`}
                        textColor='primary-950'
                        backgroundColor='from-[#8ECFF8] to-[#CBE9FC]'
                      />
                    )}
                    <div className='flex flex-col md:flex-row gap-3 lg:gap-20 relative w-full z-0'>
                      {(!isInterventionRoute || !isMobileScreen) && (
                        <div className='space-y-4 w-full md:w-[330px] mb-2'>
                          {chapters.map((chapter) => (
                            <div
                              key={chapter.chapterId}
                              ref={
                                selectedChapter?.chapterId === chapter.chapterId
                                  ? selectedChapterRef
                                  : null
                              }
                            >
                              <ChapterCard
                                chapter={chapter}
                                isSelected={selectedChapter?.chapterId === chapter.chapterId}
                                className='hover:border-teal-500 transition-colors cursor-pointer'
                                onClick={() => handleChapterSelect(chapter)}
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='transition-opacity duration-300'>
                    <Outlet />
                  </div>
                </div>

                {isMobileScreen ? (
                  <BottomSheet
                    isVisible={location.pathname.includes('/questions')}
                    onClose={closeCategoryDetails}
                  >
                    {categoryId && (
                      <CategoryDetailCard
                        idx={categoryId}
                        questions={filteredQuestions}
                        selected={true}
                        onClick={closeCategoryDetails}
                      />
                    )}
                  </BottomSheet>
                ) : (
                  location.pathname.includes('/questions') && (
                    <div
                      className={`fixed top-5  right-8 bg-white shadow-lg rounded-lg w-[25vw] z-50 overflow-y-auto
                  transition-all duration-300 transform
                  ${showPopup ? 'translate-x-10 opacity-100' : 'translate-x-full opacity-0'}`}
                      style={{
                        marginTop: '64px',
                        height: '80vh',
                      }}
                    >
                      {categoryId && (
                        <CategoryDetailCard
                          idx={categoryId}
                          questions={filteredQuestions}
                          selected={true}
                          onClick={closeCategoryDetails}
                        />
                      )}
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        </ConnectionContext.Provider>
      )}
      ;
    </div>
  );
};

export default InterventionReport;
