import { useDyteSelector } from '@dytesdk/react-web-core';
import { useContext, useEffect, useState } from 'react';

import InClassButton from 'components/inClass/Button';
import ToggleButton from 'components/inClass/ToggleButton';

import notificationSound from 'assets/notificationSound.mp3';
import {
  ActiveChat,
  ActiveMic,
  ActiveVideo,
  Chat,
  EndCall,
  Hand,
  Mic,
  ThinkingFace,
  Video,
} from 'assets/svg';

import { SocketContext } from 'contexts';
import { useInclassLogger, useMeeting, useNotification } from 'hooks';
import { IInClassControlsProps } from 'types';
import { checkMediaPermissions, listenToMediaPermissions } from 'utils';
import Network from '../Network';

const InClassControls = ({
  setChatToggle,
  chatToggle,
  setLeaveMeetingModal,
  studentHandsRaised,
}: IInClassControlsProps) => {
  // State variables

  const [isHighlighted, setIsHighlighted] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [videoPermissionDenied, setVideoPermissionDenied] = useState<boolean>(false);
  const [audioPermissionDenied, setAudioPermissionDenied] = useState<boolean>(false);
  const [deviceLoading, setDeviceLoading] = useState<{
    audio: boolean;
    video: boolean;
  }>({
    audio: false,
    video: false,
  });

  const socket = useContext(SocketContext);
  const { datadogLog } = useInclassLogger();
  const { meeting, self, studentName } = useMeeting();

  const isStudentHandRaised = studentHandsRaised?.[self?.customParticipantId] ?? false;

  // Getting the audio and video status
  const audioStatus = useDyteSelector((meeting) => meeting?.self?.audioEnabled);
  const videoStatus = useDyteSelector((meeting) => meeting?.self?.videoEnabled);

  const triggerNotification = useNotification();

  /* Check for the audio and video permission */
  useEffect(() => {
    const fetchMediaPermissions = async () => {
      const permissions = await checkMediaPermissions();
      setAudioPermissionDenied(permissions.audioPermissionDenied);
      setVideoPermissionDenied(permissions.videoPermissionDenied);
    };

    fetchMediaPermissions();

    listenToMediaPermissions(({ audioPermissionDenied, videoPermissionDenied }) => {
      setAudioPermissionDenied(audioPermissionDenied);
      setVideoPermissionDenied(videoPermissionDenied);
    });
  }, []);

  // Toggle audio
  const handleAudioToggle = async () => {
    if (deviceLoading?.audio) return;
    setDeviceLoading((prev) => ({ ...prev, audio: true }));
    try {
      if (audioStatus) {
        await self?.disableAudio();
      } else {
        await self?.enableAudio();
      }
      datadogLog(`${studentName} toggled audio`);
    } catch (error) {
      console.error('Error toggling audio', error);
    } finally {
      setDeviceLoading((prev) => ({ ...prev, audio: false }));
    }
  };

  // Toggle video
  const handleVideoToggle = async () => {
    if (deviceLoading?.video) return;
    setDeviceLoading((prev) => ({ ...prev, video: true }));
    try {
      if (videoStatus) {
        await self?.disableVideo();
      } else {
        await self?.enableVideo();
      }
      datadogLog(`${studentName} toggled video`);
    } catch (error) {
      console.error('Error toggling video', error);
    } finally {
      setDeviceLoading((prev) => ({ ...prev, video: false }));
    }
  };

  // Leave meeting
  const handleLeave = () => {
    setLeaveMeetingModal(true);
  };

  // Chat toggle
  const handleChatToggle = () => {
    setChatToggle((prev) => !prev);
    datadogLog(`${studentName} toggled chat`);
  };

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (isHighlighted) {
      timerId = setTimeout(() => {
        setIsHighlighted(false);
      }, 15000);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [isHighlighted]);

  const handleButtonClick = () => {
    confused(true);
    setIsHighlighted(true);
    datadogLog(`${studentName} is confused`);
  };

  // Socket emitter for raise hand
  const raiseHand = (value: boolean) => {
    try {
      if (socket && self) {
        socket?.emit('raiseHand', { [self?.customParticipantId]: value });
        datadogLog(`${studentName} Raised hand`);
      }
    } catch (error) {
      console.error('Error toggling raise hand', error);
    }
  };

  const confused = (value: boolean) => {
    try {
      if (socket && self) {
        socket.emit('imConfused', value);
        setTimeout(() => {
          socket.emit('imConfused', false);
        }, 15000);
      }
    } catch (error) {
      console.error('Error toggling confused', error);
    }
  };

  useEffect(() => {
    meeting?.chat?.on('chatUpdate', () => {
      if (!chatToggle) {
        setShowNotification(true);
        triggerNotification('💬 You have received a message!', 'info', notificationSound);
      }
    });
    return () => {
      meeting?.chat?.removeAllListeners('chatUpdate');
    };
  }, [meeting?.chat, chatToggle, triggerNotification]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (showNotification && chatToggle) {
      timeoutId = setTimeout(() => {
        setShowNotification(false);
      }, 1000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [showNotification, chatToggle]);

  return (
    <footer className='flex px-3 py-2 2xl:px-6 2xl:py-3 bg-[#38393d] rounded-2xl border border-[#fff4] items-center'>
      {/* Video Toggle */}
      <ToggleButton
        toggleStatus={videoStatus}
        icon={Video}
        activeIcon={ActiveVideo}
        label={'Video Off'}
        activeLabel={'Video On'}
        onClick={handleVideoToggle}
        isPermissionDenied={videoPermissionDenied}
        mediaControl={true}
        mediaType='video'
        isLoading={deviceLoading?.video}
        disabled={deviceLoading?.video}
      />
      {/* Audio Toggle */}
      <ToggleButton
        toggleStatus={audioStatus}
        icon={Mic}
        activeIcon={ActiveMic}
        label={'Mic Off'}
        activeLabel={'Mic On'}
        onClick={handleAudioToggle}
        isPermissionDenied={audioPermissionDenied}
        mediaControl={true}
        mediaType='audio'
        isLoading={deviceLoading?.audio}
        disabled={deviceLoading?.audio}
      />
      {/* End Call */}
      <InClassButton label='End Call' icon={EndCall} color='red' onClick={handleLeave} />
      <div className='h-16 mx-3 border-l-2 border-white opacity-20'></div>
      {/* Chat*/}
      <ToggleButton
        toggleStatus={chatToggle}
        icon={Chat}
        activeIcon={ActiveChat}
        label={'Chat'}
        activeLabel={'Chat'}
        onClick={handleChatToggle}
        showNotification={showNotification}
      />
      {/* Raise hand */}
      <InClassButton
        label={isStudentHandRaised ? 'Hands Down' : 'Raise Hand'}
        icon={Hand}
        onClick={() => raiseHand(!isStudentHandRaised)}
        className={`${isStudentHandRaised && 'bg-neutral-100 animate-pulse'}`}
      />
      <div className='h-16 mx-3 border-l-2 border-white opacity-20'></div>
      {/* I'm confused */}
      <InClassButton
        label='I’m Confused'
        icon={ThinkingFace}
        onClick={handleButtonClick}
        color={isHighlighted ? 'white' : undefined}
      />
      <div className='h-16 mx-3 border-l-2 border-white opacity-20'></div>
      {(self.videoEnabled || self.audioEnabled) && <Network />}
    </footer>
  );
};

export default InClassControls;
