import { CrossIcon, RevisionEvaluationIcon } from 'assets/svg';
import classNames from 'classnames';
import moment from 'moment';
import { NotAttendedClassDetails } from 'types/reports';
import Tag from '../Tag';

interface ClassNotAttendedProps {
  date: string;
  type: string;
  ChapterName: string;
  className?: string;
  onClick?: () => void;
}

interface ClassesNotAttendedProps {
  classes: NotAttendedClassDetails[];
  onClose?: () => void;
}

const ClassesNotAttended: React.FC<ClassesNotAttendedProps> = ({ classes, onClose }) => {
  return (
    <div className='w-full max-h-[85vh] max-w-md bg-white rounded-lg shadow-lg flex flex-col'>
      {/* Fixed Header */}
      <div
        style={{ boxShadow: '0px 16px 32px -4px #0c0c0d0d' }}
        className='flex items-center justify-between p-4 border-b border-neutral-100 flex-shrink-0'
      >
        <div className='flex items-start gap-2'>
          <img src={RevisionEvaluationIcon} alt='notebook icon' />
          <div className='flex flex-col items-start gap-2'>
            <div className='font-sora font-semibold text-base'>Classes Not Attended</div>
            <Tag text={classes?.[0]?.chapterName || ''} className='bg-teal-100 text-teal-900' />
          </div>
        </div>

        {onClose && (
          <button onClick={onClose} className='p-1 rounded-full '>
            <img src={CrossIcon} alt='close' width={24} height={24} />
          </button>
        )}
      </div>

      {/* Scrollable Content */}
      <div className='flex-1 overflow-y-auto min-h-0 pb-10'>
        <div className='p-4 space-y-4'>
          {classes
            ?.slice()
            ?.sort((a, b) => new Date(a?.date).getTime() - new Date(b?.date).getTime())
            ?.map((classItem, index) => (
              <ClassNotAttendedCard
                key={`${classItem?.classId}-${index}`}
                date={classItem.date}
                type={classItem.type}
                ChapterName={classItem.chapterName}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export const ClassNotAttendedCard: React.FC<ClassNotAttendedProps> = ({
  date,
  type,
  className,
  onClick,
}) => {
  return (
    <div
      className={classNames(
        'relative flex flex-col w-full p-3 bg-white rounded-xl  border border-neutral-100 cursor-pointer',

        className,
      )}
      onClick={onClick}
    >
      {/* Date and class type */}
      <div className='mb-2'>
        <div className='font-poppins text-sm font-medium text-neutral-300'>
          {moment(date).format('DD MMM, YYYY')}
        </div>
        <h3 className='font-poppins text-sm font-medium text-neutral-900 mt-2'>{type}</h3>
      </div>
    </div>
  );
};

export default ClassesNotAttended;
