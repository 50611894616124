import ZohoSalesIQ from 'components/common/ChatBot/initiateZohoWidget';
import useChatDetails from 'components/common/ChatBot/ChatBotDetails';

import { ComputerScreen } from 'assets/svg';
import { Footer } from 'components/common';
import { ClassNotesView, DashboardNav } from 'components/screens';

import { USER_TYPE } from 'configs';
import { isMobileOrTablet } from 'utils';

export default function ClassNotes() {
  const isMobOrTab = isMobileOrTablet();
  const chatBotDetails = useChatDetails();

  return (
    <div className='flex flex-col min-h-screen'>
      {/* Navigation */}
      <DashboardNav type={USER_TYPE?.STUDENT} />
      <div className='flex flex-col flex-1 py-3 mx-4 lg:mx-8'>
        {/* Main Content Area */}
        {isMobOrTab ? (
          <div className='flex items-center justify-center flex-1 h-full'>
            <span className='flex items-center'>
              Please Use Laptop or Desktop <img src={ComputerScreen} alt='computerScreenSvg' />
            </span>
          </div>
        ) : (
          <>
            <ClassNotesView />
          </>
        )}
      </div>
      {/* Footer */}
      <div className='sticky bottom-0 left-0 right-0 z-10 w-full bg-white border-t'>
        <Footer />
        <ZohoSalesIQ
          id={chatBotDetails.userId?.toString() || ''}
          name={chatBotDetails.name}
          email={chatBotDetails.email}
          contactNumber={chatBotDetails.contactNumber}
          role={chatBotDetails.role}
          page={chatBotDetails.landingScreen}
          activeCamera={chatBotDetails.activeCamera}
          activeMicrophone={chatBotDetails.activeMicrophone}
          activeSpeaker={chatBotDetails.activeSpeaker}
        />
      </div>
    </div>
  );
}
