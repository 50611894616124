import { useDyteMeeting, useDyteSelector } from '@dytesdk/react-web-core';
import { useCallback, useEffect, useRef } from 'react';

import { useAppSelector } from 'hooks/store';
import { selectMyCurrentState, selectStudentsCurrentStates } from 'store/slice/inClassConfig.slice';
import DyteAudioHandler from 'utils/inClass/DyteAudioHandler';

const DyteAudio = ({ tutorCurrentState }: { tutorCurrentState: string }) => {
  const { meeting } = useDyteMeeting();

  // Redux
  const myCurrentState = useAppSelector(selectMyCurrentState) || 'teach';
  const getStudentsCurrentState = useAppSelector(selectStudentsCurrentStates);
  const joinedParticipants = useDyteSelector((m) => m.participants.joined.toArray());
  const audioHandlerRef = useRef<DyteAudioHandler | null>(null);

  useEffect(() => {
    audioHandlerRef.current = new DyteAudioHandler();
    return () => {
      if (audioHandlerRef.current) {
        audioHandlerRef.current.dispose();
        audioHandlerRef.current = null;
      }
    };
  }, []);

  const audioUpdateListener = useCallback(
    ({
      id,
      audioEnabled,
      audioTrack,
    }: {
      id: string;
      audioEnabled: boolean;
      audioTrack: MediaStreamTrack;
    }) => {
      const audioId = `audio-${id}`;
      if (audioHandlerRef && audioHandlerRef.current) {
        if (audioEnabled && audioTrack != null) {
          audioHandlerRef.current.addTrack(audioId, audioTrack);
        } else {
          audioHandlerRef.current.removeTrack(audioId);
        }
      }
    },
    [audioHandlerRef],
  );

  useEffect(() => {
    for (const participant of joinedParticipants) {
      audioUpdateListener(participant);
    }
  }, [audioUpdateListener, joinedParticipants]);

  useEffect(() => {
    const participantLeftListener = ({ id }: { id: string }) => {
      if (audioHandlerRef && audioHandlerRef.current) {
        audioHandlerRef.current.removeTrack(`audio-${id}`);
        audioHandlerRef.current.removeTrack(`screenshare-${id}`);
      }
    };

    const screenShareUpdateListener = ({
      id,
      screenShareEnabled,
      screenShareTracks,
    }: {
      id: string;
      screenShareEnabled: boolean;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      screenShareTracks: any;
    }) => {
      const audioId = `screenshare-${id}`;
      if (audioHandlerRef && audioHandlerRef.current) {
        if (screenShareEnabled && screenShareTracks.audio != null) {
          audioHandlerRef.current.addTrack(audioId, screenShareTracks.audio);
        } else {
          audioHandlerRef.current.removeTrack(audioId);
        }
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const deviceUpdateListener = ({ device }: any) => {
      if (device.kind === 'audiooutput') {
        if (audioHandlerRef && audioHandlerRef.current) {
          audioHandlerRef.current.setDevice(device.deviceId);
        }
      }
    };

    meeting.participants.joined.addListener('audioUpdate', audioUpdateListener);
    meeting.participants.joined.addListener('screenShareUpdate', screenShareUpdateListener);
    meeting.participants.joined.addListener('participantLeft', participantLeftListener);
    meeting.self.addListener('deviceUpdate', deviceUpdateListener);
    return () => {
      meeting.participants.joined.removeListener('audioUpdate', audioUpdateListener);
      meeting.participants.joined.removeListener('screenShareUpdate', screenShareUpdateListener);
      meeting.participants.joined.removeListener('participantLeft', participantLeftListener);
      meeting.self.removeListener('deviceUpdate', deviceUpdateListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meeting]);

  // Replace the global mute/unmute effect with selective track muting
  useEffect(() => {
    if (!audioHandlerRef.current) return;

    // For each participant, decide if we should hear them
    joinedParticipants.forEach((participant) => {
      const participantId = participant.id;
      const audioId = `audio-${participantId}`;

      // Extract the student ID from the participant
      const studentId = participant.customParticipantId?.split('-').pop();

      // Determine if this is the tutor or a student
      const isTutor = participant.presetName === 'group_call_host';

      // Determine which room this participant is in
      let participantRoom = 'teach'; // Default to teach

      // If it's a student, find which room they're in
      if (!isTutor && studentId) {
        for (const [room, studentIds] of Object.entries(getStudentsCurrentState)) {
          if (studentIds.includes(studentId)) {
            participantRoom = room;
            break;
          }
        }
      } else if (isTutor) {
        // If it's the tutor, they're in tutorCurrentState
        participantRoom = tutorCurrentState;
      }

      // Determine if we should hear this participant
      let shouldHear = false;

      if (isTutor) {
        // We should hear the tutor if they're in our room
        shouldHear = tutorCurrentState === myCurrentState;
      } else {
        // We should hear other students if they're in our room
        shouldHear = participantRoom === myCurrentState;
      }

      // Apply the mute/unmute to this specific track
      if (shouldHear) {
        audioHandlerRef.current?.unmuteTrack(audioId);
      } else {
        audioHandlerRef.current?.muteTrack(audioId);
      }

      // Handle screenshare audio too
      const screenshareId = `screenshare-${participantId}`;
      if (shouldHear) {
        audioHandlerRef.current?.unmuteTrack(screenshareId);
      } else {
        audioHandlerRef.current?.muteTrack(screenshareId);
      }
    });
  }, [joinedParticipants, getStudentsCurrentState, myCurrentState, tutorCurrentState]);

  return null;
};

export default DyteAudio;
