import classNames from 'classnames';
import { Pagination } from 'flowbite-react';
import { CustomPaginationProps } from 'types/common';

export default function PaginationComp({ className, ...props }: CustomPaginationProps) {
  const customTheme = {
    base: 'flex items-center justify-center space-x-2',
    pages: {
      base: 'inline-flex items-center -space-x-px',
      next: {
        base: 'ml-3 px-4 py-2 text-blue-500 border-2 border-transparent rounded hover:border-blue-500 flex justify-center items-center',
        active: 'border-blue-500',
        disabled: 'border-gray-400',
      },
      previous: {
        base: 'mr-3 px-4 py-2 text-blue-500 border-2 border-transparent rounded hover:border-blue-500 flex justify-center items-center',
        active: 'border-blue-500',
        disabled: 'border-gray-400',
      },
    },
  };

  return (
    <div className={classNames('flex overflow-x-auto justify-center py-2', className)}>
      <Pagination theme={customTheme} {...props} />
    </div>
  );
}
