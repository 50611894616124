import moment from 'moment';
import { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';

import { useGetCurrentClassDetailsQuery } from 'store/apiSlices/studentDashboard.apiSlice';

import { Button, Card } from 'components/common';

import {
  AnalyticsBarChart,
  DateIcon,
  MathBook,
  ScienceBook,
  ScienceIcon,
  SubjectIconWhite,
  Timer,
  leftArrow,
  rightArrow,
} from 'assets/svg';

import { CLASS_NOT_FOUND, CLASS_STATUS, ROUTES, USER_TYPE } from 'configs';
import { IAllClasses } from 'types';
import { formatTimeRange } from 'utils';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { selectLoggedInStudentId, selectUserType } from 'store/slice/studentRegister.slice';
import { useAppSelector } from 'hooks/store';
import { useWindowDimensions } from 'hooks';
const settings = {
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToScroll: 1,
};

export default function AllClasses({ docs: filterClassDetail, completedClasses }: IAllClasses) {
  const navigate = useNavigate();
  const studentId = useAppSelector(selectLoggedInStudentId);
  const userType = useAppSelector(selectUserType);

  const { isMobileScreen } = useWindowDimensions();
  const [index, setIndex] = useState<number>(0);
  const sliderRef = useRef<Slider>(null);

  /* API => get current class list */
  const { data: currentClassResponse } = useGetCurrentClassDetailsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const currentClassDetails = currentClassResponse?.data;

  const handlePrevClick = () => {
    if (index > 0) {
      sliderRef.current?.slickPrev();
      setIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (index < filterClassDetail?.length - 1) {
      sliderRef.current?.slickNext();
      setIndex((prevIndex) => prevIndex + 1);
    }
  };

  /* TO check the meeting are active */
  const isClassActive = useCallback(
    (meetingStartTime: string, meetingEndTime: string, classStatus: string = '') => {
      return (
        (moment(currentClassResponse?.data?.currentIST).isBetween(
          moment(meetingStartTime),
          moment(meetingEndTime),
        ) ||
          moment(currentClassResponse?.data?.currentIST).isSame(moment(meetingStartTime))) &&
        classStatus !== CLASS_STATUS.ENDED
      );
    },
    [currentClassResponse],
  );

  const handleJoin = (joinCode: string) => {
    if (joinCode) {
      navigate(ROUTES.CLASSROOM_INVITE.replace(':id', joinCode));
    }
  };

  const handleNavPastClass = (classId: number) => {
    if (userType === USER_TYPE.STUDENT || !completedClasses) return;
    navigate(`/p/${studentId}/class/${classId}`);
  };

  return (
    <>
      {!filterClassDetail?.length ? (
        <div className='my-5 font-medium text-center text-primary-500'>{CLASS_NOT_FOUND}</div>
      ) : (
        <div className=''>
          <div className='flex items-center justify-end gap-3'>
            <Button
              className=''
              icon={leftArrow}
              preIcon={true}
              onClick={handlePrevClick}
              disabled={index === 0}
            />
            <Button
              className=''
              icon={rightArrow}
              preIcon={true}
              onClick={handleNextClick}
              disabled={filterClassDetail?.length - 1 === index}
            />
          </div>

          <Slider {...settings} slidesToShow={isMobileScreen ? 1 : 3} ref={sliderRef}>
            {filterClassDetail?.map((classItem) => (
              <div key={classItem.id} className='pr-2 mb-10'>
                <Card
                  className={`bg-neutral-50 rounded-2xl hover:shadow-sm ${userType === USER_TYPE.GUARDIAN && completedClasses && 'hover:bg-neutral-200 hover:cursor-pointer'}`}
                >
                  <div
                    className='flex justify-between p-2 md:p-4 lg:p-3 xl:p-4'
                    onClick={() => {
                      handleNavPastClass(classItem.id);
                    }}
                  >
                    {/* Class details */}
                    <div className='flex flex-col justify-between ml-1 lg:ml-2 min-h-40 xl:ml-0'>
                      <div>
                        <div className='flex flex-wrap items-center mb-1'>
                          <span className='inline-block p-2 mr-2 rounded-full bg-primary-500'>
                            <img
                              className='object-contain w-3 md:w-4 xl:w-5'
                              src={
                                currentClassDetails?.subjectDetails?.name === 'Maths'
                                  ? SubjectIconWhite
                                  : currentClassDetails?.subjectDetails?.name === 'Science'
                                    ? ScienceIcon
                                    : SubjectIconWhite
                              }
                              alt=''
                            />
                          </span>
                          <h6 className='text-base body-medium text-primary-600'>
                            {currentClassDetails?.subjectDetails?.name}
                          </h6>
                        </div>
                        <h6 className='text-base text-primary-900 display-bold md:text-lg lg:text-base xl:text-lg'>
                          {classItem?.lectureConfigurationName ||
                            classItem?.classConfigurationName ||
                            classItem?.name}
                        </h6>
                        {completedClasses && userType === USER_TYPE.GUARDIAN && (
                          <>
                            <div className='absolute flex p-1 text-sm text-white bg-black rounded bg-opacity-70'>
                              <img
                                src={AnalyticsBarChart}
                                width={15}
                                height={15}
                                className='mr-1'
                              />
                              Click to view
                            </div>
                          </>
                        )}
                      </div>
                      <div className='flex'>
                        <span className='bg-neutral-150 flex items-center text-neutral-700 font-poppins md:font-medium rounded-2xl p-1 md:px-3 lg:px-2 xl:px-3 text-[10px] whitespace-nowrap xl:text-xs'>
                          <img className='w-5 p-1 lg:w-4 xl:w-5' src={DateIcon} alt='Date' />
                          {moment(classItem?.meetingStartTime).format('DD MMM YY')}
                        </span>
                        <span className='bg-neutral-150 flex items-center text-neutral-700 font-medium font-poppins rounded-2xl whitespace-nowrap p-1 px-2 ml-2 text-[10px] xl:text-xs'>
                          <img className='w-5 p-1 lg:w-4 xl:w-5' src={Timer} alt='Time' />{' '}
                          {formatTimeRange(classItem?.meetingStartTime, classItem?.meetingEndTime)}
                        </span>
                      </div>
                    </div>

                    {/* Subject image */}
                    <div className='relative flex flex-col justify-between gap-2 rounded-2xl lg:ml-10'>
                      <img
                        className={`object-contain w-28 sm:w-32 ${
                          isClassActive(
                            classItem?.meetingStartTime,
                            classItem?.meetingEndTime,
                            classItem?.classStatus,
                          )
                            ? 'md:h-28 md:w-full'
                            : 'md:w-full md:h-full'
                        }`}
                        src={
                          currentClassDetails?.subjectDetails?.name === 'Maths'
                            ? MathBook
                            : currentClassDetails?.subjectDetails?.name === 'Science'
                              ? ScienceBook
                              : MathBook
                        }
                        alt=''
                      />

                      {/* Conditional rendering based on meeting time and current time */}
                      {userType === USER_TYPE.STUDENT &&
                      isClassActive(
                        classItem?.meetingStartTime,
                        classItem?.meetingEndTime,
                        classItem?.classStatus,
                      ) ? (
                        <div>
                          <button
                            onClick={() =>
                              handleJoin(classItem?.studentJoinLinks[0]?.joinCode || '')
                            }
                            className={`px-4 py-1 w-full text-sm md:text-base font-medium text-white rounded-lg bg-semantic-green-500 hover:bg-semantic-green-600 `}
                          >
                            Join Now
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Card>
              </div>
            ))}
          </Slider>
        </div>
      )}
    </>
  );
}
