import { datadogLogs } from '@datadog/browser-logs';
import { SocketContext } from 'contexts';
import useSocketSubscribe from 'hooks/useSocketSubscribe';
import { useEffect, useState, useCallback, useContext, useRef } from 'react';
import { isTabletDevice } from 'utils';
import useMeeting from './useMeeting';
import { useCreateOrUpdateUserDeviceLogMutation } from 'store/apiSlices/userDeviceLogs.apiSlice';
import { logMediaDeviceSelection } from 'utils/inClass/inClassDeviceLog';
import { USER_TYPE } from 'configs';

export type MediaPermissionState =
  | 'NOT_REQUESTED'
  | 'ACCEPTED'
  | 'DENIED'
  | 'SYSTEM_DENIED'
  | 'COULD_NOT_START'
  | 'NO_DEVICES_AVAILABLE'
  | 'CANCELED';

export type DevicesState = {
  audio: MediaDeviceInfo[];
  video: MediaDeviceInfo[];
  speaker: MediaDeviceInfo[];
  selected: {
    audio: string;
    video: string;
    speaker: string;
  };
};

export const useMediaDevices = () => {
  const { meeting, studentId, classId, studentName, batchId, batchName } = useMeeting();
  const socket = useContext(SocketContext);
  const isTablet = isTabletDevice();
  const [createOrUpdateUserDeviceLog] = useCreateOrUpdateUserDeviceLogMutation();
  const isApiCallInProgress = useRef(false);

  const [devices, setDevices] = useState<DevicesState>({
    audio: [],
    video: [],
    speaker: [],
    selected: { audio: '', video: '', speaker: '' },
  });
  const [permissions, setPermissions] = useState<{
    audio: MediaPermissionState;
    video: MediaPermissionState;
  }>({
    audio: meeting?.self?.mediaPermissions?.audio || 'NOT_REQUESTED',
    video: meeting?.self?.mediaPermissions?.video || 'NOT_REQUESTED',
  });

  const [errors, setErrors] = useState<{
    audio: string | null;
    video: string | null;
    speaker: string | null;
  }>({ audio: null, video: null, speaker: null });

  const [cameraInUse, setCameraInUse] = useState<boolean>(false);

  // New function to handle device logging
  const logDeviceState = useCallback(async () => {
    if (!studentId || !classId || isApiCallInProgress.current) return;

    try {
      isApiCallInProgress.current = true;
      await logMediaDeviceSelection(
        {
          userInfo: {
            userId: Number(studentId),
            userName: studentName || 'Student',
            userType: USER_TYPE.STUDENT,
            classId: Number(classId),
            batchId: batchId || 0,
            batchName: batchName || '',
          },
          deviceState: {
            availableDevices: {
              audio: devices.audio,
              video: devices.video,
              speaker: devices.speaker,
            },
            currentSelectedDevice: devices.selected,
            permissions,
            errors,
            cameraInUse,
          },
        },
        async (params) => {
          try {
            const response = await createOrUpdateUserDeviceLog({
              classId: Number(params.classId),
              payload: params.payload,
            }).unwrap();
            return response;
          } catch (error) {
            datadogLogs.logger.error('Failed to create/update user device log', {
              error,
              studentId,
              classId,
            });
          }
        },
      );
    } catch (error) {
      datadogLogs.logger.error('Failed to log device state', {
        error,
        studentId,
        classId,
      });
    } finally {
      isApiCallInProgress.current = false;
    }
  }, [
    studentId,
    classId,
    studentName,
    batchId,
    batchName,
    devices?.audio,
    devices?.video,
    devices?.speaker,
    devices?.selected,
    permissions,
    errors,
    cameraInUse,
    createOrUpdateUserDeviceLog,
  ]);

  const emitDevicesUpdate = useCallback(() => {
    if (!meeting?.self || !socket?.connected) return;

    // Format data according to the modal's expected structure
    socket.emit('studentDevices', {
      studentId: meeting.self.customParticipantId ?? '',
      devicesInfo: {
        audio: devices.audio.filter((d) => d && (d.deviceId || d.label)),
        video: devices.video.filter((d) => d && (d.deviceId || d.label)),
        speaker: devices.speaker.filter((d) => d && (d.deviceId || d.label)),
        selected: devices.selected,
      },
      permissions: {
        audio: permissions.audio,
        video: permissions.video,
      },
      errorMessages: errors,
      cameraInUse: { isInUse: cameraInUse },
    });

    logDeviceState();
  }, [
    meeting.self,
    socket,
    devices?.audio,
    devices?.video,
    devices?.speaker,
    devices?.selected,
    permissions?.audio,
    permissions?.video,
    errors,
    cameraInUse,
    logDeviceState,
  ]);

  const fetchDevices = useCallback(async () => {
    if (!meeting?.self) return;

    try {
      const audioDevices = (await meeting.self.getAudioDevices()) || [];
      const videoDevices = (await meeting.self.getVideoDevices()) || [];
      const speakerDevices = isTablet
        ? [
            {
              deviceId: 'default',
              label: 'default',
              kind: 'audiooutput',
              groupId: '',
            } as MediaDeviceInfo,
          ]
        : (await meeting.self.getSpeakerDevices()) || [];

      const current = meeting.self.getCurrentDevices() || {};
      const newDevices = {
        audio: audioDevices,
        video: videoDevices,
        speaker: speakerDevices,
        selected: {
          audio: current.audio?.deviceId || 'default',
          video: current.video?.deviceId || 'default',
          speaker: isTablet ? 'default' : current.speaker?.deviceId || 'default',
        },
      };
      setDevices(newDevices);

      return newDevices;
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Unknown error';
      datadogLogs.logger.error(
        `Error fetching devices for student ${studentId}, class ${classId}`,
        { error, classId, studentId },
      );
      setErrors((prev) => ({ ...prev, audio: errorMessage, video: errorMessage }));
      return null;
    }
  }, [classId, isTablet, meeting?.self, studentId]);

  // Effect for initial setup
  useEffect(() => {
    if (!meeting?.self) return;
    fetchDevices().then(emitDevicesUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update permission effect to log after permission changes
  useEffect(() => {
    if (!meeting?.self) return;

    const handlePermissionUpdate = async (payload: {
      kind: 'audio' | 'video' | 'screenshare';
      message: MediaPermissionState;
    }) => {
      if (payload.kind !== 'audio' && payload.kind !== 'video') return;

      const kind = payload.kind as 'audio' | 'video';

      if (kind === 'video') {
        const isInUse = payload.message === 'COULD_NOT_START';
        setCameraInUse(isInUse);
      }

      setPermissions((prev) => ({
        ...prev,
        [kind]: payload.message,
      }));

      try {
        await fetchDevices();
        emitDevicesUpdate();
        // Log device state after permission changes
        await logDeviceState();
      } finally {
        emitDevicesUpdate();
      }
    };

    meeting.self.on('mediaPermissionUpdate', handlePermissionUpdate);
    return () => {
      meeting.self.off('mediaPermissionUpdate', handlePermissionUpdate);
    };
  }, [meeting.self, emitDevicesUpdate, fetchDevices, logDeviceState]);

  // Update handleMediaSelect to log after device selection
  const handleMediaSelect = useCallback(
    async (device: MediaDeviceInfo) => {
      if (!meeting?.self || !socket || !socket.connected) return;

      try {
        await meeting.self?.setDevice(device);
        setErrors((prev) => ({ ...prev, [device.kind]: null }));
        if (device.kind === 'videoinput') setCameraInUse(false);

        const newDevices = await fetchDevices();
        if (newDevices) {
          socket.emit('studentDevices', {
            studentId: meeting.self.customParticipantId ?? '',
            devicesInfo: {
              audio: newDevices.audio.filter((d) => d && (d.deviceId || d.label)),
              video: newDevices.video.filter((d) => d && (d.deviceId || d.label)),
              speaker: newDevices.speaker.filter((d) => d && (d.deviceId || d.label)),
              selected: newDevices.selected,
            },
            permissions,
            errorMessages: errors,
            cameraInUse: { isInUse: cameraInUse },
          });
        }
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Unknown error';
        setErrors((prev) => ({ ...prev, [device.kind]: errorMessage }));
        if (device.kind === 'videoinput' && errorMessage.includes('Could not start video source')) {
          setCameraInUse(true);
        }

        const newDevices = await fetchDevices();
        if (newDevices) {
          socket.emit('studentDevices', {
            studentId: meeting.self.customParticipantId ?? '',
            devicesInfo: {
              audio: newDevices.audio.filter((d) => d && (d.deviceId || d.label)),
              video: newDevices.video.filter((d) => d && (d.deviceId || d.label)),
              speaker: newDevices.speaker.filter((d) => d && (d.deviceId || d.label)),
              selected: newDevices.selected,
            },
            permissions,
            errorMessages: errors,
            cameraInUse: { isInUse: cameraInUse },
          });
        }
      } finally {
        await logDeviceState();
      }
    },
    [meeting.self, socket, fetchDevices, permissions, errors, cameraInUse, logDeviceState],
  );

  const handleSelectDevice = useCallback(
    (data: { device?: MediaDeviceInfo }) => {
      if (data?.device) handleMediaSelect(data.device);
    },
    [handleMediaSelect],
  );

  useSocketSubscribe('getStudentDevicesToClient', emitDevicesUpdate);
  useSocketSubscribe('selectDeviceToClient', handleSelectDevice);

  return {
    audioDevices: devices.audio,
    videoDevices: devices.video,
    speakerDevices: devices.speaker,
    currentSelectedDevice: devices.selected,
    permissions,
    errors,
    cameraInUse,
    fetchDevices,
    videoPermissionDenied: permissions.video === 'DENIED' || permissions.video === 'SYSTEM_DENIED',
    audioPermissionDenied: permissions.audio === 'DENIED' || permissions.audio === 'SYSTEM_DENIED',
    handleMediaSelect,
  };
};
