import { NotebookIcon, RedirectIcon } from 'assets/svg';
import { SUBMITTED_STATUS } from 'configs/reports';
import { ChapterClassesDetailsCardProps } from 'types/reports';

const ChapterClassesDetailsCard: React.FC<ChapterClassesDetailsCardProps> = ({
  title,
  attendance,
  practice,
  isSelected = false,
}) => {
  const totalPracticeSheetAssigned = practice?.length || 0;

  const submittedPracticeSheets =
    practice.filter((rps) => SUBMITTED_STATUS.includes(rps.studentSubmissionStatus))?.length || 0;
  return (
    <div
      className={`relative bg-white rounded-xl p-4 mb-3 border transition-all duration-200 ${
        isSelected
          ? ' shadow-lg before:absolute before:top-3 before:h-[calc(100%-24px)] before:left-0 before:w-1 before:bg-teal-400 before:rounded-r'
          : 'border-neutral-100 hover:shadow-sm hover:border-emerald-100'
      }`}
    >
      <div className='flex items-center justify-between mb-6'>
        <div className='flex items-center gap-3'>
          <div className='w-[32px] h-[32px] p-[7px] rounded-[50%] border border-neutral-100'>
            <img src={NotebookIcon} alt='practice icon' className='w-full h-full' />
          </div>
          <span className='font-poppins text-sm font-medium text-neutral-700'>{title}</span>
        </div>
        <img src={RedirectIcon} alt='redirect icon' width={20} height={20} />
      </div>

      <div className='space-y-4'>
        {attendance && (
          <div>
            <h3 className='font-poppins leading-3 font-semibold text-xs pb-2'>Class attendance</h3>
            <div className='py-2 px-3 bg-neutral-50 rounded-lg'>
              <div className='flex items-center'>
                <div className='flex flex-col items-start w-[calc(100%-5px)] '>
                  <div className='font-sora font-bold text-[10px] leading-[20px] text-neutral-900 bg-neutral-100 rounded-full px-2 py-[2px] flex items-center justify-center'>
                    {attendance?.teach?.scheduled +
                      attendance?.evaluation?.scheduled +
                      attendance?.intervention?.scheduled}
                  </div>
                  <div className='font-poppins text-xs mt-3'>Classes scheduled</div>
                </div>
                <div className='h-10 mx-3 w-[1px] bg-neutral-100'></div>
                <div className='flex flex-col items-start w-[calc(100%-20px)]'>
                  <div className='font-sora font-bold text-[10px] leading-[20px] text-neutral-900 bg-gray-200 rounded-full px-2 py-[2px] flex items-center justify-center'>
                    {attendance?.teach?.attended +
                      attendance?.evaluation?.attended +
                      attendance?.intervention?.attended}
                  </div>
                  <div className='font-poppins text-xs mt-3'>Classes attended</div>
                </div>
              </div>
            </div>
          </div>
        )}

        {practice && (
          <div>
            <h3 className='font-poppins leading-3 font-semibold text-xs pb-1'>Practice Sheets</h3>
            <div className='py-2 px-3 bg-neutral-50 rounded-lg'>
              <div className='flex items-center'>
                <div className='flex flex-col items-start w-[calc(100%-15px)]'>
                  <div className='font-sora font-bold text-[10px] leading-[20px] text-neutral-900 bg-neutral-100 rounded-full px-2 py-[1px] flex items-center justify-center'>
                    {totalPracticeSheetAssigned}
                  </div>
                  <div className='font-poppins text-xs mt-3'>Sheets assigned</div>
                </div>
                <div className='h-10 mx-3 w-[1px] bg-neutral-100'></div>
                <div className='flex flex-col items-start w-[calc(100%-20px)]'>
                  <div className='font-sora font-bold text-[10px] leading-[20px] text-neutral-900 bg-gray-200 rounded-full px-2 py-[1px] flex items-center justify-center'>
                    {submittedPracticeSheets}
                  </div>
                  <div className='font-poppins text-xs mt-3'>Sheets submitted</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChapterClassesDetailsCard;
