export enum DeviceKind {
    DEFAULT = 'default',
    INPUT = 'input',
    OUTPUT = 'output',
  }
  
  export enum DevicePermissionStatus {
    GRANTED = 'granted',
    DENIED = 'denied',
    PROMPT = 'prompt',
    UNAVAILABLE = 'unavailable',
    SYSTEM_DENIED = 'system_denied',
    COULD_NOT_START = 'could_not_start',
    NO_DEVICES_AVAILABLE = 'no_device_available' 
  }
  
  export enum DevicePermissionLevel {
    BROWSER = 'browser',
    SYSTEM = 'system',
  }
  