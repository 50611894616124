import { MdSignalWifi2Bar, MdSignalWifi4Bar, MdWifiOff } from 'react-icons/md';

import { useNetworkMonitor } from 'hooks/inClass/useNetworkMonitor';
import { getConnectionColor } from 'utils/inClass/networkStatus';

import { NetworkCheckProps, NetworkStatus } from 'types';

export const NetworkCheck = ({ onStatusChange, className = '' }: NetworkCheckProps) => {
  // Hook: To monitor the network status
  const networkStatus = useNetworkMonitor((status: NetworkStatus) => {
    onStatusChange?.(status?.qualityPercentage >= 50);
  });

  // Function: To get the icon based on the network status
  const getIcon = () => {
    if (!networkStatus?.isOnline) return <MdWifiOff className='w-6 h-8 text-semantic-red-500' />;
    return networkStatus?.qualityPercentage > 50 ? (
      <MdSignalWifi4Bar className='w-6 h-8 text-semantic-green-500' />
    ) : (
      <MdSignalWifi2Bar className='w-6 h-8 text-semantic-red-500' />
    );
  };

  return (
    <div className={`${className}`}>
      <div className='flex items-center gap-2'>
        {getIcon()}
        <div className='flex-1'>
          <div className='flex justify-between mb-1'>
            <span className='text-xs text-white font-poppins'>Network Status</span>
          </div>
          <div className='w-full h-[6px] bg-gray-700 rounded-full'>
            <div
              className={`h-full transition-all duration-300 rounded-full ${getConnectionColor(networkStatus.qualityPercentage)}`}
              style={{ width: `${networkStatus?.qualityPercentage}%` }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
