import { useDyteMeeting } from '@dytesdk/react-web-core';
import { useAppSelector } from 'hooks/store';
import { useEffect, useRef } from 'react';
import { useStudentStatsBulkMutation } from 'store/apiSlices/inClass/studentStats.apiSlice';
import { selectStudentAttention } from 'store/slice/inClassConfig.slice';

import {
  ExtendedNavigator,
  IParticipentEvents,
  NetworkAssessment,
  NetworkInformation,
} from 'types';
import useMeeting from './useMeeting';

const healthCheckUrl = ` ${import.meta.env.VITE_BASE_URL_BACKEND}/health-check`;

// Get connection object with fallbacks for different browsers
const getConnection = (): NetworkInformation | undefined => {
  const nav = navigator as ExtendedNavigator;
  return nav.connection || nav.mozConnection || nav.webkitConnection;
};

// Get current network information
const getNetworkInfo = () => {
  const connection = getConnection();
  const online = navigator.onLine;

  const networkInfo = {
    online,
    connectionType: 'unknown',
    effectiveType: 'unknown',
    downlink: null as number | null,
    rtt: null as number | null,
    saveData: false,
  };

  if (connection) {
    networkInfo.connectionType = connection.type || 'unknown';
    networkInfo.effectiveType = connection.effectiveType || 'unknown';
    networkInfo.downlink = connection.downlink;
    networkInfo.rtt = connection.rtt;
    networkInfo.saveData = connection.saveData;
  }

  return networkInfo;
};

// Test the connection latency with the health check endpoint
const testConnectionLatency = async (pingUrl = healthCheckUrl): Promise<number | 'failed'> => {
  try {
    const startTime = Date.now();

    await fetch(pingUrl, {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
      },
    });

    const endTime = Date.now();
    const pingTime = endTime - startTime;

    return pingTime;
  } catch (error) {
    console.error(
      'Network latency test failed:',
      error instanceof Error ? error.message : String(error),
    );
    return 'failed';
  }
};

const assessNetworkPerformance = async (pingUrl = healthCheckUrl): Promise<NetworkAssessment> => {
  const connection = getConnection();
  const online = navigator.onLine;
  const networkInfo = getNetworkInfo();
  const timestamp = new Date().toISOString();

  if (!online) {
    return {
      status: 'offline',
      quality: 'none',
      score: 1,
      networkInfo,
      ping: null,
      bandwidthLimitations: true,
      timestamp,
    };
  }

  let theoreticalQuality = 'unknown';

  if (connection) {
    switch (connection.effectiveType) {
      case '4g':
        theoreticalQuality = connection.downlink > 5 ? 'excellent' : 'good';
        break;
      case '3g':
        theoreticalQuality = 'moderate';
        break;
      case '2g':
        theoreticalQuality = 'poor';
        break;
      case 'slow-2g':
        theoreticalQuality = 'very poor';
        break;
      default:
        theoreticalQuality = 'unknown';
    }
  }

  const pingTime = await testConnectionLatency(pingUrl);

  if (pingTime === 'failed') {
    return {
      status: 'unstable',
      quality: 'poor',
      score: 1,
      theoreticalQuality,
      networkInfo,
      ping: null,
      bandwidthLimitations: true,
      timestamp,
    };
  }

  let combinedQuality;

  if (theoreticalQuality === 'excellent' && pingTime < 200) {
    combinedQuality = 'excellent';
  } else if (
    (theoreticalQuality === 'good' || theoreticalQuality === 'excellent') &&
    pingTime < 400
  ) {
    combinedQuality = 'good';
  } else if (
    theoreticalQuality !== 'very poor' &&
    theoreticalQuality !== 'poor' &&
    pingTime < 700
  ) {
    combinedQuality = 'moderate';
  } else if (pingTime < 1000) {
    combinedQuality = 'poor';
  } else {
    combinedQuality = 'very poor';
  }

  let networkScore;
  switch (combinedQuality) {
    case 'excellent':
      networkScore = 5;
      break;
    case 'good':
      networkScore = 4;
      break;
    case 'moderate':
      networkScore = 3;
      break;
    case 'poor':
      networkScore = 2;
      break;
    case 'very poor':
      networkScore = 1;
      break;
    default:
      networkScore = 0;
  }

  const bandwidthLimitations = networkScore <= 2;

  return {
    status: 'online',
    quality: combinedQuality,
    score: networkScore,
    ping: pingTime,
    theoreticalQuality,
    networkInfo,
    bandwidthLimitations,
    timestamp,
  };
};

// Hook for monitoring student network status
const useStudentNetworkMonitor = (classId: number, participantId: number, evalStatus: boolean) => {
  const [sendNetworkStats] = useStudentStatsBulkMutation();
  const { meeting } = useDyteMeeting();
  const studentAttention = useAppSelector(selectStudentAttention);
  const studentAttentionRef = useRef(studentAttention);
  const evalStatusRef = useRef(evalStatus);
  const { meetingEndTime, meetingStartTime } = useMeeting();

  const isWithinMeetingTimeWindow = () => {
    if (!meetingEndTime || !meetingStartTime) {
      return true;
    }
    const now = new Date();
    const startTime = new Date(meetingStartTime);
    const endTime = new Date(meetingEndTime);
    const isWithinWindow = now >= startTime && now <= endTime;
    return isWithinWindow;
  };

  useEffect(() => {
    studentAttentionRef.current = studentAttention;
    evalStatusRef.current = evalStatus;
  }, [evalStatus, studentAttention]);

  useEffect(() => {
    if (!classId || !participantId) return;

    let networkStats: IParticipentEvents[] = [];
    let batchIntervalId: number | null = null;
    let sampleIntervalId: number | null = null;

    const batchInterval = 30000;
    const sampleInterval = 1000;

    const collectSample = async (): Promise<void> => {
      try {
        if (!isWithinMeetingTimeWindow() || !meeting?.self) {
          return;
        }

        const assessment = await assessNetworkPerformance();
        const { networkInfo, ping, quality, score, timestamp } = assessment;

        // Only proceed if we have a valid ping
        if (ping !== 'failed' && ping !== null) {
          const statEntry: IParticipentEvents = {
            timestamp,
            score: score,
            ping: ping,
            effectiveType: networkInfo.effectiveType,
            downlink: networkInfo.downlink,
            rtt: networkInfo.rtt,
            quality: quality,
            category: 'bandwidth',
            notes: JSON.stringify({
              effectiveType: networkInfo.effectiveType,
              downlink: networkInfo.downlink,
              rtt: networkInfo.rtt,
              ping: ping,
              quality: quality,
            }),
            isEvaluationOngoing: evalStatusRef.current,
            audioStatus: meeting.self.audioEnabled ? 'ON' : 'OFF',
            videoStatus: meeting.self.videoEnabled ? 'ON' : 'OFF',
            tabStatus: document.hidden ? 'inactive' : 'active',
            videoPermission:
              meeting.self.mediaPermissions.video === 'ACCEPTED' ? 'GRANTED' : 'NOT_GRANTED',
            audioPermission:
              meeting.self.mediaPermissions.audio === 'ACCEPTED' ? 'GRANTED' : 'NOT_GRANTED',
            attentionScore: !meeting.self.videoEnabled ? 0 : studentAttentionRef.current,
          };

          networkStats.push(statEntry);
        }
      } catch (error) {
        console.error(
          'Error collecting network sample:',
          error instanceof Error ? error.message : String(error),
        );
      }
    };

    const sendBulkStats = async (): Promise<void> => {
      if (!isWithinMeetingTimeWindow()) {
        return;
      }
      if (networkStats.length === 0) return;

      try {
        const statsToSend = [...networkStats];
        networkStats = [];

        await sendNetworkStats({
          classId,
          participantId,
          participantType: 'STUDENT',
          participantEvents: statsToSend,
        });
      } catch (error) {
        console.error(
          'Failed to send student network stats:',
          error instanceof Error ? error.message : String(error),
        );
      }
    };

    // Start collecting and sending network stats
    sampleIntervalId = window.setInterval(collectSample, sampleInterval);
    batchIntervalId = window.setInterval(sendBulkStats, batchInterval);
    collectSample();

    // Cleanup function to stop collection and send remaining stats
    return () => {
      if (sampleIntervalId !== null) {
        window.clearInterval(sampleIntervalId);
        sampleIntervalId = null;
      }

      if (batchIntervalId !== null) {
        window.clearInterval(batchIntervalId);
        batchIntervalId = null;
      }

      // Send any remaining stats before unmounting
      sendBulkStats();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classId, participantId, sendNetworkStats]);
};

export default useStudentNetworkMonitor;
