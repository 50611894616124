/* eslint-disable @typescript-eslint/no-explicit-any */
import { DevicePermissionStatus } from 'configs';
import { IDevice, IUserDeviceLogPayload } from 'types/inClass/useDeviceLogs';
import { datadogLogs } from '@datadog/browser-logs';
import { NavigatorWithConnection } from 'types';

const getConnection = () => {
  const nav = navigator as NavigatorWithConnection;
  return nav?.connection || nav?.mozConnection || nav?.webkitConnection;
};

// Store previous state to compare changes
let previousState: {
  permissionStatus?: any;
  devices?: any;
  cameraInUse?: boolean;
  errorMessages?: any;
} | null = null;

// Document tracking state
let documentCreated = false;

// Last update timestamp to enforce minimum interval
let lastUpdateTimestamp = 0;

// STRICT RATE LIMITING: Track the last time a call was made, regardless of reason
// Enforce absolute minimum interval of 3 seconds between ANY calls
const ABSOLUTE_MIN_INTERVAL = 3000; // 3 seconds minimum between ANY calls

// New: Track the last time we detected each type of change
const lastChangeTimestamps = {
  permissions: 0,
  devices: 0,
  activeDevices: 0,
  cameraInUse: 0,
  errorMessages: 0,
};

// New: Define minimum intervals between updates for each change type (in ms)
const MIN_UPDATE_INTERVALS = {
  permissions: 3000, // 3 seconds for permission changes
  devices: 3000, // 3 seconds for device list changes
  activeDevices: 3000, // 3 seconds for active device changes
  cameraInUse: 3000, // 3 seconds for camera use status
  errorMessages: 3000, // 3 seconds for error messages
  default: 3000, // 3 seconds for other changes
};

interface IDeviceStateInfo {
  availableDevices: {
    audio: MediaDeviceInfo[];
    video: MediaDeviceInfo[];
    speaker: MediaDeviceInfo[];
  };
  activeDevices: {
    audio?: string;
    video?: string;
    speaker?: string;
  };
  permissionStatus: {
    browser: {
      camera: DevicePermissionStatus;
      microphone: DevicePermissionStatus;
    };
    system: {
      camera: DevicePermissionStatus;
      microphone: DevicePermissionStatus;
    };
  };
  errorMessages?: {
    camera?: string;
    microphone?: string;
  };
  cameraInUse?: {
    isInUse: boolean;
    deviceId?: string;
  };
}

interface IUserInfo {
  userId: number;
  userName: string;
  userType: string;
  classId: string;
  batchId: number;
  batchName: string;
}

export const getDeviceType = () => {
  const userAgent = navigator.userAgent;

  // Check for iPad specifically
  if (
    /iPad/.test(userAgent) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  ) {
    return 'ipad';
  }

  // Check for Mac
  if (/Macintosh|Mac OS X/.test(userAgent)) {
    return 'mac';
  }

  // Check for Windows
  if (/Windows NT|Win64|Win32/.test(userAgent)) {
    return 'windows';
  }

  return 'unknown';
};

// Direct API call function with strict rate limiting
const makeApiCall = (
  params: { classId: number; payload: IUserDeviceLogPayload },
  apiCallback: (params: { classId: number; payload: IUserDeviceLogPayload }) => Promise<unknown>,
) => {
  // Don't make the call if we're missing critical data
  if (!params?.classId || !params?.payload?.userId) {
    return;
  }

  // STRICT RATE LIMITING: No more than one call per 30 seconds, ABSOLUTE minimum
  const now = Date.now();
  if (lastUpdateTimestamp && now - lastUpdateTimestamp < ABSOLUTE_MIN_INTERVAL) {
    return;
  }

  // Additional check: Verify that we have valid permission status data
  if (
    !params.payload.permissions?.browser?.camera?.status ||
    !params.payload.permissions?.browser?.microphone?.status
  ) {
    return;
  }

  // Update timestamp immediately before making the call
  lastUpdateTimestamp = now;

  apiCallback(params)
    .then((response) => {
      // If this is our first successful call, record that the document was created
      if (!documentCreated && response && typeof response === 'object' && 'id' in response) {
        documentCreated = true;
      }
    })
    .catch((error) => {
      datadogLogs.logger.error('Error in API callback', { error });
    });
};

/**
 * Transforms MediaDeviceInfo array to IDevice array
 */
const mapAvailableDevices = (devices: MediaDeviceInfo[] = []): IDevice[] => {
  try {
    return (
      devices?.map((device) => ({
        deviceId: device?.deviceId || 'default',
        label: device?.label || 'Default',
        groupId: device?.groupId || 'default',
        kind: device?.kind || 'default',
      })) || []
    );
  } catch (error) {
    datadogLogs.logger.error('Error mapping available devices', { error });
    return [];
  }
};

/**
 * Checks if the current state is significantly different from the previous state
 * to determine if we need to send an update - NOW WITH MUCH STRICTER RULES
 */
const hasSignificantChanges = (currentState: IDeviceStateInfo): boolean => {
  try {
    // First call always returns true
    if (!previousState) {
      return true;
    }

    // Check if basic parameters are available
    if (!currentState) {
      return false;
    }

    // STRICTLY LIMIT ALL CHANGE DETECTION
    const now = Date.now();

    // ONLY consider critical permission changes as significant enough to trigger an update
    // Ignore all other changes (devices, camera in use, error messages) for API call purposes

    // ======= PERMISSION CHANGES - ONLY SIGNIFICANT CHANGE WE CARE ABOUT =======
    // Only send updates for actual permission changes (granted/denied)
    const permissionStatusBrowser = currentState?.permissionStatus?.browser;
    const prevPermissionStatusBrowser = previousState?.permissionStatus?.browser;
    const permissionBrowserChanged =
      prevPermissionStatusBrowser?.camera !== permissionStatusBrowser?.camera ||
      prevPermissionStatusBrowser?.microphone !== permissionStatusBrowser?.microphone;

    const permissionStatusSystem = currentState?.permissionStatus?.system;
    const prevPermissionStatusSystem = previousState?.permissionStatus?.system;
    const permissionSystemChanged =
      prevPermissionStatusSystem?.camera !== permissionStatusSystem?.camera ||
      prevPermissionStatusSystem?.microphone !== permissionStatusSystem?.microphone;

    const permissionsChanged = permissionBrowserChanged || permissionSystemChanged;

    // ONLY return true if permissions changed AND it's been at least 30 seconds since last permission update
    if (
      permissionsChanged &&
      now - lastChangeTimestamps.permissions > MIN_UPDATE_INTERVALS.permissions
    ) {
      lastChangeTimestamps.permissions = now;
      return true;
    }

    // All other changes are ignored to reduce API calls
    return false;
  } catch (error) {
    datadogLogs.logger.error('Error comparing device states', { error });
    // On error, return false to avoid unnecessary updates
    return false;
  }
};

/**
 * Updates the previous state with current values for future comparison
 */
const updatePreviousState = (currentState: IDeviceStateInfo): void => {
  try {
    // Deep clone the important parts to avoid reference issues
    previousState = {
      permissionStatus: {
        browser: {
          camera: currentState?.permissionStatus?.browser?.camera,
          microphone: currentState?.permissionStatus?.browser?.microphone,
        },
        system: {
          camera: currentState?.permissionStatus?.system?.camera,
          microphone: currentState?.permissionStatus?.system?.microphone,
        },
      },
      cameraInUse: currentState?.cameraInUse?.isInUse ?? false, // Handle null/undefined
      errorMessages: {
        camera: currentState?.errorMessages?.camera || '',
        microphone: currentState?.errorMessages?.microphone || '',
      },
      devices: {
        // Store device counts rather than devices themselves
        audio: currentState?.availableDevices?.audio?.length || 0,
        video: currentState?.availableDevices?.video?.length || 0,
        speaker: currentState?.availableDevices?.speaker?.length || 0,
        // Store exact active device IDs for comparison
        activeAudio: currentState?.activeDevices?.audio || '',
        activeVideo: currentState?.activeDevices?.video || '',
        activeSpeaker: currentState?.activeDevices?.speaker || '',
      },
    };
  } catch (error) {
    datadogLogs.logger.error('Error updating previous state', { error });
  }
};

/**
 * Prepares device logs payload and determines if it should be sent.
 * NOW WITH EXTREMELY STRICT RATE LIMITING
 */
export const prepareDeviceLogsPayload = (
  userInfo: IUserInfo,
  deviceState: IDeviceStateInfo,
  apiCallback: (params: { classId: number; payload: IUserDeviceLogPayload }) => Promise<unknown>,
  forceUpdate = false,
): boolean => {
  try {
    // CRITICAL VALIDATION - ensure minimum required data
    if (!userInfo?.userId || !userInfo?.classId || isNaN(userInfo.userId)) {
      return false;
    }

    // Validate device state has minimum required fields
    if (!deviceState?.permissionStatus || !deviceState?.availableDevices) {
      return false;
    }

    // ABSOLUTE HARD RATE LIMIT - if we've sent an update in the last 30 seconds, skip no matter what
    // Even force updates must respect this absolute minimum
    const now = Date.now();
    const timeSinceLastUpdate = now - lastUpdateTimestamp;

    if (lastUpdateTimestamp && timeSinceLastUpdate < ABSOLUTE_MIN_INTERVAL) {
      return false;
    }

    // Update previous state for future comparisons - do this before other checks
    // to ensure we're capturing the latest state even if we don't send an update
    updatePreviousState(deviceState);

    // VERY STRICT UPDATE RULES:
    // 1. Force update was explicitly requested (but limited to once per 3 seconds)
    // 2. First time initialization (document not created yet)
    // 3. More than 2 minutes elapsed since last update (periodic heartbeat)
    // 4. Critical permission changes (handled by hasSignificantChanges)
    const shouldUpdate =
      forceUpdate ||
      !documentCreated ||
      timeSinceLastUpdate > MIN_UPDATE_INTERVALS.default || // Periodic update every 2 minutes
      hasSignificantChanges(deviceState);

    if (!shouldUpdate) {
      return false;
    }

    // Complete user info with defaults if missing
    const completeUserInfo: IUserInfo = {
      ...userInfo,
      batchId: userInfo?.batchId || 0,
      batchName: userInfo?.batchName || '',
    };

    // Network info
    const networkInfo = {
      effectiveType: 'unknown',
    };

    try {
      if (getConnection()?.effectiveType) {
        networkInfo.effectiveType = getConnection()?.effectiveType ?? '';
      }
    } catch (e) {
      // Network connection info not available - just use default
    }

    // Permission status
    const safePermissionStatus = {
      browser: {
        camera: deviceState?.permissionStatus?.browser?.camera || DevicePermissionStatus.GRANTED,
        microphone:
          deviceState?.permissionStatus?.browser?.microphone || DevicePermissionStatus.GRANTED,
      },
      system: {
        camera: deviceState?.permissionStatus?.system?.camera || DevicePermissionStatus.GRANTED,
        microphone:
          deviceState?.permissionStatus?.system?.microphone || DevicePermissionStatus.GRANTED,
      },
    };

    // Permissions info
    const permissions = {
      browser: {
        camera: {
          status: safePermissionStatus.browser.camera,
          reason: deviceState?.errorMessages?.camera,
        },
        microphone: {
          status: safePermissionStatus.browser.microphone,
          reason: deviceState?.errorMessages?.microphone,
        },
      },
      system: {
        camera: {
          status: safePermissionStatus.system.camera,
          reason: deviceState?.errorMessages?.camera,
        },
        microphone: {
          status: safePermissionStatus.system.microphone,
          reason: deviceState?.errorMessages?.microphone,
        },
      },
      cameraIsInUse: {
        status: deviceState?.cameraInUse?.isInUse || false,
      },
    };

    // Available devices
    const safeAvailableDevices = {
      audio: deviceState?.availableDevices?.audio || [],
      video: deviceState?.availableDevices?.video || [],
      speaker: deviceState?.availableDevices?.speaker || [],
    };

    // Map to the expected format
    const availableDevices = {
      audio: mapAvailableDevices(safeAvailableDevices.audio),
      video: mapAvailableDevices(safeAvailableDevices.video),
      speaker: mapAvailableDevices(safeAvailableDevices.speaker),
    };

    // Default device for when nothing is found
    const defaultDevice: IDevice = {
      deviceId: 'default',
      label: 'Default',
      groupId: 'default',
      kind: 'default',
    };

    // Get active devices by looking up the device ID in the available devices
    const activeAudioDevice = safeAvailableDevices.audio.find(
      (d) => d.deviceId === deviceState?.activeDevices?.audio,
    );
    const activeVideoDevice = safeAvailableDevices.video.find(
      (d) => d.deviceId === deviceState?.activeDevices?.video,
    );
    const activeSpeakerDevice = safeAvailableDevices.speaker.find(
      (d) => d.deviceId === deviceState?.activeDevices?.speaker,
    );

    // Construct the active device object
    const activeDevice = {
      audio: activeAudioDevice
        ? {
            deviceId: activeAudioDevice.deviceId || 'default',
            label: activeAudioDevice.label || 'Default',
            groupId: activeAudioDevice.groupId || 'default',
            kind: activeAudioDevice.kind || 'default',
          }
        : defaultDevice,
      video: activeVideoDevice
        ? {
            deviceId: activeVideoDevice.deviceId || 'default',
            label: activeVideoDevice.label || 'Default',
            groupId: activeVideoDevice.groupId || 'default',
            kind: activeVideoDevice.kind || 'default',
          }
        : defaultDevice,
      speaker: activeSpeakerDevice
        ? {
            deviceId: activeSpeakerDevice.deviceId || 'default',
            label: activeSpeakerDevice.label || 'Default',
            groupId: activeSpeakerDevice.groupId || 'default',
            kind: activeSpeakerDevice.kind || 'default',
          }
        : defaultDevice,
    };

    // Prepare the final payload
    const payload: IUserDeviceLogPayload = {
      userId: completeUserInfo.userId,
      userName: completeUserInfo.userName || 'Unknown User',
      userType: completeUserInfo.userType || 'student',
      classId: Number(completeUserInfo.classId) || 0,
      batchId: completeUserInfo.batchId || 0,
      batchName: completeUserInfo.batchName || '',
      systemInfo: {
        userAgent: navigator.userAgent,
        deviceType: getDeviceType() || 'unknown',
        networkInfo,
      },
      availableDevices,
      activeDevice,
      permissions,
    };

    if (shouldUpdate && completeUserInfo.classId && completeUserInfo.userId && payload) {
      makeApiCall(
        {
          classId: Number(completeUserInfo.classId),
          payload,
        },
        apiCallback,
      );
    }

    return true;
  } catch (error) {
    datadogLogs.logger.error('Error preparing device logs payload', { error });
    return false;
  }
};

// Reset tracking state on cleanup
export const resetDeviceLogsTracker = () => {
  previousState = null;
  documentCreated = false;
  lastUpdateTimestamp = 0;
};
