import React, { useMemo } from 'react';
import classNames from 'classnames';
import { CategoryDetailCardProps } from 'types/reports';
import QuestionCard from '../QuestionCard';
import Tag from '../Tag';
import { useParams } from 'react-router-dom';
import { CrossIcon } from 'assets/svg';

const CategoryDetailCard: React.FC<CategoryDetailCardProps> = ({
  idx: categoryId,
  questions,
  understandingLevelData,
  onClick,
  className,
  selected = false,
}) => {
  const { chapterId } = useParams();

  const interventionTypes = useMemo(() => {
    const selectedCategory = understandingLevelData
      ?.filter((uld) => uld?.chapterId == chapterId)
      ?.find((uld) => uld?.categoriesCovered?.some((cv) => cv?.id === categoryId));

    return selectedCategory?.interventionTypes || [];
  }, [categoryId, chapterId, understandingLevelData]);

  return (
    <div
      className={classNames(
        'relative bg-white rounded-[12px] p-[16px] h-full overflow-y-hidden',
        className,
      )}
    >
      <div className=' flex items-center justify-between w-full h-[56px] bg-white shadow-[0px_16px_32px_-4px_rgba(12,12,13,0.05)]'>
        <h3 className='font-sora font-bold text-base text-neutral-950 mb-2'>
          In-depth step level evaluation
        </h3>
        <button className='mb-2' onClick={onClick}>
          <img
            src={CrossIcon}
            alt='close popup'
            width={20}
            height={20}
            className='cursor-pointer'
          />
        </button>
      </div>
      <div className='flex flex-col gap-[8px] py-[8px] px-[4px] rounded-[8px] overflow-y-auto h-full pb-20'>
        <div className='mb-[8px] flex flex-col space-y-2'>
          <Tag
            text={`Category ${questions?.[0]?.categoryNumber} - ${questions?.[0]?.categoryName}`}
            className='purple'
          ></Tag>
        </div>

        <div className='flex flex-col gap-[16px] p-2 h-full' style={{ overflowY: 'auto' }}>
          {questions.map((question, index) => (
            <QuestionCard
              className='question-card'
              key={index}
              idx={index}
              questionEntity={question}
              isSelected={selected}
              interventionTypes={interventionTypes}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryDetailCard;
