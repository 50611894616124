import { useDyteMeeting } from '@dytesdk/react-web-core';
import { datadogLogs } from '@datadog/browser-logs';
import { useCallback, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useGetClassJoinTokenQuery } from 'store/apiSlices/classes.apiSlice';
import { SocketContext } from 'contexts';
import { useExternalScript, useMeeting } from 'hooks';
import { getAiSdkControls } from 'configs/morphcast';
import { CONFIG } from 'configs';
import { useAppDispatch } from 'hooks/store';
import { setStudentAttentionData } from 'store/slice/inClassConfig.slice';

/**
 * Custom hook for managing Morphcast based on the active state.
 *
 * @param {Object} params The parameters object.
 * @param {boolean} params.activeState Indicates whether the active state is true or false.
 */
const useMorphcast = ({ activeState }) => {
  // Get the auth token from the URL
  const { id } = useParams();

  // API Call -> Get the meeting token
  const { data: JoinClassRoomData } = useGetClassJoinTokenQuery(
    {
      authToken: id,
    },
    {
      skip: !id,
    },
  );

  const dispatch = useAppDispatch();
  const { socketUser } = useMeeting();
  const mphToolsState = useExternalScript('https://sdk.morphcast.com/mphtools/v1.0/mphtools.js');
  const aiSdkState = useExternalScript('https://ai-sdk.morphcast.com/v1.16/ai-sdk.js');
  const { meeting } = useDyteMeeting();
  const videoEnabled = meeting?.self?.videoEnabled;
  const cameraDevice = meeting?.self?.getCurrentDevices().video;
  const classId = JoinClassRoomData?.data?.classId;
  const clientId = meeting?.self?.customParticipantId?.split('-').pop();
  const socket = useContext(SocketContext);

  const controlAiSdk = useCallback(
    async (action) => {
      try {
        if (aiSdkState === 'success' && mphToolsState === 'success') {
          const { source, stop, start } = await getAiSdkControls();
          if (action === 'start') {
            await source.useCamera({
              deviceData: cameraDevice,
            });
            datadogLogs.logger.info(
              `Starting morphCast for classId: ${classId}, clientId: ${clientId}, type: ${socketUser}`,
              { classId, clientId, type: socketUser },
            );
            await start();
          } else if (action === 'stop') {
            datadogLogs.logger.info(
              `Stopping morphCast for classId: ${classId}, clientId: ${clientId}, type: ${socketUser}`,
              { classId, clientId, type: socketUser },
            );
            await stop();
          }
        }
      } catch (error) {
        console.error('Error in getAiSdk:', error);
      }
    },
    [aiSdkState, mphToolsState, cameraDevice, classId, clientId, socketUser],
  );

  useEffect(() => {
    const controlAiSdkAsync = async () => {
      const rooms = [
        CONFIG.ROOMS.COACH_ADVANCE,
        CONFIG.ROOMS.COACH_BASIC,
        CONFIG.ROOMS.COACH_INTERMEDIATE,
      ];
      if (videoEnabled && activeState && !rooms.includes(meeting?.meta?.meetingTitle)) {
        await controlAiSdk('start');
      } else {
        await controlAiSdk('stop');
      }
    };
    controlAiSdkAsync();
  }, [videoEnabled, activeState, controlAiSdk, meeting?.meta?.meetingTitle]);

  useEffect(() => {
    const handleDeviceUpdate = ({ device }) => {
      if (device.kind === 'videoinput') controlAiSdk('stop');
    };
    const roomLeftListener = () => controlAiSdk('stop');

    meeting?.self?.on('deviceUpdate', handleDeviceUpdate);
    meeting?.self?.on('roomLeft', roomLeftListener);

    return () => {
      meeting?.self?.removeListener('deviceUpdate', handleDeviceUpdate);
      meeting?.self?.removeListener('roomLeft', roomLeftListener);
    };
  }, [controlAiSdk, meeting?.self]);

  useEffect(() => {
    let count = 1;
    const noOfLogsRequiredPerHour = 36;
    const countInterval = Math.ceil((60 * 60 * 1000) / 1000 / noOfLogsRequiredPerHour);
    function handleAggEvent(evt) {
      const customEvent = evt;
      // const keysToRemove = ['min', 'max', 'last'];
      if (count === 1 || count % countInterval === 0) {
        datadogLogs.logger.info(
          `Regularly sending morph data for, classId: ${classId}, type: ${socketUser}, clientId: ${clientId}, count: ${count}, attention: ${evt?.detail?.attention?.avg}, pitch: ${evt?.detail?.pose_pitch?.avg}`,
          {
            classId: classId,
            socketUser,
            count,
            attention: evt?.detail?.attention?.avg,
            pitch: evt?.detail?.pose_pitch?.avg,
            clientId: clientId,
          },
        );
      }
      count++;
      if (!socket) {
        datadogLogs.logger.warn(
          `Not sending message as socket is not defined for classId: ${classId}, userType: ${socketUser}`,
          { type: socketUser, classId: classId, clientId: clientId },
        );
      }
      dispatch(setStudentAttentionData(customEvent.detail.attention.avg));
      socket?.emit(
        'message',
        JSON.stringify({
          classId: classId,
          type: socketUser,
          // data: removeKeys(customEvent.detail, keysToRemove),
          data: customEvent.detail, // Not removing any keys for now
        }),
      );
      return () => {
        socket?.removeAllListeners('message');
      };
    }
    datadogLogs.logger.info(
      `Adding Event Listener for MorphCast, classId: ${classId}, type: ${socketUser}, studentId: ${clientId}`,
      {
        classId: classId,
        socketUser,
        clientId: clientId,
      },
    );
    window.addEventListener('CY_DATA_AGGREGATOR', handleAggEvent);

    return () => {
      window.removeEventListener('CY_DATA_AGGREGATOR', handleAggEvent);
      datadogLogs.logger.info(
        `Removing Event Listener for MorphCast, classId: ${classId}, type: ${socketUser}, studentId: ${clientId}`,
        {
          classId: classId,
          socketUser,
          clientId: clientId,
        },
      );
    };
  }, [clientId, socketUser, socket, classId]);
};

export default useMorphcast;
