import InDepthQcCard from 'components/screens/reports/InDepthQcCard';
import SectionHeader from 'components/screens/reports/SectionHeader';
import {
  DROP_IN_PERFORMANCE_VARIANT,
  ERROR,
  IMPROVED_VARIANT,
  SAME_AS_BEFORE_VARIANT,
  SUCCESS,
} from 'configs/reports';
import {
  getDotInterventionColor,
  getHeaderInterventionBgColor,
  getHeaderTextInterventionColor,
} from 'configs/studentReport';
import { useConnectionContext } from 'contexts/ConnectionContext';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Connection, ImprovementVariant, InterventionMetricsEntity } from 'types/reports';

const InDepthPostInterventionQc = () => {
  const location = useLocation();
  const { studentId, chapterId, categoryId } = useParams();
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const inDepthQcCardRef = useRef<HTMLDivElement>(null);

  const {
    selectedPostInterventionQcRef,
    postInterventionQCRefs,
    selectedStatus,
    prePostQcGapCompData,
  } = useConnectionContext();
  const isQuestionsRoute = location.pathname.includes('/questions');
  const isAdminRoute = location.pathname.startsWith('/a');
  const prefix = isAdminRoute ? '/a' : '/p';

  const [connections, setConnections] = useState<Connection[]>([]);
  const [svgDimensions, setSvgDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const calculateConnections = () => {
      if (
        !containerRef.current ||
        !selectedPostInterventionQcRef?.current ||
        !inDepthQcCardRef.current
      )
        return;

      const containerRect = containerRef.current.getBoundingClientRect();
      const selectedPostInterventionQcRect =
        selectedPostInterventionQcRef.current.getBoundingClientRect();
      const inDepthQcCardRect = inDepthQcCardRef.current.getBoundingClientRect();

      const newConnections: Connection[] = [];

      const startX = selectedPostInterventionQcRect.right - containerRect.left;
      let startY =
        selectedPostInterventionQcRect.top +
        selectedPostInterventionQcRect.height / 2 -
        containerRect.top;
      const endX = inDepthQcCardRect.left - containerRect.left;
      const endY = inDepthQcCardRect.top + 32 - containerRect.top;

      const curveWidth = 40;
      const curveRadius = 30;
      const yDifference = Math.abs(endY - startY);
      const isDescending = endY > startY;
      const minLineHeight = 40; // Ensure a minimum height for the line

      const isNearlyHorizontal = yDifference < minLineHeight;

      let startCurve, endCurve, line;

      if (isNearlyHorizontal) {
        startY = startY - selectedPostInterventionQcRect.height / 4;

        startCurve = `
                  M ${startX} ${startY}
                  H ${startX + curveWidth - curveRadius}
                  Q ${startX + curveWidth} ${startY},
                    ${startX + curveWidth} ${startY + (isDescending ? curveRadius : -curveRadius)}
                  V ${isDescending ? endY - curveRadius : endY + curveRadius}
                `;

        endCurve = `
                  M ${endX - curveWidth} ${isDescending ? endY - curveRadius : startY - curveRadius}
                  V ${endY - (isDescending ? curveRadius : -curveRadius)}
                  Q ${endX - curveWidth} ${endY},
                    ${endX - curveWidth + curveRadius} ${endY}
                  H ${endX}
                `;

        line = {
          top: Math.min(
            startY + (isDescending ? curveRadius - 1 : 0),
            endY + (isDescending ? 0 : curveRadius - 1),
          ),
          left: startX + curveWidth - 1,
          height: Math.abs(endY - startY) - curveRadius * 2 + 2,
        };
      } else {
        startCurve = `
                  M ${startX} ${startY}
                  H ${startX + curveWidth - curveRadius}
                  Q ${startX + curveWidth} ${startY},
                    ${startX + curveWidth} ${startY + (isDescending ? curveRadius : -curveRadius)}
                  V ${isDescending ? endY - curveRadius : endY + curveRadius}
                `;

        endCurve = `
                  M ${endX - curveWidth} ${isDescending ? endY - curveRadius : startY - curveRadius}
                  V ${endY - (isDescending ? curveRadius : -curveRadius)}
                  Q ${endX - curveWidth} ${endY},
                    ${endX - curveWidth + curveRadius} ${endY}
                  H ${endX}
                `;

        line = {
          top: Math.min(
            startY + (isDescending ? curveRadius - 1 : 0),
            endY + (isDescending ? 0 : curveRadius - 1),
          ),
          left: startX + curveWidth - 1,
          height: Math.abs(endY - startY) - curveRadius * 2 + 2,
        };
      }

      newConnections.push({
        startCurve,
        endCurve,
        line,
        startDot: { x: startX, y: startY },
        endDot: { x: endX, y: endY },
      });

      setConnections(newConnections);
      setSvgDimensions({
        width: containerRect.width,
        height: containerRect.height,
      });
    };

    if (postInterventionQCRefs || selectedPostInterventionQcRef?.current || categoryId) {
      calculateConnections();

      const observer = new ResizeObserver(calculateConnections);

      if (containerRef.current) {
        observer.observe(containerRef.current);
      }
      if (postInterventionQCRefs?.current) {
        postInterventionQCRefs.current.forEach((ref) => {
          if (
            ref === selectedPostInterventionQcRef?.current &&
            selectedPostInterventionQcRef?.current
          ) {
            observer.observe(selectedPostInterventionQcRef.current);
          }
        });
      }

      window.addEventListener('scroll', calculateConnections);
      window.addEventListener('resize', calculateConnections);

      return () => {
        observer.disconnect();
        window.removeEventListener('scroll', calculateConnections);
        window.removeEventListener('resize', calculateConnections);
      };
    }
  }, [postInterventionQCRefs, categoryId, selectedPostInterventionQcRef]);

  const interventionCompData = prePostQcGapCompData?.find((data) => data.categoryId === categoryId);
  if (!interventionCompData) {
    return null;
  }

  const getInterventionData = (metrics: InterventionMetricsEntity) => ({
    questionsAsked: metrics.questionsAsked,
    answeredCorrectly: metrics.questionsAnsweredCorrectly,
    answeredPercentage: Math.round(
      (metrics.questionsAnsweredCorrectly / metrics.questionsAsked) * 100,
    ),
    correctSteps: metrics.correctSteps,
    correctStepsPercentage: Math.round((metrics.correctSteps / metrics.totalSteps) * 100),
    totalSteps: metrics.totalSteps,
  });

  const handleCardClick = () => {
    navigate(
      `${prefix}/reports/${studentId}/chapter/intervention/${chapterId}/pre-intervention/details/category/${categoryId}/details/questions`,
    );
  };

  const preIntervention = getInterventionData(interventionCompData.preIntervention);
  const postIntervention = getInterventionData(interventionCompData.postIntervention);

  const getStatus = () => {
    const prePercentage = preIntervention.answeredPercentage;
    const postPercentage = postIntervention.answeredPercentage;
    if (postPercentage > prePercentage) return SUCCESS;
    if (postPercentage < prePercentage) return ERROR;
    return 'warning';
  };

  const toImprovementVariant = (status: string): ImprovementVariant => {
    const normalized = status.trim().toLowerCase();
    if (normalized === IMPROVED_VARIANT) return IMPROVED_VARIANT;
    if (normalized === 'drop in performance') return DROP_IN_PERFORMANCE_VARIANT;
    return SAME_AS_BEFORE_VARIANT;
  };

  const variant = toImprovementVariant(selectedStatus || 'Same as before');

  return (
    <div ref={containerRef} className='relative min-h-screen'>
      <div
        className={`flex ${isQuestionsRoute ? 'w-[1084px]' : 'w-full'} transition-all duration-300`}
      >
        <div className={`flex flex-col min-w-full md:min-w-fit md:max-w-[550px]`}>
          <SectionHeader
            text='In-Depth QC Improvement Status'
            backgroundColor={getHeaderInterventionBgColor(variant)}
            textColor={getHeaderTextInterventionColor(variant)}
          />

          <div className='space-y-4 w-[550px]' ref={inDepthQcCardRef}>
            <InDepthQcCard
              key={`${interventionCompData.chapterId}-${categoryId}`}
              title={interventionCompData.categoryTitle}
              preIntervention={preIntervention}
              postIntervention={postIntervention}
              status={getStatus()}
              variant={variant}
              className='bg-white hover:shadow-lg transition-shadow'
              isSelected={true}
              onClick={handleCardClick}
            />
          </div>
        </div>
      </div>

      {/* Connection Elements */}
      <div className='absolute inset-0 pointer-events-none ' style={{ zIndex: 0 }}>
        <svg
          className='absolute top-0 left-0'
          width={svgDimensions.width}
          height={svgDimensions.height}
          style={{ overflow: 'visible' }}
        >
          {connections.map((conn, index) => (
            <g key={`curves-${index}`}>
              {/* Connection Curves */}
              <path
                d={conn.startCurve}
                fill='none'
                stroke='#B4B6C5'
                strokeWidth='2'
                strokeLinecap='round'
                className='transition-all duration-300'
              />
              <path
                d={conn.endCurve}
                fill='none'
                stroke='#B4B6C5'
                strokeWidth='2'
                strokeLinecap='round'
                className='transition-all duration-300'
              />
              {/* Start Half-Cycle Dot */}
              <path
                d={`M ${conn.startDot.x},${conn.startDot.y - 5} 
                A 5,5 0 1,1 ${conn.startDot.x},${conn.startDot.y + 5}`}
                fill={getDotInterventionColor(variant)}
              />
              {/* End Half-Cycle Dot */}
              <path
                d={`M ${conn.endDot.x},${conn.endDot.y - 5} 
                A 5,5 0 1,0 ${conn.endDot.x},${conn.endDot.y + 5}`}
                fill={getDotInterventionColor(variant)}
              />
            </g>
          ))}
        </svg>

        {connections.map(
          (conn, index) =>
            conn.line && (
              <div
                key={`line-${index}`}
                className='absolute bg-neutral-300 w-0.5 transition-all duration-300'
                style={{
                  top: `${conn.line.top}px`,
                  left: `${conn.line.left}px`,
                  height: `${conn.line.height}px`,
                }}
              />
            ),
        )}
      </div>
    </div>
  );
};

export default InDepthPostInterventionQc;
