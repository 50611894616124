import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import Tag from '../Tag';
import { QuestionCardProps } from 'types/reports';
import { CheckCircleIcon, CrossCircleIcon, ExclamationCircleIcon, NotebookIcon } from 'assets/svg';
import NotebookImageGallery from '../NotebookImageGallery';
import {
  CORRECT,
  CORRECT_STARTCASE,
  ERROR,
  INCORRECT,
  NOT_ATTEMPTED_STARTCASE,
  NOT_DONE,
  SUCCESS,
  WARNING,
} from 'configs/reports';
import RenderEvalContent from 'components/inClass/RenderEvalContent';

const QuestionCard: React.FC<QuestionCardProps> = ({
  idx,
  questionEntity,
  isSelected,
  className,
  onClick,
  interventionTypes,
}) => {
  const [isGalleryOpen, setIsGalleryOpen] = useState<boolean>(false);

  const openGallery = () => {
    if (questionEntity.notebookImages?.length) {
      setIsGalleryOpen(true);
    }
  };

  const closeGallery = () => {
    setIsGalleryOpen(false);
  };

  return (
    <>
      <div
        className={classNames(
          'relative max-w-full bg-white rounded-[12px] border-[1px] border-neutral-100 p-[8px] cursor-pointer',
          {
            'ring-2 ring-primary-500': isSelected,
          },
          className,
        )}
        onClick={onClick}
      >
        {/* Header */}
        <div className='mb-[12px] max-w-full p-2 overflow-auto'>
          <div className='flex items-center justify-between mb-[16px]'>
            <h3 className='font-poppins text-xs font-medium text-neutral-400'>
              Question {idx + 1}
            </h3>
            <Tag
              text={questionEntity?.status}
              className={
                questionEntity?.status === CORRECT_STARTCASE
                  ? SUCCESS
                  : questionEntity?.status === NOT_ATTEMPTED_STARTCASE
                    ? WARNING
                    : ERROR
              }
            />
          </div>

          <div className='font-sora font-semibold text-xs text-black'>
            <RenderEvalContent data={questionEntity?.question} />
          </div>
        </div>

        {/* Content */}
        <div className='flex flex-col gap-[16px] px-[8px] py-[12px] rounded-[8px] bg-neutral-50'>
          <div className='mb-[8px]'>
            <h3 className='text-neutral-900 text-xs font-semibold font-poppins'>Steps Required</h3>
          </div>

          <div className='flex flex-col gap-[12px]'>
            {questionEntity?.stepsRequired?.map((stepEntity, index) => (
              <div className='flex items-center gap-[8px]' key={index}>
                <img
                  src={
                    stepEntity?.stepStatus.toLowerCase() === NOT_DONE
                      ? ExclamationCircleIcon
                      : stepEntity?.status.toLowerCase() === CORRECT
                        ? CheckCircleIcon
                        : stepEntity?.status.toLowerCase() === INCORRECT
                          ? CrossCircleIcon
                          : ''
                  }
                  alt={stepEntity?.status}
                  className='w-[13px] h-[17px]'
                />
                <span className='font-poppins font-medium text-xs text-neutral-500'>
                  {stepEntity?.step}
                </span>
              </div>
            ))}
          </div>
        </div>

        {/* Footer */}
        <div className={classNames('mt-[8px]')}>
          {questionEntity?.notebookImages && questionEntity?.notebookImages?.length > 0 ? (
            <div
              className='p-[8px] rounded-[8px] flex items-center gap-[8px] mb-[8px] bg-primary-50'
              onClick={openGallery}
            >
              <img src={NotebookIcon} alt='notebook-icon' className='w-[24px] h-[24px]' />
              <label className='text-sm font-semibold font-poppins text-primary-800 cursor-pointer'>
                <span>{questionEntity?.notebookImages?.length} </span>
                <span>View Notebook Image</span>
              </label>
            </div>
          ) : null}

          {interventionTypes && interventionTypes?.length > 0 ? (
            <div className='p-[8px] rounded-[8px] flex justify-between flex-wrap bg-secondary-50'>
              <label className='text-neutral-800 text-sm font-poppins font-normal mb-[8px]'>
                Student will be Intervened by:
              </label>
              <div className='flex flex-nowrap gap-[4px]'>
                {interventionTypes?.map((intervention, index) => (
                  <Tag key={index} text={intervention} className='bordered secondary' />
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {/* Gallery Modal */}
      {isGalleryOpen &&
        createPortal(
          <div className='fixed inset-0 w-full h-full bg-black bg-opacity-70 flex justify-center items-center z-[1000] px-[5%] py-[3%]'>
            <NotebookImageGallery
              notebookImages={questionEntity?.notebookImages}
              className='max-w-[800px] max-h-[600px] bg-white rounded-[12px] overflow-hidden'
              onClose={closeGallery}
            />
          </div>,
          document.body,
        )}
    </>
  );
};

export default QuestionCard;
