import moment from 'moment';
import { useDyteSelector } from '@dytesdk/react-web-core';
import { useEffect, useState } from 'react';
import StudentSetupScreen from 'components/inClass/StudentSetupScreen';
import InClass from 'components/inClass/InClass';
import HuddleTransition from 'components/inClass/HuddleTransition';
import { BouncingDotsLoader } from 'components/common';
import { useMeeting } from 'hooks';
import OldStudentSetupScreen from '../StudentSetupScreen/OldApproach';
import { CONFIG, ENABLE_OLD_SETUP_SCREEN } from 'configs';
import NetworkOffline from '../StudentSetupScreen/Modals/NetworkOffline';
import { FaChrome } from 'react-icons/fa6';
import { getBrowserInformation, isTabletDevice } from 'utils';
import { FiAlertTriangle } from 'react-icons/fi';
import { InClassUprioLogo } from 'assets/svg';

const Meeting = () => {
  moment.tz?.setDefault('Asia/Kolkata');
  const [showLoader] = useState<boolean>(false);
  const [isOnline, setIsOnline] = useState<boolean>(true);
  const roomJoined = useDyteSelector((meeting) => meeting.self.roomJoined);
  const { state } = useMeeting();

  // Reload the page if the meeting is ended to show the upcoming classes screen
  useEffect(() => {
    if (state === 'ended') {
      window.location.reload();
    }
  }, [state]);

  const browserInfo = getBrowserInformation();
  const isTablet = isTabletDevice();
  const isUnsupportedBrowser = browserInfo?.name === 'firefox' || browserInfo?.name === 'safari';

  const getChromeDownloadLink = () => {
    const ua = navigator.userAgent.toLowerCase();
    if (ua.includes('mac')) {
      return 'https://www.google.com/chrome/browser/desktop/index.html?platform=mac';
    } else if (ua.includes('ipad') || ua.includes('iphone') || ua.includes('ios')) {
      return 'https://apps.apple.com/app/google-chrome/id535886823';
    }
    return 'https://www.google.com/chrome';
  };

  // Only show the unsupported browser message in non-production environments
  if (isUnsupportedBrowser && CONFIG.VITE_BASE_DOMAIN_ENV !== 'production' && !isTablet) {
    return (
      <>
        <div className='flex items-center justify-between p-4 bg-primary-900'>
          <img src={InClassUprioLogo} alt='logo' className='h-8' />
          <div className='h-8 ml-4 mr-2 border-l border-white/20' />
        </div>
        <div className='flex items-center justify-center min-h-[calc(100vh-64px)] bg-primary-900'>
          <div className='flex flex-col items-center max-w-md px-6 text-center'>
            <div className='mb-8'>
              <div className='p-5 rounded-full bg-primary-750'>
                <FiAlertTriangle className='w-8 h-8 text-semantic-orange-500' />
              </div>
            </div>

            <h3 className='text-[32px] leading-[40px] font-semibold mb-4 font-sora text-white'>
              Browser Not
              <br />
              Supported
            </h3>

            <p className='mb-8 text-base text-secondary-400 font-poppins'>
              We apologize for the inconvenience, but for the best learning experience, Uprio
              currently supports Google Chrome browser only.
            </p>

            <div className='flex items-center w-full gap-2 px-4 py-3 mb-8 rounded-lg bg-primary-800'>
              <FaChrome className='w-6 h-6 text-primary-500' />
              <span className='text-white font-poppins'>Please switch to Chrome browser</span>
            </div>

            <a
              href={getChromeDownloadLink()}
              target='_blank'
              rel='noopener noreferrer'
              className='w-full px-6 py-4 text-base font-medium text-white transition-colors rounded-lg bg-primary-500 hover:bg-primary-600 font-poppins'
            >
              Download Chrome
            </a>

            <p className='mt-6 text-sm text-secondary-500 font-poppins'>
              This ensures smooth video calls and optimal feature performance.
            </p>
          </div>
        </div>
      </>
    );
  }

  if (!isOnline) {
    return <NetworkOffline />;
  }

  return (
    <>
      {state === 'connected-meeting' || showLoader ? (
        <div className='flex flex-col items-center justify-center text-white bg-primary-800 w-[100vw] h-[100vh] gap-10'>
          <HuddleTransition />
          <BouncingDotsLoader />
        </div>
      ) : !roomJoined ? (
        ENABLE_OLD_SETUP_SCREEN === 'true' ? (
          <>
            {/* Info: OldStudentSetupScreen is the old approach for the setup screen */}
            <OldStudentSetupScreen
              onStatusChange={(isGoodConnection) => setIsOnline(isGoodConnection)}
            />
          </>
        ) : (
          <>
            {/* Info: StudentSetupScreen is the new approach for the setup screen */}
            <StudentSetupScreen
              onStatusChange={(isGoodConnection) => setIsOnline(isGoodConnection)}
            />
          </>
        )
      ) : (
        <InClass />
      )}
    </>
  );
};

export default Meeting;
