import { datadogLogs } from '@datadog/browser-logs';
import { DyteDialogManager } from '@dytesdk/react-ui-kit';
import { DyteProvider, useDyteClient } from '@dytesdk/react-web-core';
import { BouncingDotsLoader, SocketProvider } from 'components/common';
import { NotificationProvider } from 'components/common/NotificationProvider';
import Meeting from 'components/inClass/Meeting';
import { PreClassErrorScreen } from 'components/inClass/PreClassErrorScreen';
import PreClassScreen from 'components/inClass/PreClassScreen';
import NetworkOffline from 'components/inClass/StudentSetupScreen/Modals/NetworkOffline';
import { useNetworkMonitor } from 'hooks/inClass/useNetworkMonitor';
import { useAppDispatch } from 'hooks/store';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetClassJoinTokenQuery } from 'store/apiSlices/classes.apiSlice';
import { setMyCurrentState } from 'store/slice/inClassConfig.slice';
import { NetworkStatus } from 'types';
import getCurrentState from 'utils/inClass/inClassFunctions';

import ZohoSalesIQ from 'components/common/ChatBot/initiateZohoWidget';
import useChatDetails from 'components/common/ChatBot/ChatBotDetails';


export default function StudentClassroom() {
  const dispatch = useAppDispatch();
  const [meetingToken, setMeetingToken] = useState<string>('');
  const [hasMeetingStarted, setHasMeetingStarted] = useState<boolean>(false);
  const [isOnline, setIsOnline] = useState<boolean>(true);
  const { id } = useParams();
  const authToken = String(id);
  const [classId, setClassId] = useState<string>('');
  // Hook: To monitor the network status
  useNetworkMonitor((status: NetworkStatus) => {
    setIsOnline(status?.qualityPercentage >= 50);
  });

  // API Call -> Get the meeting token
  const {
    data: joinClassRoomData,
    isSuccess,
    error,
    isFetching,
  } = useGetClassJoinTokenQuery(
    {
      authToken,
    },
    {
      skip: !authToken,
    },
  );

  useEffect(() => {
    if (isSuccess) {
      setClassId(String(joinClassRoomData?.data?.classId));
    }
  }, [isSuccess, joinClassRoomData?.data]);

  // Initialize the meeting
  const [meeting, initMeeting] = useDyteClient();

  // Get the chatbot details
  const chatBotDetails = useChatDetails();

  // When the query is successful, set the meeting token
  useEffect(() => {
    if (isSuccess && joinClassRoomData?.data?.meetingToken) {
      datadogLogs.logger.warn('Setting meeting token', {
        label: 'initializing-meeting',
        studentId: joinClassRoomData?.data?.studentId,
        classId: joinClassRoomData?.data?.classId,
        tokenLength: joinClassRoomData?.data?.meetingToken.length,
        isTokenSet: !!joinClassRoomData?.data?.meetingToken,
      });
      setMeetingToken(joinClassRoomData?.data?.meetingToken);
    }
  }, [isSuccess, joinClassRoomData]);

  // When the meeting token changes, initialize the meeting
  useEffect(() => {
    const initializeMeeting = async () => {
      if (!meetingToken) return;

      datadogLogs.logger.warn('Initializing Dyte meeting', {
        label: 'initializing-meeting',
        studentId: joinClassRoomData?.data?.studentId,
        classId: joinClassRoomData?.data?.classId,
        meetingTokenLength: meetingToken.length,
        isMeetingTokenSet: !!meetingToken,
      });

      await initMeeting({
        authToken: meetingToken,
        defaults: {
          audio: true,
          video: true,
        },
        onError: (error) => {
          datadogLogs.logger.error('Error initializing Dyte meeting', {
            label: 'dyte-error',
            source: 'student-in-class',
            error: error?.message,
            code: error?.code,
            studentId: joinClassRoomData?.data?.studentId,
            classId: joinClassRoomData?.data?.classId,
            browser: navigator?.userAgent?.toLowerCase(),
          });
        },
      })
        .then(async (meet) => {
          if (meet && meet?.self?.customParticipantId) {
            const currentStatus = await getCurrentState(String(classId));
            const selectedStudentStatus = currentStatus?.data.find(
              (data: { studentId: number | undefined }) =>
                data.studentId === joinClassRoomData?.data?.studentId,
            );
            if (selectedStudentStatus?.currentState) {
              dispatch(setMyCurrentState(selectedStudentStatus?.currentState));
            }
            datadogLogs.logger.info(
              `Socket connected for student with studentId:${joinClassRoomData?.data?.studentId} and classId:${joinClassRoomData?.data?.classId}`,
              {
                classId: joinClassRoomData?.data?.classId,
                studentId: joinClassRoomData?.data?.studentId,
                browser: navigator.userAgent.toLowerCase(),
              },
            );
            setHasMeetingStarted(true);
          }
        })
        .catch((error) => {
          datadogLogs.logger.error('Error initializing the meeting', {
            error,
            browser: navigator.userAgent.toLowerCase(),
          });
          setHasMeetingStarted(false);
        });
    };

    initializeMeeting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingToken]);

  if (!isOnline) {
    return <NetworkOffline />;
  }

  if (isFetching || (!hasMeetingStarted && joinClassRoomData?.data?.inOngoingClass)) {
    return (
      <div className='flex items-center justify-center h-[100vh]'>
        <BouncingDotsLoader />
      </div>
    );
  }

  return isSuccess ? (
    joinClassRoomData?.data?.inOngoingClass ? (
      <>
        <DyteProvider value={meeting}>
          <SocketProvider
            userId={joinClassRoomData?.data?.studentId?.toString()}
            userType='student'
            classId={joinClassRoomData?.data?.classId?.toString()}
          >
            <NotificationProvider>
              <DyteDialogManager meeting={meeting} />
              <Meeting />
            </NotificationProvider>
          </SocketProvider>
        </DyteProvider>
        <ZohoSalesIQ
          id={chatBotDetails.userId?.toString() || ''}
          name={chatBotDetails.name}
          email={chatBotDetails.email}
          contactNumber={chatBotDetails.contactNumber}
          role={chatBotDetails.role}
          page={chatBotDetails.landingScreen}
          activeCamera={chatBotDetails.activeCamera}
          activeMicrophone={chatBotDetails.activeMicrophone}
          activeSpeaker={chatBotDetails.activeSpeaker}
        />
      </>
    ) : (
      <>
        <PreClassScreen
          classStartTime={joinClassRoomData?.data?.meetingStartTime}
          tutorDetails={joinClassRoomData?.data?.tutorDetails}
          subject={joinClassRoomData?.data?.subject}
          className={
            joinClassRoomData?.data?.lectureConfigurationName || joinClassRoomData?.data?.name
          }
          onStatusChange={(isGoodConnection) => setIsOnline(isGoodConnection)}
        />
      </>
    )
  ) : (
    <>
      <PreClassErrorScreen
        error={error}
        onStatusChange={(isGoodConnection) => setIsOnline(isGoodConnection)}
      />
    </>
  );
}
