import classNames from 'classnames';

const MetricItem: React.FC<{
  value: number | string;
  percentage?: number;
  label: string;
  circleStyle: string;
}> = ({ value, percentage, label, circleStyle }) => (
  <div className='flex flex-col gap-2'>
    <div className='flex items-center gap-2'>
      <div
        className={classNames(
          'flex items-center justify-center h-6 px-2 rounded-full font-semibold text-xs',
          circleStyle,
        )}
      >
        <span className='font-medium font-sora'>{value}</span>
      </div>
      {percentage !== undefined && (
        <span className='text-gray-600 text-xs font-sora'>({percentage}%)</span>
      )}
    </div>
    <p className='text-gray-600 text-xs font-sora'>{label}</p>
  </div>
);

export default MetricItem;
