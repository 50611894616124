import { MdWifiOff } from 'react-icons/md';

export default function NetworkOffline() {
  return (
    <div className='flex items-center justify-center min-h-screen p-4 bg-primary-900'>
      <div className='w-full max-w-md p-8 text-center bg-primary-850 rounded-xl'>
        <MdWifiOff className='w-16 h-16 mx-auto mb-4 text-semantic-red-500' />
        <h2 className='mb-3 text-2xl font-bold text-white font-sora'>No Internet Connection</h2>
        <p className='mb-3 text-secondary-400 font-poppins'>
          Looks like you're offline. Please make sure your Wi-Fi or mobile data is turned on to join
          the class.
        </p>
        <p className='text-sm text-gray-300 font-poppins'>
          Need help? Try refreshing the page once you're back online.
        </p>
      </div>
    </div>
  );
}
