import { AttendanceDetailsItemProps } from 'types/reports';

const AttendanceDetailsItem: React.FC<AttendanceDetailsItemProps> = ({
  title,
  scheduled,
  attended,
  attendedPercentage,
}) => (
  <div className='mb-3 last:border-b-0 last:pb-0'>
    {title !== 'Teach classes' && (
      <h3 className='font-poppins font-semibold text-xs mb-3'>{title}</h3>
    )}
    <div className='flex justify-between border border-neutral-100 p-3 rounded-[6px] items-center gap-4'>
      <div className='flex flex-col items-start gap-3 w-[48%]'>
        <div className='bg-gray-100 w-6 h-6 rounded-full flex items-center justify-center'>
          <span className='text-[10px] font-sora font-bold text-gray-900 '>{scheduled}</span>
        </div>
        <span className='font-poppins font-medium text-xs text-neutral-600'>Classes scheduled</span>
      </div>
      <div className='h-12 w-[1px] bg-neutral-50'></div>
      <div className='flex flex-col items-start gap-3 w-[48%]'>
        <div className='flex items-center'>
          <div
            className={`${
              attendedPercentage === 100
                ? 'bg-semantic-green-100'
                : attendedPercentage >= 65
                  ? 'bg-orange-100'
                  : 'bg-gray-100'
            } w-6 h-6 rounded-full flex items-center mr-2 justify-center`}
          >
            <span className='text-[10px] font-sora font-bold text-gray-900'>{attended}</span>
          </div>
          <span className='text-[10px] font-sora text-gray-500'>({attendedPercentage}%)</span>
        </div>
        <span className='font-poppins font-medium text-xs text-neutral-600'>Classes attended</span>
      </div>
    </div>
  </div>
);

export default AttendanceDetailsItem;
