import WaterMark from 'components/common/WaterMark';

import { USER_TYPE } from 'configs';
import { ContentProps } from 'types';

export default function Content({ contentUrl, iframeRef }: ContentProps) {
  const url = contentUrl + `?userType=${USER_TYPE.STUDENT}`;
  return (
    <div className='relative w-full max-w-[80vw] h-[calc(100vh-300px)] flex justify-center items-center'>
      <WaterMark />
      <iframe
        src={url}
        title='Class Notes'
        allowFullScreen={true}
        className='size-full'
        ref={iframeRef}
      ></iframe>
    </div>
  );
}
