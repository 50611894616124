import { CategoryCounts, MenuIconItem } from 'types/reports';

export const SEGMENTS_SUBJECT = [
  { name: 'Maths', _id: '' },
  { name: 'Science', _id: '' },
];

export const evaluationsData = [
  {
    id: 1,
    name: 'Well understood',
    rating: 5,
    totalCategories: 11,
    categoriesCount: 5,
    interventions: ['N/A'],
    colorName: 'semantic-green',
  },
  {
    id: 2,
    name: 'Understood but student makes silly mistakes',
    rating: 4,
    totalCategories: 11,
    categoriesCount: 3,
    interventions: ['Practice'],
    colorName: 'orange',
  },
  {
    id: 3,
    name: 'Requires further reinforcement',
    rating: 3,
    totalCategories: 11,
    categoriesCount: 2,
    interventions: ['Practice', 'Re-explain'],
    colorName: 'semantic-red',
  },
  {
    id: 4,
    name: 'Categories not assessed in class',
    rating: null,
    totalCategories: 11,
    categoriesCount: 1,
    interventions: null,
    colorName: 'neutral',
  },
];
export const WELL_UNDERSTOOD_GAP = ['ni'];
export const UNDERSTOOD_BUT_MAKES_SILLY_MISTAKES_GAP = ['li'];
export const NEEDS_REINFORCEMENT_GAP = ['hi', 'snd'];
export const NOT_ASSESSED_GAP = ['not_assessed', 'cant_evaluate'];

export const QC_GAP_STATUS = [
  'qc_is_gap_snd',
  'qc_is_gap_hi',
  'qc_is_not_gap_li',
  'qc_is_not_gap_ni',
  'cant_evaluate',
  'cant_evaluate_not_enough_data',
];
export const SUBMITTED = 'Submitted';
export const NOT_ASSESSED = 'NOT_ASSESSED';
export const GAP_NI = 'qc_is_not_gap_ni';
export const GAP_LI = 'qc_is_not_gap_li';
export const GAP_HI = 'qc_is_gap_hi';
export const CANT_EVALUATE = 'cant_evaluate';
export const CANT_EVALUATE_NOT_ENOUGH_DATA = 'cant_evaluate_not_enough_data';
export const GAP_SND = 'qc_is_gap_snd';
export const ABSENT_GAP = 'absent';
export const CANT_EVALUATE_GAPS = [
  'cant_evaluate',
  'cant_evaluate_not_enough_data',
  'not_assessed',
  'absent',
];
export const NOTEBOOK = 'notebook';
export const PRACTICE = 'practice';
export const RE_EXPLAIN = 're-explain';
export const POLL = 'poll';
export const SUCCESS = 'success';
export const ERROR = 'error';
export const VIEW_LESS = 'View less';
export const INCORRECT_ANSWER = ['incorrect', 'student_could_not_solve'];
export const INTERVENTION_CLASS_TYPES = ['intervention_class', 'intervention_evaluation_class'];
export const REGULAR_CLASS_TYPES = ['regular_class', 'regular_evaluation_class'];
export const INTERVENTION_REPORT_TYPES = [
  'regular_class',
  'regular_evaluation_class',
  'intervention_class',
  'intervention_evaluation_class',
];
export const SUBMITTED_STATUS = [
  'submitted_within_deadline',
  'submitted_post_deadline',
  'correct_submission',
];

export const statusToIdMap = {
  GAP_NI: 1,
  GAP_LI: 2,
  GAP_HI_SND: 3,
} as const;

export const REINFORCEMENT_GAPS = ['hi', 'snd'];
export const SCIENCE_SUBJECT = 'Science';
export const MATHS_SUBJECT = 'Mathematics';
export const IMPROVED_VARIANT = 'improved';
export const DROP_IN_PERFORMANCE_VARIANT = 'drop-in-performance';
export const SAME_AS_BEFORE_VARIANT = 'same-as-before';
export const CATEGORY = 'category';
export const REGULAR_MATH = 'regularMath';
export const INTERVENTION_MATH = 'interventionMath';
export const EXAM_STATUS = 'examStatus';
export const VIEW_MORE = 'View more';
export const PRESENT = 'Present';
export const QUESTION_CATEGORY_ID = 'questionCategoryId';
export const LEFT = 'left';
export const RIGHT = 'right';
export const SCIENCE = 'science';
export const CORRECT = 'correct';
export const CORRECT_STARTCASE = 'Correct';
export const INCORRECT = 'incorrect';
export const WARNING = 'brown';
export const NOT_DONE = 'not_done';
export const INCORRECT_STARTCASE = 'Incorrect';
export const DRAFT = 'draft';
export const STUDENT_COULD_NOT_SOLVE = 'student_could_not_solve';
export const NOT_ATTEMPTED = 'not_attempted';
export const NOT_ATTEMPTED_STARTCASE = 'Not Attempted';
export const REGULAR_CLASS = 'regular_class';
export const REGULAR_EVAL_CLASS = 'regular_evaluation_class';
export const TEACH = 'Teach';
export const EVALUATION = 'Evaluation';
export const TEACH_EVALUATION = 'Teach + Evaluation';
export const NOT_EVALUATED = 'Not Evaluated';
export const CATEGORIES_NOT_ASSESSED_IN_CLASS = 'Categories not assessed in class';
export const BLOCK_CATEGORY_EXPAND_FURTHUR = [
  'Categories not assessed in class',
  'Categories taught but student was absent',
];

export const NO_CHAPTERS_FOUND = 'No Chapters Found';
export const NO_EXAMS_FOUND = 'No Exams Found';
export const NO_CHAPTERS_FOUND_EXPLAINATION =
  'It looks like there are no chapters available for this subject right now. Please check back later.';

export const NO_CHAPTERS_COVERED_FOR_EXAMS =
  'It appears that no chapters have been covered for any of the exams at this time.Please check again later.';

export const NOTEBOOK_GENERAL_QUES = 'What is notebook evaluation?';

export const NOTEBOOK_QUES_ANS =
  'Notebook evaluation is a process where a teacher checks the work done by the students in their notebooks.';

export const PRACTICE_GENERAL_QUES = 'What is practice evaluation?';
export const PRACTICE_QUES_ANS =
  'Practice Sheets help students improve their problem-solving skills by providing a set of problems for them to solve.';
export const POLL_GENERAL_QUES = 'What is poll evaluation?';
export const POLL_QUES_ANS =
  'Polls are tools used to gather opinions or answers from students on various topics, providing insight into their understanding and preferences.';

export const QC_BASED_REPORT_BRIEF_EXPAINATION = [
  {
    section: 'What We Have Taught So Far',
    points: [
      'Provides an overview of the topics covered in the curriculum so far.',
      'Displays the chapters taught with details like notebook evaluations and practice sheets completed.',
      'Helps parents understand the child’s learning progression and effort put into each topic.',
    ],
  },
  {
    section: 'Class Details',
    points: [
      'Lists specific classes taken and tasks completed (e.g.,Notebook Evaluations, Poll Evaluations, practice sheet submissions).',
      'Includes the number of attempts made for tasks like word problems and basic operations.',
      'Allows parents to track classroom engagement and participation.',
    ],
  },
  {
    section: 'Chapter-Level Understanding',
    points: [
      'Evaluates the student’s overall understanding of each chapter using ratings or stars.',
      'Highlights if any intervention is needed, such as re-explaining or extra practice.',
      'Gives parents a clear view of their child’s grasp of major topics.',
    ],
  },
  {
    section: 'Question Category-Level Understanding',
    points: [
      'Breaks down performance in specific question categories, like converting decimals to fractions.',
      'Displays metrics such as the total number of questions asked for a specific category, along with the number of correct and incorrect answers, including their respective percentages.',
      'Helps parents identify specific strengths and weaknesses in their child’s learning.',
    ],
  },
  {
    section: 'In-Depth Step-Level Evaluation',
    points: [
      'Analyzes a single question or concept by breaking it into step-by-step requirements.',
      'Evaluates the correctness of each step and suggests interventions if needed.',
      'Gives parents detailed insights into their child’s problem-solving process and where errors occur.',
    ],
  },
];

export const reportInfo = [
  "QC Report : Question Category-Level Understanding shows student's grasp of concepts across different categories",
  "Intervention Report : Stepwise analysis of the student's understanding with targeted improvement suggestions",
];

export const menuItems: MenuIconItem[] = [
  { id: 'M', label: 'Mathematics' },
  { id: 'S', label: 'Science' },
  { id: 'E', label: 'Exam Readiness' },
];

export const examTypeReports = ['PT1', 'PT2', 'PT3', 'PT4'];
export const categoryNotAssessedNote = [
  { text: 'Student Absent', highlight: true },
  { text: 'Not Required for In-Class Evaluation', highlight: false },
];
export const SUBJECT_REPORTS = [
  { name: 'QC Report', type: 'Maths', reportType: 'regularMath' },
  { name: 'Intervention Report', type: 'Maths', reportType: 'interventionMath' },
  { name: 'QC Report', type: 'Science', reportType: 'regularScience' },
  { name: 'Intervention Report', type: 'Science', reportType: 'interventionScience' },
];
export const FIXED_CATEGORIES = [
  {
    key: 'wellUnderstood' as keyof CategoryCounts,
    title: 'Well understood',
    rating: 5,
    status: 1,
  },
  {
    key: 'sillyMistakes' as keyof CategoryCounts,
    title: 'Understood but student makes silly mistakes',
    rating: 3,
    status: 2,
  },
  {
    key: 'needsReinforcement' as keyof CategoryCounts,
    title: 'Requires further reinforcement',
    rating: 2,
    status: 3,
  },
  {
    key: 'notAssessed' as keyof CategoryCounts,
    title: 'Categories not assessed in class',
    rating: 0,
    status: 4,
  },
];

export const CATEGORY_CONFIG = [
  {
    key: 'wellUnderstood' as const,
    title: 'Well understood',
    rating: 5,
    preClass: 'bg-neutral-100',
    postClass: 'bg-semantic-green-100 text-semantic-green-800',
  },
  {
    key: 'sillyMistakes' as const,
    title: 'Understood but student makes silly mistakes',
    rating: 3,
    preClass: 'bg-neutral-100',
    postClass: 'bg-semantic-orange-100 text-semantic-orange-800',
  },
  {
    key: 'needsReinforcement' as const,
    title: 'Requires further reinforcement',
    rating: 2,
    preClass: 'bg-neutral-100',
    postClass: 'bg-red-100 text-red-900',
  },
  {
    key: 'notAssessed' as const,
    title: 'Categories not assessed in class',
    rating: 0,
    preClass: 'bg-neutral-100',
    postClass: 'bg-neutral-100 text-neutral-800',
  },
];

export const CHAPTER_STATUS = [
  { text: 'Completed', highlight: true },
  { text: 'Pending', highlight: false },
];

export const RATING_MAP = { 5: 1, 3: 2, 2: 3 };
