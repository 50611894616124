import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { CheckmarkIcon } from 'react-hot-toast';
import { ActiveMic, ActiveVideo, Close, ExclamationWarn, Mic, Speaker, Video } from 'assets/svg';
import { useDropdown } from 'hooks/useDropdown';
import { getDeviceStatus, isTabletDevice } from 'utils/inClass/deviceUtils';
import { useAudioAnalyzer, useMeeting } from 'hooks';
import { DeviceCardProps } from 'types';
import AudioLevelMeter from './AudioLevelMeter';
import notificationSound from 'assets/notificationSound.mp3';
import { DEVICE_SOURCE } from 'configs/deviceType';
import { Spinner } from 'flowbite-react';
import { isEqual } from 'lodash';
import { useSetupScreenMediaDevices } from 'hooks/inClass/useSetupScreenMediaDevices';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa6';

interface HTMLAudioElement extends HTMLMediaElement {
  setSinkId(sinkId: string): Promise<void>;
}

const DeviceCard = memo(
  ({
    type,
    isWorking,
    hasPermission,
    isDeviceLoading,
    devices,
    selectedDevice,
    onDeviceChange,
    isOpen,
    onToggleDropdown,
    dropdownPosition,
    onToggle,
    isEnabled = false,
  }: DeviceCardProps) => {
    const [currentDeviceId, setCurrentDeviceId] = useState<string>(selectedDevice);
    const [isChangingDevice, setIsChangingDevice] = useState<boolean>(false);
    const [lastError, setLastError] = useState<string | null>(null);
    const [isToggling, setIsToggling] = useState<boolean>(false);
    const [deviceInitialized, setDeviceInitialized] = useState<boolean>(false);
    const previousDeviceRef = useRef<string>(selectedDevice);
    const [permissionState, setPermissionState] = useState<PermissionState | null>(null);
    const permissionCheckedRef = useRef<boolean>(false);

    const { studentId, studentName, classId } = useMeeting();
    const { audioDevices } = useSetupScreenMediaDevices();
    const { dropdownRef, buttonRef } = useDropdown(isOpen, onToggleDropdown);

    // Check permission state on mount and when permission changes
    useEffect(() => {
      const checkPermission = async () => {
        try {
          const permissionStatus = await navigator.permissions.query({
            name: 'microphone' as PermissionName,
          });
          setPermissionState(permissionStatus.state);

          permissionStatus.addEventListener('change', () => {
            setPermissionState(permissionStatus.state);
          });

          return () => {
            permissionStatus.removeEventListener('change', () => {
              setPermissionState(permissionStatus.state);
            });
          };
        } catch (error) {
          console.error('Error checking permission:', error);
          setPermissionState('denied');
        }
      };

      if (!permissionCheckedRef.current) {
        checkPermission();
        permissionCheckedRef.current = true;
      }
    }, []);

    // Enhanced audio analyzer hook usage with proper dependency management
    const audioAnalyzerConfig = useMemo(
      () => ({
        deviceId: type === 'Microphone' ? currentDeviceId : audioDevices[0]?.deviceId,
        isEnabled: isEnabled && type === 'Microphone' && permissionState === 'granted',
      }),
      [type, currentDeviceId, audioDevices, isEnabled, permissionState],
    );

    const {
      analyserRef,
      audioLevel: micLevel,
      isInitialized: micInitialized,
      isMicrophoneWorking: isMicWorking,
      resetStream,
    } = useAudioAnalyzer(audioAnalyzerConfig);

    // Batch update effect
    useEffect(() => {
      if (selectedDevice !== previousDeviceRef.current) {
        setCurrentDeviceId(selectedDevice);
        previousDeviceRef.current = selectedDevice;
      }
    }, [selectedDevice]);

    useEffect(() => {
      if (selectedDevice && !deviceInitialized) {
        setDeviceInitialized(true);
      }
    }, [selectedDevice, deviceInitialized]);

    // Optimized device selection
    const handleDeviceSelect = useCallback(
      async (deviceId: string) => {
        if (isChangingDevice) return;

        try {
          setIsChangingDevice(true);
          setDeviceInitialized(false);

          const wasEnabled = isEnabled;
          await onDeviceChange(deviceId);
          setCurrentDeviceId(deviceId);
          setLastError(null);

          if (type === 'Microphone') {
            await resetStream();
          }

          onToggleDropdown();

          datadogLogs.logger.info('Device changed successfully', {
            type,
            deviceId,
            wasEnabled,
            studentId,
            classId,
            studentName,
          });
        } catch (error) {
          const errorMessage = error instanceof Error ? error.message : 'Unknown error';
          setLastError(`Failed to change device: ${errorMessage}`);

          datadogLogs.logger.error('Device change failed', {
            type,
            deviceId,
            error: errorMessage,
            studentId,
            classId,
            studentName,
          });
        } finally {
          setIsChangingDevice(false);
          setDeviceInitialized(true);
        }
      },
      [
        isChangingDevice,
        isEnabled,
        onDeviceChange,
        type,
        resetStream,
        onToggleDropdown,
        studentId,
        classId,
        studentName,
      ],
    );

    // Optimized speaker test with debouncing
    const handleTestSpeaker = useCallback(async () => {
      if (!selectedDevice || isDeviceLoading || isChangingDevice) return;

      try {
        const audio = new Audio(notificationSound);
        const isTablet = isTabletDevice();
        if (!isTablet) {
          // For desktop browsers, use setSinkId
          await (audio as HTMLAudioElement).setSinkId(selectedDevice);
        }

        audio.volume = 0.5;
        await audio.play();

        datadogLogs.logger.info('Test Speaker playing through device', {
          deviceId: selectedDevice,
          deviceLabel:
            devices?.find((d) => d?.deviceId === selectedDevice)?.label || 'System Default',
          isTablet,
          studentId,
          classId,
          studentName,
        });
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Unknown error';
        // Don't show error for tablets, just play through default device
        if (!/iPad|Android/.test(navigator.userAgent)) {
          setLastError(`Failed to play through selected device: ${errorMessage}`);
        }

        datadogLogs.logger.error('Test Speaker device routing failed', {
          deviceId: selectedDevice,
          error: errorMessage,
          studentId,
          classId,
          studentName,
        });
      }
    }, [
      selectedDevice,
      isDeviceLoading,
      isChangingDevice,
      devices,
      studentId,
      studentName,
      classId,
    ]);

    // Debounced toggle handling
    const handleToggleClick = useCallback(async () => {
      if (!onToggle || !hasPermission || isDeviceLoading || isToggling) return;

      try {
        setIsToggling(true);
        await onToggle();

        datadogLogs.logger.info(`${type} toggled successfully`, {
          type,
          deviceId: currentDeviceId,
          newState: !isEnabled,
          studentId,
          classId,
          studentName,
        });
      } catch (error) {
        datadogLogs.logger.error(`Error toggling ${type.toLowerCase()}`, {
          error,
          studentId,
          classId,
          studentName,
        });
      } finally {
        setIsToggling(false);
      }
    }, [
      onToggle,
      hasPermission,
      isDeviceLoading,
      isToggling,
      type,
      currentDeviceId,
      isEnabled,
      studentId,
      classId,
      studentName,
    ]);

    // Memoized device icon
    const getDeviceIcon = useMemo(() => {
      switch (type) {
        case 'Camera':
          return isEnabled ? (
            <img src={ActiveVideo} className='w-5 h-5' alt='Video On' />
          ) : (
            <img src={Video} className='w-5 h-5' alt='Video Off' />
          );
        case 'Microphone':
          return isEnabled ? (
            <img src={ActiveMic} className='w-5 h-5' alt='Audio On' />
          ) : (
            <img src={Mic} className='w-5 h-5' alt='Audio Off' />
          );
        case 'Speaker':
          return <img src={Speaker} className='w-5 h-5' alt='Speaker' />;
        default:
          return null;
      }
    }, [type, isEnabled]);

    // Enhanced status information with permission state
    const statusInfo = useMemo(() => {
      const baseStatus = {
        isLoading: isDeviceLoading || isChangingDevice,
        hasPermission,
        deviceStatus: isEnabled,
      };

      if (type === 'Microphone') {
        return getDeviceStatus(type, {
          ...baseStatus,
          isWorking: isMicWorking,
          audioLevel: micLevel,
          isMicrophoneWorking: isMicWorking && !lastError,
          hasStream: micInitialized,
        });
      } else if (type === 'Camera') {
        return getDeviceStatus(type, {
          ...baseStatus,
          isWorking: isWorking && !lastError,
        });
      } else {
        // For Speaker devices
        return getDeviceStatus(type, {
          ...baseStatus,
          isWorking: isWorking && !lastError,
        });
      }
    }, [
      type,
      isDeviceLoading,
      isChangingDevice,
      hasPermission,
      isWorking,
      lastError,
      micLevel,
      isEnabled,
      isMicWorking,
      micInitialized,
    ]);

    return (
      <div
        key={type + currentDeviceId + analyserRef?.current}
        className={`w-full p-3 xl:p-4 rounded-xl font-poppins bg-primary-900 border 
      ${statusInfo.textColor === 'text-semantic-red-400' ? 'border-semantic-red-500' : 'border-primary-700'}`}
      >
        <div className='flex flex-col gap-3'>
          <div className='flex items-center justify-between'>
            {/* STATUS ICON */}
            <div className='flex items-center gap-2'>
              <div
                className={`w-8 h-8 flex items-center justify-center rounded-lg ${statusInfo.color}`}
              >
                {statusInfo.icon === 'loading' && <Spinner size='sm' className='w-5 h-5' />}
                {statusInfo.icon === 'success' && <CheckmarkIcon className='w-5 h-5' />}
                {statusInfo.icon === 'warning' && (
                  <img src={ExclamationWarn} className='w-5 h-5' alt='warn' />
                )}
                {statusInfo.icon === 'error' && <img src={Close} alt='close' />}
                {!statusInfo.icon && null}
              </div>
              <div>
                <h3 className='text-xs font-medium text-white'>{type}</h3>
                <p className={`text-[10px] ${statusInfo.textColor}`}>{statusInfo.message}</p>
              </div>
            </div>

            <div className='flex items-center gap-4'>
              {/* AUDIO VISUALIZER */}
              {type.toLocaleLowerCase() === DEVICE_SOURCE.MICROPHONE.toLocaleLowerCase() && (
                <div className='flex-shrink-0'>
                  <AudioLevelMeter
                    analyserNode={analyserRef?.current}
                    className='flex-1 max-w-xs'
                    isWorking={isMicWorking}
                    isLoading={!micInitialized}
                    show={isEnabled && hasPermission}
                  />
                </div>
              )}

              {/* AUDIO / VIDEO TOGGLE BUTTON */}
              {type.toLocaleLowerCase() !== DEVICE_SOURCE.SPEAKER.toLocaleLowerCase() && (
                <button
                  onClick={handleToggleClick}
                  disabled={!hasPermission || isDeviceLoading || isToggling}
                  className={`w-8 h-8 flex items-center justify-center rounded-lg transition-all
                  ${
                    hasPermission && !isDeviceLoading && !isToggling
                      ? 'hover:bg-primary-500 hover:opacity-80'
                      : 'cursor-not-allowed opacity-50'
                  }
                  ${isEnabled ? 'bg-white' : 'bg-secondary-900'}`}
                >
                  {getDeviceIcon}
                </button>
              )}

              {/* TEST SPEAKER BUTTON */}
              {type.toLocaleLowerCase() === DEVICE_SOURCE.SPEAKER.toLocaleLowerCase() && (
                <button
                  onClick={handleTestSpeaker}
                  disabled={!hasPermission || isDeviceLoading || isChangingDevice}
                  className={`px-3 py-1 text-xs rounded-lg transition-colors flex-shrink-0
                  ${isWorking ? 'bg-primary-500 text-white' : 'bg-primary-800 text-white hover:bg-primary-700'} 
                  ${(!hasPermission || isDeviceLoading || isChangingDevice) && 'opacity-50 cursor-not-allowed'}`}
                >
                  Test Speaker
                </button>
              )}
            </div>
          </div>

          {/* DEVICE SELECTION DROPDOWN */}
          <div className='relative'>
            <button
              ref={buttonRef}
              onClick={(e) => {
                e.stopPropagation();
                onToggleDropdown();
              }}
              disabled={isDeviceLoading || isChangingDevice}
              className={`w-full p-2 md:p-3 text-xs md:text-sm text-white bg-primary-950 rounded-lg 
              flex justify-between items-center transition-all
              ${
                isDeviceLoading || isChangingDevice
                  ? 'opacity-50 cursor-not-allowed'
                  : 'hover:bg-primary-800 border border-primary-800'
              }`}
              aria-expanded={isOpen}
              aria-haspopup='true'
            >
              <span className='truncate'>
                {currentDeviceId
                  ? devices.find((d) => d.deviceId === currentDeviceId)?.label || `${type} device`
                  : `Choose your ${type.toLowerCase()}`}
              </span>
              {isOpen ? <FaChevronUp /> : <FaChevronDown />}
            </button>

            {isOpen && (
              <div
                ref={dropdownRef}
                className={`absolute z-50 w-full mt-1 border rounded-lg shadow-lg md:mt-2 
                bg-primary-900 border-primary-700
                ${dropdownPosition.placement === 'top' ? 'bottom-full mb-1' : 'top-full mt-1'}`}
                role='menu'
                aria-orientation='vertical'
                aria-labelledby='device-select'
              >
                {devices.length > 0 ? (
                  devices.map((device) => (
                    <button
                      key={device.deviceId}
                      onClick={() => handleDeviceSelect(device.deviceId)}
                      disabled={isDeviceLoading || isChangingDevice}
                      className='w-full p-2 text-xs text-left text-white truncate transition-colors md:p-3 md:text-sm hover:bg-primary-700 first:rounded-t-lg last:rounded-b-lg disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none focus:bg-primary-700'
                      role='menuitem'
                    >
                      {device.label || `${type} ${device.deviceId.slice(0, 5)}`}
                    </button>
                  ))
                ) : (
                  <div
                    className='p-2 text-xs text-center text-white md:p-3 md:text-sm'
                    role='menuitem'
                  >
                    No {type.toLowerCase()} devices found
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.type === nextProps.type &&
      prevProps.isWorking === nextProps.isWorking &&
      prevProps.hasPermission === nextProps.hasPermission &&
      prevProps.isDeviceLoading === nextProps.isDeviceLoading &&
      prevProps.selectedDevice === nextProps.selectedDevice &&
      prevProps.isOpen === nextProps.isOpen &&
      prevProps.isEnabled === nextProps.isEnabled &&
      isEqual(prevProps.devices, nextProps.devices) &&
      prevProps.dropdownPosition.placement === nextProps.dropdownPosition.placement
    );
  },
);

export default DeviceCard;
