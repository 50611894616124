import { useEffect } from 'react';

import { VITE_ZOHO_SRC_URL } from 'configs';
import { Zoho } from 'types/common/index';

declare global {
  interface Window {
    $zoho: Zoho['$zoho'];
  }
}

type ZohoUserDetails = {
  id: string;
  name: string;
  email: string;
  role: string;
  page: string;
  contactNumber: string | number;
  activeCamera: string;
  activeMicrophone: string;
  activeSpeaker: string;
};

const defaultZohoSalesIQ = {
  ready: function () {},
  chatbutton: { visible: function () {} },
  chatwindow: { visible: function () {} },
  reset: function () {},
};

const initZoho = async () => {
  window.$zoho = window.$zoho || {};
  window.$zoho.salesiq = window.$zoho.salesiq || defaultZohoSalesIQ;
  const widgetScript = document.createElement('script');
  widgetScript.id = 'zsiqscript';
  widgetScript.src = VITE_ZOHO_SRC_URL;
  widgetScript.defer = true;

  document.body.appendChild(widgetScript);
  const style = document.createElement('style');
  style.textContent = `
            .zsiq-float { 
                right: 40px !important; 
                bottom: 50px !important;
                z-index: 10 !important; 
            }`;
  document.head.appendChild(style);
};

const UpdateZohoSalesIQ = (userDetails: ZohoUserDetails) => {
  window.$zoho = window.$zoho || {};
  window.$zoho.salesiq = window.$zoho.salesiq || defaultZohoSalesIQ;

  if (window.$zoho?.salesiq?.visitor) {
    window.$zoho.salesiq.visitor.name?.(userDetails.name);
    window.$zoho.salesiq.visitor.email?.(userDetails.email);
    window.$zoho.salesiq.visitor.contactnumber?.(userDetails.contactNumber?.toString());

    const visitorInfo: Record<string, string> = {
      userId: userDetails.id?.toString() ?? 'Unknown',
      role: userDetails.role,
      landingScreen: userDetails.page,
      activeCamera: userDetails.activeCamera,
      activeSpeaker: userDetails.activeSpeaker,
      activeMicrophone: userDetails.activeMicrophone,
    };
    window.$zoho.salesiq.visitor.info?.(visitorInfo);
  }
  return () => {
    const existingWidgetScript = document.getElementById('zsiqscript');
    if (existingWidgetScript && existingWidgetScript?.parentNode) {
      existingWidgetScript?.parentNode?.removeChild(existingWidgetScript);
    }
  };
};

const ZohoSalesIQ = ({
  id,
  name,
  email,
  contactNumber,
  role,
  page,
  activeCamera,
  activeMicrophone,
  activeSpeaker,
}: ZohoUserDetails) => {
  useEffect(() => {
    if (!VITE_ZOHO_SRC_URL) return;
    initZoho();

    const userDetails = {
      id,
      name,
      email,
      contactNumber,
      role,
      page,
      activeCamera,
      activeMicrophone,
      activeSpeaker,
    };
    UpdateZohoSalesIQ(userDetails);

    return () => {
      const existingWidgetScript = document.getElementById('zsiqscript');
      if (existingWidgetScript && existingWidgetScript?.parentNode) {
        existingWidgetScript?.parentNode?.removeChild(existingWidgetScript);
      }
    };
  }, [id, name, email, contactNumber, role, page, activeCamera, activeMicrophone, activeSpeaker]);

  return null;
};

export default ZohoSalesIQ;
