import { CustomTabsProps } from 'types/common';
import { BiSpreadsheet } from 'react-icons/bi';
import { MdPendingActions } from 'react-icons/md';
import { IoCheckmarkDoneCircleOutline } from 'react-icons/io5';
import { motion } from 'framer-motion';
import { TAB_STATES } from 'configs';

const tabVariants = {
  active: {
    borderColor: 'rgb(59, 130, 246)',
    color: 'rgb(59, 130, 246)',
    backgroundColor: 'rgb(239, 246, 255)',
    transition: { duration: 0.3 },
  },
  inactive: {
    borderColor: 'transparent',
    color: 'rgb(107, 114, 128)',
    backgroundColor: 'transparent',
    transition: { duration: 0.3 },
  },
};

const TabItem = ({
  isActive,
  onClick,
  icon: Icon,
  label,
}: {
  isActive: boolean;
  onClick: () => void;
  icon: React.ComponentType<{ className: string }>;
  label: string;
}) => (
  <motion.div
    onClick={onClick}
    initial='inactive'
    animate={isActive ? 'active' : 'inactive'}
    variants={tabVariants}
    className={`
      flex flex-wrap items-center gap-1 sm:gap-2 
      px-2 sm:px-6 py-2 sm:py-3 
      border-b-2 cursor-pointer 
      font-sora text-xs sm:text-sm lg:text-base font-medium
      hover:bg-blue-50 rounded-t-lg
      transition-all duration-200
      ${isActive ? 'text-primary-500 border-primary-500' : 'text-gray-500 border-transparent'}
    `}
  >
    <Icon className={`text-xl ${isActive ? 'text-primary-500' : 'text-gray-500'}`} />
    <span>{label}</span>
  </motion.div>
);

function CustomTabs({
  submittedState,
  setSubmittedState,
  setPageNumber,
  setShowDashboard,
}: CustomTabsProps) {
  // Convert submittedState to current tab
  const getCurrentTab = () => {
    if (submittedState === null) return TAB_STATES.OVERALL;
    if (submittedState === false) return TAB_STATES.NOT_SUBMITTED;
    return TAB_STATES.SUBMITTED;
  };

  const handleTabClick = (tabState: (typeof TAB_STATES)[keyof typeof TAB_STATES]) => {
    switch (tabState) {
      case TAB_STATES.OVERALL:
        setShowDashboard(true);
        setSubmittedState(null);
        break;
      case TAB_STATES.NOT_SUBMITTED:
        setShowDashboard(false);
        setSubmittedState(false);
        break;
      case TAB_STATES.SUBMITTED:
        setShowDashboard(false);
        setSubmittedState(true);
        break;
    }
    setPageNumber(1);
  };

  const currentTab = getCurrentTab();

  return (
    <div className='flex justify-center gap-2 py-3 border-b rounded-t-lg shadow-sm'>
      <TabItem
        isActive={currentTab === TAB_STATES.OVERALL}
        onClick={() => handleTabClick(TAB_STATES.OVERALL)}
        icon={BiSpreadsheet}
        label='Summary'
      />
      <TabItem
        isActive={currentTab === TAB_STATES.NOT_SUBMITTED}
        onClick={() => handleTabClick(TAB_STATES.NOT_SUBMITTED)}
        icon={MdPendingActions}
        label='Not Submitted'
      />
      <TabItem
        isActive={currentTab === TAB_STATES.SUBMITTED}
        onClick={() => handleTabClick(TAB_STATES.SUBMITTED)}
        icon={IoCheckmarkDoneCircleOutline}
        label='Submitted'
      />
    </div>
  );
}

export default CustomTabs;
