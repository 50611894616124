import { Card } from 'flowbite-react';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';

import { useGetLectureQuery } from 'store/apiSlices/inClass/lecture.apiSlice';
import { useAddClassNotesAnaltyicsMutation } from 'store/apiSlices/studentDashboard.apiSlice';
import {
  selectActiveElement,
  setActiveElement,
  setActiveElementIndex,
  setCurrentPrevIndex,
} from 'store/slice/classNotes.slice';

import { rightArrow } from 'assets/svg';
import { useAppDispatch, useAppSelector } from 'hooks/store';

import { CONTENT_TYPE } from 'configs';
import { LectureData, LectureItem, PreviewProps } from 'types';

export default function Preview({
  classId,
  iframeRef,
  isGoogleSide,
  setIsClassContainerCollapsed,
}: PreviewProps) {
  const dispatch = useAppDispatch();

  // Redux
  const getActiveElement = useAppSelector(selectActiveElement);
  // const lectureData = useAppSelector(selectMultiLecture);

  const [addClassNotesAnaltyics] = useAddClassNotesAnaltyicsMutation(); // add ClassNotesAnaltyics data

  // Fetch lecture data with skip option if classId is missing
  const { data: lectureData } = useGetLectureQuery(
    { classId: classId.toString() },
    { skip: !classId },
  );
  // Use useMemo to memoize the lectures array
  const lectures = useMemo(() => {
    return (
      lectureData?.data?.lectures
        ?.filter((lecture: LectureData) => lecture.teach && lecture.teach.length > 0)
        ?.map((lecture) => [
          ...(lecture.teach || []),
          ...(lecture.coach_basic || []),
          ...(lecture.coach_intermediate || []),
          ...(lecture.coach_advanced || []),
        ])
        ?.flat() || []
    );
  }, [lectureData]);

  const lectureElements = useMemo(() => {
    return lectures.filter((item) => {
      return item.type === CONTENT_TYPE?.ELEMENT || item.type === CONTENT_TYPE.COACH_MODULE;
    });
  }, [lectures]);

  // Refs to keep track of each card
  const cardRefs = useRef<HTMLDivElement | null>(null);

  // Effect to scroll to the active element when it changes
  useEffect(() => {
    let activeIndex = lectureElements.findIndex(
      (item) => item?.elementDetails?.elementId === getActiveElement?.elementDetails?.elementId,
    );
    if (getActiveElement?.type === CONTENT_TYPE.COACH_MODULE) {
      activeIndex = lectureElements.findIndex(
        (item) => item?.module?._key === getActiveElement?.module?._key,
      );
    }

    // Scroll to the active element
    if (activeIndex !== -1 && cardRefs.current) {
      cardRefs.current.children[activeIndex]?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [getActiveElement, lectureElements]);

  // Handle card click event
  const handleCardClick = ({ data, index }: { data: LectureItem; index: number }) => {
    setIsClassContainerCollapsed(true);
    dispatch(setActiveElement(data));
    dispatch(setActiveElementIndex(index));
    if (iframeRef?.current && data?.contentUrl) {
      iframeRef.current.src = data.contentUrl + '?userType=student';
    }
    dispatch(setCurrentPrevIndex(1)); // reset the img prev to 1
  };

  const handleClickPrev = ({
    slideNo,
    slideName,
    slideId,
    totalSlides,
  }: {
    slideNo: number;
    // elementDetails: ElementDetailsType;
    slideName: string;
    slideId: string;
    totalSlides: number;
  }) => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.contentWindow.postMessage(
        {
          type: 'uprio_custom',
          fn: 'JumpToAnim',
          params: [0, slideNo, true],
        },
        '*',
      );
    }

    addClassNotesAnaltyics({
      classId: classId,
      elementId: slideId,
      elementName: slideName,
      totalSlides: totalSlides,
    });
  };

  return (
    <>
      {!isEmpty(lectureElements) && !isGoogleSide && (
        <div className='w-[20vw] p-2 shadow-md rounded-md bg-blue-500/10'>
          <div className='flex items-center justify-between px-3'>
            <h2 className='text-lg font-bold'>Preview</h2>
          </div>
          <div
            className='flex flex-col flex-1 h-[calc(100vh-20rem)] overflow-y-auto classNotesScrollbar gap-4 p-2'
            ref={cardRefs}
          >
            {lectureElements?.map((data, index) => {
              if (!data) return null; // Check if data is undefined and return early if true
              const { elementDetails, slideNo, thumbnails, type, module, title } = data;
              const isSelected =
                (elementDetails?.elementId || module?._key) ===
                (getActiveElement?.elementDetails?.elementId || getActiveElement?.module?._key);
              if (isEmpty(elementDetails)) {
                if (type !== CONTENT_TYPE.COACH_MODULE) {
                  return;
                }
              }
              const totalSlides = thumbnails?.length ?? 0;
              const slideName = elementDetails?.name || title || '';
              const slideId = elementDetails?.elementId || module?._key || '';
              return (
                <div
                  key={elementDetails?.elementId.toString() || module?._key}
                  onClick={() => handleCardClick({ data, index })}
                  className='cursor-pointer'
                >
                  <Card
                    className={`rounded-[20px] bg-white font-sora font-medium shadow-md ${
                      isSelected ? 'border-2 border-primary-500' : 'hover:bg-primary-500/20'
                    }`}
                    onClick={() => handleClickPrev({ slideNo, slideName, slideId, totalSlides })}
                  >
                    <div className='flex items-center'>
                      <div className='flex-1 text-xs'>
                        <span>
                          {index + 1}.{' ' + slideName}
                        </span>
                      </div>
                      <div>
                        <img src={rightArrow} alt='right-arrow' />
                      </div>
                    </div>
                  </Card>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}
