import React from 'react';
import { RedirectIcon, StarIcon } from 'assets/svg';
import { getCountBadgeStatus, getStatusClasses } from 'configs/studentReport';
import { FIXED_CATEGORIES } from 'configs/reports';
import { PreInterventionCardProps } from 'types/reports';

const PreInterventionCard: React.FC<PreInterventionCardProps> = ({
  categoryCounts,
  totalCategories,
  isSelected = false,
  onClick,
  className,
}) => {
  return (
    <div
      className={`relative bg-white rounded-[12px] pl-4 pr-3 py-3 cursor-pointer flex items-center transition-all duration-200 ease-in-out font-roboto
      ${isSelected ? 'md:border-[2px] border-purple-200' : ''} ${className}`}
      onClick={onClick}
    >
      {/* Left Purple Border */}
      <div
        className={`absolute top-4 bottom-4 left-[-2px] w-1 rounded-r bg-purple-400 
        ${isSelected ? 'md:w-[4px] md:bg-accent-purple-400' : ''}`}
      ></div>

      {/* Card Content */}
      <div className='flex flex-col gap-[4px] w-full'>
        {/* Title Section */}
        <div className='flex items-center justify-between text-neutral-950 sh201 mb-4'>
          <div className='flex items-center gap-2'>
            <h2 className='text-sm font-sora font-semibold'>Pre-Intervention</h2>
            <span className='text-neutral-500 text-sm font-sora font-semibold'>
              ({totalCategories} categories)
            </span>
          </div>
          <img src={RedirectIcon} alt='redirect' width={20} height={20} />
        </div>

        {/* Categories */}
        <div className='space-y-3'>
          {FIXED_CATEGORIES.map((category) => (
            <div
              key={category.key}
              className={`bg-white rounded-lg border border-neutral-100 p-3 ${getStatusClasses(category.status)}`}
            >
              <div className='space-y-2'>
                {/* Category Header */}
                <div className='flex items-center justify-between'>
                  <span className='text-sm font-sora font-semibold max-w-[180px]'>
                    {category.title}
                  </span>
                  <span
                    className={`px-[6px] py-[1px] rounded-[24px] font-normal text-[12px] leading-[20px] text-xs font-sora ${getCountBadgeStatus(category.status)}`}
                  >
                    <strong>{categoryCounts[category.key] || 0}</strong>/{totalCategories}
                  </span>
                </div>

                {/* Rating Stars */}
                {category.rating > 0 && (
                  <div className='flex gap-1'>
                    {Array.from({ length: category.rating }, (_, i) => (
                      <img key={i} src={StarIcon} alt='star' width={24} height={24} />
                    ))}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PreInterventionCard;
