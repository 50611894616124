import { CloseX, RightAngularIcon } from 'assets/svg';
import { useWindowDimensions } from 'hooks';
import { AttendanceDetailsProps } from 'types/reports';
import OutlinedButton from '../OutlinedButton';
import AttendanceDetailsItem from '../AttendanceDetailsItem';

const AttendanceDetails: React.FC<AttendanceDetailsProps> = ({
  onClose,
  data,
  practiceSheetClick,
  classesScheduledClick,
  // isPracticeSheetAssigned,
}) => {
  const { isMobileScreen } = useWindowDimensions();
  return (
    <div
      className={`relative bg-white w-full rounded-[8px] border border-neutral-100 p-4 ${
        isMobileScreen
          ? ''
          : 'before:absolute before:top-3 before:h-[calc(100%-24px)] before:left-0 before:w-1 before:bg-teal-400 before:rounded-r'
      } `}
    >
      <div className='flex justify-between items-center mb-3'>
        <h2 className='font-poppins font-semibold text-xs mb-0'>Teach classes</h2>
        {!isMobileScreen && (
          <button
            onClick={onClose}
            className='text-gray-500 hover:text-gray-700  hover:cursor-pointer'
          >
            <img src={CloseX} alt='Close' />
          </button>
        )}
      </div>

      <div className=''>
        {data.map((item, index) => (
          <AttendanceDetailsItem key={index} {...item} />
        ))}

        {isMobileScreen ? (
          <div className='flex flex-col gap-y-3'>
            <OutlinedButton onClick={practiceSheetClick} className='w-full'>
              Practice sheet submission status
            </OutlinedButton>
            <OutlinedButton onClick={classesScheduledClick} className={`w-full`}>
              Classes Not Attended by Student
            </OutlinedButton>
          </div>
        ) : (
          <div
            onClick={practiceSheetClick}
            className='flex items-center gap-[9px] text-teal-600 cursor-pointer py-[5.5px] '
          >
            <span className='font-sora font-medium hover:cursor-pointer'>
              Practice sheet submission status
            </span>
            <img src={RightAngularIcon} alt='Right Arrow' />
          </div>
        )}
      </div>
    </div>
  );
};

export default AttendanceDetails;
