import { useMemo } from 'react';
import Latex from 'react-latex-next';
import { IRenderContent } from 'types';

const RenderEvalContent = ({ data }: { data: IRenderContent[] }) => {
  const cleanedData = useMemo(() => {
    return data?.filter(
      (item, index, arr) =>
        !(
          (index === 0 && item?.type === 'break') ||
          (index === arr?.length - 1 && item?.type === 'break') ||
          (item?.type === 'break' && arr[index - 1]?.type === 'break')
        ),
    );
  }, [data]);

  return (
    <>
      <span>
        {cleanedData?.map((item, index) => {
          switch (item.type) {
            case 'latex':
              return <Latex key={index}>{`$${item.content?.trim() || ''}$`}</Latex>;
            case 'break':
              return <br key={index} />;
            case 'text':
              return <span key={index}>{item.content?.trim() || ''}</span>;
            case 'image':
              return (
                <div key={index} className='block w-full'>
                  <img className='inline w-100' src={item?.content} alt='' />
                </div>
              );
            default:
              return null;
          }
        })}
      </span>
    </>
  );
};

export default RenderEvalContent;
