import classNames from 'classnames';
import { useState } from 'react';
import { Button as FlowbiteButton } from 'flowbite-react';

import { Modal } from 'components/common';

import MediaSelectDropdown from '../MediaSelectDropdown';
import MediaPermission from '../Permission';
import { IToggleProps } from 'types';

const ToggleButton = ({
  toggleStatus,
  icon,
  activeIcon,
  label,
  activeLabel,
  isPermissionDenied,
  className,
  mediaControl,
  mediaType,
  isSetup = false,
  showNotification,
  isLoading = false,
  disabled = false,
  ...props
}: IToggleProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleIconClick = () => {
    setIsModalOpen(true);
  };

  return (
    <div className='flex flex-col items-center justify-center h-full w-fit'>
      <div
        className={`flex items-center ${mediaControl && isSetup ? 'bg-primary-900' : 'bg-[#4f4f52] rounded-3xl'} ${mediaControl ? 'ml-5  rounded-3xl' : 'justify-center'}`}
      >
        <FlowbiteButton
          theme={{
            size: {
              md: 'text-sm p-2 2xl:p-3',
            },
          }}
          color='default'
          className={classNames(
            `flex items-center relative focus:ring-0 justify-center size-10 2xl:size-12 
            ${toggleStatus ? 'bg-[#EAEAEA] enabled:hover:bg-[#cac9c9]' : 'bg-[#292929] enabled:hover:bg-[#292929d5]'} 
            rounded-full transition duration-300 ease-in-out
            ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`,
            className,
          )}
          {...props}
          onClick={isPermissionDenied ? handleIconClick : props.onClick}
          disabled={disabled || isPermissionDenied}
        >
          {isLoading ? (
            <div className='w-4 h-4 border-2 rounded-full animate-spin border-neutral-200 border-t-neutral-800' />
          ) : (
            <img src={toggleStatus ? activeIcon : icon} className='size-4 2xl:size-5' alt='icon' />
          )}
          {isPermissionDenied && (
            <div
              key={`status-${toggleStatus}`}
              className='absolute top-[-10px] right-0 z-10 cursor-pointer animate-pulse hover:animate-none'
            >
              <span className='py-[2px] px-[10px] text-sm text-white rounded-full bg-semantic-orange-500 hover:ring-8 hover:ring-neutral-600'>
                !
              </span>
            </div>
          )}
          {showNotification && (
            <div className='absolute top-0 right-0 z-10'>
              <span className='relative flex w-3 h-3'>
                <span className='absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg-sky-400'></span>
                <span className='relative inline-flex w-3 h-3 rounded-full bg-sky-500'></span>
              </span>
            </div>
          )}
        </FlowbiteButton>
        {mediaControl ? (
          <span className={`${mediaControl ? 'mr-1' : ''} cursor-pointer`}>
            <MediaSelectDropdown mediaDevice={mediaType} />
          </span>
        ) : (
          ''
        )}
      </div>
      <p className='text-[10px] mt-1 text-white font-bold w-[85px] text-center transition duration-300 ease-in-out'>
        {toggleStatus ? activeLabel : label}
      </p>

      {isPermissionDenied && (
        <Modal
          children={
            <>
              <MediaPermission />
            </>
          }
          openModal={isModalOpen}
          setOpenModal={setIsModalOpen}
        />
      )}
    </div>
  );
};

export default ToggleButton;
