/* eslint-disable react-hooks/exhaustive-deps */
import { ConnectionContext, useConnectionContext } from 'contexts/ConnectionContext';
import { useWindowDimensions } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Connection } from 'types/reports';
import { ConnectionLines } from '../ExamChapters/ConnectionLines';
import { BackHeader } from 'components/screens/reports/BackHeader';
import SectionHeader from 'components/screens/reports/SectionHeader';
import UnderstandingAndProgressCard from 'components/screens/reports/UnderstandingAndProgressCard';
import MobileFooter from 'components/screens/reports/MobileFooter';
import { RightAngularIcon } from 'assets/svg';

const ExamChapterWiseUnderstandingProgress = () => {
  const { studentId, examId } = useParams();
  const context = useConnectionContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobileScreen } = useWindowDimensions();

  const containerRef = useRef<HTMLDivElement>(null);
  const cardRef = useRef<HTMLDivElement>(null);

  const [connections, setConnections] = useState<Connection[]>([]);
  const [svgDimensions, setSvgDimensions] = useState({ width: 0, height: 0 });
  const [showMobileFoorter, setShowMobileFooter] = useState<boolean>(true);

  const isAdminRoute = location.pathname.startsWith('/a');
  const prefix = isAdminRoute ? '/a' : '/p';

  const { progressData, examinations } = context;
  const selectedExamData = examinations?.find((exam) => exam.examId === examId);

  useEffect(() => {
    const calculateConnections = () => {
      if (!containerRef.current || !context.chapterDetailsRef?.current || !cardRef.current) {
        return;
      }

      const containerRect = containerRef.current.getBoundingClientRect();
      const startRect = context.chapterDetailsRef?.current.getBoundingClientRect();
      const endRect = cardRef.current.getBoundingClientRect();

      if (startRect.width === 0 || endRect.width === 0) return;

      const config = {
        curveWidth: 40,
        curveRadius: 30,
        minLineHeight: 30,
      };

      const startX = startRect.right - containerRect.left;
      const startY = startRect.top - 40;
      const endX = endRect.left - containerRect.left;
      const endY = endRect.top + endRect.height / 2 - containerRect.top;
      const isStartBelow = startY > endY;
      const isNearlyHorizontal = Math.abs(endY - startY) < config.minLineHeight;

      let connection: Connection;

      if (isNearlyHorizontal) {
        // Create straight horizontal line
        connection = {
          startCurve: `M ${startX} ${startY} L ${endX} ${startY}`,
          endCurve: '',
          line: { top: 0, left: 0, height: 0 },
          startDot: { x: startX, y: startY },
          endDot: { x: endX, y: startY },
        };
      } else {
        // Create curved line for vertical connections
        const verticalDistance = Math.abs(endY - startY);
        const effectiveHeight = Math.max(verticalDistance - config.curveRadius * 2, 0);

        const startCurve = `
            M ${startX} ${startY}
            H ${startX + config.curveWidth - config.curveRadius}
            Q ${startX + config.curveWidth} ${startY},
              ${startX + config.curveWidth} ${startY + (isStartBelow ? -config.curveRadius : config.curveRadius)}
          `;

        const endCurve = `
            M ${endX - config.curveWidth} ${endY + (isStartBelow ? config.curveRadius : -config.curveRadius)}
            Q ${endX - config.curveWidth} ${endY},
              ${endX - config.curveWidth + config.curveRadius} ${endY}
            H ${endX}
          `;

        connection = {
          startCurve,
          endCurve,
          line: {
            top: Math.min(
              startY + (isStartBelow ? -config.curveRadius : config.curveRadius),
              endY + (isStartBelow ? config.curveRadius : -config.curveRadius),
            ),
            left: startX + config.curveWidth - 1,
            height: effectiveHeight,
          },
          startDot: { x: startX, y: startY },
          endDot: { x: endX, y: endY },
        };
      }

      setConnections([connection]);
      setSvgDimensions({
        width: containerRect.width,
        height: containerRect.height,
      });
    };

    if (cardRef?.current) {
      calculateConnections();
      const observer = new ResizeObserver(() => {
        requestAnimationFrame(calculateConnections);
      });

      if (containerRef.current) observer.observe(containerRef.current);
      if (context.chapterDetailsRef?.current) observer.observe(context.chapterDetailsRef?.current);
      if (cardRef.current) observer.observe(cardRef.current);

      let scrollTimeout: NodeJS.Timeout;
      let resizeTimeout: NodeJS.Timeout;

      const handleScroll = () => {
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => {
          requestAnimationFrame(calculateConnections);
        }, 16);
      };

      const handleResize = () => {
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(() => {
          requestAnimationFrame(calculateConnections);
        }, 16);
      };

      window.addEventListener('scroll', handleScroll, { passive: true });
      window.addEventListener('resize', handleResize, { passive: true });

      return () => {
        observer.disconnect();
        clearTimeout(scrollTimeout);
        clearTimeout(resizeTimeout);
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [cardRef?.current]);

  if (!selectedExamData) return null;
  const shouldShowConnections = Boolean(context.chapterDetailsRef?.current);
  const contextValue = {
    ...context,
    understandingRef: cardRef,
    chapterDetailsRef: context.chapterDetailsRef,
  };

  const isRevisionView = location.pathname.includes('revision');
  const isProgressDetailsView = location.pathname.includes('details/progress/details');

  const handleRevisionClick = () => {
    navigate(
      `${prefix}/reports/${studentId}/chapter/exam-status/${examId}/details/progress/revision`,
    );
  };

  return (
    <ConnectionContext.Provider value={contextValue}>
      <div className='w-full relative pb-20 md:pb-0' ref={containerRef}>
        <div
          className={`flex md:gap-20 relative z-0 ${isProgressDetailsView ? 'w-[1700px] ' : ''}`}
        >
          {!(isRevisionView && isMobileScreen) && (
            <div className='flex flex-col min-w-full md:min-w-fit w-full md:w-[320px]'>
              <div className='mb-3 md:mb-0'>
                {isMobileScreen ? (
                  <BackHeader
                    title={`Understanding & Progress of ${selectedExamData?.examName} Syllabus Chapters`}
                    route={`${prefix}/reports/${studentId}/chapter/exam-status/${examId}/details`}
                  />
                ) : (
                  <SectionHeader
                    text={`Understanding & Progress of ${selectedExamData?.examName} Syllabus Chapters`}
                    textColor='semantic-orange-800'
                    backgroundColor='from-[#EFDD81] to-[#F2E6AE]'
                  />
                )}
              </div>
              {progressData && (
                <div className='w-full md:min-w-[385px]' ref={cardRef}>
                  <UnderstandingAndProgressCard
                    data={progressData}
                    defaultSubject={Object.keys(progressData)[0]}
                    onClick={handleRevisionClick}
                    onUnderstaningCardClick={() => {
                      navigate(
                        `${prefix}/reports/${studentId}/chapter/exam-status/${examId}/details/progress/details`,
                      );
                    }}
                    setShowMobileFooter={setShowMobileFooter}
                  />
                </div>
              )}
            </div>
          )}
          <div className='transition-opacity duration-300 w-full'>
            <Outlet />
          </div>
        </div>
        <div className='absolute top-0 left-0 w-full h-full pointer-events-none'>
          {shouldShowConnections && (
            <ConnectionLines connections={connections} svgDimensions={svgDimensions} />
          )}
        </div>

        {/* Revision */}
        {isMobileScreen && !isRevisionView && showMobileFoorter && (
          <div className='fixed bottom-0 left-0 right-0 p-4 bg-white border-t border-gray-200 shadow-lg z-10'>
            <MobileFooter
              text={`View ${selectedExamData?.examName} Revision Plan`}
              onClick={() =>
                navigate(
                  `${prefix}/reports/${studentId}/chapter/exam-status/${examId}/details/progress/revision`,
                )
              }
              iconUrl={RightAngularIcon}
            />
          </div>
        )}
      </div>
    </ConnectionContext.Provider>
  );
};

export default ExamChapterWiseUnderstandingProgress;
