import { DeviceType, IGetDeviceStatus, StatusInfo } from 'types';

// Updated getDeviceStatus function with better state management

export const isTabletDevice = () => {
  return (
    /iPad|Android/.test(navigator.userAgent) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  );
};

export const getDeviceStatus = (
  type: 'Camera' | 'Microphone' | 'Speaker',
  {
    isLoading,
    hasPermission,
    isWorking,
    audioLevel = 0,
    deviceStatus,
    isMicrophoneWorking,
    hasStream,
  }: IGetDeviceStatus,
): StatusInfo => {
  // Loading state check
  if (isLoading) {
    return {
      message: `Getting your ${type.toLowerCase()} ready...`,
      color: 'bg-primary-800',
      textColor: 'text-primary-400',
      icon: 'loading',
    };
  }

  // Handle device-specific permission states
  if (type !== 'Speaker' && !hasPermission) {
    return {
      message: type === 'Camera' ? 'Cam access denied' : 'Mic access denied',
      color: 'bg-semantic-red-900',
      textColor: 'text-semantic-red-400',
      icon: 'error',
    };
  }

  // Microphone-specific states
  if (type === 'Microphone') {
    if (deviceStatus && !hasStream) {
      return {
        message: 'Initializing microphone...',
        color: 'bg-primary-800',
        textColor: 'text-primary-400',
        icon: 'loading',
      };
    }

    if (!deviceStatus && hasPermission) {
      return {
        message: 'Mic is turned off',
        color: 'bg-semantic-orange-900',
        textColor: 'text-semantic-orange-400',
        icon: 'warning',
      };
    }

    if (deviceStatus && hasStream) {
      if (isMicrophoneWorking || audioLevel > 0 || isWorking || hasPermission) {
        return {
          message: 'Mic is working',
          color: 'bg-semantic-green-900',
          textColor: 'text-semantic-green-400',
          icon: 'success',
        };
      }
      if (!isWorking && !isMicrophoneWorking && audioLevel === 0) {
        return {
          message: 'Microphone not working',
          color: 'bg-semantic-orange-900',
          textColor: 'text-semantic-orange-400',
          icon: 'error',
        };
      }
      return {
        message: 'Checking mic...',
        color: 'bg-primary-800',
        textColor: 'text-primary-400',
        icon: 'loading',
      };
    }
  }

  // Camera-specific states
  if (type === 'Camera') {
    if (!deviceStatus && hasPermission) {
      return {
        message: 'Camera is turned off',
        color: 'bg-semantic-orange-900',
        textColor: 'text-semantic-orange-400',
        icon: 'warning',
      };
    }

    if (hasPermission) {
      if (!isWorking && !deviceStatus) {
        return {
          message: 'Camera not working',
          color: 'bg-semantic-red-900',
          textColor: 'text-semantic-red-400',
          icon: 'error',
        };
      }

      // Give me the camera access denied message
      if (!deviceStatus && !hasPermission) {
        return {
          message: 'Camera access denied',
          color: 'bg-semantic-red-900',
          textColor: 'text-semantic-red-400',
          icon: 'error',
        };
      }

      if (isWorking && deviceStatus) {
        return {
          message: 'Camera is working',
          color: 'bg-semantic-green-900',
          textColor: 'text-semantic-green-400',
          icon: 'success',
        };
      }
    }
  }

  // Speaker-specific states
  if (type === 'Speaker') {
    if (!isWorking) {
      return {
        message: 'No speaker detected',
        color: 'bg-semantic-red-900',
        textColor: 'text-semantic-red-400',
        icon: 'error',
      };
    }

    return {
      message: 'Speaker is ready',
      color: 'bg-semantic-green-900',
      textColor: 'text-semantic-green-400',
      icon: 'success',
    };
  }

  // Default states based on type and permissions
  return {
    message: hasPermission
      ? `${type} is ready`
      : `${type === 'Camera' ? 'Cam access denied' : 'Mic access denied'}`,
    color: hasPermission ? 'bg-semantic-green-900' : 'bg-semantic-red-900',
    textColor: hasPermission ? 'text-semantic-green-400' : 'text-semantic-red-400',
    icon: hasPermission ? 'success' : 'error',
  };
};

export const getSystemInstructions = (device: 'camera' | 'microphone', deviceType: DeviceType) => {
  const deviceWord = device === 'camera' ? 'Camera' : 'Mic';

  switch (deviceType) {
    case 'Windows':
      return [
        'Open Settings from Start menu',
        `Go to Privacy & security → ${deviceWord}`,
        `Turn ON both "${deviceWord} access" and "Let apps access"`,
        'Come back and click Refresh',
      ];

    case 'macOS':
      return [
        'Click Apple menu → System Preferences',
        'Open Security & Privacy → Privacy tab',
        `Find "${deviceWord}" and allow browser access`,
        'Come back and click Refresh',
      ];

    case 'iPadOS':
      return [
        'Open Settings on your iPad',
        'Find and tap your browser (Safari)',
        `Tap ${deviceWord} and turn it ON`,
        'Go back and tap Refresh',
      ];

    case 'iOS':
      return [
        'Open Settings on your iPhone',
        'Find and tap your browser name',
        `Turn ON ${deviceWord} access`,
        'Go back and tap Refresh',
      ];

    case 'Android':
      return [
        'Open Settings → Privacy',
        `Tap Permissions → ${deviceWord}`,
        'Find your browser and select "Allow"',
        'Go back and tap Refresh',
      ];

    default:
      return [
        'Open device Settings',
        'Find Privacy or Security settings',
        `Enable ${deviceWord} for your browser`,
        'Come back and click Refresh',
      ];
  }
};

export const getCameraInUseInstructions = (deviceType: DeviceType) => {
  switch (deviceType) {
    case 'Windows':
      return [
        'Close other apps that use camera (Zoom, Teams, Google Meet)',
        'Open Task Manager and close any camera apps',
        'Close all browser windows and open them again',
        'If nothing works, restart your computer',
      ];
    case 'macOS':
      return [
        'Go to System Settings > Privacy > Camera and check which apps are using it',
        'Close all other apps that might be using your camera',
        'Close all browser windows and open them again',
        'If nothing works, restart your computer',
      ];
    default:
      return [
        'Close all other apps that use your camera',
        'Check your device settings for any apps using the camera',
        'Close all browser windows and open them again',
        'If nothing works, restart your device',
      ];
  }
};
