import { ImprovementDownIcon, ImprovementSameIcon, ImprovementUpIcon } from 'assets/svg';
import {
  CORRECT,
  CORRECT_STARTCASE,
  DRAFT,
  EVALUATION,
  GAP_HI,
  GAP_LI,
  GAP_NI,
  GAP_SND,
  INCORRECT_STARTCASE,
  CANT_EVALUATE_GAPS,
  REGULAR_CLASS,
  REGULAR_EVAL_CLASS,
  statusToIdMap,
  TEACH,
  TEACH_EVALUATION,
  NOT_ASSESSED,
  NOT_EVALUATED,
  PRESENT,
  QUESTION_CATEGORY_ID,
  STUDENT_COULD_NOT_SOLVE,
  NOT_ATTEMPTED_STARTCASE,
  REGULAR_CLASS_TYPES,
  INTERVENTION_CLASS_TYPES,
  IMPROVED_VARIANT,
  SAME_AS_BEFORE_VARIANT,
  DROP_IN_PERFORMANCE_VARIANT,
} from 'configs/reports';
import { groupBy, orderBy, startCase, uniqBy } from 'lodash';
import {
  CategoryEntity,
  ChapterClassEntity,
  ChapterEntity,
  Chapters,
  ChapterWiseData,
  ExamCategoryEntity,
  ExamChapterUnderstanding,
  ExamData,
  ExamQuestionEntity,
  ImprovementVariant,
  InterventionMetricsEntity,
  IPostIntervention,
  IPreIntervention,
  IStudentRating,
  PracticeSheetAssigned,
  PracticeSheetAssignPayload,
  PracticeSheetEntity,
  practiceSheetResponseDoc,
  QcGap,
  QcGapData,
  QuestionCategoryEntity,
  QuestionCategorySummary,
  QuestionEntity,
  RevisionPlans,
  StatusKey,
  StatusKeyIdenf,
  StepLevelAnswers,
  SubjectData,
} from 'types/reports';

export const getChaptersEntity = (reportData: ChapterWiseData[] | undefined) => {
  if (!reportData?.length) {
    return [];
  }

  const formatDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  };

  const reportDataByChapter = reportData?.reduce(
    (acc: ChapterEntity[], chapter: ChapterWiseData) => {
      const { chapterId, chapterName, subjectId, classes = [], practiceSheets } = chapter;

      if (chapterId && !classes?.length) {
        return acc;
      }

      const sortedClasses = [...classes].sort(
        (a, b) => new Date(a?.classDate || 0).getTime() - new Date(b?.classDate || 0).getTime(),
      );

      if (!sortedClasses?.length) return acc;

      const firstClassDate = new Date(sortedClasses?.[0]?.classDate);
      const lastClassDate = new Date(sortedClasses?.[sortedClasses.length - 1]?.classDate);

      const dateRange = `${formatDate(firstClassDate)} - ${formatDate(lastClassDate)}`;

      const totalNotebooks = classes.reduce((sum, cls) => sum + cls?.questions?.notebooks, 0);
      const totalPollEvaluations = classes.reduce((sum, cls) => sum + cls?.questions?.poll, 0);

      const totalPracticeSheetAssigned = Array.from(
        new Set(practiceSheets?.map((practice) => practice?.practiceSheetId) || []),
      )?.length;

      acc.push({
        chapterId,
        chapterName,
        subjectId,
        dateRange,
        notebookEvaluations: totalNotebooks,
        pollEvaluation: totalPollEvaluations,
        practiceSheets: totalPracticeSheetAssigned || 0,
        lastClassDate,
        assignedPracticeSheet: practiceSheets || [],
        reportStatus: chapter?.reportStatus,
      });

      return acc;
    },
    [] as ChapterEntity[],
  );

  const filteredChapters = reportDataByChapter.filter(
    (chapter) => chapter.notebookEvaluations > 0 || chapter.pollEvaluation > 0,
  );

  return filteredChapters.sort(
    (a, b) => new Date(b.lastClassDate).getTime() - new Date(a.lastClassDate).getTime(),
  );
};

export const getChapterClassEntity = (reportData: ChapterWiseData[] | undefined) => {
  if (!reportData) return [];

  const chapterClassArray: ChapterClassEntity[] = [];

  const formatDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  };

  reportData.forEach((chapter) => {
    const { chapterId, subjectId, studentId, classes = [] } = chapter;

    if (!classes?.length) return;

    const sortedClasses = [...classes].sort(
      (a, b) => new Date(a?.classDate || 0).getTime() - new Date(b?.classDate || 0).getTime(),
    );

    if (!sortedClasses?.length) return;

    sortedClasses?.forEach((classItem, index) => {
      const { classId, classDate, classType, questions, attendance } = classItem;

      if (!classId || !classDate || !classType) return;

      let type = '';
      if (classType?.includes(REGULAR_CLASS) && classType?.includes(REGULAR_EVAL_CLASS)) {
        type = TEACH_EVALUATION;
      } else if (classType?.includes(REGULAR_CLASS)) {
        type = TEACH;
      } else if (classType?.includes(REGULAR_EVAL_CLASS)) {
        type = EVALUATION;
      }

      const chapterClassEntity: ChapterClassEntity = {
        id: `${index}-${classId}`,
        chapterClassId: `${chapterId}-${classId}`,
        chapterId,
        subjectId,
        studentId: studentId?.toString(),
        date: formatDate(new Date(classDate)),
        type,
        attendance: attendance ? startCase(attendance) : PRESENT,
        notebookEvaluations: questions?.notebooks || 0,
        missedNotebook: questions?.missedNotebook || false,
        missedPoll: questions?.missedPoll || false,
        pollEvaluations: questions?.poll || 0,
      };

      chapterClassArray.push(chapterClassEntity);
    });
  });

  return chapterClassArray;
};

export const getStatusClasses = (id: number | null | undefined) => {
  switch (id) {
    case 1:
      return 'before:bg-semantic-green-500';
    case 2:
      return 'before:bg-semantic-orange-300';
    case 3:
      return 'before:bg-semantic-red-500';
    case 4:
      return 'before:bg-neutral-400';
    default:
      return 'before:bg-semantic-green-500';
  }
};
export const getCountBadgeStatus = (id: number | null | undefined) => {
  switch (id) {
    case 1:
      return 'bg-semantic-green-100 text-semantic-green-800';
    case 2:
      return 'bg-semantic-orange-100 text-semantic-orange-800';
    case 3:
      return 'bg-semantic-red-100 text-semantic-red-800';
    case 4:
      return 'bg-neutral-100 text-neutral-800';
    default:
      return 'bg-semantic-green-100 text-semantic-green-800';
  }
};

export const getPracticeSheetEntity = (
  practiceEvalData: practiceSheetResponseDoc[],
  assignedPracticeSheet: PracticeSheetAssignPayload[] | PracticeSheetAssigned[],
): PracticeSheetEntity[] => {
  if (!practiceEvalData?.length || !assignedPracticeSheet?.length) {
    return [];
  }

  const practiceSheets: PracticeSheetEntity[] = assignedPracticeSheet?.map((practice) => {
    const { studentSubmissionStatus, practiceSheetId, assignedOn } = practice || {};

    const practiceSheetEntity: PracticeSheetEntity = {
      practiceSheetId,
      status: studentSubmissionStatus,
      assignedOn: assignedOn instanceof Date ? assignedOn.toISOString() : assignedOn,
      evaluations: [],
    };

    // Find all evaluation data related to the current practiceSheetId
    const evalData = practiceEvalData?.filter((e) => e?.practiceSheetId === practiceSheetId);

    if (evalData?.length > 0) {
      practiceSheetEntity.evaluations = evalData?.map((evl: practiceSheetResponseDoc) => {
        const evaluationName =
          assignedPracticeSheet.find(
            (e) => e?.practiceSheetId === practiceSheetId && evl?.evaluationId === e?.evaluationId,
          )?.evaluationName || '';

        return {
          evaluationId: evl?.evaluationId,
          evaluationName,
          total: evl?.totalQuestions || 0,
          completed: evl?.attemptedQuestion || 0,
          questions:
            evl?.questions?.map((question) => ({
              questionId: question?.questionId,
              questionStatus: question?.questionStatus,
              score: question?.score,
            })) || [],
        };
      });
    }

    return practiceSheetEntity;
  });

  return practiceSheets;
};

const getUnderstandingStatus = (str: string) => {
  const statusMap = {
    GAP_NI: {
      text: 'Well understood',
      badgeColor: 'success',
      stars: 5,
      interventions: ['N/A'],
    },
    GAP_LI: {
      text: 'Understood but student makes silly mistakes',
      badgeColor: 'warning',
      stars: 4,
      interventions: ['Practice'],
    },
    GAP_HI_SND: {
      text: 'Requires further reinforcement',
      badgeColor: 'danger',
      stars: 2,
      interventions: ['Re-explain', 'Practice'],
    },
    NOT_ASSESSED: {
      text: 'Categories not assessed in class',
      badgeColor: 'secondary',
      stars: 0,
      interventions: ['N/A'],
    },
  };

  return (
    statusMap[str as StatusKey] || {
      text: str,
      badgeColor: 'secondary',
      stars: 0,
      interventions: [],
    }
  );
};

export const getQuestionCategoriesEntity = (reportData: ChapterWiseData[] | undefined) => {
  if (!reportData) return { questionCategoryArray: [], connectionData: [] };

  const questionCategoryArray: QuestionCategoryEntity[] = [];
  const connectionData: QuestionCategoryEntity[] = [];

  reportData?.forEach((chapter) => {
    const { chapterId, questionCategorySummary } = chapter;

    if (!questionCategorySummary?.length) return;

    const statusGroups: {
      GAP_NI: QuestionCategorySummary[];
      GAP_LI: QuestionCategorySummary[];
      GAP_HI_SND: QuestionCategorySummary[];
      NOT_ASSESSED: QuestionCategorySummary[];
    } = {
      GAP_NI: [],
      GAP_LI: [],
      GAP_HI_SND: [],
      NOT_ASSESSED: [],
    };

    // Group question categories by their `qcGapStatus`
    questionCategorySummary?.forEach((qc: QuestionCategorySummary) => {
      if (qc?.qcGapStatus === GAP_NI) statusGroups.GAP_NI.push(qc);
      else if (qc?.qcGapStatus === GAP_LI) statusGroups.GAP_LI.push(qc);
      else if (qc?.qcGapStatus === GAP_HI || qc?.qcGapStatus === GAP_SND)
        statusGroups.GAP_HI_SND.push(qc);
      else if (CANT_EVALUATE_GAPS.includes(qc?.qcGapStatus)) statusGroups.NOT_ASSESSED.push(qc);
    });

    Object.entries(statusGroups).forEach(([statusKey, group]) => {
      let isNotAssessed = false;
      let finalGroup;
      const understanding = getUnderstandingStatus(statusKey);
      if (statusKey === NOT_ASSESSED) {
        finalGroup = uniqBy(group as QuestionCategorySummary[], QUESTION_CATEGORY_ID);
        isNotAssessed = true;
      } else {
        finalGroup = group;
      }
      const id = isNotAssessed ? 4 : statusToIdMap[statusKey as StatusKeyIdenf] || 4;

      let categoriesCovered =
        finalGroup
          ?.map((category) => ({
            id: category?.questionCategoryId,
            name: category?.questionCategoryName,
            categoryNumber: category?.categoryNumber || 0,
            status: category?.qcGapStatus || '',
          }))
          ?.filter(Boolean) || [];

      const categoriesCount = finalGroup?.length || 0;
      const totalCategoriesCount = questionCategorySummary?.length;

      categoriesCovered = orderBy(categoriesCovered, ['categoryNumber'], ['asc']);

      // Push data into `questionCategoryArray`
      if (finalGroup?.length > 0) {
        questionCategoryArray.push({
          chapterId,
          id,
          text: understanding?.text,
          categoriesCovered,
          categoriesCount,
          totalCategoriesCount,
          rating: understanding?.stars,
          interventionTypes: understanding?.interventions,
          color: understanding?.badgeColor,
        });
      }

      // Push connection data for non-NOT_ASSESSED statuses
      if (!isNotAssessed && finalGroup?.length > 0) {
        connectionData.push({
          id,
          text: understanding?.text,
          categoriesCount,
          totalCategoriesCount: questionCategorySummary?.length || 0,
          rating: understanding?.stars,
          interventionTypes: understanding?.interventions,
        });
      }
    });
  });
  return { questionCategoryArray, connectionData };
};

export const getCategoryEntity = (
  qcSummaryOfThisChapter: QuestionCategorySummary[] | undefined,
  stepLevelAnswers: StepLevelAnswers[],
  chapterId: string,
) => {
  if (!stepLevelAnswers?.length) return [];

  const categoryEntity: CategoryEntity[] = [];

  stepLevelAnswers.forEach((stepLevelAnswer) => {
    const { questionsScoreForEachSteps, questionCategoryId, questionCategoryName } =
      stepLevelAnswer;
    if (!questionsScoreForEachSteps?.length) return;
    const groupedByQuestionId = groupBy(questionsScoreForEachSteps, 'questionId');
    const totalNoOfQuestions = Object.keys(groupedByQuestionId)?.length;
    let questionsAnsweredCorrectly = 0;
    let questionNotAttempted = 0;
    for (const questionId in groupedByQuestionId) {
      if (groupedByQuestionId?.[questionId]?.every((record) => record?.questionScore === CORRECT)) {
        questionsAnsweredCorrectly++;
      }

      if (
        groupedByQuestionId?.[questionId]?.every(
          (record) => record.questionScore === STUDENT_COULD_NOT_SOLVE,
        )
      ) {
        questionNotAttempted++;
      }
    }

    const categoryNumber =
      qcSummaryOfThisChapter?.find((record) => record?.questionCategoryId === questionCategoryId)
        ?.categoryNumber || 0;

    categoryEntity.push({
      chapterId,
      categoryId: questionCategoryId,
      title: questionCategoryName,
      categoryNumber,
      questionsAnsweredCorrectly: questionsAnsweredCorrectly,
      questionNotAttempted: questionNotAttempted,
      totalQuestions: totalNoOfQuestions,
      incorrectAnswer: totalNoOfQuestions - (questionsAnsweredCorrectly + questionNotAttempted),
    });
  });

  const sortedCategoryEntities = orderBy(categoryEntity, ['categoryNumber'], ['asc']);

  return sortedCategoryEntities;
};

export const getExamProcessedCategoryEntity = (
  chapterCategories: ExamChapterUnderstanding[],
  stepLevelAnswers: StepLevelAnswers[],
  selectedChapter: Chapters,
) => {
  if (!stepLevelAnswers?.length) return [];
  const { chapterId, chapterName } = selectedChapter;

  const categoryEntity: ExamCategoryEntity[] = [];

  chapterCategories.forEach((category) => {
    const { questionCategoryId, questionCategoryName, type, categoryNumber } = category;
    const relevantAnswers = stepLevelAnswers.find(
      (answer) => answer.questionCategoryId === questionCategoryId && answer.type == type,
    );

    const { questionsScoreForEachSteps = [] } = relevantAnswers || {};

    const groupedByQuestionId = groupBy(questionsScoreForEachSteps, 'questionId');
    const totalNoOfQuestions = Object.keys(groupedByQuestionId)?.length;
    let questionsAnsweredCorrectly = 0;
    let totalSteps = 0;
    let correctSteps = 0;

    for (const questionId in groupedByQuestionId) {
      if (groupedByQuestionId?.[questionId]?.every((record) => record?.questionScore === CORRECT)) {
        questionsAnsweredCorrectly++;
      }
      totalSteps += groupedByQuestionId?.[questionId].length;
      correctSteps += groupedByQuestionId?.[questionId].filter(
        (record) => record?.stepScore === CORRECT,
      ).length;
    }

    categoryEntity.push({
      questionCategoryId: questionCategoryId,
      questionCategoryName: questionCategoryName || '',
      questionsAnswered: questionsAnsweredCorrectly,
      totalQuestions: totalNoOfQuestions || 0,
      correctSteps: correctSteps || 0,
      totalSteps: totalSteps || 0,
      chapterId,
      chapterName,
      categoryNumber,
    });
  });

  return categoryEntity;
};
export const getExamProcessedQuestionEntity = (
  chapterCategories: ExamChapterUnderstanding[],
  stepLevelAnswers: StepLevelAnswers[],
  selectedChapter: Chapters,
) => {
  if (!stepLevelAnswers?.length) return [];
  const { chapterId } = selectedChapter;

  const questionEntity: ExamQuestionEntity[] = [];

  chapterCategories.forEach((category) => {
    const { questionCategoryId, type, categoryNumber } = category;
    const relevantAnswers = stepLevelAnswers.find(
      (answer) => answer.questionCategoryId === questionCategoryId && answer.type == type,
    );

    if (!relevantAnswers) return;
    const { questionsScoreForEachSteps, questionCategoryName } = relevantAnswers;
    if (!questionsScoreForEachSteps?.length) return;

    const groupedByQuestionId = groupBy(questionsScoreForEachSteps, 'questionId');
    Object.entries(groupedByQuestionId).forEach(([questionId, steps]) => {
      // Derive the question's status (Correct if all steps have "correct" questionScore)
      const isCorrect = steps?.every((step) => step?.questionScore === CORRECT);
      const isDraft = steps?.some((step) => step?.questionScore === DRAFT);
      const studentCouldNotSolve = steps?.some(
        (step) => step?.questionScore === STUDENT_COULD_NOT_SOLVE,
      );
      const status = isCorrect
        ? CORRECT_STARTCASE
        : studentCouldNotSolve
          ? NOT_ATTEMPTED_STARTCASE
          : isDraft
            ? NOT_EVALUATED
            : INCORRECT_STARTCASE;

      // Create stepsRequired array with stepName and stepStatus
      const stepsRequired = steps
        ?.map((step) => ({
          stepId: step?.stepId,
          step: step?.stepName,
          status: step?.stepScore,
          stepStatus: step?.stepStatus,
        }))
        ?.sort((a, b) => a.stepId.localeCompare(b.stepId));

      // Collect notebookImages from all steps
      const notebookImages = steps?.[0]?.image || [];

      // Derive question string from questionDescription
      const question = steps?.[0]?.questionDescription || '';

      // Push the question entity to the result
      questionEntity.push({
        questionId,
        chapterId,
        categoryId: questionCategoryId,
        categoryName: questionCategoryName,
        categoryNumber,
        question,
        stepsRequired,
        status,
        notebookImages,
      });
    });
  });

  return questionEntity;
};
export const getQuestionsEntity = (
  qcSummaryOfThisChapter: QuestionCategorySummary[] | undefined,
  stepLevelAnswers: StepLevelAnswers[],
  chapterId: string,
  interventionQcGaps?: QcGap[],
  interventionQcIds?: string[],
) => {
  if (!stepLevelAnswers?.length) return [];

  const questionsEntity: QuestionEntity[] = [];
  let iteratedStepLevelAnswers;

  if (interventionQcIds?.length) {
    iteratedStepLevelAnswers = stepLevelAnswers?.filter((step) =>
      INTERVENTION_CLASS_TYPES?.includes(step.type),
    );
  } else {
    iteratedStepLevelAnswers = stepLevelAnswers;
  }

  iteratedStepLevelAnswers?.forEach((stepLevelAnswer) => {
    const { questionsScoreForEachSteps, questionCategoryId, questionCategoryName } =
      stepLevelAnswer;
    if (!questionsScoreForEachSteps?.length) return;

    const groupedByQuestionId = groupBy(questionsScoreForEachSteps, 'questionId');

    let categoryNumber = 0;
    if (qcSummaryOfThisChapter) {
      categoryNumber =
        qcSummaryOfThisChapter?.find((record) => record?.questionCategoryId === questionCategoryId)
          ?.categoryNumber || 0;
    } else if (interventionQcIds?.length) {
      categoryNumber =
        interventionQcGaps?.find((record) => record?.questionCategoryId === questionCategoryId)
          ?.categoryNumber || 0;
    }

    Object.entries(groupedByQuestionId).forEach(([questionId, steps]) => {
      // Derive the question's status (Correct if all steps have "correct" questionScore)
      const isCorrect = steps?.every((step) => step?.questionScore === CORRECT);
      const isDraft = steps?.some((step) => step?.questionScore === DRAFT);
      const studentCouldNotSolve = steps?.some(
        (step) => step?.questionScore === STUDENT_COULD_NOT_SOLVE,
      );
      const status = isCorrect
        ? CORRECT_STARTCASE
        : studentCouldNotSolve
          ? NOT_ATTEMPTED_STARTCASE
          : isDraft
            ? NOT_EVALUATED
            : INCORRECT_STARTCASE;

      // Create stepsRequired array with stepName and stepStatus
      const stepsRequired = steps
        ?.map((step) => ({
          stepId: step?.stepId,
          step: step?.stepName,
          status: step?.stepScore,
          stepStatus: step?.stepStatus,
        }))
        ?.sort((a, b) => a.stepId.localeCompare(b.stepId));

      // Collect notebookImages from all steps
      const notebookImages = steps?.[0]?.image || [];

      // Derive question string from questionDescription
      const question = steps?.[0]?.questionDescription || '';

      // Push the question entity to the result
      questionsEntity.push({
        questionId,
        chapterId,
        categoryId: questionCategoryId,
        categoryName: questionCategoryName,
        categoryNumber,
        question,
        stepsRequired,
        status,
        notebookImages,
      });
    });
  });

  return questionsEntity;
};

export const getDotColor = (evaluationId: number) => {
  switch (evaluationId) {
    case 1:
      return 'var(--semantic-green-600)';
    case 2:
      return 'var(--orange-400)';
    case 3:
      return 'var(--semantic-red-600)';
    case 4:
      return 'var(--neutral-500)';
    default:
      return '#000000';
  }
};

export const getHeaderBgColor = (id: number) => {
  switch (id) {
    case 1:
      return 'from-[#B6E6A0] to-[#CEF1BF]';
    case 2:
      return 'from-[#EDBE97] to-[#FBE2C6]';
    case 3:
      return 'from-[#F4B6B6] to-[#FBDCDC]';
    case 4:
      return 'from-neutral-400 to-neutral-200';
    default:
      return 'from-green-400 to-green-200';
  }
};

export const getHeaderTextColor = (id: number) => {
  switch (id) {
    case 1:
      return 'semantic-green-900';
    case 2:
      return 'orange-950';
    case 3:
      return 'semantic-red-900';
    case 4:
      return 'neutral-900';
    default:
      return '#000000';
  }
};

export const getHeaderTextInterventionColor = (variant: ImprovementVariant) => {
  switch (variant) {
    case 'improved':
      return 'semantic-green-800';
    case 'same-as-before':
      return 'orange-900';
    default:
      return 'semantic-red-800';
  }
};

export const getHeaderInterventionBgColor = (variant: ImprovementVariant) => {
  switch (variant) {
    case 'improved':
      return 'from-[#B6E6A0] to-[#CEF1BF]';
    case 'same-as-before':
      return 'from-[#EDBE97] to-[#FBE2C6]';
    default:
      return 'from-[#F4B6B6] to-[#FBDCDC]';
  }
};

export const getDotInterventionColor = (variant: ImprovementVariant) => {
  switch (variant) {
    case 'improved':
      return 'var(--semantic-green-600)';
    case 'same-as-before':
      return 'var(--orange-500)';
    default:
      return 'var(--red-600)';
  }
};

export const processInterventionData = (qcGaps: QcGap[]): IPreIntervention | IPostIntervention => {
  return qcGaps.reduce(
    (accumulator: IPreIntervention, curr: QcGap) => {
      const category = {
        questionCategoryId: curr.questionCategoryId,
        questionCategoryName: curr.questionCategoryName,
        categoryNumber: curr.categoryNumber,
        qcGapStatus: curr.qcGapStatus,
      };

      accumulator.totalCategories++;

      switch (curr.qcGapStatus) {
        case GAP_NI:
          accumulator.ni.push(category);
          break;
        case GAP_LI:
          accumulator.li.push(category);
          break;
        case GAP_HI:
          accumulator.hi.push(category);
          break;
        case GAP_SND:
          accumulator.snd.push(category);
          break;
        default:
          accumulator.not_assessed.push(category);
      }

      return accumulator;
    },
    { ni: [], li: [], hi: [], snd: [], not_assessed: [], totalCategories: 0 } as
      | IPreIntervention
      | IPostIntervention,
  );
};

export const processInterventionQcCompData = (
  interventionQcIds: string[],
  regularQcGaps: QcGap[],
  interventionQcGaps: QcGap[],
  chapterId: string,
  stepLevelAnswer: StepLevelAnswers[],
) => {
  const calculateRating = (qcGap?: QcGap): IStudentRating => {
    switch (qcGap?.qcGapStatus) {
      case GAP_NI:
        return 5;
      case GAP_LI:
        return 3;
      case GAP_HI:
      case GAP_SND:
        return 2;
      default:
        return 0;
    }
  };

  const calculateMetrics = (qcId: string, classTypes: string[]): InterventionMetricsEntity => {
    const stepDetails = stepLevelAnswer.find(
      (sla) => sla.questionCategoryId === qcId && classTypes.includes(sla.type),
    );

    if (!stepDetails)
      return { questionsAsked: 0, questionsAnsweredCorrectly: 0, correctSteps: 0, totalSteps: 0 };

    const groupedByQuestionId = groupBy(stepDetails.questionsScoreForEachSteps, 'questionId');
    const totalNoOfQuestions = Object.keys(groupedByQuestionId).length;

    let questionsAnsweredCorrectly = 0;
    let correctSteps = 0;
    let totalSteps = 0;

    Object.values(groupedByQuestionId).forEach((records) => {
      if (records.every((record) => record?.questionScore === CORRECT)) {
        questionsAnsweredCorrectly++;
      }
      totalSteps += records.length;
      correctSteps += records.filter((record) => record?.stepScore === CORRECT).length;
    });

    return {
      questionsAsked: totalNoOfQuestions,
      questionsAnsweredCorrectly,
      correctSteps,
      totalSteps,
    };
  };

  return orderBy(
    interventionQcIds.map((qcId) => {
      const regularQcGap = regularQcGaps.find((gap) => gap.questionCategoryId === qcId);
      const interventionQcGap = interventionQcGaps.find((gap) => gap.questionCategoryId === qcId);

      const preRating = calculateRating(regularQcGap);
      const postRating = calculateRating(interventionQcGap);

      return {
        chapterId,
        categoryId: interventionQcGap?.questionCategoryId || '',
        categoryTitle: interventionQcGap?.questionCategoryName || '',
        categoryNumber: interventionQcGap?.categoryNumber || 0,
        preRating,
        postRating,
        remarkMessage:
          postRating > preRating
            ? IMPROVED_VARIANT
            : postRating === preRating
              ? SAME_AS_BEFORE_VARIANT
              : DROP_IN_PERFORMANCE_VARIANT,
        preIntervention: calculateMetrics(qcId, REGULAR_CLASS_TYPES),
        postIntervention: calculateMetrics(qcId, INTERVENTION_CLASS_TYPES),
      };
    }),
    ['categoryNumber'],
    ['asc'],
  );
};

export const getImprovementIcon = (variant: ImprovementVariant) => {
  switch (variant) {
    case IMPROVED_VARIANT:
      return ImprovementUpIcon;
    case DROP_IN_PERFORMANCE_VARIANT:
      return ImprovementDownIcon;
    default:
      return ImprovementSameIcon;
  }
};

export const getStatusLabel = (variant: ImprovementVariant): string => {
  switch (variant) {
    case IMPROVED_VARIANT:
      return 'Improved';
    case DROP_IN_PERFORMANCE_VARIANT:
      return 'Drop in performance';
    default:
      return 'Same as before';
  }
};

export const processProgressData = (input: QcGapData[]) => {
  const subjectMap: Record<string, SubjectData> = {};

  input.forEach((entry) => {
    const {
      subjectId,
      subjectName,
      chapterId,
      chapterName,
      qcGapStatus,
      questionCategoryId,
      questionCategoryName,
      type,
      categoryNumber,
    } = entry;

    if (!subjectMap[subjectId]) {
      subjectMap[subjectId] = { label: subjectName, chapters: [] };
    }

    let chapter = subjectMap[subjectId].chapters.find((ch) => ch.chapterId === chapterId);

    if (!chapter) {
      chapter = {
        chapterId,
        chapterName,
        understanding: 0,
        categories: 0,
        stats: {
          ni: [],
          li: [],
          hi: [],
          snd: [],
          not_assessed: [],
          cant_evaluate: [],
        },
      };
      subjectMap[subjectId].chapters.push(chapter);
    }

    chapter.categories += 1;

    if (qcGapStatus.includes('_ni')) {
      chapter.stats?.ni.push({ qcGapStatus, questionCategoryId, questionCategoryName, type, categoryNumber });
    } else if (qcGapStatus.includes('_li')) {
      chapter.stats?.li.push({ qcGapStatus, questionCategoryId, questionCategoryName, type, categoryNumber });
    } else if (qcGapStatus.includes('_hi')) {
      chapter.stats?.hi.push({ qcGapStatus, questionCategoryId, questionCategoryName, type, categoryNumber });
    } else if (qcGapStatus.includes('_snd')) {
      chapter.stats?.snd.push({ qcGapStatus, questionCategoryId, questionCategoryName, type, categoryNumber });
    } else {
      chapter.stats?.not_assessed.push({ qcGapStatus, questionCategoryId, questionCategoryName, type, categoryNumber });
    }
  });

  // Determine understanding based on percentage of NI responses
  Object.values(subjectMap).forEach((subject) => {
    subject.chapters.forEach((chapter) => {
      const counts = {
        ni: chapter.stats?.ni.length || 0,
        li: chapter.stats?.li.length || 0,
        hi: chapter.stats?.hi.length || 0,
        snd: chapter.stats?.snd.length || 0,
        not_assessed: chapter.stats?.not_assessed.length || 0,
      };

      // Calculate total responses and percentage of NI
      const totalQcCounts = Object.values(counts).reduce((sum, count) => sum + count, 0);

      // Set default understanding as 0 if no total qc counts is zero
      if (totalQcCounts === 0) {
        chapter.understanding = 0;
      } else {
        const niPercentage = (counts.ni / totalQcCounts) * 100;

        // Assign understanding based on NI percentage thresholds
        if (counts.ni === totalQcCounts) {
          chapter.understanding = 5; // 100% NI
        } else if (niPercentage >= 75) {
          chapter.understanding = 4; // 75-99% NI
        } else if (niPercentage >= 50) {
          chapter.understanding = 3; // 50-74% NI
        } else {
          chapter.understanding = 2; // <50% NI
        }
      }
    });
  });

  return subjectMap;
};

export const getExamWiseRevisionPlans = (
  exams: ExamData[] | null | undefined,
  revisionPlans: RevisionPlans[],
) => {
  if (!exams?.length || !revisionPlans?.length) return {};

  const sortedExams = [...exams].sort(
    (a, b) => new Date(a.examDate).getTime() - new Date(b.examDate).getTime(),
  );

  const examWisePlans: Record<string, RevisionPlans[]> = {};

  sortedExams.forEach((exam, index) => {
    const currentExamDate = new Date(exam.examDate);
    const nextExamDate = sortedExams[index + 1]
      ? new Date(sortedExams[index + 1].examDate)
      : new Date('9999-12-31');

    // Filter revision plans that fall between current exam and next exam
    const plansForExam = revisionPlans.filter((plan) => {
      const planDate = new Date(plan.date);
      return planDate >= currentExamDate && planDate < nextExamDate;
    });

    examWisePlans[exam.examId] = plansForExam;
  });

  return examWisePlans;
};
