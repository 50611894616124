interface BrowserInfo {
  name: string;
  version: string;
  isMobile: boolean;
  isTablet: boolean;
  isIpad: boolean;
}

export const getBrowserInformation = (): BrowserInfo => {
  const userAgent = navigator.userAgent.toLowerCase();
  const vendor = navigator.vendor?.toLowerCase() || '';
  let browserName = 'unknown';
  let browserVersion = 'unknown';

  // Check for mobile/tablet devices
  const isMobile = /mobile|android|webos|iphone|ipod|blackberry|iemobile|opera mini/i.test(
    userAgent,
  );
  const isTablet = /(tablet|ipad|playbook|silk)|(android(?!.*mobile))/i.test(userAgent);

  // Specific iPad detection (works for both newer and older iPads)
  const isIpad = isTablet && /ipad|macintosh/i.test(userAgent) && 'ontouchend' in document;

  // For iPads running iOS 13+ which report as desktop Safari
  if (isIpad) {
    browserName = 'safari';
    // Try to extract version for iPad
    const match = userAgent.match(/version\/(\d+(\.\d+)?)/i);
    browserVersion = match ? match[1] : 'unknown';
  }
  // Chrome on iOS devices
  else if (/crios/.test(userAgent)) {
    browserName = 'chrome';
    const match = userAgent.match(/crios\/(\d+(\.\d+)?)/i);
    browserVersion = match ? match[1] : 'unknown';
  }
  // Firefox on iOS devices
  else if (/fxios/.test(userAgent)) {
    browserName = 'firefox';
    const match = userAgent.match(/fxios\/(\d+(\.\d+)?)/i);
    browserVersion = match ? match[1] : 'unknown';
  }
  // Standard Chrome
  else if (/chrome/.test(userAgent) && /google inc/.test(vendor) && !/opr\//.test(userAgent)) {
    browserName = 'chrome';
    const match = userAgent.match(/chrome\/(\d+(\.\d+)?)/i);
    browserVersion = match ? match[1] : 'unknown';
  }
  // Firefox
  else if (/firefox/.test(userAgent) && !/seamonkey/.test(userAgent)) {
    browserName = 'firefox';
    const match = userAgent.match(/firefox\/(\d+(\.\d+)?)/i);
    browserVersion = match ? match[1] : 'unknown';
  }
  // Safari (desktop and iOS)
  else if (/safari/.test(userAgent) && /apple computer/.test(vendor)) {
    browserName = 'safari';
    const match = userAgent.match(/version\/(\d+(\.\d+)?)/i);
    browserVersion = match ? match[1] : 'unknown';
  }
  // Edge
  else if (/edg/.test(userAgent)) {
    browserName = 'edge';
    const match = userAgent.match(/edg\/(\d+(\.\d+)?)/i);
    browserVersion = match ? match[1] : 'unknown';
  }
  // Opera
  else if (/opr\//.test(userAgent)) {
    browserName = 'opera';
    const match = userAgent.match(/opr\/(\d+(\.\d+)?)/i);
    browserVersion = match ? match[1] : 'unknown';
  }

  return {
    name: browserName,
    version: browserVersion,
    isMobile,
    isTablet,
    isIpad,
  };
};
