// src/hooks/useChatDetails.ts
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetUserDataQuery } from 'store/apiSlices/login.apiSlice';
import { join, compact, isEmpty } from 'lodash';

interface MediaDevice {
    cameras: string[];
    microphones: string[];
    speakers: string[];
    activeCamera: string | null;
    activeMicrophone: string | null;
    activeSpeaker: string | null;
};

const getMediaDevices = (): Promise<MediaDevice> => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        console.error('Media Devices API not supported in this browser');
        return Promise.reject(new Error('Media Devices API not supported'));
    }

    return navigator.mediaDevices
        .enumerateDevices()
        .then(async (devices) => {
            const cameras: string[] = [];
            const microphones: string[] = [];
            const speakers: string[] = [];

            let activeCamera = '';
            let activeMicrophone = '';
            let activeSpeaker = '';

            devices.forEach((device) => {
                if (device.kind === 'videoinput') {
                    cameras.push(device.label);
                    if (device.deviceId === 'default') {
                        activeCamera = device.label;
                    }
                } else if (device.kind === 'audioinput') {
                    microphones.push(device.label);
                    if (device.deviceId === 'default') {
                        activeMicrophone = device.label;
                    }
                } else if (device.kind === 'audiooutput') {
                    speakers.push(device.label);
                    if (device.deviceId === 'default') {
                        activeSpeaker = device.label;
                    }
                }
            });

            if (isEmpty(activeCamera) && cameras.length > 0) {
                activeCamera = cameras[0] ? cameras[0] : 'No Active Camera Source';
            }
            if (isEmpty(activeMicrophone) && microphones.length > 0) {
                activeMicrophone = microphones[0] ? microphones[0] : 'No Active Mic Source';
            }
            if (isEmpty(activeSpeaker) && speakers.length > 0) {
                activeSpeaker = speakers[0] ? speakers[0] : 'No Active Speaker Source';
            }

            return {
                cameras,
                microphones,
                speakers,
                activeCamera: activeCamera,
                activeMicrophone: activeMicrophone,
                activeSpeaker: activeSpeaker,
            };
        })
        .catch((error) => {
            console.error('Error accessing media devices:', error);
            return {
                cameras: [],
                microphones: [],
                speakers: [],
                activeCamera: 'No Active Camera Source',
                activeMicrophone: 'No Active Mic Source',
                activeSpeaker: 'No Active Speaker Source',
            };
        });
};

const useChatDetails = () => {
    const [userId, setUserId] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [landingScreen, setLandingScreen] = useState('');
    const [activeCamera, setActiveCamera] = useState('');
    const [activeMicrophone, setActiveMicrophone] = useState('');
    const [activeSpeaker, setActiveSpeaker] = useState('');
    const [role, setRole] = useState('');

    const location = useLocation();
    const page = location.pathname;

    const isClassJoinRoute = page.includes("class/join");
    const { data: profileData } = useGetUserDataQuery(undefined, {
        skip: !document.cookie.includes('Token'),
        refetchOnMountOrArgChange: true
    });

    useEffect(() => {
        if (page.includes("/p/")) {
            setRole("Parent");
        } else {
            setRole("Student");
        }
    }, [page]);

    const handleDeviceChange = (
        setActiveCamera: (value: string) => void,
        setActiveMicrophone: (value: string) => void,
        setActiveSpeaker: (value: string) => void
    ) => {
        getMediaDevices().then((mediaDevices) => {
            setActiveCamera(mediaDevices.activeCamera || 'No Active Camera Source');
            setActiveMicrophone(mediaDevices.activeMicrophone || 'No Active Mic Source');
            setActiveSpeaker(mediaDevices.activeSpeaker || 'No Active Speaker Source');
        }).catch((error) => {
            console.error('Failed to get media devices:', error);
        });
    };
    useEffect(() => {
        if (profileData) {
            setName(join(compact([profileData?.data?.firstName, profileData?.data?.lastName]), ' '));
            setEmail(profileData?.data?.email || '');
            setContactNumber(profileData?.data?.mobileNumber || '');
            setUserId(profileData?.data?.id.toString());
        }
        setLandingScreen(location.pathname);
        if (isClassJoinRoute) {
            handleDeviceChange(setActiveCamera, setActiveMicrophone, setActiveSpeaker);
            // Add event listener for device changes
            navigator.mediaDevices.ondevicechange = () => {
                handleDeviceChange(setActiveCamera, setActiveMicrophone, setActiveSpeaker);
            };

        }
        return () => {
            navigator.mediaDevices.ondevicechange = null;
        };
    }, [profileData, location.pathname, isClassJoinRoute]);

    return {
        userId,
        name,
        email,
        contactNumber,
        landingScreen,
        role,
        activeCamera,
        activeMicrophone,
        activeSpeaker,
    };
};

export default useChatDetails;