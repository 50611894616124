import { USER_TYPE } from 'configs/userData';
import { useLocalStorage, useWindowDimensions } from 'hooks';
import { useAppDispatch } from 'hooks/store';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Member, User } from 'types';
import Cookie from 'js-cookie';

import { OnboardingNav } from 'components/common/Navbar';
import ShowParentsSection from 'components/screens/accounts/ShowParentsSection';
import ShowStudentsSection from 'components/screens/accounts/ShowStudentsSection';

import {
  useGetAccountHolderQuery,
  useLoginWithTempTokenMutation,
} from 'store/apiSlices/login.apiSlice';
import { useGetCurrentClassDetailsOfStudentQuery } from 'store/apiSlices/parentDashboard.apiSlice';
import { setLoggedInStudentId, setUserType } from 'store/slice/studentRegister.slice';

import { BouncingDotsLoader, Button, Modal, ModalBottomSheet } from 'components/common';

import { DemoClassCheck } from 'components/screens';
import { PARENT_PATHS, STUDENT_PATHS, CONFIG } from 'configs';
import { isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';
import { Zoho } from 'types/common';

type LoadingButtonType = string;

declare global {
  interface Window {
    $zoho: Zoho['$zoho'];
  }
}

const AccountHolder = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobileScreen } = useWindowDimensions();
  const { removeFromLocalStorage } = useLocalStorage();
  const loginToken = Cookie.get(`${CONFIG.VITE_BASE_DOMAIN_ENV}CoachToken`);

  const [token, setToken] = useState<string>('');
  const [studentId, setStudentId] = useState<number>(0);
  const [user, setUser] = useState<string>('');
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const [loadingButtons, setLoadingButtons] = useState<LoadingButtonType[]>([]);
  const [isProceedEnabled, setIsProceedEnabled] = useState<boolean>(false);
  const [isParentSelected, setIsParentSelected] = useState<boolean>(false);
  const [selectedParentId, setSelectedParentId] = useState<number>(0);
  const [selectedStudentId, setSelectedStudentId] = useState<number>(0);

  // Get account holder data
  const { data, refetch } = useGetAccountHolderQuery(loginToken ?? 'default_value', {
    refetchOnMountOrArgChange: true, //if we added parent on student dashboard and coming back from the dashboard to account to switch, then refetch accounts
  });
  const accountHolderData = data?.data;

  // Login user with tempToken
  const [loginWithTempToken] = useLoginWithTempTokenMutation();

  // get current class of student selected
  const { data: currentClassData, isLoading: isLoadingClass } =
    useGetCurrentClassDetailsOfStudentQuery(
      {
        studentId: studentId?.toString(),
      },
      {
        skip: !token || (!loginToken && user !== USER_TYPE.STUDENT) || !studentId,
      },
    );

  // Filter guardians and students separately
  const studentsList: User[] = [];
  const guardiansList: User[] = [];

  if (Array.isArray(accountHolderData)) {
    accountHolderData.forEach((item: User) => {
      if (item.members.userType === 'student') {
        studentsList.push(item);
      } else if (item.members.userType === 'guardian') {
        guardiansList.push(item);
      }
    });
  }

  // Handle login with tempToken
  const handleClick = async () => {
    const selectedId = selectedParentId || selectedStudentId;
    const userType = isParentSelected ? 'guardian' : 'student';
    removeFromLocalStorage('loginToken');
    if (window.$zoho && window.$zoho.salesiq) {
      if (window.$zoho?.salesiq?.reset) {
        window.$zoho.salesiq.reset();
      }
    }


    setUser(userType);
    dispatch(setUserType(userType));
    if (selectedId && isProceedEnabled) {
      setLoadingButtons([...loadingButtons, `${userType}-${selectedId}`]);
      const userId = {
        memberId: { memberId: selectedId },
      };
      dispatch(setLoggedInStudentId(selectedStudentId.toString()));
      if (userType === USER_TYPE.STUDENT) {
        setStudentId(selectedStudentId);
      }
      try {
        const payload = await loginWithTempToken(userId).unwrap();
        dispatch(setUserType(userType));
        if (userType === USER_TYPE.STUDENT) {
          setToken(payload?.data?.loginToken);
          if (location.state?.from && location.state?.from?.includes('/s')) {
            navigate(location.state?.from);
            window.location.reload();
            return;
          }
        } else {
          if (location.state?.from && !location.state?.from?.includes('/s')) {
            navigate(location.state?.from);
            return;
          }
          navigate(
            PARENT_PATHS.PARENT_DASHBOARD.replace(':studentId', selectedStudentId.toString()),
            {
              replace: true,
            },
          );
          window.location.reload();
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingButtons(
          loadingButtons.filter((buttonId) => buttonId !== `${userType}-${selectedId}`),
        );
      }
    }
  };

  const handleDemoClassBook = useCallback(() => {
    if (currentClassData) {
      if (user === USER_TYPE.STUDENT) {
        if (isEmpty(currentClassData)) {
          setIsOpenModal(true);
        } else {
          navigate(STUDENT_PATHS.STUDENT_DASHBOARD, { replace: true });
        }
      } else if (user === USER_TYPE.GUARDIAN) {
        if (isEmpty(currentClassData)) {
          setIsOpenModal(true);
        } else {
          navigate(PARENT_PATHS.PARENT_DASHBOARD.replace(':studentId', studentId.toString()), {
            replace: true,
          });
        }
      }
    }
  }, [currentClassData, navigate, studentId, user]);

  useEffect(() => {
    if (currentClassData) {
      handleDemoClassBook();
    }
  }, [currentClassData, handleDemoClassBook]);

  // Handle parent select
  const handleParentSelect = (type: string, member: Member) => {
    if (type === 'student') {
      setSelectedStudentId(member?.id);
      setIsProceedEnabled(true);
    } else {
      setSelectedParentId(member?.id);
      setSelectedStudentId(0);
      setIsParentSelected(!isParentSelected);
      setIsProceedEnabled(false);
    }
  };

  // Handle student select
  const handleStudentSelect = (member: Member) => {
    setSelectedStudentId(member?.id);
    setIsParentSelected(false);
    setSelectedParentId(0);
    setIsProceedEnabled(true);
  };

  return (
    <>
      <div className='min-h-screen bg-neutral-25'>
        <OnboardingNav />
        <div className='flex flex-col items-center justify-center mt-10 gap-y-5'>
          {isLoadingClass ? (
            <>
              <div className='flex items-center justify-center flex-1 mx-2 xl:mx-6'>
                <BouncingDotsLoader />
              </div>
            </>
          ) : (
            studentsList.length > 0 && (
              <>
                <div className='container flex flex-col justify-center w-full px-10 mx-auto gap-y-10 sm:px-0 sm:gap-y-0 sm:flex-row'>
                  {/* student section */}
                  <div className='flex flex-col flex-1 gap-y-10'>
                    <div className='text-2xl font-semibold tracking-tight text-center font-sora'>
                      Login as Student
                    </div>
                    <ShowStudentsSection
                      studentsList={studentsList}
                      handleStudentSelect={handleStudentSelect}
                      selectedStudentId={selectedStudentId}
                      isParentSelected={isParentSelected}
                    />
                  </div>
                  {/* divider line */}
                  <div className='w-full border-b-4 border-dotted sm:w-0 sm:border-b-0 sm:border-r-4 border-neutral-200'></div>
                  {/* parent section */}
                  <div className='flex flex-col flex-1 gap-y-10'>
                    <div className='text-2xl font-semibold tracking-tight text-center font-sora'>
                      Login as Parent
                    </div>
                    <ShowParentsSection
                      handleParentSelect={handleParentSelect}
                      guardiansList={guardiansList}
                      studentsList={studentsList}
                      selectedStudentId={selectedStudentId}
                      isParentSelected={isParentSelected}
                      selectedParentId={selectedParentId}
                      refetch={refetch}
                    />
                  </div>
                </div>

                {/* proceed button */}
                <Button
                  text='Proceed'
                  className={`px-32 my-10 font-sora ${isProceedEnabled ? 'bg-primary-500 text-white' : 'bg-white text-black'}`}
                  onClick={() => handleClick()}
                />
              </>
            )
          )}
        </div>

        {isOpenModal && (
          <div>
            {isMobileScreen ? (
              <ModalBottomSheet
                setOpenModal={setIsOpenModal}
                openModal={isOpenModal}
                heading='DEMO CLASS BOOKING'
                showCloseBtn={false}
                children={
                  <div className=''>
                    <DemoClassCheck
                      studentId={currentClassData?.data?.studentDetails?.id || studentId}
                      gradeName={currentClassData?.data?.studentDetails?.gradeName || ''}
                      isDemoClassBooked={(isBooked: boolean) => {
                        if (isBooked) {
                          setIsOpenModal(false);
                          if (user === USER_TYPE.STUDENT) {
                            navigate(STUDENT_PATHS.STUDENT_DASHBOARD, { replace: true });
                          } else {
                            navigate(
                              PARENT_PATHS.PARENT_DASHBOARD.replace(
                                ':studentId',
                                studentId?.toString(),
                              ),
                              {
                                replace: true,
                              },
                            );
                          }
                        }
                      }}
                    />
                  </div>
                }
              />
            ) : (
              <Modal
                heading='DEMO CLASS BOOKING'
                children={
                  <div className=''>
                    <DemoClassCheck
                      studentId={currentClassData?.data?.studentDetails?.id || studentId}
                      gradeName={currentClassData?.data?.studentDetails?.gradeName || ''}
                      isDemoClassBooked={(isBooked: boolean) => {
                        if (isBooked) {
                          setIsOpenModal(false);
                          if (user === USER_TYPE.STUDENT) {
                            navigate(STUDENT_PATHS.STUDENT_DASHBOARD, { replace: true });
                          } else {
                            navigate(
                              PARENT_PATHS.PARENT_DASHBOARD.replace(
                                ':studentId',
                                studentId?.toString(),
                              ),
                              {
                                replace: true,
                              },
                            );
                          }
                        }
                      }}
                    />
                  </div>
                }
                showCloseBtn={false}
                openModal={isOpenModal}
                setOpenModal={setIsOpenModal}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default AccountHolder;
