import React, { useState, useEffect } from 'react';
import { FaChevronDown, FaChevronRight, FaChevronLeft } from 'react-icons/fa6';
import { ExpandedSections, IReportType, SectionName } from 'types/reports';
import MenuIcons from 'components/screens/reports/MenuIcons';
import MainContent from 'components/screens/reports/MainContent';

interface SidebarMenuProps {
  isCollapsed: boolean;
  setIsCollapsed: (value: boolean) => void;
  studentId?: string;
  handleReportSelection: (reportType: IReportType) => void;
  activeReport: Record<IReportType, boolean>;
  segments: { _id: string; name: string }[];
}

const SidebarMenu: React.FC<SidebarMenuProps> = ({
  isCollapsed,
  setIsCollapsed,
  handleReportSelection,
  activeReport,
  segments,
}) => {
  const [expandedSections, setExpandedSections] = useState<ExpandedSections>({
    mathematics: true,
    science: true,
  });
  const [activeSection, setActiveSection] = useState<string>('M');
  const [mobileIsCollapsed, setMobileIsCollapsed] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1024) {
        setMobileIsCollapsed(true);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSection = (section: SectionName) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  return (
    <>
      {/* Mobile/Tablet Header Menu */}
      <div className='lg:hidden fixed top-16 left-0 right-0 z-40 bg-neutral-50 border-b border-gray-200 shadow-md'>
        <div className='flex items-center justify-between px-4 py-2 border-b border-gray-100'>
          <MenuIcons
            setActiveSection={setActiveSection}
            activeSection={activeSection}
            className='flex-1'
          />
          <button
            onClick={() => setMobileIsCollapsed(!mobileIsCollapsed)}
            className='ml-4 p-2 rounded-lg bg-gray-50 hover:bg-gray-100 transition-colors'
          >
            <FaChevronDown
              className={`w-4 h-4 text-gray-600 transform transition-transform duration-300 ${
                mobileIsCollapsed ? '' : 'rotate-180'
              }`}
            />
          </button>
        </div>

        <div
          className={`transition-all duration-300 ease-in-out overflow-hidden ${
            mobileIsCollapsed ? 'max-h-0' : 'max-h-[calc(100vh-9rem)]'
          }`}
        >
          <MainContent
            expandedSections={expandedSections}
            toggleSection={toggleSection}
            handleReportSelection={handleReportSelection}
            activeReport={activeReport}
            segments={segments}
          />
        </div>
      </div>

      {/* Desktop Sidebar */}
      <div className='hidden lg:block fixed left-0 top-16 z-40 h-[calc(100vh-4rem)]'>
        <div
          className={`relative bg-neutral-50 h-full border-r border-gray-200 shadow-xl transition-all duration-300 ease-in-out ${
            isCollapsed ? 'w-14' : 'w-80'
          }`}
        >
          <button
            onClick={() => setIsCollapsed(!isCollapsed)}
            className='absolute top-4 right-4 p-2 bg-indigo-50 text-indigo-600 rounded-lg hover:bg-indigo-100 hover:text-indigo-700'
          >
            {isCollapsed ? (
              <FaChevronRight className='w-4 h-4' />
            ) : (
              <FaChevronLeft className='w-4 h-4' />
            )}
          </button>

          <div
            className={`transition-all duration-300 ease-in-out ${isCollapsed ? 'opacity-100' : 'opacity-0'}`}
          >
            {isCollapsed && (
              <div className='pt-16 px-2'>
                <MenuIcons setActiveSection={setActiveSection} activeSection={activeSection} />
              </div>
            )}
          </div>

          <div
            className={`transition-all duration-300 ease-in-out ${isCollapsed ? 'opacity-0' : 'opacity-100'}`}
          >
            {!isCollapsed && (
              <MainContent
                expandedSections={expandedSections}
                toggleSection={toggleSection}
                handleReportSelection={handleReportSelection}
                activeReport={activeReport}
                segments={segments}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SidebarMenu;
