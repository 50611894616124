import apiSlice from 'store/apiSlices';
import { IStudentStats } from 'types';

const studentStatsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    studentStatsBulk: builder.mutation<void, IStudentStats>({
      query: (body) => ({
        url: `v2/class-summaries/participant-events/bulk`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useStudentStatsBulkMutation } = studentStatsApiSlice;
