import React from 'react';

interface SectionHeaderProps {
  text: string;
  textColor: string;
  backgroundColor: string;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ text, textColor, backgroundColor }) => {
  return (
    <div className={`bg-gradient-to-r ${backgroundColor} bg-opacity-10 p-3 rounded-lg my-6`}>
      <h2 className={`font-sora text-xs font-semibold`} style={{ color: `var(--${textColor})` }}>
        {text}
      </h2>{' '}
    </div>
  );
};

export default SectionHeader;
