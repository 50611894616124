/* eslint-disable react-hooks/exhaustive-deps */
import { BackHeader } from 'components/screens/reports/BackHeader';
import MultipleChaptersCard from 'components/screens/reports/MultipleChapterCard';
import SectionHeader from 'components/screens/reports/SectionHeader';
import {
  ConnectionContext,
  ConnectionContextType,
  useConnectionContext,
} from 'contexts/ConnectionContext';
import { useWindowDimensions } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Chapter, Connection, ExamData } from 'types/reports';
import { ConnectionLines } from './ConnectionLines';
import { RightAngularIcon } from 'assets/svg';
import MobileFooter from 'components/screens/reports/MobileFooter';

const ExamChapters = () => {
  const { examId, studentId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobileScreen } = useWindowDimensions();
  const { examCardRefs, selectedExam, examinations, student } = useConnectionContext();
  const [connections, setConnections] = useState<Connection[]>([]);
  const [svgDimensions, setSvgDimensions] = useState({ width: 0, height: 0 });

  const chaptersRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const isAdminRoute = location.pathname.startsWith('/a');
  const prefix = isAdminRoute ? '/a' : '/p';

  const selectedExamData = examinations?.find((exam) => exam.examId === examId);

  const currentExamCardElement =
    examId && examCardRefs?.current ? examCardRefs.current[examId] ?? null : null;

  useEffect(() => {
    const calculateConnections = () => {
      if (!containerRef.current || !currentExamCardElement || !chaptersRef.current) {
        return;
      }

      const containerRect = containerRef.current.getBoundingClientRect();
      const startRect = currentExamCardElement.getBoundingClientRect();
      const endRect = chaptersRef.current.getBoundingClientRect();

      // Check if elements are actually visible in the DOM
      if (startRect.width === 0 || endRect.width === 0) {
        return;
      }

      const config = {
        curveWidth: 40,
        curveRadius: 30,
        minLineHeight: 30,
      };

      let connection: Connection;

      const startX = startRect.right - containerRect.left;
      const startY = startRect.top + 32 - containerRect.top;
      const endX = endRect.left - containerRect.left;
      const endY = endRect.top + 32 - containerRect.top;

      const yDifference = Math.abs(endY - startY);
      const isStartBelow = startY > endY; // Check against original endY
      const isNearlyHorizontal = yDifference < config.minLineHeight;

      // For nearly horizontal cases, adjust the end point upward
      if (isNearlyHorizontal) {
        // Create straight horizontal line
        connection = {
          startCurve: `M ${startX} ${startY} L ${endX} ${startY}`,
          endCurve: '',
          line: { top: 0, left: 0, height: 0 },
          startDot: { x: startX, y: startY },
          endDot: { x: endX, y: startY },
        };
      } else {
        // Calculate vertical connection with direction awareness
        const verticalDistance = Math.abs(endY - startY);
        const effectiveHeight = Math.max(verticalDistance - config.curveRadius * 2, 0);

        // For start curve, move up if start is below or nearly horizontal
        const startCurve = `
          M ${startX} ${startY}
          H ${startX + config.curveWidth - config.curveRadius}
          Q ${startX + config.curveWidth} ${startY},
            ${startX + config.curveWidth} ${startY + (isStartBelow || isNearlyHorizontal ? -config.curveRadius : config.curveRadius)}
        `;

        // For end curve, approach from below if start is above, from above if start is below or nearly horizontal
        const endCurve = `
          M ${endX - config.curveWidth} ${endY + (isStartBelow || isNearlyHorizontal ? config.curveRadius : -config.curveRadius)}
          Q ${endX - config.curveWidth} ${endY},
            ${endX - config.curveWidth + config.curveRadius} ${endY}
          H ${endX}
        `;

        // Line positioning depends on curve direction and whether points are nearly horizontal
        const line = {
          top: Math.min(
            startY +
              (isStartBelow || isNearlyHorizontal ? -config.curveRadius : config.curveRadius),
            endY + (isStartBelow || isNearlyHorizontal ? config.curveRadius : -config.curveRadius),
          ),
          left: startX + config.curveWidth - 1,
          height: effectiveHeight,
        };

        connection = {
          startCurve,
          endCurve,
          line,
          startDot: { x: startX, y: startY },
          endDot: { x: endX, y: endY },
        };
      }

      setConnections([connection]);
      setSvgDimensions({
        width: containerRect.width,
        height: containerRect.height,
      });
    };

    if (chaptersRef?.current) {
      calculateConnections();

      const observer = new ResizeObserver(() => {
        requestAnimationFrame(calculateConnections);
      });

      if (containerRef.current) observer.observe(containerRef.current);
      if (currentExamCardElement) observer.observe(currentExamCardElement);
      if (chaptersRef.current) observer.observe(chaptersRef.current);

      // Set up event listeners with debouncing
      let scrollTimeout: NodeJS.Timeout;
      let resizeTimeout: NodeJS.Timeout;

      const handleScroll = () => {
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => {
          window.requestAnimationFrame(calculateConnections);
        }, 16); // ~60fps
      };

      const handleResize = () => {
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(() => {
          window.requestAnimationFrame(calculateConnections);
        }, 16);
      };

      window.addEventListener('scroll', handleScroll, { passive: true });
      window.addEventListener('resize', handleResize, { passive: true });

      // Cleanup
      return () => {
        observer.disconnect();
        clearTimeout(scrollTimeout);
        clearTimeout(resizeTimeout);
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [chaptersRef?.current, examId]);

  if (!selectedExamData) return null;
  const formatSubjectsData = (examData: ExamData) => {
    if (!examData?.subjects) return {};
    const formattedData: { [key: string]: Chapter[] } = {};
    examData.subjects.forEach(({ subjectId, subjectName }) => {
      const relatedChapters = examData.chapters.filter(
        (chapter) => chapter.subjectId === subjectId,
      );
      
      // Sort chapters - completed first, then pending
      const sortedChapters = [...relatedChapters]?.sort((a, b) => {
        if (a?.isCompleted && !b?.isCompleted) return -1;
        if (!a?.isCompleted && b?.isCompleted) return 1;
        return 0;
      });

      formattedData[subjectName] = sortedChapters || [];
    });

    return formattedData;
  };

  const isDetailsView = location.pathname.includes('details');
  const subjectsData = formatSubjectsData(selectedExamData);

  const handleChaptersClick = () => {
    navigate(`${prefix}/reports/${studentId}/chapter/exam-status/${examId}/details`);
  };

  // Prepare context value with proper typing
  const contextValue: ConnectionContextType = {
    chaptersRef,
    examCardRefs,
    selectedExam,
    subjectsData,
    examinations,
    student,
  };

  return (
    <ConnectionContext.Provider value={contextValue}>
      <div className='flex flex-col min-w-full md:min-w-fit relative pb-20 md:pb-0'>
        <div className='flex md:gap-20 relative z-0' ref={containerRef}>
          {/* Left Column */}
          <div
            className={`flex flex-col min-w-full md:min-w-fit w-full md:w-[320px] ${
              isDetailsView ? 'hidden md:flex' : ''
            }`}
          >
            {/* Mobile/Desktop Headers */}
            {isMobileScreen ? (
              <BackHeader
                title='Examination Details'
                route={`${prefix}/reports/${studentId}/chapter/exam-status`}
              />
            ) : (
              <div className='hidden md:block'>
                <SectionHeader
                  text={`${selectedExamData?.examName}: List of Chapters`}
                  textColor='purple-700'
                  backgroundColor='from-[#D7BBF3] to-[#EAD7FD]'
                />
              </div>
            )}

            {/* Single MultipleChaptersCard with all subjects */}
            <div className='w-full md:w-[320px] mt-1 md:mt-0' ref={chaptersRef}>
              <MultipleChaptersCard
                subjects={subjectsData}
                onClick={handleChaptersClick}
                className='bg-white hover:shadow-lg transition-shadow'
                isSelected={true}
              />
            </div>
          </div>

          {/* Right Column */}
          <div className='transition-opacity duration-300 w-full'>
            <Outlet />
          </div>
        </div>

        {/* Connection Lines - Only render when both elements are available */}
        <ConnectionLines connections={connections} svgDimensions={svgDimensions} />

        {/* Mobile Footer */}
        {isMobileScreen && !isDetailsView && (
          <MobileFooter
            text={`View ${student?.members?.firstName}'s input for ${selectedExamData?.examName} exam`}
            onClick={handleChaptersClick}
            iconUrl={RightAngularIcon}
          />
        )}
      </div>
    </ConnectionContext.Provider>
  );
};

export default ExamChapters;
