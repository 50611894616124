import React from 'react';
import { MultipleChaptersCardProps } from 'types/reports';
import { GreenBulletIcon, InfoIconBlue, OrangeBulletIcon, RedirectIcon } from 'assets/svg';
import { Tooltip } from 'flowbite-react';
import { CHAPTER_STATUS } from 'configs/reports';

const MultipleChaptersCard: React.FC<MultipleChaptersCardProps> = ({
  subjects,
  className,
  isSelected = true,
  onClick,
}) => {
  return (
    <div
      className={`relative flex flex-col w-full p-3 bg-white cursor-pointer rounded-[12px] shadow-md border-2 ${
        isSelected ? 'border-purple-200' : 'border-transparent'
      } ${className}`}
      onClick={onClick}
    >
      {/* Left Border Highlight */}
      <div
        className={`absolute top-4 bottom-4 left-[-2px] rounded-r ${
          isSelected ? 'w-[4px] bg-purple-400' : 'w-1 bg-purple-400'
        }`}
      ></div>

      <div className='flex justify-between mb-4'>
        <div className='flex items-center gap-2'>
          <h1 className='font-sora font-semibold text-base'>Subject & Chapters</h1>
          <Tooltip
            style='light'
            content={
              <div className='w-full bg-white'>
                <div className='space-y-1'>
                  {CHAPTER_STATUS.map((note) => (
                    <div key={note?.text} className='flex items-start gap-x-2'>
                      <img
                        src={note.highlight ? GreenBulletIcon : OrangeBulletIcon}
                        alt='bullet point'
                        className='w-4 h-4'
                      />
                      <span className='font-sora font-medium text-xs text-neutral-500'>
                        {note.text}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            }
          >
            <img
              src={InfoIconBlue}
              alt='info icon'
              className='ml-2 cursor-pointer'
              width={20}
              height={20}
            />
          </Tooltip>
        </div>
        <img src={RedirectIcon} alt='redirect' width={18} height={18} />
      </div>

      <div className='flex flex-col gap-2'>
        {Object.entries(subjects).map(([subjectName, chapters]) =>
          !chapters?.length ? null : (
            <div key={subjectName} className='flex flex-col gap-1'>
              <div className='flex justify-between items-center mb-2'>
                <h3 className='font-sora font-semibold text-sm text-neutral-700'>{subjectName}</h3>
              </div>
              <div className='flex flex-col gap-2 bg-neutral-50 rounded-[8px] p-1'>
                {chapters.map((chapter, index) => (
                  <div
                    key={`${chapter?.chapterId}-${index}`}
                    className='flex items-start gap-x-2 font-sora font-medium text-sm'
                  >
                    <div className='flex items-start gap-x-2'>
                      {chapter?.isCompleted ? (
                        <>
                          <img src={GreenBulletIcon} alt='bullet point' width={16} height={16} />
                          <span className='text-neutral-500'>{chapter?.chapterName}</span>
                        </>
                      ) : (
                        <>
                          <img src={OrangeBulletIcon} alt='bullet point' width={16} height={16} />
                          <span className='text-neutral-500'>{chapter?.chapterName}</span>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ),
        )}
      </div>
    </div>
  );
};

export default MultipleChaptersCard;
