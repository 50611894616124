import { datadogRum } from '@datadog/browser-rum';
import { Dropdown, Navbar } from 'flowbite-react';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useGetUserDataQuery } from 'store/apiSlices/login.apiSlice';
import { setLoggedInStudentId } from 'store/slice/studentRegister.slice';

import {
  ALlClassesIcon,
  AllClassIconDark,
  Book,
  BookIcon,
  ClosedBook,
  ClosedWhiteBook,
  HomeDarkIcon,
  HomeLightIcon,
  InputDropdownIcon,
  Logo,
  ReportIcon,
  ReportIconBlack,
} from 'assets/svg';
import { Button } from 'components/common';
import { USER_TYPE } from 'configs/userData';
import { useAppDispatch } from 'hooks/store';
import HandleLogout from './HandleLogout';
import Cookie from 'js-cookie';

import { ADMIN_PATHS, CONFIG, PARENT_PATHS, STUDENT_BATCH, STUDENT_PATHS } from 'configs';
import { IDashboardNav, membersList } from 'types';

interface NavItem {
  path: string;
  text: string;
  icon: {
    active: string;
    inactive: string;
  };
}

export default function DashboardNav({ type, parentSiblingsData, batchType }: IDashboardNav) {
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/a');

  /* API => view profile to get the school data */
  const { data: profileData } = useGetUserDataQuery(undefined, {
    refetchOnMountOrArgChange: true,
    skip: isAdminRoute,
  });

  const { studentId: selectedStudentId = '' } = useParams();

  const userType = profileData?.data?.userType;
  const studentId = profileData?.data?.id;

  // Memoize navigation items to prevent recreating on every render
  const navItems = useMemo(() => {
    const items: NavItem[] = [
      {
        path:
          userType === USER_TYPE.STUDENT
            ? STUDENT_PATHS.STUDENT_DASHBOARD
            : PARENT_PATHS.PARENT_DASHBOARD,
        text: 'Home',
        icon: {
          active: HomeLightIcon,
          inactive: HomeDarkIcon,
        },
      },
      {
        path:
          userType === USER_TYPE.STUDENT ? STUDENT_PATHS.STUDENT_CLASSES : PARENT_PATHS.ALLCLASSES,
        text: 'All Classes',
        icon: {
          active: ALlClassesIcon,
          inactive: AllClassIconDark,
        },
      },
      {
        path:
          userType === USER_TYPE.STUDENT
            ? STUDENT_PATHS.STUDENT_PRACTICE
            : PARENT_PATHS.PRACTICE_SHEET,
        text: 'Practice',
        icon: {
          active: Book,
          inactive: BookIcon,
        },
      },
    ];

    if (userType === USER_TYPE.STUDENT) {
      items.push({
        path: STUDENT_PATHS.CLASS_NOTES,
        text: 'Class Notes',
        icon: {
          active: ClosedWhiteBook,
          inactive: ClosedBook,
        },
      });
    }

    return items;
  }, [userType]);

  // Memoize student list processing
  const { studentList, selectedStudentName } = useMemo(() => {
    const list: { label: string; value: number }[] = [];
    let name = '';

    if (Array.isArray(parentSiblingsData?.data)) {
      parentSiblingsData?.data?.forEach((item: membersList) => {
        const studentName = item?.members?.firstName;

        if (item?.members?.userType === USER_TYPE.STUDENT) {
          list.push({ label: studentName, value: item?.members?.id });
        }

        if (
          item?.members?.userType === USER_TYPE.STUDENT &&
          item?.members?.id === Number(selectedStudentId)
        ) {
          name = studentName;
        }
      });
    }

    return { studentList: list, selectedStudentName: name };
  }, [parentSiblingsData?.data, selectedStudentId]);

  useEffect(() => {
    //Set User for datadog RUM

    if (profileData?.data) {
      datadogRum.setUser({
        id: profileData?.data?.id?.toString(),
        name: `${profileData?.data?.firstName} ${profileData?.data?.lastName}`.trim(),
        email: profileData?.data?.email,
        userType: profileData?.data?.userType,
        mobileNumber: profileData?.data?.mobileNumber,
      });
    }
  }, [profileData?.data]);

  const handleStudentChange = (value: string) => {
    dispatch(setLoggedInStudentId(value));
    const basePath = location.pathname.includes('/home')
      ? userType === USER_TYPE.STUDENT
        ? STUDENT_PATHS.STUDENT_DASHBOARD
        : PARENT_PATHS.PARENT_DASHBOARD
      : userType === USER_TYPE.STUDENT
        ? STUDENT_PATHS.STUDENT_CLASSES
        : PARENT_PATHS.ALLCLASSES;

    navigate(
      userType === USER_TYPE.STUDENT
        ? basePath
        : basePath.replace(
            ':studentId',
            value || selectedStudentId?.toString() || studentId?.toString() || '',
          ),
    );
  };

  const handleAdminExit = () => {
    const env = CONFIG.VITE_BASE_DOMAIN_ENV;
    const cookieOptions = env === 'local' ? {} : { domain: '.uprio.com' };

    Cookie.remove(`${env}StudentDetails`, cookieOptions);
    Cookie.remove(`${env}IsImpersonatedUser`, cookieOptions);
    navigate('/');
  };

  const dropDownTheme = {
    floating: {
      item: {
        base: 'flex w-full cursor-pointer items-center justify-start px-4 py-2 text-sm hover:text-white text-gray-700 hover:bg-primary-500 focus:outline-none dark:text-gray-200 dark:hover:bg-primary-500 dark:hover:text-white dark:focus:text-white font-medium rounded-md font-poppins capitalize',
      },
    },
  };

  const isReportsEnabled =
    userType === USER_TYPE.GUARDIAN && selectedStudentId
      ? CONFIG.REPORT_ENABLED_STUDENTS.includes(Number(selectedStudentId))
      : CONFIG.REPORTS_ENABLED;

  return (
    <Navbar
      fluid
      rounded
      theme={{
        root: {
          inner: {
            base: 'mx-2 md:mx-10 flex flex-wrap items-center justify-start lg:justify-between',
          },
          base: 'border-b border-secondary-100 px-0 bg-neutral-50 py-2.5 sticky top-0 z-10',
        },
        toggle: {
          base: 'inline-flex items-center rounded-lg p-2 text-sm lg:hidden',
        },
        collapse: {
          base: 'w-full lg:block lg:w-auto',
          list: 'flex flex-col lg:flex-row lg:items-center md:space-y-4 lg:space-y-0 lg:space-x-8 mt-4 lg:mt-0',
        },
      }}
    >
      {/* {brand} */}
      <Navbar.Toggle />
      <Navbar.Brand className='justify-between flex-1 lg:flex-none'>
        <span className='text-xl font-semibold text-green-800 whitespace-nowrap'>
          <img src={Logo} alt='UPRIO-logo' />
        </span>
        {/* in mobile view ww have to show in nav bar only not on toggle */}
        {!isAdminRoute && (
          <div className='flex flex-row lg:hidden'>
            {userType === USER_TYPE.GUARDIAN && (
              <Dropdown
                label={selectedStudentName}
                renderTrigger={() => (
                  <div className='inline-flex items-center justify-center w-32 text-sm text-gray-700 capitalize bg-white border border-gray-300 rounded-3xl'>
                    <div className='flex items-center justify-center'>
                      <div className='px-[6px] font-poppins font-semibold text-neutral-900 text-xs py-[10px]'>
                        {selectedStudentName}
                      </div>
                      <img className='w-[10px] h-[10px]:' src={InputDropdownIcon} alt='dropdown' />
                    </div>
                  </div>
                )}
                theme={dropDownTheme}
              >
                {studentList?.map((item) => (
                  <Dropdown.Item
                    key={item?.value}
                    onClick={() => handleStudentChange(item?.value.toString())}
                  >
                    {item.label}
                  </Dropdown.Item>
                ))}
              </Dropdown>
            )}
            <HandleLogout profileData={profileData} />
          </div>
        )}
      </Navbar.Brand>

      {/* {links } */}
      <Navbar.Collapse className='lg:flex lg:w-auto'>
        {batchType !== STUDENT_BATCH?.DEMO && !isAdminRoute && (
          <>
            {navItems?.map((item) => {
              const isActive =
                pathname ===
                item?.path?.replace(':studentId', String(selectedStudentId || studentId));

              return (
                <Navbar.Link
                  key={item.text}
                  onClick={() =>
                    navigate(
                      item.path.replace(':studentId', String(selectedStudentId || studentId)),
                    )
                  }
                >
                  <div className='block lg:hidden'>{item.text}</div>
                  <Button
                    text={item.text}
                    icon={isActive ? item?.icon?.active : item?.icon?.inactive}
                    preIcon={true}
                    className={`hidden lg:block text-sm xl:text-lg px-2 rounded-xl body-semibold ${
                      isActive ? 'bg-primary-500 text-white' : 'text-primary-800'
                    }`}
                  />
                </Navbar.Link>
              );
            })}

            {userType === USER_TYPE.GUARDIAN && isReportsEnabled && (
              <Navbar.Link
                onClick={() =>
                  navigate(
                    userType === USER_TYPE.GUARDIAN
                      ? PARENT_PATHS.REPORTS.replace(
                          ':studentId',
                          String(selectedStudentId || studentId),
                        )
                      : '',
                  )
                }
                activeclass='active-link'
              >
                <div className='block lg:hidden'>Reports</div>
                <Button
                  text={'Reports'}
                  icon={
                    pathname.includes(
                      PARENT_PATHS.REPORTS.replace(
                        ':studentId',
                        String(selectedStudentId || studentId),
                      ),
                    )
                      ? ReportIcon
                      : ReportIconBlack
                  }
                  preIcon={true}
                  className={`hidden lg:block text-sm xl:text-lg px-2  body-semibold ${location?.pathname?.includes(userType === USER_TYPE.GUARDIAN ? PARENT_PATHS.REPORTS.replace(':studentId', String(selectedStudentId || studentId)) : '') ? 'bg-primary-500 text-white' : 'text-primary-800'}`}
                />
              </Navbar.Link>
            )}
          </>
        )}

        {isAdminRoute && isReportsEnabled && (
          <>
            <Navbar.Link
              onClick={() =>
                navigate(
                  ADMIN_PATHS.ADMIN_REPORTS.replace(
                    ':studentId',
                    String(selectedStudentId || studentId),
                  ),
                )
              }
              activeclass='active-link'
            >
              <div className='block lg:hidden'>Reports</div>
              <Button
                text={'Reports'}
                icon={
                  pathname.includes(
                    ADMIN_PATHS.ADMIN_REPORTS.replace(
                      ':studentId',
                      String(selectedStudentId || studentId),
                    ),
                  )
                    ? ReportIcon
                    : ReportIconBlack
                }
                preIcon={true}
                className={`hidden lg:block text-sm xl:text-lg px-2  body-semibold ${location?.pathname?.includes(userType === USER_TYPE.GUARDIAN ? ADMIN_PATHS.ADMIN_REPORTS.replace(':studentId', String(selectedStudentId || studentId)) : '') ? 'bg-primary-500 text-white' : 'text-primary-800'}`}
              />
            </Navbar.Link>

            <Button
              text={'Exit'}
              className='px-4 text-sm text-white bg-red-500 xl:text-lg body-semibold'
              onClick={handleAdminExit}
            />
          </>
        )}
      </Navbar.Collapse>

      {/* {icons} */}
      {!isAdminRoute && (
        <div className='navbar-default'>
          <ul className='inline-flex items-center list-none gap-x-2'>
            {type === USER_TYPE.GUARDIAN && (
              <div className='relative hidden lg:block'>
                <Dropdown
                  label={selectedStudentName}
                  renderTrigger={() => (
                    <div className='inline-flex items-center justify-center w-32 text-sm text-gray-700 capitalize bg-white border border-gray-300 cursor-pointer rounded-3xl'>
                      <div className='flex items-center justify-center'>
                        <div className='hidden lg:block px-[6px] font-poppins font-semibold text-neutral-900 text-xs py-[10px]'>
                          {selectedStudentName}
                        </div>
                        <img
                          className='w-[10px] h-[10px]:'
                          src={InputDropdownIcon}
                          alt='dropdown'
                        />
                      </div>
                    </div>
                  )}
                  theme={dropDownTheme}
                >
                  {studentList?.map((item) => (
                    <Dropdown.Item
                      key={item?.value}
                      onClick={() => handleStudentChange(item?.value.toString())}
                    >
                      {item.label}
                    </Dropdown.Item>
                  ))}
                </Dropdown>
              </div>
            )}

            {/* handing logout */}
            <div className='hidden lg:block'>
              <HandleLogout profileData={profileData} />
            </div>
          </ul>
        </div>
      )}
    </Navbar>
  );
}
