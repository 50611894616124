import { IUserDeviceLogResponse, IUserDeviceLogPayload } from 'types';
import apiSlice from '.';
import { datadogLogs } from '@datadog/browser-logs';

const userDeviceLogsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    createOrUpdateUserDeviceLog: build.mutation<
      IUserDeviceLogResponse,
      { classId: number; payload: IUserDeviceLogPayload }
    >({
      query: ({ classId, payload }) => {
        // Validate inputs to prevent API call failures
        if (!classId) {
          throw new Error('Missing classId');
        }
        
        if (!payload || !payload.userId) {
          throw new Error('Invalid payload');
        }
      
        
        return {
          url: `/v2/user-device-logs/${classId}/device-logs`,
          method: 'PUT',
          body: payload,
        };
      },
      // Add error handling for API calls
      async onQueryStarted({ classId, payload }, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          datadogLogs.logger.error('❌ Device logs API call failed', {
            error,
            classId,
            userId: payload.userId
          });
        }
      },
    }),
  }),
});

export const { useCreateOrUpdateUserDeviceLogMutation } = userDeviceLogsApiSlice;
