import { datadogLogs } from '@datadog/browser-logs';
import { useMeeting } from 'hooks';
import { useSetupScreenMediaDevices } from 'hooks/inClass/useSetupScreenMediaDevices';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DeviceErrorStateProps, IPermissionStatus } from 'types';
import DeviceCard from './Modals/DeviceCard';
import { TOGGLE_DEBOUNCE, DEVICE_CHANGE_THROTTLE } from 'configs';

const DeviceErrorState = memo(
  ({ onCameraChange, onMicrophoneChange, className = '' }: DeviceErrorStateProps) => {
    const { studentId, classId } = useMeeting();
    const {
      audioDevices,
      videoDevices,
      speakerDevices,
      currentSelectedDevice,
      handleMediaSelect,
      videoPermissionDenied,
      audioPermissionDenied,
    } = useSetupScreenMediaDevices();

    const { audioStatus, videoStatus, self } = useMeeting();

    // Refs for debouncing and throttling
    const toggleTimeoutRef = useRef<NodeJS.Timeout>();
    const deviceChangeTimeoutRef = useRef<NodeJS.Timeout>();
    const lastDeviceChangeRef = useRef<number>(0);

    // Memoized state to reduce renders
    const [activeDropdown, setActiveDropdown] = useState<
      'Camera' | 'Microphone' | 'Speaker' | null
    >(null);
    const [deviceLoading, setDeviceLoading] = useState<IPermissionStatus>({
      camera: false,
      microphone: false,
      sound: false,
    });

    // Debounced toggle handlers
    const handleAudioToggle = useCallback(async () => {
      if (toggleTimeoutRef.current) {
        clearTimeout(toggleTimeoutRef.current);
      }

      toggleTimeoutRef.current = setTimeout(async () => {
        try {
          if (audioStatus) {
            await self?.disableAudio();
          } else {
            await self?.enableAudio();
          }
        } catch (error) {
          datadogLogs.logger.error('Error toggling audio', { error, studentId, classId });
        }
      }, TOGGLE_DEBOUNCE);
    }, [audioStatus, classId, self, studentId]);

    const handleVideoToggle = useCallback(async () => {
      if (toggleTimeoutRef.current) {
        clearTimeout(toggleTimeoutRef.current);
      }

      // Add a check for in-progress toggle
      if (deviceLoading.camera) return;

      toggleTimeoutRef.current = setTimeout(async () => {
        try {
          setDeviceLoading((prev) => ({ ...prev, camera: true }));

          if (videoStatus) {
            await self?.disableVideo();
          } else {
            await self?.enableVideo();
          }
        } catch (error) {
          datadogLogs.logger.error('Error toggling video', { error, studentId, classId });
        } finally {
          setDeviceLoading((prev) => ({ ...prev, camera: false }));
        }
      }, TOGGLE_DEBOUNCE);
    }, [videoStatus, self, studentId, classId, deviceLoading.camera]);

    // Throttled device change handlers
    const handleDeviceChange = useCallback(
      async (
        deviceId: string,
        type: 'camera' | 'microphone' | 'speaker',
        devices: MediaDeviceInfo[],
        changeCallback: (id: string) => Promise<void>,
      ) => {
        const now = Date.now();
        if (now - lastDeviceChangeRef.current < DEVICE_CHANGE_THROTTLE) {
          if (deviceChangeTimeoutRef.current) {
            clearTimeout(deviceChangeTimeoutRef.current);
          }
          deviceChangeTimeoutRef.current = setTimeout(() => {
            handleDeviceChange(deviceId, type, devices, changeCallback);
          }, DEVICE_CHANGE_THROTTLE);
          return;
        }

        try {
          setDeviceLoading((prev) => ({ ...prev, [type]: true }));
          const device = devices.find((d) => d.deviceId === deviceId);

          if (device) {
            const wasEnabled =
              type === 'camera' ? videoStatus : type === 'microphone' ? audioStatus : true;
            await handleMediaSelect(device);
            await changeCallback(deviceId);

            if (wasEnabled && (type === 'camera' || type === 'microphone')) {
              await self?.[type === 'camera' ? 'enableVideo' : 'enableAudio']();
            }

            datadogLogs.logger.info(`${type} device changed successfully`, {
              deviceId,
              studentId,
              classId,
              deviceLabel: device.label,
              wasEnabled,
            });
          }
        } catch (error) {
          datadogLogs.logger.error(`${type} change failed`, {
            error,
            deviceId,
            classId,
            studentId,
          });
        } finally {
          setDeviceLoading((prev) => ({ ...prev, [type]: false }));
          lastDeviceChangeRef.current = Date.now();
        }
      },
      [handleMediaSelect, videoStatus, audioStatus, self, studentId, classId],
    );

    // Memoized device change handlers
    const handleCameraChange = useCallback(
      (deviceId: string) => handleDeviceChange(deviceId, 'camera', videoDevices, onCameraChange),
      [handleDeviceChange, videoDevices, onCameraChange],
    );

    const handleMicrophoneChange = useCallback(
      (deviceId: string) =>
        handleDeviceChange(deviceId, 'microphone', audioDevices, onMicrophoneChange),
      [handleDeviceChange, audioDevices, onMicrophoneChange],
    );

    const handleSpeakerChange = useCallback(
      (deviceId: string) =>
        handleDeviceChange(deviceId, 'speaker', speakerDevices, async (id) => {
          const speakerDevice = speakerDevices?.find((d) => d?.deviceId === id);
          if (speakerDevice) {
            await self?.setDevice(speakerDevice);
          }
        }),
      [handleDeviceChange, self, speakerDevices],
    );

    // Memoized dropdown toggle
    const handleToggleDropdown = useCallback((type: 'Camera' | 'Microphone' | 'Speaker') => {
      setActiveDropdown((current) => (current === type ? null : type));
    }, []);

    // Memoized device permissions
    const getDevicePermissions = useMemo(
      () => ({
        Camera: {
          hasPermission: !videoPermissionDenied,
          isEnabled: self?.videoEnabled,
          isWorking: !videoPermissionDenied,
        },
        Microphone: {
          hasPermission: !audioPermissionDenied,
          isEnabled: self?.audioEnabled,
          isWorking: !audioPermissionDenied,
        },
        Speaker: {
          hasPermission: !audioPermissionDenied,
          isEnabled: true,
          isWorking: !audioPermissionDenied,
          devices: speakerDevices,
        },
      }),
      [
        videoPermissionDenied,
        self?.videoEnabled,
        self?.audioEnabled,
        audioPermissionDenied,
        speakerDevices,
      ],
    );

    // Cleanup timeouts
    useEffect(() => {
      return () => {
        if (toggleTimeoutRef.current) clearTimeout(toggleTimeoutRef.current);
        if (deviceChangeTimeoutRef.current) clearTimeout(deviceChangeTimeoutRef.current);
      };
    }, []);

    return (
      <div className={`w-full flex-1 h-full font-poppins ${className}`}>
        <div className='flex flex-col items-center justify-between flex-1 h-full'>
          <DeviceCard
            type='Camera'
            {...getDevicePermissions.Camera}
            isDeviceLoading={deviceLoading.camera}
            devices={videoDevices}
            selectedDevice={currentSelectedDevice.video}
            onDeviceChange={handleCameraChange}
            isOpen={activeDropdown === 'Camera'}
            onToggleDropdown={() => handleToggleDropdown('Camera')}
            dropdownPosition={{ placement: 'top' }}
            onToggle={handleVideoToggle}
            isEnabled={self?.videoEnabled}
          />

          <DeviceCard
            type='Microphone'
            {...getDevicePermissions.Microphone}
            isDeviceLoading={deviceLoading.microphone}
            devices={audioDevices}
            selectedDevice={currentSelectedDevice.audio}
            onDeviceChange={handleMicrophoneChange}
            isOpen={activeDropdown === 'Microphone'}
            onToggleDropdown={() => handleToggleDropdown('Microphone')}
            dropdownPosition={{ placement: 'top' }}
            onToggle={handleAudioToggle}
            isEnabled={self?.audioEnabled}
          />

          <DeviceCard
            type='Speaker'
            {...getDevicePermissions.Speaker}
            isDeviceLoading={deviceLoading.sound}
            devices={speakerDevices}
            selectedDevice={currentSelectedDevice.speaker}
            onDeviceChange={handleSpeakerChange}
            isOpen={activeDropdown === 'Speaker'}
            onToggleDropdown={() => handleToggleDropdown('Speaker')}
            dropdownPosition={{ placement: 'top' }}
            isEnabled={true}
          />
        </div>
      </div>
    );
  },
);

DeviceErrorState.displayName = 'DeviceErrorState';

export default DeviceErrorState;
