import {
  BookIcon,
  MockPaperIcon,
  PracticeIcon,
  RevisionEvaluationIcon,
  RevisionIcon,
} from 'assets/svg';
import classNames from 'classnames';
import { RevisionPlans, RevisionTimelineCardProps } from 'types/reports';

const getTagConfig = (tag: string): { icon: React.ReactNode; className: string } => {
  const configs: {
    [key: string]: { icon: React.ReactNode; className: string };
  } = {
    revision_class: {
      icon: <img src={RevisionIcon} alt='revision icon' className='w-[18px] h-[18px]' />,
      className: 'bg-orange-50/50 text-orange-800',
    },
    revision_evaluation_class: {
      icon: (
        <img src={RevisionEvaluationIcon} alt='evaluation icon' className='w-[18px] h-[18px]' />
      ),
      className: 'bg-blue-50/50 text-blue-800',
    },
    mock_paper: {
      icon: <img src={MockPaperIcon} alt='mock paper icon' className='w-[18px] h-[18px]' />,
      className: 'bg-purple-100 text-purple-800',
    },
  };

  return (
    configs[tag] || {
      icon: <img src={BookIcon} alt='book_icon' className='w-4 h-4' />,
      className: 'bg-gray-100 text-gray-800',
    }
  );
};

const Tag: React.FC<{ text: string }> = ({ text }) => {
  const config = getTagConfig(text);
  return (
    <span
      className={classNames(
        'inline-flex items-center gap-1.5 px-3 py-1.5 rounded-full font-poppins text-xs font-semibold',
        config.className,
      )}
    >
      {config.icon}
      {text === 'mock_paper' ? 'Mock Test' : text === 'revision_class' ? 'Revision' : 'Evaluation'}
    </span>
  );
};

const TimelineItem: React.FC<RevisionPlans> = ({
  date,
  chapterName,
  startTime,
  endTime,
  types,
}) => {
  const formattedDate = new Date(date).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  return (
    <div className='py-5 border-b border-neutral-100 last:border-b-0'>
      <div className='text-neutral-300 font-poppins text-xs font-medium pb-3'>{formattedDate}</div>

      <div className='flex items-start gap-3'>
        <div className='flex-1'>
          <div className='flex items-center'>
            <div className='w-[32px] h-[32px] p-[7px] rounded-[50%] border-[1px] border-solid border-neutral-100 mr-[8px]'>
              <img src={PracticeIcon} alt='practice icon' className='w-[18px] h-[18px]' />
            </div>
            <h3 className='font-sora text-sm font-medium tracking-tighter'>{chapterName}</h3>
          </div>

          <div className='flex flex-wrap gap-2 my-3'>
            {types.map((tag, index) => (
              <Tag key={`${tag}-${index}`} text={tag} />
            ))}
          </div>

          <p className=' font-poppins text-xs font-semibold text-neutral-600'>
            {startTime} to {endTime}
          </p>
        </div>
      </div>
    </div>
  );
};

const RevisionTimelineCard: React.FC<RevisionTimelineCardProps> = ({ plans, className }) => {
  if (!plans?.length) {
    return (
      <div
        className={classNames(
          'bg-white rounded-xl p-8 flex flex-col items-center justify-center text-center relative border-[2px] border-semantic-green-200 px-4 before:absolute before:top-3 before:h-[calc(100%-24px)] before:left-0 before:w-1 before:bg-semantic-green-500 before:rounded-r',
          className,
        )}
      >
        <img src={RevisionIcon} alt='No revisions' className='w-16 h-16 mb-4 opacity-50' />
        <h3 className='font-poppins text-lg font-medium text-neutral-800 mb-2'>
          No revision plans yet
        </h3>
        <p className='font-poppins text-sm text-neutral-600'>
          Your upcoming revision plans will appear here once they are scheduled.
        </p>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        'bg-white rounded-xl relative border-[2px] border-semantic-green-200 px-4 before:absolute before:top-3 before:h-[calc(100%-24px)] before:left-0 before:w-1 before:bg-semantic-green-500 before:rounded-r',
        className,
      )}
    >
      {plans.map((plan, index) => (
        <TimelineItem key={index} {...plan} />
      ))}
    </div>
  );
};

export default RevisionTimelineCard;
