import { RightAngularIcon } from 'assets/svg';

interface RevisionPlanButtonProps {
  className?: string;
  onClick?: () => void;
}

const RevisionPlanButton: React.FC<RevisionPlanButtonProps> = ({ className = '', onClick }) => (
  <button
    onClick={onClick}
    className={`py-3 px-4 bg-teal-500 text-white rounded-lg flex gap-2 items-center justify-center hover:bg-teal-400  ${className}`}
  >
    <h1 className='font-sora font-semibold tracking-tight leading-3 text-sm'>
      View PT 2 Revision Plan
    </h1>
    <img
      src={RightAngularIcon}
      alt='Right arrow'
      className='w-5 h-5'
      style={{ filter: 'brightness(0) invert(100)' }}
    />
  </button>
);

export default RevisionPlanButton;
