export const DEVICE_TYPES = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
  IOS: 'iOS',
  IPADOS: 'iPadOS',
  ANDROID: 'Android',
};

export const DEVICE_SOURCE = {
  CAMERA: 'camera',
  MICROPHONE: 'microphone',
  SPEAKER: 'Speaker',
  CAM_MIC: 'camera and microphone',
};

export const DEVICE_SOURCE_MESSAGE = {
  CAM_MIC_MISSING:
    "We've detected issues with both your camera and microphone. Please select different devices.",
  CAM_MISSING: "We've detected an issue with your camera. Please select a different video source.",
  MIC_MISSING:
    "We've detected an issue with your microphone. Please select a different audio source.",
};
