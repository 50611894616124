import { Badge } from 'flowbite-react';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import { useGetCurrentTimeQuery } from 'store/apiSlices/common.apiSlice';
import {
  useGetSiblingParentListDetailsQuery,
  useGetStudentDetailsQuery,
} from 'store/apiSlices/parentDashboard.apiSlice';
import { useGetClassDetailsQuery } from 'store/apiSlices/studentDashboard.apiSlice';
import { selectUserType } from 'store/slice/studentRegister.slice';

import { School } from 'assets/images';
import { Accordian, BouncingDotsLoader, Footer, Loader } from 'components/common';
import { AllClasses, DashboardNav } from 'components/screens';
import { USER_TYPE } from 'configs/userData';
import { useAppSelector } from 'hooks/store';

import { CLASS_STATUS } from 'configs';
import ZohoSalesIQ from 'components/common/ChatBot/initiateZohoWidget';
import useChatDetails from 'components/common/ChatBot/ChatBotDetails';

export default function Classes() {
  const { studentId } = useParams();
  const userType = useAppSelector(selectUserType);

  /* API for getting student details*/
  const { data: studentData, isLoading: isStudentDetailsLoading } = useGetStudentDetailsQuery({
    studentId,
  });
  const studentDetails = studentData?.data;

  /* API to get Current Time */
  const { data: currentTime } = useGetCurrentTimeQuery();

  /* API => get the Upcoming classes data */
  const { data: upcomingClass, isLoading: isUpcomingClassLoading } = useGetClassDetailsQuery({
    filter: {
      meetingEndTime: { $gte: currentTime?.data.currentTime || moment().toISOString() },
      classStatus: { $ne: CLASS_STATUS.ENDED },
      studentId: studentId,
    },
  });
  const upcomingClassData = upcomingClass;

  /* API => get the completed class list */
  const { data: completedClass, isLoading: isCompletedClassLoading } = useGetClassDetailsQuery({
    filter: { classStatus: CLASS_STATUS.ENDED, studentId: studentId },
    sort: {
      meetingEndTime: 'desc',
    },
  });

  // Get Parent Siblings Info
  const { data: parentSiblingsData, isLoading: isParentSiblingsDataLoading } =
    useGetSiblingParentListDetailsQuery(undefined, {
      refetchOnMountOrArgChange: true,
      skip: userType === USER_TYPE.STUDENT,
    });

  const isLoading =
    isUpcomingClassLoading ||
    isCompletedClassLoading ||
    isParentSiblingsDataLoading ||
    isStudentDetailsLoading;

  const chatBotDetails = useChatDetails();

  return (
    <div className='flex flex-col justify-between min-h-screen'>
      <DashboardNav type={userType || USER_TYPE.STUDENT} parentSiblingsData={parentSiblingsData} />
      {isLoading ? (
        <div className='flex items-center justify-center flex-1 mx-2 xl:mx-6'>
          <BouncingDotsLoader />
        </div>
      ) : (
        <div className={`mx-3 flex-1 h-full xl:mx-6 my-10 ${isLoading ? 'h-screen' : ''}`}>
          <div className='relative mb-10 overflow-hidden rounded-2xl bg-neutral-800'>
            <img
              className='object-cover w-full h-64 bg-no-repeat'
              src={studentDetails?.school?.headerImage || School}
              alt='School'
            />
            <div className='absolute inset-0 flex flex-col items-center justify-center gap-2 text-center text-white bg-black bg-opacity-50'>
              <Badge className='inline p-1 px-3 leading-3 rounded-xl text-neutral-800 bg-neutral-25 body-medium'>
                <span className='text-sm capitalize body-medium'>
                  {studentDetails?.firstName}'s school
                </span>
              </Badge>
              <p className='mt-2 text-xl body-semibold lg:mt-1'>{studentDetails?.school?.name}</p>
              <p className='hidden mt-2 body-medium opacity-70 lg:mt-1 md:block'>
                {studentDetails?.school?.address}
              </p>
              <p className='block mt-2 body-medium opacity-70 lg:mt-1 md:hidden'>
                {studentDetails?.school?.cityDetails?.name}
              </p>
            </div>
          </div>

          <div className={userType === USER_TYPE.GUARDIAN ? 'flex flex-col-reverse' : ''}>
            {/* collapseAll true for gaurdian */}
            <Accordian title={'UPCOMING CLASSES'} collapseAll={userType === USER_TYPE.GUARDIAN}>
              {isLoading ? (
                <Loader className='relative' />
              ) : (
                <>{<AllClasses docs={upcomingClassData?.docs || []} />}</>
              )}
            </Accordian>
            {/* collapseAll true for student */}
            <Accordian title={'COMPLETED CLASSES'} collapseAll={userType === USER_TYPE.STUDENT}>
              {isLoading ? (
                <Loader className='relative' />
              ) : (
                <>{<AllClasses docs={completedClass?.docs || []} completedClasses={true} />}</>
              )}
            </Accordian>
          </div>
        </div>
      )}
      <Footer />
      <ZohoSalesIQ
          id={chatBotDetails.userId?.toString() || ''}
          name={chatBotDetails.name}
          email={chatBotDetails.email}
          contactNumber={chatBotDetails.contactNumber}
          role={chatBotDetails.role}
          page={chatBotDetails.landingScreen}
          activeCamera={chatBotDetails.activeCamera}
          activeMicrophone={chatBotDetails.activeMicrophone}
          activeSpeaker={chatBotDetails.activeSpeaker}
        />
    </div>
  );
}
