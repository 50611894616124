export const STUDENT_JOINING_NOTE =
  ' Note: You will be able to join the class 2 minutes before the class';

export const CLASS_NOT_FOUND = 'Class not found or not started yet';

export const SHEET_NOT_FOUND = 'No sheets have been assigned yet 📄';

export const EMPTY_PRACTICE_QUESTION = `🌟 No practice questions at the moment, but hang tight – they're on their way soon! 🚀🙌`;

export const EMPTY_PRACTICE_PAPER = ` 🌟 No practice papers at the moment, but hang tight – they're on their way soon! 🚀🙌`;

/* common data for the class progress */
export const CLASS_PROGRESS = {
  STARTED: 'Started',
  NOT_STARTED: 'NotStarted',
  END: 'End',
};

export const CLASS_STATUS = {
  SCHEDULED: 'scheduled',
  ON_GOING: 'on-going',
  ENDED: 'ended',
};

export const STUDENT_BATCH = {
  DEMO: 'Demo',
  REGUALR: 'Regular',
  PRACTICE: 'Practice',
};

export const TUTOR_STATS = {
  NO_OF_CLASSES_TAKEN: '5000',
  NO_OF_STUDENTS_HANDLED: '1500',
};

export const EVALUATION_STATUS = {
  COMPLETED: 'completed',
  SCHEDULED: 'scheduled',
  EXPIRED: 'expired',
  INPROGRESS: 'on-going',
};

export const EVALUATION_SUBMISSION_STATUS = {
  SUBMITTED: 'submitted',
  CORRECT_SUBMISSION: 'correct_submission',
  NOT_SUBMITTED: 'not_submitted',
  SUBMITTED_WITHIN_DEADLINE: 'submitted_within_deadline',
  SUBMITTED_POST_DEADLINE: 'submitted_post_deadline',
  BLANK_SUBMISSION: 'blank_submission',
  WRONG_SUBMISSION: 'wrong_submission',
  PARTIAL_SUBMISSION: 'partial_submission',
};

export const EVALUATED_STATUS = {
  EVALUATED: 'evaluated',
  NOT_EVALUATED: 'not_evaluated',
};

export const QUESTION_EVALUATION_STATUS = {
  CORRECT: 'correct',
  INCORRECT: 'incorrect',
  SUBMITTED: 'submitted',
  NOT_ATTEMPTED: 'not attempted',
  EVALUATION_IN_PROGRESS: 'evaluation in progress',
  NOT_SUBMITTED: 'not submitted',
};

export const SUBJECTS = {
  MATHS: 'Maths',
  SCIENCE: 'Science',
};

export const NUMBER_OF_SLIDES_TO_SHOW = 3;

export const DUMMY_BOOK_INFORMATION = [
  {
    id: '1',
    name: 'Maths',
    bookName: 'Secondary Mathematics',
    coverImage: 'https://m.media-amazon.com/images/I/91-FpPiNegL._AC_UF1000,1000_QL80_.jpg',
  },
  {
    id: '3',
    name: 'Science',
    bookName: 'NCERT',
    coverImage: 'https://shribalajibooks.com/wp-content/uploads/2024/01/Science-10-800x1026.jpg',
  },
];

export const PARENT_REVIEW_ARRAY = [
  {
    reviewId: '1',
    name: 'Mr. Raghav Yadav',
    childGrade: 'Child is in 4th grade',
    videoReview: '',
    textReview:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat',
  },
  {
    reviewId: '2',
    name: 'Mr. Suresh Yadav',
    childGrade: 'Child is in 4th grade',
    videoReview: 'Child is in 4th grade',
    textReview:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },
  {
    reviewId: '3',
    name: 'Mr. Ram Yadav',
    childGrade: 'Child is in 4th grade',
    videoReview: 'Child is in 4th grade',
    textReview:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },
  {
    reviewId: '4',
    name: 'Mr. Shreyas Yadav',
    childGrade: 'Child is in 4th grade',
    videoReview: 'Child is in 4th grade',
    textReview:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },
  {
    reviewId: '5',
    name: 'Mr. Sachetan Yadav',
    childGrade: 'Child is in 4th grade',
    videoReview: 'Child is in 4th grade',
    textReview:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },
  {
    reviewId: '6',
    name: 'Mr. Vaibhav Yadav',
    childGrade: 'Child is in 4th grade',
    videoReview: 'Child is in 4th grade',
    textReview:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },
];

export const parentRelation = [
  {
    value: 'father',
    label: 'Father',
  },
  {
    value: 'mother',
    label: 'Mother',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const EXAM_SCHEDULE = [
  // {
  //   id: 1,
  //   examName: 'Pre Mids',
  //   date: '19 Jan 2024',
  //   batchType: 'expected',
  //   ExamStatus: 'Expected',
  // },
  {
    id: 2,
    examName: 'Mid Terms',
    date: `3rd week of May`,
    batchType: 'expected',
    ExamStatus: 'Expected',
  },
  {
    id: 3,
    examName: 'Pre Finals',
    date: `3rd week of July`,
    batchType: 'expected',
    ExamStatus: 'Expected',
  },
  {
    id: 4,
    examName: 'Finals',
    date: `3rd week of Sep`,
    batchType: 'expected',
    ExamStatus: 'Expected',
  },
];

export const ABOUT_US_SETTING = {
  arrows: true,
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  centerMode: false,
  initialSlide: 0,
  centerPadding: '0',
  responsive: [
    {
      breakpoint: 1536,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        dots: true,
      },
    },
  ],
};

export const DUMMY_CLASS_FLOW_DATA = [
  {
    segmentId: 31,
    segmentName: 'Introduction to polynomial',
    teachDetails: [
      {
        sequence: 1,
        type: 'teach',
        name: 'Introduction to polynomial',
        id: 133,
        status: 'notStarted',
      },
      {
        sequence: 2,
        type: 'teach',
        name: 'Revision of Whole Numbers',
        id: 134,
        status: 'notStarted',
      },
      {
        sequence: 3,
        type: 'evaluation',
        name: 'poll_evaluations',
        id: 'evaluation id',
        status: 'notStarted',
      },
      {
        sequence: 4,
        type: 'teach',
        name: 'Revision of Exponents',
        id: 135,
        status: 'notStarted',
      },
      {
        sequence: 5,
        type: 'teach',
        name: 'Revision of Exponents',
        id: 135,
        status: 'notStarted',
      },
      {
        sequence: 6,
        type: 'teach',
        name: 'Revision of Exponents',
        id: 135,
        status: 'notStarted',
      },
      {
        sequence: 7,
        type: 'teach',
        name: 'Revision of Exponents',
        id: 135,
        status: 'notStarted',
      },
      {
        sequence: 8,
        type: 'teach',
        name: 'Revision of Exponents',
        id: 135,
        status: 'notStarted',
      },
      {
        sequence: 9,
        type: 'teach',
        name: 'Revision of Exponents',
        id: 135,
        status: 'notStarted',
      },
      {
        sequence: 10,
        type: 'teach',
        name: 'Revision of Exponents',
        id: 135,
        status: 'completed',
      },
    ],
  },
];

export const CLASS_FLOW_COACH_DATA = {
  coach_basic: [
    {
      sequence: 1,
      evaluateAt: 'teach',
      level: 'coach_basic',
      name: 'Basic Q&A',
      status: 'completed',
    },
    {
      sequence: 1,
      evaluateAt: 'teach',
      level: 'coach_basic',
      name: 'Basic Q&A',
      status: 'completed',
    },
  ],
  coach_intermediate: [
    {
      sequence: 1,
      evaluateAt: 'teach',
      level: 'coach_basic',
      name: 'Basic Q&A',
      status: 'completed',
    },
  ],
  coach_advanced: [
    {
      sequence: 1,
      evaluateAt: 'teach',
      level: 'coach_basic',
      name: 'Basic Q&A',
      status: 'completed',
    },
  ],
};

export const RomanNumber = [
  { class: '1', romanValue: 'I' },
  { class: '2', romanValue: 'II' },
  { class: '3', romanValue: 'III' },
  { class: '4', romanValue: 'IV' },
  { class: '5', romanValue: 'V' },
  { class: '6', romanValue: 'VI' },
  { class: '7', romanValue: 'VII' },
  { class: '8', romanValue: 'VIII' },
  { class: '9', romanValue: 'IX' },
  { class: '10', romanValue: 'X' },
  { class: '11', romanValue: 'XI' },
  { class: '12', romanValue: 'XII' },
];

export const CLASS_OVER_BUFFER_TIME = 60;

export const IS_IOS_DEVICE = /iPad|iPhone|iPod/;

export const DRAWER_VARIENTS = {
  open: { transform: 'translateX(0%)', opacity: 1 },
  closed: { transform: 'translateX(100%)', opacity: 0 },
};

export const BADGE_CONTENT = 'Each Class is divided into 2 Phases, Teach Phase and Coach Phase';

export const AI_EVALUATION = {
  IRRELEVENT_IMAGE: 'The provided answer does not match the question. Please submit Again.',
  HEADER: 'You can use your phone to submit your answer to this question',
  SCAN_QRCODE_HEADER: ' Scan the QR code on your phone to submit your answer',
  SCAN_QRCODE_FOOTER: 'Please use your mobile phone to scan this QR code',
};

export const AI_REPORT = [
  {
    evaluationId: 'd609ca46-308d-4da4-ae7b-5d831f9dd0f2',
    questionId: '59a0c056-75a4-410c-a3d3-48c978e217c5',
    studentId: 267,
    aiResponse: {
      result: 'incorrect',
      ai_reason_for_result:
        'The student did not replace * with 1; instead they found 22 divisible by 11 in an incorrect manner.',
      steps: [
        {
          name: 'Label digits by position',
          status: 'correct',
          what_was_the_mistake_in_the_step: '',
        },
        {
          name: 'Find the sum of digits in odd positions',
          status: 'correct',
          what_was_the_mistake_in_the_step: '',
        },
        {
          name: 'Find the sum of digits in even positions',
          status: 'correct',
          what_was_the_mistake_in_the_step: '',
        },
        {
          name: 'Find the difference between the sums',
          status: 'correct',
          what_was_the_mistake_in_the_step: '',
        },
        {
          name: 'Equate the difference to 0 or the nearest multiple of 11',
          status: 'incorrect',
          what_was_the_mistake_in_the_step:
            'The student incorrectly set 23-1 as 22 and then equated it to 11.',
        },
        {
          name: 'Find the required digit',
          status: 'incorrect',
          what_was_the_mistake_in_the_step:
            'The required digit * should be 1 to make the overall number divisible by 11.',
        },
      ],
      responseTime: '0 hours 0 minutes 18 seconds',
    },
    questionCategoryId: 'c7adb061-6dde-4f18-a3e8-b3a7247d4a41',
    questionCategoryName: 'Find Missing Number for divisibility by 11',
    questionDescription:
      'In the following number, replace * by the smallest number to make it divisible by 11: 9*8071',
  },
];

export const CONSTRAINTS_VARIENTS = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
    },
  },
};

export const ITEM_VARIENTS = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

// Create an enum-like object for tab states
export const TAB_STATES = {
  OVERALL: 'OVERALL',
  NOT_SUBMITTED: 'NOT_SUBMITTED',
  SUBMITTED: 'SUBMITTED',
} as const;
