/* eslint-disable react-hooks/exhaustive-deps */
import { BackHeader } from 'components/screens/reports/BackHeader';
import SectionHeader from 'components/screens/reports/SectionHeader';
import { ConnectionContext, useConnectionContext } from 'contexts/ConnectionContext';
import { useWindowDimensions } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Connection, RevisionPlans } from 'types/reports';
import { ConnectionLines } from '../ExamChapters/ConnectionLines';
import { useGetExamChaptersRevisionPlanQuery } from 'store/apiSlices/reports.apiSlice';
import moment from 'moment';
import RevisionTimelineCard from 'components/screens/reports/RevisionTimelineCard';
import { getExamWiseRevisionPlans } from 'configs/studentReport';

const RevisionPlan: React.FC = () => {
  const { examId, studentId } = useParams();
  const context = useConnectionContext();
  const { isMobileScreen } = useWindowDimensions();

  const containerRef = useRef<HTMLDivElement>(null);
  const revisionCardRef = useRef<HTMLDivElement>(null);
  const isAdminRoute = location.pathname.startsWith('/a');
  const prefix = isAdminRoute ? '/a' : '/p';

  const [connections, setConnections] = useState<Connection[]>([]);
  const [svgDimensions, setSvgDimensions] = useState({ width: 0, height: 0 });
  const [plans, setPlans] = useState<RevisionPlans[]>([]);
  const selectedExamData = context?.examinations?.find((exam) => exam.examId === examId);
  const chapterIds = selectedExamData?.chapters
    ?.filter((chapter) => chapter.isCompleted)
    ?.map((chapter) => chapter.chapterId);
  const subjectIds = selectedExamData?.chapters
    ?.filter((chapter) => chapter.isCompleted)
    ?.map((chapter) => chapter.subjectId);

  const { data: revisionPlanData, isLoading } = useGetExamChaptersRevisionPlanQuery(
    {
      studentId: studentId && !isNaN(Number(studentId)) ? Number(studentId) : null,
      subjectIds: subjectIds ? [...new Set(subjectIds)] : null,
      chapterIds: chapterIds ? [...new Set(chapterIds)] : null,
    },
    { skip: !studentId || isNaN(Number(studentId)) || !chapterIds?.length || !subjectIds?.length },
  );

  useEffect(() => {
    const calculateConnections = () => {
      if (!containerRef.current || !context.understandingRef?.current || !revisionCardRef.current) {
        return;
      }

      const containerRect = containerRef.current.getBoundingClientRect();
      const startRect = context.understandingRef?.current.getBoundingClientRect();
      const endRect = revisionCardRef.current.getBoundingClientRect();

      if (startRect.width === 0 || endRect.width === 0) return;

      const config = {
        curveWidth: 40,
        curveRadius: 30,
        minLineHeight: 30,
      };

      const startX = startRect.right - containerRect.left;
      const startY = startRect.top - 40;
      const endX = endRect.left - containerRect.left;
      const endY = endRect.top + endRect.height / 2 - containerRect.top;
      const isStartBelow = startY > endY;
      const isNearlyHorizontal = Math.abs(endY - startY) < config.minLineHeight;

      let connection;

      if (isNearlyHorizontal) {
        // Create straight horizontal line
        connection = {
          startCurve: `M ${startX} ${startY} L ${endX} ${startY}`,
          endCurve: '',
          line: { top: 0, left: 0, height: 0 },
          startDot: { x: startX, y: startY },
          endDot: { x: endX, y: startY },
        };
      } else {
        // Create curved line for vertical connections
        const verticalDistance = Math.abs(endY - startY);
        const effectiveHeight = Math.max(verticalDistance - config.curveRadius * 2, 0);

        const startCurve = `
            M ${startX} ${startY}
            H ${startX + config.curveWidth - config.curveRadius}
            Q ${startX + config.curveWidth} ${startY},
              ${startX + config.curveWidth} ${startY + (isStartBelow ? -config.curveRadius : config.curveRadius)}
          `;

        const endCurve = `
            M ${endX - config.curveWidth} ${endY + (isStartBelow ? config.curveRadius : -config.curveRadius)}
            Q ${endX - config.curveWidth} ${endY},
              ${endX - config.curveWidth + config.curveRadius} ${endY}
            H ${endX}
          `;

        connection = {
          startCurve,
          endCurve,
          line: {
            top: Math.min(
              startY + (isStartBelow ? -config.curveRadius : config.curveRadius),
              endY + (isStartBelow ? config.curveRadius : -config.curveRadius),
            ),
            left: startX + config.curveWidth - 1,
            height: effectiveHeight,
          },
          startDot: { x: startX, y: startY },
          endDot: { x: endX, y: endY },
        };
      }

      setConnections([connection]);
      setSvgDimensions({
        width: containerRect.width,
        height: containerRect.height,
      });
    };

    if (revisionCardRef.current) {
      calculateConnections();

      const observer = new ResizeObserver(() => {
        requestAnimationFrame(calculateConnections);
      });

      if (containerRef.current) observer.observe(containerRef.current);
      if (context.understandingRef?.current) observer.observe(context.understandingRef?.current);
      if (revisionCardRef.current) observer.observe(revisionCardRef.current);

      let scrollTimeout: NodeJS.Timeout;
      let resizeTimeout: NodeJS.Timeout;

      const handleScroll = () => {
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => {
          requestAnimationFrame(calculateConnections);
        }, 16);
      };

      const handleResize = () => {
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(() => {
          requestAnimationFrame(calculateConnections);
        }, 16);
      };

      window.addEventListener('scroll', handleScroll, { passive: true });
      window.addEventListener('resize', handleResize, { passive: true });

      return () => {
        observer.disconnect();
        clearTimeout(scrollTimeout);
        clearTimeout(resizeTimeout);
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [revisionCardRef.current]);

  useEffect(() => {
    if (revisionPlanData?.data?.length && examId) {
      const revsionPlan = revisionPlanData?.data || [];
      const processedPlans: RevisionPlans[] = revsionPlan.map((plan) => {
        const { chapters, type, meetingEndTime, meetingStartTime, date } = plan;
        return {
          types: type,
          startTime: moment(meetingStartTime).format('hh:mm A'),
          endTime: moment(meetingEndTime).format('hh:mm A'),
          date,
          chapterName: chapters?.map((chapter) => chapter.chapterName).join(' + '),
        };
      });
      const examWisePlans = getExamWiseRevisionPlans(context?.examinations, processedPlans);
      const selectedExamPlans = examWisePlans[examId];
      setPlans(selectedExamPlans);
    }
  }, [revisionPlanData]);

  if (!selectedExamData) return null;

  const contextValue = {
    ...context,
    revisionRef: revisionCardRef,
    understandingRef: context.understandingRef,
  };

  const shouldShowConnections = Boolean(context.understandingRef?.current);

  return (
    <ConnectionContext.Provider value={contextValue}>
      <div className='w-full relative' ref={containerRef}>
        <div className='flex md:gap-20 relative z-0'>
          <div className='flex flex-col min-w-full md:min-w-fit w-full md:w-[320px]'>
            <div className='mb-3 md:mb-0'>
              {isMobileScreen ? (
                <BackHeader
                  title={`Revision Timeline for ${selectedExamData?.examName}`}
                  route={`${prefix}/reports/${studentId}/chapter/exam-status/${examId}/details/progress`}
                />
              ) : (
                <SectionHeader
                  text={`Revision Timeline for ${selectedExamData?.examName}`}
                  textColor='semantic-green-800'
                  backgroundColor='from-[#B6E6A0] to-[#CEF1BF]'
                />
              )}
            </div>
            <div className='w-full md:min-w-[385px]' ref={revisionCardRef}>
              {isLoading ? (
                <div className='animate-pulse p-4 w-96 bg-white rounded-2xl shadow-md border border-gray-200'>
                  <div className='h-4 w-24 bg-gray-300 rounded mb-2'></div>
                  <div className='h-6 w-40 bg-gray-400 rounded mb-3'></div>
                  <div className='flex gap-2'>
                    <div className='h-6 w-16 bg-gray-300 rounded'></div>
                    <div className='h-6 w-20 bg-gray-300 rounded'></div>
                  </div>
                  <div className='h-4 w-32 bg-gray-300 rounded mt-3'></div>
                </div>
              ) : (
                <RevisionTimelineCard plans={plans} />
              )}
            </div>
          </div>
        </div>
        {shouldShowConnections && (
          <div className='absolute top-0 left-0 w-full h-full pointer-events-none'>
            <ConnectionLines connections={connections} svgDimensions={svgDimensions} />
          </div>
        )}
      </div>
    </ConnectionContext.Provider>
  );
};

export default RevisionPlan;
